const Increase = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="#242424"
      stroke="#242424"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17 8.5C17.2519 8.5 17.461 8.68677 17.4951 8.92914L17.4999 9.01197C17.4941 9.25855 17.3094 9.46152 17.0709 9.49508L16.9856 9.5H10H9.5V10V17C9.5 17.2519 9.31323 17.461 9.07086 17.4951L8.98803 17.4999C8.74145 17.4941 8.53848 17.3094 8.50492 17.0709L8.5 16.9856V10V9.5H8H1C0.748047 9.5 0.539022 9.31323 0.504918 9.07085L0.500141 8.98803C0.505946 8.74145 0.690621 8.53848 0.929151 8.50492L1.01442 8.5H8H8.5V8V1C8.5 0.748047 8.68677 0.539022 8.92915 0.504918L9.01197 0.500141C9.25855 0.505946 9.46152 0.690621 9.49508 0.929151L9.5 1.01442V8V8.5H10H17Z" />
    </svg>
  );
};
export default Increase;
