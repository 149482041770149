import styled, { css } from 'styled-components';

export const HeaderWrapper = styled.header`
  ${({ theme }) => css`
    position: sticky;
    top: 0;
    background: ${theme.colors.neutral.neutral0};
    display: flex;
    align-items: center;
    height: 5.6rem;
    padding: 0 3.2rem;
    border-bottom: 1px solid ${theme.colors.neutral.neutral30};
    justify-content: center;
    z-index: 1;
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flexbox;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    width: 130rem;

    > div {
      display: flex;
      align-items: center;
      gap: ${theme.spacings.large};
    }
  `}
`;

export const ButtonAjuda = styled.button`
  ${({ theme }) => css`
    border: none;
    font-size: ${theme.font.sizes.medium};
    font-weight: 500;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2.4rem;

    &:hover {
      background: ${theme.colors.neutral.neutral5};
      color: ${theme.colors.neutral.neutral100};
    }
  `}
`;

export const Nav = styled.nav`
  ${({ theme }) => css`
    ul {
      margin-bottom: 0;
      padding: 0;
    }
    li {
      list-style: none;
      font-size: ${theme.font.sizes.xsmall};

      a {
        display: flex;
        color: ${theme.colors.neutral.neutral60};
        text-decoration: none;
        padding: 8px 2.4rem 8px 2.4rem;
        font-weight: 500;
        line-height: 16px;
        &:hover {
          background: ${theme.colors.neutral.neutral5};
          color: ${theme.colors.neutral.neutral100};
        }
      }
    }
  `}
`;
