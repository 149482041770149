import * as S from './styles';
export type SignatureStatusProps = {
  text: React.ReactNode;
  status: 'ativo' | string;
};

export const SignaturesStatus = ({ status, text }: SignatureStatusProps) => {
  return (
    <S.Wrapper status={status}>
      <S.Text>{text}</S.Text>
    </S.Wrapper>
  );
};
