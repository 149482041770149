import * as S from './styles';

type MainBenefitsProps = {
  title: string;
  link: string;
  iconBenefit1: React.ReactNode;
  descriptionBenefit1: string;
  iconBenefit2: React.ReactNode;
  descriptionBenefit2: string;
  iconBenefit3: React.ReactNode;
  descriptionBenefit3: string;
};

export const MainBenefits = ({
  title,
  link,
  iconBenefit1,
  descriptionBenefit1,
  iconBenefit2,
  descriptionBenefit2,
  iconBenefit3,
  descriptionBenefit3,
}: MainBenefitsProps) => {
  return (
    <S.Wrapper>
      <S.WrapperTitle>
        <h2>{title}</h2>
        <a href={link} target="blank">
          Ver todos
        </a>
      </S.WrapperTitle>
      <S.WrapperBenefits>
        <ul>
          <li>
            {iconBenefit1}
            <p>{descriptionBenefit1}</p>
          </li>
          <li>
            {iconBenefit2}
            <p>{descriptionBenefit2}</p>
          </li>
          <li>
            {iconBenefit3}
            <p>{descriptionBenefit3}</p>
          </li>
        </ul>
      </S.WrapperBenefits>
    </S.Wrapper>
  );
};
