import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${() => css`
    justify-content: space-between;
  `}
`;

export const WrapperAdditional = styled.div`
  ${({ theme }) => css`
    ul {
      display: flex;
      grid-template-columns: repeat(3, 1fr);
      margin-top: 2.4rem;
      gap: 2.4rem;
      align-items: center;
      padding: 0;
      li {
        width: 39.8rem;
        padding: 2.4rem;
        display: flex;
        border-radius: ${theme.border.radius};
        border: 1px solid ${theme.colors.neutral.neutral10};
        font-size: ${theme.font.sizes.medium};
        justify-content: space-between;
        > div {
          display: flex;
          gap: 1rem;
        }
      }
      p {
        display: contents;
      }
    }
  `}
`;
