import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  forwardRef,
} from 'react';
import * as S from './styles';
type ButtonTypes =
  | AnchorHTMLAttributes<HTMLAnchorElement>
  | ButtonHTMLAttributes<HTMLButtonElement>;

export type ButtonProps = {
  border?: boolean;
  background?: string;
  borderColor?: string;
  borderHover?: string;
  color?: string;
  size?: string;
  height?: string;
  icon?: React.ReactNode;
  minimal?: boolean;
  hover?: string;
  hoverBackground?: string;
  hoverText?: string;
  margin?: string;
  weight?: string;
  as?: React.ElementType | string;
  onClick?: () => void;
} & ButtonTypes;
const Button: React.ForwardRefRenderFunction<S.WrapperProps, ButtonProps> = (
  {
    border,
    borderColor = '#F82E52',
    borderHover,
    background = '#F82E52',
    color = '#FFFFFF',
    hover = '#DC1840',
    hoverBackground,
    hoverText = '#FFFFFF',
    children,
    icon,
    size,
    height,
    weight = 'bold',
    onClick,
    margin = '1rem',
    minimal = true,
    ...props
  },
  ref,
) => (
  <S.Wrapper
    onClick={onClick}
    size={size}
    height={height}
    hasIcon={!!icon}
    {...props}
    minimal={minimal}
    border={border}
    borderColor={borderColor}
    borderHover={borderHover}
    color={border && color === '#FFFFFF' ? '#F82E52' : color}
    hover={hover}
    hoverBackground={hoverBackground}
    hoverText={hoverText}
    background={background}
    margin={margin}
    weight={weight}
    ref={ref}
  >
    {icon}
    {!!children && <span>{children}</span>}
  </S.Wrapper>
);
export default forwardRef(Button);
