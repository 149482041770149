import { createContext, useCallback, useState } from 'react';
import { api } from 'services/api';

type ChildrenProps = {
  children: React.ReactNode;
};

type lastBoughtProps = {
  date: string | null;
};

type qualificationProps = {
  days_estimated: number | undefined;
  last_bought: lastBoughtProps;
  average_monthy_leads: string;
  balance_premium: number | undefined;
  balance_light: number | undefined;
};

type QualificationContextType = {
  dataQualifierBalance: qualificationProps | null;
  fetchDataQualifierBalance: (id: string) => void;
};

export const QualificationContext = createContext(
  {} as QualificationContextType,
);

export const QualificationProvider = ({ children }: ChildrenProps) => {
  const [dataQualifierBalance, setDataQualifierBalance] =
    useState<qualificationProps | null>(null);

  const accessToken = localStorage.getItem('accessToken');
  const idToken = localStorage.getItem('idToken');

  const fetchDataQualifierBalance = useCallback(async () => {
    try {
      const { data } = await api.get(`qualifier/balance`, {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          idToken,
        },
      });
      setDataQualifierBalance(data.data);
    } catch (error) {
      console.log(error);
    }
  }, [accessToken, idToken]);

  return (
    <QualificationContext.Provider
      value={{ dataQualifierBalance, fetchDataQualifierBalance }}
    >
      {children}
    </QualificationContext.Provider>
  );
};
