const MegaPhone = ({
  color,
  width,
  height,
}: {
  color?: string;
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      width={width || '48'}
      height={height || '48'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9254 6.89772C19.9749 7.06414 20 7.23684 20 7.41046V16.5908C20 17.5849 19.1941 18.3908 18.2 18.3908C18.0263 18.3908 17.8536 18.3657 17.6871 18.3162L13.276 17.0044C12.7502 18.0805 11.6468 18.7994 10.4 18.7994C8.68624 18.7994 7.28714 17.4522 7.20392 15.7591L7.2 15.5994L7.1992 15.1988L5.28712 14.6302C4.52357 14.4033 4 13.7014 4 12.9049V11.0953C4 10.2987 4.52364 9.5968 5.28726 9.36988L17.6873 5.68503C18.6402 5.40185 19.6423 5.94479 19.9254 6.89772ZM8.3992 15.5556L8.4 15.5994C8.4 16.7039 9.29543 17.5994 10.4 17.5994C11.1082 17.5994 11.7427 17.228 12.0991 16.6558L8.3992 15.5556ZM18.0291 6.83532L5.62909 10.5202C5.37455 10.5958 5.2 10.8298 5.2 11.0953V12.9049C5.2 13.1704 5.37452 13.4043 5.62904 13.48L18.029 17.1659C18.0845 17.1824 18.1421 17.1908 18.2 17.1908C18.5314 17.1908 18.8 16.9222 18.8 16.5908V7.41046C18.8 7.35259 18.7916 7.29502 18.7751 7.23954C18.6807 6.9219 18.3467 6.74092 18.0291 6.83532Z"
        fill={color || '#2183D9'}
      />
    </svg>
  );
};
export default MegaPhone;
