import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    p {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.neutral.neutral140};
      font-weight: 600;
    }

    > span {
      font-size: ${theme.font.sizes.xlarge};
      color: ${theme.colors.neutral.neutral120};
      font-weight: 700;
    }

    span + span {
      font-size: ${theme.font.sizes.medium};
      font-weight: 500;
    }
  `}
`;
