import * as Dialog from '@radix-ui/react-dialog';
import * as S from './styles';
import { BoxContent } from 'components/BoxContent';
import Button from 'components/Button';
import { useState } from 'react';
import { BaseModal } from '../BaseModal';
import { theme } from 'styles/theme';
import { ArrowRightNeutral } from 'components/Icons';
import moment from 'moment';

interface IModalProps {
  element?: DailyQualificationTotal;
  item?: DailyQualification;
}

export type DailyQualification = {
  date?: string;
  name_broker: string;
  name_client: string;
};

type DailyQualificationTotal = {
  total: number;
};

export const DetailPackageNewClient = ({ item, element }: IModalProps) => {
  const [showDetails, setShowDetails] = useState(false);

  const getQualified = () => {
    return (
      <>
        <S.Qualify>
          <S.TexTwo_>
            <S.Title color={theme.colors.neutral.neutral80}>
              Investimos na melhor experiência em seus primeiros meses na Kenlo.
              Com a LIA sua imobiliária estará a frente no mercado imobiliário,
              aumentando a eficiência dos seus atendimentos com o foco nos
              clientes mais interessados em fechar negócios.
            </S.Title>
          </S.TexTwo_>
        </S.Qualify>
      </>
    );
  };

  const getTable = () => {
    return (
      <>
        <BoxContent
          color={theme.colors.neutral.neutral10}
          style={{
            padding: ' 1.5rem 2.2rem 1.5rem 2.2rem',
            marginTop: '2.4rem',
          }}
        >
          <S.Row>
            <S.Qualify>
              <S.TextFooter>
                <S.TitleTree color={theme.colors.neutral.neutral130}>
                  Pacote gratuito
                </S.TitleTree>
                <S.TitleTwo color={theme.colors.neutral.neutral120}>
                  {`${element?.total} qualificações`}
                </S.TitleTwo>
              </S.TextFooter>
              <S.TexTree>
                <S.TitleTree color={theme.colors.neutral.neutral130}>
                  Recebido em
                </S.TitleTree>
                <S.Title color={theme.colors.neutral.neutral120}>
                  {moment.parseZone(item?.date).utcOffset(0, true).format('L')}
                </S.Title>
              </S.TexTree>
            </S.Qualify>
          </S.Row>
        </BoxContent>
      </>
    );
  };
  return (
    <>
      <Dialog.Root>
        <Dialog.Trigger asChild>
          <Button margin="1px" onClick={() => setShowDetails(true)}>
            <ArrowRightNeutral />
          </Button>
        </Dialog.Trigger>
        {showDetails && (
          <BaseModal title="Detalhe do pacote">
            {getQualified()}
            {getTable()}
          </BaseModal>
        )}
      </Dialog.Root>
    </>
  );
};
