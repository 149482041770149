import * as S from './styles';

import { useDebounce } from 'hooks/useDebounce';
import { useEffect, useState } from 'react';

type InputEditProps = {
  disabled?: boolean;
  value?: string | number;
  onChange?: (event: any) => void;
  inputValue: number | string;
  decCount: () => void;
  inCount: () => void;
  count: number;
  handleSetCount: (value: any) => void;
};

export const InputEdit = ({ onChange, inputValue, count }: InputEditProps) => {
  const [displayValue, setDisplayValue] = useState(count);
  const deboucedChange = useDebounce(onChange, 1000);

  const handleOnChange = (event: any) => {
    deboucedChange(Number(event.target.value));
    setDisplayValue(Number(event.target.value?.replace(/\D/g, '')));
  };

  useEffect(() => {
    if (Number(inputValue) <= 14 || isNaN(count)) {
      setDisplayValue(15);
    } else {
      setDisplayValue(count);
    }
  }, [inputValue, count]);

  return <S.Input type="text" value={displayValue} onChange={handleOnChange} />;
};
