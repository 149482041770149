import styled, { css } from 'styled-components';

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

export const TitleTree = styled.div<{ color: string }>`
  ${({ theme, color }) => css`
    font-size: ${theme.font.sizes.large};
    font-weight: ${theme.font.normal};
    color: ${color};
  `}
`;

export const ButtonCard = styled.button<{
  color: string;
  fontSize: string;
  colorHover: string;
}>`
  ${({ color, colorHover, fontSize }) => css`
    background-color: transparent;
    border: 2px solid transparent;
    border-radius: 0.8rem;
    padding: 8px 0px;
    color: ${color};
    font-size: ${fontSize};
    font-weight: 700;
    &:hover {
      color: ${colorHover};
    }
  `}
`;

export const TitleTwo = styled.div<{ color: string }>`
  ${({ theme, color }) => css`
    font-size: ${theme.font.sizes.large};
    font-weight: ${theme.font.semiBold};
    color: ${color};
  `}
`;
export const Title = styled.div<{ color: string }>`
  ${({ theme, color }) => css`
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.semiBold};
    color: ${color};
  `}
`;
export const Subtitle = styled.span`
  ${({ theme, color }) => css`
    font-size: ${theme.font.sizes.small};
    color: ${color};
    font-weight: ${theme.font.normal};
    line-height: 24px;
  `}
`;

export const Qualify = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;
export const RowQualify = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
export const RowTable = styled.div`
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: flex-start;
`;
export const RowTwoColumn = styled.div`
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: flex-start;
`;
export const ColOne = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-start;
`;

export const TextOne = styled.div`
  margin-right: 1rem;
  text-align: end;
`;

export const TexTwo = styled.div`
  margin-right: 2rem;
  text-align: start;
  line-height: 40px;
`;

export const TexTree = styled.div`
  margin-right: 1rem;
  text-align: end;
  line-height: 32px;
`;

export const ToolTip = styled.div`
  margin-left: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const BodyTable = styled.div`
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const IconRight = styled.div`
  width: 2.8rem;
  text-align: end;
`;

export const WrapperSelect = styled.select`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.neutral70};
    background-color: ${theme.colors.neutral.neutral5};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 1.6rem 0.8rem;
    gap: 139px;
    border: 0;
    border-radius: 12px;
    width: 95%;
    box-sizing: content-box;
    margin-bottom: 3rem;
    font-size: ${theme.font.sizes.medium};
    background-size: 24px 24px;
    background-position: right 1.75rem center;

    option {
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.neutral.neutral70};
    }
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    font-weight: normal;
    padding: 1rem 0px 0px 1.5rem !important;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    font-weight: 500;
    color: ${theme.colors.neutral.neutral120};
    margin-bottom: 1.6rem;
  `}
`;
