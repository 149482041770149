import { BaseModal } from '../BaseModal';
import * as Dialog from '@radix-ui/react-dialog';
import { useState } from 'react';
import * as S from './styles';
import { IOptionsPayment, PackageProps } from 'pages/BuyPackage';
import { formattedValue } from 'helpers/util';

interface IModalProps {
  borderColor?: string;
  borderHover?: string;
  background?: string;
  hoverBackground?: string;
  hover?: string;
  hoverText?: string;
  colorText?: string;
  el?: PackageProps;
  options?: IOptionsPayment;
  value?: number;
  type?: string;
  titleButton?: string;
  titleButtonConfirm?: string;
  count?: number;
  color?: string;
  colorHover?: string;
  fontSize?: string;
  setIsOpenPaymentOption?: (boolean: boolean) => void;
  isOpenPaymentOption?: boolean;
  onOpen?: () => void;
  onConfirm?: () => void;
  setValue: (value: any) => void;
  onClose?: () => void;
  element?: any;
}

export const PaymentOptions = ({
  element,
  el,
  options,
  colorText,
  setValue,
  value,
  type,
  titleButton = 'Opções de pagamento',
  titleButtonConfirm = 'Salvar opção',
  count,
  onConfirm,
  hover,
  hoverText,
  onOpen,
  color = '#F82E52',
  background,
  hoverBackground,
  borderColor,
  borderHover,
  setIsOpenPaymentOption,
  isOpenPaymentOption,
  colorHover = '#dc1840',
  fontSize = '1.6rem',
}: IModalProps) => {
  const [showDetails, setShowDetails] = useState(false);

  const [paymentOptionsValue, setPaymentOptionsValue] = useState('');

  const getRenewOptions = () => {
    const renewOptions = [];

    for (let i = 1; i <= 5; i++) {
      const option = {
        label: i,
        value: value ? value / i : 0,
      };

      if (options && options.percent_discount) {
        const discountedValue =
          option.value * (1 - options.percent_discount / 100);
        options.final_value = formattedValue(discountedValue);
      }

      renewOptions.push(option);
    }

    return renewOptions;
  };

  const getLastValue = () => {
    const sortedOptions = el?.options_payment?.sort(
      (a, b) =>
        (a?.amount_installment || 0) < (b?.amount_installment || 0)
          ? -1
          : (a?.amount_installment || 0) > (b?.amount_installment || 0)
          ? 1
          : 0,
      // (a?.amount_installment ?? 0) - (b?.amount_installment ?? 0),
    );

    return sortedOptions && sortedOptions[sortedOptions?.length - 1];
  };

  const getPaymentOptionsValue = () => {
    const getForPurchase = () => {
      let sortedOptions = el?.options_payment?.sort(
        (a, b) =>
          (a?.amount_installment || 0) < (b?.amount_installment || 0)
            ? -1
            : (a?.amount_installment || 0) > (b?.amount_installment || 0)
            ? 1
            : 0,
        // (a?.amount_installment ?? 0) - (b?.amount_installment ?? 0),
      );
      if (onConfirm)
        sortedOptions = sortedOptions?.filter(
          (el) => el.amount_installment !== 1,
        );
      return (
        sortedOptions?.map((item) => {
          const renewalOption = getRenewOptions().find(
            (renew) =>
              renew.label.toString() === item.amount_installment?.toString(),
          );

          const optionLabel = `${item.amount_installment}x ${formattedValue(
            item.installment_value,
          )}`;
          const finalLabel =
            item.percent_discount > 0
              ? renewalOption && item.amount_installment === 1
                ? `${optionLabel} no boleto (20% de desconto)`
                : `${optionLabel} no boleto`
              : `${optionLabel} no boleto`;
          return (
            <option key={item.amount_installment} value={JSON.stringify(item)}>
              {finalLabel}
            </option>
          );
        }) || []
      );
    };
    const getForRenew = (el: PackageProps | undefined) => {
      if (!el?.options_payment?.length) return null;

      const renewalOptions: {
        id_qualifier_installment: number;
        name: string;
        value: number;
        percent_discount: number;
        discount_value: number;
        final_value: string;
        amount_installment: number | undefined;
        installment_value: number;
      }[] = [];

      el.options_payment.map((element) => {
        const percentDiscount =
          element.amount_installment === 1 ? element.percent_discount / 100 : 0;
        // const percentDiscount = 0;

        const finalValue =
          count && element.amount_installment
            ? (Number(el.cost_qualifier) * count) / element.amount_installment
            : 0;
        const discountValue = finalValue ? finalValue * percentDiscount : 0;

        const option = {
          id_qualifier_installment: element.id_qualifier_installment,
          name: `${element.amount_installment}x ${formattedValue(
            percentDiscount > 0 ? finalValue - discountValue : finalValue,
          )} no boleto${
            percentDiscount
              ? `( ${element.percent_discount}%  de desconto)`
              : ''
          }`,
          value: percentDiscount > 0 ? finalValue - discountValue : finalValue,
          percent_discount: element.percent_discount,
          discount_value: discountValue,
          final_value: formattedValue(finalValue),
          amount_installment: element.amount_installment,
          installment_value: finalValue,
        };
        renewalOptions.push(option);
      });

      const sortedOptions = renewalOptions.sort((a, b) =>
        (a?.amount_installment || 0) < (b?.amount_installment || 0)
          ? -1
          : (a?.amount_installment || 0) > (b?.amount_installment || 0)
          ? 1
          : 0,
      );
      return sortedOptions.map((option) => (
        <option
          key={option.id_qualifier_installment}
          value={JSON.stringify(option)}
        >
          {option.name}
        </option>
      ));
    };

    const getOptions = () => {
      switch (type) {
        case 'details':
          return getForPurchase();
        case 'renew':
          return getForRenew(el);
        default:
          return getForPurchase();
      }
    };

    return (
      <>
        <S.Description>Você pode escolher o número de parcelas.</S.Description>
        <form>
          <div className="form-floating">
            <S.WrapperSelect
              className="form-select"
              id="floatingSelect"
              aria-label="Floating label select example"
              onChange={(e) => {
                setPaymentOptionsValue(e.target.value);
              }}
              value={
                onConfirm
                  ? paymentOptionsValue || JSON.stringify(getLastValue())
                  : paymentOptionsValue
              }
            >
              {getOptions()}
            </S.WrapperSelect>
            <S.Label htmlFor="floatingSelect">Parcelas</S.Label>
          </div>
        </form>
      </>
    );
  };

  const getModal = () => {
    if (
      (setIsOpenPaymentOption &&
        isOpenPaymentOption &&
        el?.id_qualifier_pack.toString() ===
          element?.id_qualifier_pack.toString()) ||
      showDetails
    ) {
      return (
        <BaseModal
          hover={hover}
          hoverText={hoverText}
          background={background}
          hoverBackground={hoverBackground}
          borderColor={borderColor}
          borderHover={borderHover}
          color={colorText}
          title="Opções de parcelamento"
          cancelLabel="Cancelar"
          confirmLabel={titleButtonConfirm}
          isReturn={!!setIsOpenPaymentOption}
          onClose={() =>
            setIsOpenPaymentOption && setIsOpenPaymentOption(false)
          }
          onCloseAll={() =>
            setIsOpenPaymentOption && setIsOpenPaymentOption(false)
          }
          onCloseX={() =>
            setIsOpenPaymentOption && setIsOpenPaymentOption(false)
          }
          onConfirm={() => {
            onConfirm && onConfirm();
            if (paymentOptionsValue) {
              const parsedValue =
                JSON.parse(paymentOptionsValue) || getLastValue();
              setValue(parsedValue);
              // setLastSelectedValue(parsedValue);
            } else {
              setValue(getLastValue());
            }
            setShowDetails(false);
          }}
          isFooter={true}
          // onClose={() => setShowDetails(false)}
        >
          {getPaymentOptionsValue()}
        </BaseModal>
      );
    }
  };

  return (
    <>
      <Dialog.Root
        open={setIsOpenPaymentOption ? isOpenPaymentOption : undefined}
        // open={true}
      >
        <Dialog.Trigger asChild id={el?.id_qualifier_pack.toString()}>
          {el?.id_qualifier_pack !== 3 && (
            <S.ButtonCard
              onClick={() => {
                setShowDetails(true);
                setIsOpenPaymentOption && setIsOpenPaymentOption(true);
                onOpen && onOpen();
              }}
              color={color}
              fontSize={fontSize}
              colorHover={colorHover}
            >
              {titleButton}
            </S.ButtonCard>
          )}
        </Dialog.Trigger>
        {getModal()}
      </Dialog.Root>
    </>
  );
};
