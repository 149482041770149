import styled, { css, DefaultTheme } from 'styled-components';
import { ButtonProps } from '.';
export type WrapperProps = Pick<
  ButtonProps,
  | 'size'
  | 'minimal'
  | 'border'
  | 'background'
  | 'borderColor'
  | 'borderHover'
  | 'color'
  | 'icon'
  | 'height'
  | 'margin'
  | 'weight'
  | 'hover'
  | 'hoverText'
  | 'hoverBackground'
>;
const wrapperModifiers = {
  withIcon: (theme: DefaultTheme) => css`
    svg {
      fill: #ffffff;
      & + span {
        margin-left: ${theme.spacings.xsmall};
      }
    }
  `,
  withBorder: (theme: DefaultTheme, borderColor: string) => css`
    border: 2px solid ${borderColor};
    background: transparent;
    &:not(:disabled):hover {
      background: ${theme.colors.primary70};
      color: ${theme.colors.neutral.neutral0};
      border: 2px solid ${borderColor};
      transition: all 0.2s ease;
    }
    &[type='submit']:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `,

  withBorderHover: () => css`
    &:hover {
      border: 2px solid #114879 !important;
    }
  `,
  withBackgroundHover: (hoverBackground: string) => css`
    &:not(:disabled):hover {
      background: ${hoverBackground} !important;
    }
  `,
  minimal: (theme: DefaultTheme, margin: any) => css`
    background: none;
    color: ${theme.colors.primary50};
    width: auto;
    padding: 0;
    svg {
      fill: ${theme.colors.primary50};
    }
    span {
      margin-left: ${margin};
      letter-spacing: -0.4px;
      line-height: 24px;
      color: ${theme.colors.primary50};
    }
    &:hover {
      color: ${theme.colors.primary70};
      svg {
        fill: ${theme.colors.primary70};
      }
    }
  `,
};
export const Wrapper = styled.button<WrapperProps>`
  ${({
    border,
    borderColor,
    borderHover,
    color,
    hover,
    hoverText,
    hoverBackground,
    background,
    theme,
    size,
    icon,
    minimal,
    height,
    margin,
    weight,
  }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: ${background};
    color: ${color};
    font-size: ${theme.font.sizes.medium};
    font-weight: ${weight};
    border-radius: ${theme.border.radius};
    border: none;
    height: ${height};
    width: ${size};
    padding: 0 2.4rem;
    text-decoration: none;
    &:not(:disabled):hover {
      background: ${minimal ? 'none' : `${hover}!important`};
      color: ${hoverText}!important;
    }
    ${!!icon && wrapperModifiers.withIcon(theme)}
    ${!!minimal && wrapperModifiers.minimal(theme, margin)}
    ${!!border && wrapperModifiers.withBorder(theme, `${borderColor}`)}
    ${!!borderHover && wrapperModifiers.withBorderHover()}
    ${!!hoverBackground &&
    wrapperModifiers.withBackgroundHover(hoverBackground)}
  `}
`;
