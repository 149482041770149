import styled, { css } from 'styled-components';

import welcome from 'assets/image/welcome.png';

export const Container = styled.div`
  ${() => css`
    display: flex;
    height: 100vh;
    align-items: stretch;
    background-size: auto;
  `}
`;

export const ListRealEstateContent = styled.div`
  ${({ theme }) => css`
    width: 32rem;
    margin-top: 4.8rem;

    > span {
      display: inline-block;
      margin-bottom: 3.2rem;
      font-size: 1.6rem;
      color: ${theme.colors.neutral.neutral140};
    }
  `}
`;

export const LogoContent = styled.div`
  width: 32rem;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 700px;
    background: #fff;

    /* > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 32rem;
      line-height: 2;
    } */

    form {
      margin: 4.8rem 0 3.2rem 0;
      width: 32rem;
      text-align: center;
      justify-content: center;
      position: relative;

      input {
        position: relative;
        width: 100%;
        height: 5.6rem;
        border-radius: 12px;
        border: 0;
        background: #f2f2f2;
        padding: 1.6rem;
        outline: none;
        font-size: 1.6rem;
        color: ${theme.colors.neutral.neutral50};

        & + p + input {
          margin-top: 2rem;
        }
      }
      p {
        margin-top: 0.5rem;
        font-size: 12px;
        text-align: left;
        color: ${theme.colors.primary50};
        transition: all 0.2s ease;
      }

      button {
        color: ${theme.colors.neutral.neutral0};
        margin-top: 2rem;
      }
    }

    h1 {
      font-size: 1.6rem;
      text-align: left;
      font-weight: 600;
    }
  `}
`;

export const WrapperInputShowPassword = styled.div`
  ${() => css`
    display: flex;
    position: relative;
    margin-top: 1.95rem;
  `}
`;

export const WrapperShowPassword = styled.div`
  ${({ theme }) => css`
    position: absolute;
    display: inline-flex;
    top: -0.3rem;
    right: 1.5rem;
    z-index: ${theme.layers.alwaysOnTop};
  `}
`;

export const ShowPassword = styled.button`
  ${() => css`
    cursor: pointer;
    background: none;
    outline: none;
    border: none;
  `}
`;

export const Background = styled.div`
  ${() => css`
    flex: 1;
    background: url(${welcome}) no-repeat center;
    background-size: contain;
    background-position: right;
  `}
`;

export const WrapperLink = styled.span`
  ${({ theme }) => css`
    display: inline-block;
    font-size: 1.6rem;
    color: ${theme.colors.neutral.neutral100};
    margin-top: 3.2rem;
  `}
`;

export const BoxError = styled.h1`
  ${() => css`
  position: absolute;
  align-items: center;
  flexDirection: "column",
  alignItems: "center",
  marginTop: 80,
  `}
`;

export const Footer = styled.footer`
  ${({ theme }) => css`
    display: inline-block;
    align-items: center;
    font-size: 1.6rem;
    color: ${theme.colors.neutral.neutral100};
    margin-top: 3.2rem;

    p {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      ${theme.colors.neutral.neutral70};
      font-size: 12px;
      line-height: 16px;

      span {
        font-size: 12px;
        font-weight: 600;
      }
    }

    .status {
      margin-top: 4rem;
    }

    .link {
      color: #f82e52;
      cursor: pointer;
    }
  `}
`;

export const WrapperBanners = styled.div`
  ${() => css`
    margin-bottom: 0.1rem;
  `}
`;
