import * as S from './styles';

type BenefitProps = {
  icon: React.ReactNode;
  description: string;
};

export const Benefit = ({ description, icon }: BenefitProps) => {
  return (
    <S.Wrapper>
      {icon}
      <span>{description}</span>
    </S.Wrapper>
  );
};
