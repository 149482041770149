import styled, { css } from 'styled-components';

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;
export const Title = styled.div<{ color: string; fontSize?: string }>`
  ${({ theme, color, fontSize }) => css`
    font-size: ${fontSize || theme.font.sizes.large};
    font-weight: ${theme.font.semiBold};
    color: ${color};
  `}
`;
export const Subtitle = styled.span<{ fontSize?: string }>`
  ${({ theme, color, fontSize }) => css`
    font-size: ${fontSize || theme.font.sizes.xsmall};
    color: ${color};
    font-weight: 500;
    line-height: 24px;
  `}
`;

export const Qualify = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;
export const QualifyTable = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;
export const RowQualify = styled.div`
  display: flex;
  width: 30%;
  flex-direction: row;
  justify-content: flex-end;
`;
export const RowTable = styled.div<{ justifyContent?: string }>`
  ${({ justifyContent }) => css`
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: ${justifyContent || 'flex-start'};
  `}
`;
export const RowTwoColumn = styled.div`
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: flex-start;
`;
export const ColOne = styled.div<{ width?: string }>`
  ${({ width }) => css`
    display: flex;
    width: ${width || '50%'};
    justify-content: flex-start;
    align-items: center;
  `}
`;
export const DivIcon = styled.div<{ margin?: string }>`
  ${({ margin }) => css`
    margin-right: ${margin || '1rem'};
  `}
`;

export const TextOne = styled.div`
  margin-right: 1rem;
  align-items: center;
`;
export const ToolTip = styled.div`
  margin-left: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const BodyTable = styled.div`
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 0.5rem; /* width of the entire scrollbar */
  }
  ::-webkit-scrollbar-thumb {
    background-color: #888888; /* color of the scroll thumb */
  }
`;

export const IconRight = styled.div`
  width: 2.8rem;
  text-align: end;
`;
