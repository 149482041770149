import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`


*,
*::after,
*::before{
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; // 10px/16px = 62.5% -> 1rem = 10px
}

/* #root {
  display: inline-block;
  width: ${() =>
    window.location.pathname.toString() === '/' ? '100%' : 'auto'};
} */

body {
  background: ${({ theme }) => theme.colors.neutral.neutral5};
  font-family: ${({ theme }) => theme.font.family};
  -webkit-font-smoothing: antialiased;
}


/* Overwrite css movidesk */
#md-app-widget.md-chat-widget-wrapper {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  @media only screen and (max-width: 1450px) {
    width: 100%;
  }
  height: 85vh;
  display: ${() =>
    window.location.pathname.toString() === '/' ||
    window.location.pathname.toString() === '/Forgot-password-cod' ||
    window.location.pathname.toString() === '/Forgot-password' ||
    window.location.pathname.toString() === '/Auth' ||
    window.location.pathname.toString() === '/New-password'
      ? 'none'
      : 'hidden'} !important;
}
/* Overwrite css movidesk */
.md-chat-widget-btn-container, .RightSide .md-chat-widget-container {
  text-align: right !important;
}


button {
  font-family: ${({ theme }) => theme.font.family};
  cursor: pointer;
}

.hideModal {
  display: none;
}

.showModal {
  display: block;
}

[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}
`;
