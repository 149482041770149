import * as S from './styles';

type UserContentProps = {
  image: string | undefined;
  name: string | null;
  occupation: string | null;
  identifier: string | null;
  text: string;
};

export const UserContent = ({
  image,
  name,
  occupation,
  identifier,
  text,
}: UserContentProps) => {
  return (
    <S.Wrapper>
      <div>
        <img src={image} alt={text} />
        <div className="wrapperUser">
          <strong style={{ overflowWrap: 'anywhere' }}>{name}</strong>
          <span data-testid="occupation identifier">
            {occupation}&bull; {identifier}
          </span>
        </div>
      </div>
      <hr />
    </S.Wrapper>
  );
};
