import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { api } from 'services/api';
import { movideskLogin } from './MovideskLogin';
import { ClientInfoContext } from './ClientInfoContext';

type ChildrenProps = {
  children: React.ReactNode;
};

type dataAgencyProps = {
  id_client: string;
  name: string;
  creci: string;
  cpfcnpj: string;
  arrEmail: Array<string>;
  city: string | undefined;
  state: string;
  status: string;
};

type FeaturesContextType = {
  dataAgency: dataAgencyProps | null;
  fetchDataAgency: (accessToken: string, idToken: string) => void;
  setIdImob: (idImob?: string) => void;
};

export const FeatureContext = createContext({} as FeaturesContextType);

export const FeaturesProvider = ({ children }: ChildrenProps) => {
  const [dataAgency, setDataAgency] = useState<dataAgencyProps | null>(null);
  const [idImob, setIdImob] = useState<string | undefined>();
  const { fetchDataClientInfo } = useContext(ClientInfoContext);

  const accessToken = localStorage.getItem('accessToken');
  const idToken = localStorage.getItem('idToken');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const LoginMovidesk = () => {
    movideskLogin({
      name: dataAgency?.name,
      email: dataAgency?.arrEmail[0],
      codeReference: dataAgency?.id_client,
      organizationCodeReference: idImob,
      stayConnected: true,
      emptySubject: true,
      startChat: true, // Se o startChat for true, o aplicativo irá maximizar e a conversa irá inicializar automaticamente
    });
  };

  const fetchDataAgency = useCallback(
    async (accessToken, idToken) => {
      try {
        const { data } = await api.get('client/features', {
          headers: {
            'content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            idToken,
          },
        });
        setDataAgency(data.data);
        LoginMovidesk();
      } catch (error) {
        console.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idImob],
  );

  useEffect(() => {
    if (accessToken && idToken) {
      fetchDataAgency(accessToken, idToken);
    }
  }, [accessToken, idToken, fetchDataAgency]);

  useEffect(
    () => {
      if (dataAgency) {
        fetchDataClientInfo(dataAgency.id_client);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataAgency],
  );

  return (
    <FeatureContext.Provider value={{ dataAgency, fetchDataAgency, setIdImob }}>
      {children}
    </FeatureContext.Provider>
  );
};
