import { BackButton } from 'components/BackButton';
import { BaseContainer } from 'components/BaseContainer';
import { Heading } from 'components/Heading';
import {
  AlertOn,
  ArrowLeft,
  ArrowTrendingNeutral,
  BatteryCheck,
  CaretDoubleUpEmpty,
  ChatDouble,
  DesktopPulse,
  Gift,
  HexagonCaretDouble,
  PersonClock,
  Robo,
} from 'components/Icons';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { api } from 'services/api';

import * as Dialog from '@radix-ui/react-dialog';
import * as S from './style';

import 'moment/locale/pt-br';
import { Card } from './components/Card';
import { MainHeader } from 'components/MainHeader';
import { BaseMain } from 'components/BaseMain';
// import { PlanCustomContext } from 'contexts/PlanCustomContext';
import { theme } from 'styles/theme';
import { BoxContent } from 'components/BoxContent';
import RadialButtonSelected from 'components/Icons/RadialButtonSelected';
import RadialButton from 'components/Icons/RadialButton';
import { DetailPackageSummary } from 'components/Modals/DetailPackageSummary';
import { PaymentOptions } from 'components/Modals/PaymentOptions';

import { DetailPackageSummaryNewClient } from 'components/Modals/DetailPackageSummaryNewClient';
import { formattedValue } from 'helpers/util';
import { CardNewPackage } from './components/CardNewPackage';
import { QualificationContext } from 'contexts/AvailableQualification';

import moment from 'moment';
import 'moment/locale/pt-br';
import { ClientInfoContext } from 'contexts/ClientInfoContext';
import { BannerPromotion } from 'components/BannerPromotion';
import { DetailPackageSummaryQualifier } from 'components/Modals/DetailPackageSummaryQualifier';

export type PackageProps = {
  installments: number;
  amount_installment: number;
  id_qualifier_pack: number;
  name: string;
  has_bot: string;
  cost_qualifier: number;
  amount_qualifier: number;
  amount_leads: number;
  value: number;
  options_payment: IOptionsPayment[];
  percent_save: number;
  estimated_duration: {
    month: number;
    day: number;
  };
};
export interface IOptionsPayment {
  id_qualifier_installment: number;
  name: string;
  value: number;
  percent_discount: number;
  discount_value: number;
  final_value?: string;
  amount_installment?: number;
  installment_value: number;
}

export const BuyPackage = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [showDetailsBasic, setShowDetailsBasic] = useState(false);

  const [isIcon, setIsIcon] = useState<number>(6);
  const [packageDetails, setPackageDetails] = useState<PackageProps[]>([]);
  const [isOpenPaymentOption, setIsOpenPaymentOption] = useState(false);
  const [isOpenInCash, setIsOpenInCash] = useState(false);
  const [plan, setPlan] = useState<PackageProps>();
  const [value, setValue] = useState<any>();
  const [count, setCount] = useState(15);
  const [flag, setFlag] = useState(true);
  const { id, type } = useParams<{
    id: string;
    type: string;
  }>();

  const { dataQualifierBalance, fetchDataQualifierBalance } =
    useContext(QualificationContext);

  const { dataClientInfo } = useContext(ClientInfoContext);

  useEffect(() => {
    fetchDataQualifierBalance(id);
  }, [id, fetchDataQualifierBalance]);

  const handleIcon = useCallback((id: number) => {
    setIsIcon(id);
  }, []);

  const promotionMock = () => {
    return JSON.parse(process.env.REACT_APP_BANNER ?? '{}');
  };

  const numberOfInstallments = packageDetails.find(
    (plan) => plan.name === 'custom',
  )?.options_payment.length;

  const accessToken = localStorage.getItem('accessToken');
  const idToken = localStorage.getItem('idToken');
  useEffect(() => {
    const fetchDataPackage = async () => {
      try {
        const { data } = await api.get('qualifier/package/available', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            idToken,
          },
        });

        if (Array.isArray(data.data)) {
          setPackageDetails(data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchDataPackage();
  }, [accessToken, idToken]);

  useEffect(() => {
    const getTheMinimumAmountOfLeads = async () => {
      try {
        const { data } = await api.get(`qualifier/plan-lead`, {
          headers: {
            'content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            idToken,
          },
        });
        setCount(data.data.min_lead_quantity);
      } catch (error) {
        console.log(error);
      }
    };
    getTheMinimumAmountOfLeads();
  }, [accessToken, dataClientInfo?.id_realstate, idToken]);

  const setTextEstimatedDur = (el: any): string => {
    if (el?.estimated_duration) {
      let month = Number(el.estimated_duration.month);
      const day = Number(el.estimated_duration.day);

      let txt_dur = month <= 0 ? '' : Number(month) > 1 ? 'meses' : 'mês';

      if (month > 0 && day <= 0) return `${month} ${txt_dur}`;
      if (month > 0 && day > 0) {
        if (day <= 20) return `${month} ${txt_dur} e meio`;
        if (day > 20) {
          month += 1;
          txt_dur = month <= 0 ? '' : Number(month) > 1 ? 'meses' : 'mês';
          return `${month} ${txt_dur}`;
        }
      }
      if (month === 0 && day > 0) {
        return `${day} dia(s)`;
      }
    }
    return '';
  };
  const getFormatValue = (value: any) => {
    if (!value) return value;
    const optionPrice = value?.options_payment?.find(
      (option: any) => option.amount_installment === 1,
    );
    const formattedFinalValue =
      value.name !== 'custom'
        ? optionPrice
          ? formattedValue(optionPrice.final_value)
          : ''
        : formattedValue(
            Number(count) * value.cost_qualifier -
              Number(count) *
                value.cost_qualifier *
                (optionPrice.percent_discount / 100),
          );
    return formattedFinalValue;
  };

  const getValueCount = (el: any) => {
    return calcDiscountCostLead(el) * count;
  };

  const calcDiscountCostLead = (el: any) => {
    const percentDiscount = el.options_payment.find(
      (discount: any) => discount.amount_installment === 1,
    ).percent_discount;
    return el.cost_qualifier - (el.cost_qualifier * percentDiscount) / 100;
  };

  const getValueCountFinal = (el: any) => {
    return count * el.cost_qualifier;
  };

  const getParseValue = (value?: any) => {
    return count * value;
  };

  const handleCountDecrease = () => {
    count > 15 && setCount((c) => c - 1);
  };

  const handleCountIncrease = () => {
    setCount((c) => c + 1);
  };

  const handleSetCount = (e: any) => {
    setCount(e.target.value.replace(/\D/g, ''));
  };

  const filteredPackageElite = packageDetails.filter(
    (el) => el.id_qualifier_pack === 6,
  );

  const filteredPackageDetails = packageDetails.filter(
    (el) => el.id_qualifier_pack !== 3,
  );

  const getPlanName = (planName: string, list: any[]) => {
    switch (planName) {
      case 'Basic': {
        const basic = list.filter((el: any) => el.amount_installment === 2);
        return `até ${basic[0]?.name} de R$ ${basic[0]?.installment_value}`;
      }
      case 'Growth': {
        const growth = list.filter((el: any) => el.amount_installment === 3);
        return `até ${growth[0]?.name} de R$ ${growth[0]?.installment_value}`;
      }
      case 'Elite': {
        const elite = list.filter((el: any) => el.amount_installment === 5);
        return `até ${elite[0]?.name} de R$ ${elite[0]?.installment_value}`;
      }
      case 'custom': {
        const custom = list.filter((el: any) => el.amount_installment === 5);
        return `até ${custom[0]?.name} de R$ ${custom[0]?.installment_value}`;
      }
      default:
        break;
    }
  };
  const getValueInstallments = (el: any) => {
    const sortedOptions = el?.options_payment?.sort((a: any, b: any) =>
      (a?.amount_installment || 0) < (b?.amount_installment || 0)
        ? -1
        : (a?.amount_installment || 0) > (b?.amount_installment || 0)
        ? 1
        : 0,
    );

    if (el.name !== 'custom') {
      return sortedOptions.at(-1).final_value.toString();
    } else {
      return (Number(count) * el.cost_qualifier).toString();
    }
  };
  const SorteedOptions = (el: any) => {
    const sortedOptions = el?.options_payment?.sort((a: any, b: any) =>
      (a?.amount_installment || 0) < (b?.amount_installment || 0)
        ? -1
        : (a?.amount_installment || 0) > (b?.amount_installment || 0)
        ? 1
        : 0,
    );
    return sortedOptions;
  };

  const getPackageCard = (el: any, planName: string) => {
    if (el.name === 'custom' && flag) {
      setCount(el?.amount_qualifier);
      setFlag(false);
    }

    return (
      <Card
        key={el.id_qualifier_pack}
        className={planName === 'Elite' ? 'active' : ''}
        id={`${el?.id_qualifier_pack}`}
        title={el?.name}
        numberOfInstallments={numberOfInstallments}
        subtitle={getPlanName(planName, el.options_payment)}
        descriptionQualification={el?.amount_qualifier}
        percentage={el?.percent_save}
        valueForQualification={`${formattedValue(el?.cost_qualifier)}`}
        valueForQualificationInCash={`${formattedValue(
          el.cost_qualifier -
            (el.cost_qualifier *
              el.options_payment.find(
                (el: { amount_installment: number }) =>
                  el.amount_installment === 1,
              ).percent_discount) /
              100,
        )}`}
        price={getFormatValue(el)}
        promoPrice={promotionMock().price}
        valueInstallments={getValueInstallments(el)}
        final_value={getFormatValue(el)}
        installments={el?.options_payment[0].amount_installment}
        value={`${formattedValue(el?.options_payment[0].installment_value)}`}
        estimated_duration={setTextEstimatedDur(el)}
        handleCountDecrease={handleCountDecrease}
        handleCountIncrease={handleCountIncrease}
        handleSetCount={() => handleSetCount}
        count={count}
        setCount={setCount}
        HandleClick={
          <Dialog.Root>
            <Dialog.Trigger asChild>
              <S.Button
                color={el?.name !== 'Elite' ? '#2183D9' : 'white'}
                borderColor="#2183D9"
                background={el?.name === 'Elite' ? '#2183D9' : 'transparent'}
                isButtuonOutline={el?.name === 'Elite'}
                onClick={() => {
                  setIsOpenInCash(true);
                  handleIcon(el?.id_qualifier_pack);
                  if (el.name !== 'custom') {
                    setPlan(el);
                  } else {
                    setPlan({ ...el, amount_qualifier: count });
                  }

                  if (el.name !== 'custom') {
                    el.options_payment.map((element: any) => {
                      if (element.amount_installment === 1) {
                        setValue(element);
                      }
                    });
                  } else {
                    const renewalOptions = {
                      id_qualifier_installment: el.options_payment.find(
                        (el: { amount_installment: number }) =>
                          el.amount_installment === 1,
                      ).id_qualifier_installment,
                      name: 'À vista',
                      value:
                        Number(count) * el.cost_qualifier -
                        Number(count) *
                          el.cost_qualifier *
                          (el.options_payment.find(
                            (el: { amount_installment: number }) =>
                              el.amount_installment === 1,
                          ).percent_discount /
                            100),
                      percent_discount: el.options_payment.find(
                        (el: { amount_installment: number }) =>
                          el.amount_installment === 1,
                      ).percent_discount,
                      discount_value: 0,
                      final_value:
                        Number(count) * el.cost_qualifier -
                        Number(count) *
                          el.cost_qualifier *
                          (el.options_payment.find(
                            (el: { amount_installment: number }) =>
                              el.amount_installment === 1,
                          ).percent_discount /
                            100),
                      amount_installment: 1,
                      installment_value:
                        Number(count) * el.cost_qualifier -
                        Number(count) *
                          el.cost_qualifier *
                          (el.options_payment.find(
                            (el: { amount_installment: number }) =>
                              el.amount_installment === 1,
                          ).percent_discount /
                            100),
                    };
                    setValue(renewalOptions);
                  }
                }}
              >
                Comprar à vista
              </S.Button>
            </Dialog.Trigger>
            {isOpenInCash && (
              <DetailPackageSummaryQualifier
                amount_leads={el.amount_leads}
                className={
                  Number(isIcon) === plan?.id_qualifier_pack ? 'active' : ''
                }
                onClose={() => setIsOpenInCash(false)}
                name={plan?.name}
                cost_qualifier={plan?.cost_qualifier}
                estimated_duration={Number(plan?.estimated_duration.month)}
                estimated_days={Number(plan?.estimated_duration.day)}
                amount_qualifier={plan?.amount_qualifier}
                discount_twenty_value={getDiscountTwenty()}
                discount_value={getDiscount()}
                percent_discount={
                  el.options_payment.find(
                    (el: { amount_installment: number }) =>
                      el.amount_installment === 1,
                  ).percent_discount
                }
                final_value={getFinalValue()}
                value={
                  el.name !== 'custom'
                    ? SorteedOptions(el).at(-1).final_value.toString()
                    : (Number(count) * el.cost_qualifier).toString()
                }
                valueName={getTextInstallment()}
                amount_installment={getInstallment().toString()}
                id_pack={plan?.id_qualifier_pack.toString()}
                installment_value={Number(getInstallmentValue())}
              />
            )}
          </Dialog.Root>
        }
        HandlePaymentInstallments={
          <>
            {el?.name === 'Basic' && (
              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <S.ButtonInstallments
                    color="#2183D9"
                    onClick={() => {
                      setShowDetailsBasic(true);
                      handleIcon(el?.id_qualifier_pack);

                      if (el.name !== 'custom') {
                        setPlan(el);
                      } else {
                        setPlan({ ...el, amount_qualifier: count });
                      }
                      const sortedOptions = el?.options_payment?.sort(
                        (a: any, b: any) =>
                          (a?.amount_installment || 0) <
                          (b?.amount_installment || 0)
                            ? -1
                            : (a?.amount_installment || 0) >
                              (b?.amount_installment || 0)
                            ? 1
                            : 0,
                      );
                      if (el.name !== 'custom') {
                        setValue(sortedOptions.at(-1));
                      } else {
                        const renewalOptions = {
                          id_qualifier_installment:
                            sortedOptions.at(-1).id_qualifier_installment,
                          name: '5x',
                          value:
                            Number(count) * el.cost_qualifier -
                            Number(count) *
                              el.cost_qualifier *
                              (sortedOptions.at(-1).percent_discount / 100),
                          percent_discount:
                            sortedOptions.at(-1).percent_discount,
                          discount_value: 0,
                          final_value:
                            Number(count) * el.cost_qualifier -
                            Number(count) *
                              el.cost_qualifier *
                              (sortedOptions.at(-1).percent_discount / 100),
                          amount_installment: 5,
                          installment_value:
                            Number(count) * el.cost_qualifier -
                            Number(count) *
                              el.cost_qualifier *
                              (sortedOptions.at(-1).percent_discount / 100),
                        };
                        setValue(renewalOptions);
                      }
                    }}
                  >
                    Comprar parcelado
                  </S.ButtonInstallments>
                </Dialog.Trigger>

                {showDetailsBasic && (
                  <DetailPackageSummaryQualifier
                    amount_leads={el.amount_leads}
                    className={
                      Number(isIcon) === plan?.id_qualifier_pack ? 'active' : ''
                    }
                    onClose={() => false}
                    name={plan?.name}
                    cost_qualifier={plan?.cost_qualifier}
                    estimated_duration={Number(plan?.estimated_duration.month)}
                    estimated_days={Number(plan?.estimated_duration.day)}
                    amount_qualifier={plan?.amount_qualifier}
                    discount_twenty_value={getDiscountTwenty()}
                    discount_value={getDiscount()}
                    final_value={getFinalValue()}
                    value={
                      el.name !== 'custom'
                        ? SorteedOptions(el).at(-1).final_value.toString()
                        : (Number(count) * el.cost_qualifier).toString()
                    }
                    valueName={getTextInstallment()}
                    amount_installment={getInstallment().toString()}
                    id_pack={plan?.id_qualifier_pack.toString()}
                    installment_value={Number(getInstallmentValue())}
                  />
                )}
              </Dialog.Root>
            )}

            {el?.name !== 'Basic' && (
              <>
                <Dialog.Root open={showDetails}>
                  {showDetails &&
                    el.id_qualifier_pack.toString() ===
                      plan?.id_qualifier_pack.toString() && (
                      <DetailPackageSummaryQualifier
                        onReturn={() => {
                          setShowDetails(false);
                        }}
                        titleButton="Voltar"
                        amount_leads={el.amount_leads}
                        className={
                          Number(isIcon) === plan?.id_qualifier_pack
                            ? 'active'
                            : ''
                        }
                        onClose={() => {
                          setShowDetails(false);
                          setIsOpenPaymentOption(false);
                        }}
                        name={plan?.name}
                        cost_qualifier={plan?.cost_qualifier}
                        estimated_duration={Number(
                          plan?.estimated_duration.month,
                        )}
                        estimated_days={Number(plan?.estimated_duration.day)}
                        amount_qualifier={plan?.amount_qualifier}
                        discount_twenty_value={getDiscountTwenty()}
                        discount_value={getDiscount()}
                        final_value={getFinalValue()}
                        value={
                          el.name !== 'custom'
                            ? SorteedOptions(el).at(-1).final_value.toString()
                            : (Number(count) * el.cost_qualifier).toString()
                        }
                        valueName={getTextInstallment()}
                        amount_installment={getInstallment().toString()}
                        id_pack={plan?.id_qualifier_pack.toString()}
                        installment_value={Number(getInstallmentValue())}
                        isInstallment={el?.name === 'custom'}
                      />
                    )}
                </Dialog.Root>
                <PaymentOptions
                  setIsOpenPaymentOption={setIsOpenPaymentOption}
                  isOpenPaymentOption={isOpenPaymentOption}
                  element={el}
                  el={plan}
                  setValue={setValue}
                  value={count * 5}
                  type={type}
                  titleButton="Comprar parcelado"
                  titleButtonConfirm="Continuar"
                  background="#2183D9"
                  hoverBackground="#114879"
                  borderColor="#2183D9"
                  borderHover="#114879"
                  color="#2183D9"
                  colorText="#2183D9"
                  hoverText="#114879"
                  hover="#FFF"
                  colorHover="#114879"
                  fontSize="1.2rem"
                  count={count}
                  onOpen={() => {
                    handleIcon(el?.id_qualifier_pack);
                    if (el.name !== 'custom') {
                      setPlan(el);
                    } else {
                      setPlan({ ...el, amount_qualifier: count });
                    }
                  }}
                  onConfirm={() => {
                    setShowDetails(true);

                    const sortedOptions = el?.options_payment?.sort(
                      (a: any, b: any) =>
                        (a?.amount_installment || 0) <
                        (b?.amount_installment || 0)
                          ? -1
                          : (a?.amount_installment || 0) >
                            (b?.amount_installment || 0)
                          ? 1
                          : 0,
                    );
                    if (el.name !== 'custom') {
                      setValue(sortedOptions.at(-1));
                    }
                  }}
                />
              </>
            )}
          </>
        }
        icon={
          isIcon === el.id_qualifier_pack ? (
            <RadialButtonSelected />
          ) : (
            <RadialButton />
          )
        }
      />
    );
  };

  const getPackageCardNewPackage = (el: any) => {
    return (
      <CardNewPackage
        isPromo={promotionMock()?.showBanner}
        className={isIcon === el.id_qualifier_pack ? 'active' : ''}
        id={`${el?.id_qualifier_pack}`}
        percentage={el?.percent_save}
        valueForQualification={`${formattedValue(el?.cost_qualifier)}`}
        installments={5}
        value={`${getValueCountFinal(el)}`}
        final_value={`${getValueCount(el)}`}
        handleCountDecrease={handleCountDecrease}
        handleCountIncrease={handleCountIncrease}
        handleSetCount={() => handleSetCount}
        count={count}
        setCount={setCount}
        title={''}
        price={`${getValueCountFinal(el)}`}
        estimated_duration={0}
        handleClick={() => {
          handleIcon(el?.id_qualifier_pack);
          setPlan(el);
          setValue(el.options_payment);
        }}
        icon={
          isIcon === el.id_qualifier_pack ? (
            <RadialButtonSelected />
          ) : (
            <RadialButton />
          )
        }
      />
    );
  };

  const showDataLastBought = () => {
    const lastDateBought = dataQualifierBalance?.last_bought?.date;
    if (lastDateBought && moment(lastDateBought).isValid()) {
      return `${moment(dataQualifierBalance?.last_bought?.date).format('L')}`;
    }
    return `Indisponível`;
  };

  const getAside = () => {
    switch (type) {
      case 'renew':
        return (
          <>
            <S.DataDetailsLia>
              <h2>Saldo disponível</h2>
              <span>
                {`${
                  dataQualifierBalance?.balance_premium !== 0
                    ? `${dataQualifierBalance?.balance_premium} qualificações`
                    : `${dataQualifierBalance?.balance_premium} qualificação`
                }`}
              </span>
            </S.DataDetailsLia>

            <S.DataDetailsLia>
              <h2>Última contratação</h2>
              <span>{showDataLastBought()}</span>
            </S.DataDetailsLia>
          </>
        );
      case 'details':
        return (
          <>
            <S.DataDetailsLia>
              <h2>Saldo disponível</h2>
              <span>
                {`${
                  dataQualifierBalance?.balance_premium !== 0
                    ? `${dataQualifierBalance?.balance_premium} qualificações `
                    : `${dataQualifierBalance?.balance_premium} qualificação`
                }`}
              </span>
            </S.DataDetailsLia>

            <S.DataDetailsLia>
              <h2>Duração estimada</h2>
              <span>
                {`${
                  dataQualifierBalance?.days_estimated !== 1
                    ? `${dataQualifierBalance?.days_estimated} dias`
                    : `${dataQualifierBalance?.days_estimated} dia`
                }
                      `}
              </span>
            </S.DataDetailsLia>

            <S.DataDetailsLia>
              <h2>Última contratação</h2>
              <span>{showDataLastBought()}</span>
            </S.DataDetailsLia>

            <S.DataDetailsLia>
              <h2>Média de leads</h2>
              <span>{`${dataQualifierBalance?.average_monthy_leads} leads/mês`}</span>
            </S.DataDetailsLia>
          </>
        );
      default:
        return (
          <>
            <S.DataDetailsLia>
              <h2>Saldo disponível</h2>
              <span>
                {`${dataQualifierBalance?.balance_premium} qualificações`}
              </span>
            </S.DataDetailsLia>

            <S.DataDetailsLia>
              <h2>Duração estimada</h2>
              <span>
                {`${
                  dataQualifierBalance?.days_estimated !== 1
                    ? `${dataQualifierBalance?.days_estimated} dias`
                    : `${dataQualifierBalance?.days_estimated} dia`
                }
                      `}
              </span>
            </S.DataDetailsLia>

            <S.DataDetailsLia>
              <h2>Última contratação</h2>
              <span>{showDataLastBought()}</span>
            </S.DataDetailsLia>

            <S.DataDetailsLia>
              <h2>Média de leads</h2>
              <span>{`${dataQualifierBalance?.average_monthy_leads} leads/mês`}</span>
            </S.DataDetailsLia>
          </>
        );
    }
  };

  const getBodyNewPackage = () => {
    switch (type) {
      case 'renew':
        return (
          <>
            <BoxContent color={'transparent'}>
              <S.TextCustomized
                fontWeight={500}
                color={theme.colors.neutral.neutral40}
                lineHeight={'24px'}
                fontSize="1.5rem"
              >
                Aproveite os valores promocionais de novo cliente por mais{' '}
                <b>
                  {`${dataClientInfo?.daysToTerminateOffer || 0}`}{' '}
                  {`${
                    dataClientInfo?.daysToTerminateOffer &&
                    dataClientInfo?.daysToTerminateOffer > 1
                      ? 'dias'
                      : 'dia'
                  }. `}
                </b>
                Ao fim desse período utilizaremos a média de leads para criar
                pacotes personalizados para atender as demandas da sua
                imobiliária.
              </S.TextCustomized>
            </BoxContent>
            {promotionMock()?.showBanner && (
              <BannerPromotion
                icon={<CaretDoubleUpEmpty />}
                text={`Aproveite o valor promocional de lançamento da LIA até ${
                  promotionMock().dateLimit
                }`}
              >
                Não fique de fora deste lançamento que vai potencializar o seu
                pré-vendas.
              </BannerPromotion>
            )}
            {filteredPackageElite.map((el) => getPackageCardNewPackage(el))}
          </>
        );
      case 'details':
      case 'new':
        return (
          <>
            <BoxContent
              color={'transparent'}
              style={{ display: 'flex', marginBottom: 0 }}
            >
              <div>
                <S.WrapperTitleNewPlan>
                  <Heading
                    title="LIA - Assistente de pré-vendas"
                    weight="700"
                  />
                </S.WrapperTitleNewPlan>
                <S.TextCustomized
                  fontWeight={500}
                  color={theme.colors.neutral.neutral40}
                  lineHeight={'24px'}
                  fontSize="1.5rem"
                >
                  Analisando sua média mensal de leads, a LIA cria planos
                  inteligentes. Compras antecipadas reduzem o preço por lead.
                </S.TextCustomized>
              </div>
              <S.ImageLia />
            </BoxContent>
            {promotionMock()?.showBanner && (
              <BannerPromotion
                icon={<CaretDoubleUpEmpty />}
                text={`Aproveite o valor promocional de lançamento da LIA até ${
                  promotionMock().dateLimit
                }`}
              >
                Não fique de fora deste lançamento que vai potencializar o seu
                pré-vendas.
              </BannerPromotion>
            )}
            <S.DivPLans>
              {filteredPackageDetails.map((el) => getPackageCard(el, el.name))}
            </S.DivPLans>
          </>
        );

      default:
        return (
          <>
            <BoxContent color={'transparent'} style={{ display: 'flex' }}>
              <div>
                <S.WrapperTitleNewPlan>
                  <Heading
                    title="LIA - Assistente de pré-vendas"
                    weight="700"
                  />
                </S.WrapperTitleNewPlan>
                <S.TextCustomized
                  fontWeight={500}
                  color={theme.colors.neutral.neutral40}
                  lineHeight={'24px'}
                  fontSize="1.5rem"
                >
                  Analisando sua média mensal de leads, a LIA cria planos
                  inteligentes. Compras antecipadas reduzem o preço por lead.
                </S.TextCustomized>
              </div>
              <S.ImageLia />
            </BoxContent>
            <S.WrapperBenefit>
              {filteredPackageDetails.map((el) => getPackageCard(el, el.name))};
            </S.WrapperBenefit>
          </>
        );
    }
  };

  const getValue = () => {
    switch (type) {
      case 'details':
      case 'new':
        if (value) {
          if (value.amount_installment !== 1) {
            return `${formattedValue(value.value)}`;
          } else {
            return `${formattedValue(value.final_value)}`;
          }
        } else {
          return getFormatValue(plan);
        }
      case 'renew':
        if (value) {
          if (value.amount_installment && value.amount_installment !== 1) {
            // return `${formattedValue(Number(plan?.cost_qualifier) * count)}`;
            return `${formattedValue(
              Number(plan?.cost_qualifier) * count + value.discount_value,
            )}`;
          } else if (value.amount_installment === 1) {
            // return `${formattedValue(Number(plan?.cost_qualifier) * count)}`;
            return `${formattedValue(
              Number(plan?.cost_qualifier) * count -
                ((Number(plan?.cost_qualifier) * count) / 100) *
                  value.percent_discount,
            )}`;
          } else {
            return `${formattedValue(
              Number(plan?.cost_qualifier) * count -
                Number(plan?.cost_qualifier) * count,
            )}`;
            // return `${formattedValue(
            //   Number(plan?.cost_qualifier) * count -
            //     Number(plan?.cost_qualifier) * count * 0.2,
            // )}`;
          }
        } else {
          return `${formattedValue(count * Number(plan?.cost_qualifier))}`;
        }
      default:
        if (value) {
          if (value.amount_installment !== 1) {
            return `${formattedValue(value.final_value)}`;
          } else {
            return `${formattedValue(value.value)}`;
          }
        } else {
          return getFormatValue(plan);
        }
    }
  };

  const getInstallment = () => {
    switch (type) {
      case 'details':
      case 'new':
        if (value) {
          if (value.amount_installment !== 1) {
            return value.id_qualifier_installment;
          } else {
            return value.id_qualifier_installment;
          }
        } else {
          return 0;
        }
      case 'renew':
        if (value) {
          if (value.amount_installment && value.amount_installment !== 1) {
            return value.id_qualifier_installment;
          } else {
            return value.id_qualifier_installment;
          }
        } else {
          return 0;
        }
      default:
        if (value) {
          if (value.amount_installment !== 1) {
            return value.id_qualifier_installment;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
    }
  };

  const getTextInstallment = () => {
    switch (type) {
      case 'details':
      case 'new':
        if (value) {
          if (value.amount_installment !== 1) {
            return `em ${value.name}`;
          } else {
            return 'à vista';
          }
        } else {
          return 'à vista';
        }
      case 'renew':
        if (value) {
          if (value?.amount_installment && value?.amount_installment !== 1) {
            return `em ${value.amount_installment}x`;
          } else {
            return 'à vista';
          }
        } else {
          return 'à vista';
        }
      default:
        if (value) {
          if (value.amount_installment !== 1) {
            return `em ${value.name}`;
          } else {
            return 'à vista';
          }
        } else {
          return 'à vista';
        }
    }
  };

  const getFinalValue = () => {
    switch (type) {
      case 'details':
      case 'new':
        if (value) {
          if (value.amount_installment !== 1) {
            return value.final_value;
          } else {
            return value.value;
          }
        } else {
          return `${plan}`;
        }
      case 'renew':
        if (value) {
          if (value.amount_installment && value.amount_installment !== 1) {
            return Number(plan?.cost_qualifier) * count;
          } else if (value.amount_installment === 1) {
            return Number(plan?.cost_qualifier) * count - value.discount_value;
          } else {
            return Number(plan?.cost_qualifier) * count;
            // return (
            //   Number(plan?.cost_qualifier) * count -
            //   Number(plan?.cost_qualifier) * count * 0.2
            // );
          }
        } else {
          return count * 2;
          // return count * 2.5;
        }
      default:
        if (value) {
          if (value.amount_installment !== 1) {
            return `${value.final_value}`;
          } else {
            return value.value;
          }
        } else {
          return plan;
        }
    }
  };

  const baseCost =
    packageDetails?.find((el: any) => el?.id_qualifier_pack === 3)
      ?.cost_qualifier || 0;

  const fullCosts = () => {
    if (value) {
      if (value?.id_qualifier_pack && value?.id_qualifier_pack === 3) {
        return 0;
      } else if (value?.id_qualifier_pack !== 1) {
        return Number(baseCost) * Number(plan?.amount_qualifier);
      }
    } else {
      return Number(value);
    }
  };

  const getDiscountTwenty = () => {
    switch (type) {
      case 'renew':
        if (value) {
          value.amount_installment && value.amount_installment === 1;
          return Number(plan?.cost_qualifier) * count;
          // return Number(plan?.cost_qualifier) * count * 0.2;
        } else {
          // return count * 2.5;
          return count * 2;
        }
      case 'details':
      case 'new':
        if (value) {
          if (value?.amount_installment && value?.amount_installment !== 1) {
            return value?.value - Number(fullCosts());
          } else if (value?.amount_installment === 1) {
            return value?.value - Number(fullCosts());
          } else {
            return 0;
          }
        } else {
          return 0;
        }
    }
  };

  const getDiscount = () => {
    switch (type) {
      case 'details':
      case 'new':
        if (value) {
          if (value.amount_installment && value.amount_installment !== 1) {
            return 0;
          } else if (value.amount_installment === 1) {
            return value.discount_value;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      case 'renew':
        if (value) {
          if (value.amount_installment === 1) {
            // return count * 2.5;
            return count * 2;
          } else {
            // return count * 2.5;
            return count * 2;
          }
        } else {
          // return count * 2.5;
          return count * 2;
        }
      default:
        if (value) {
          if (value.amount_installment !== 1) {
            return 0;
          } else {
            return value.discount_value;
          }
        }
    }
  };

  const getInstallmentValue = (el?: any) => {
    switch (type) {
      case 'details':
      case 'new':
        if (value) {
          if (value.amount_installment && value.amount_installment !== 1) {
            return value.installment_value;
          } else if (value.amount_installment === 1) {
            return 0;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      case 'renew':
        if (value) {
          if (value.amount_installment && value.amount_installment !== 1) {
            // return (count * 2.5) / value.amount_installment;
            return (count * el) / value.amount_installment;
          } else if (value.amount_installment === 1) {
            return 0;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      default:
        if (value) {
          if (value.amount_installment !== 1) {
            return 0;
          } else {
            return value.discount_value;
          }
        }
    }
  };

  const getButtonConfirmValue = () => {
    switch (type) {
      case 'renew':
        return (
          <>
            <DetailPackageSummaryNewClient
              className={
                Number(isIcon) === plan?.id_qualifier_pack ? 'active' : ''
              }
              onClose={() => false}
              name={plan?.name}
              cost_qualifier={packageDetails[0]?.cost_qualifier}
              estimated_duration={Number(plan?.estimated_duration.month)}
              estimated_days={Number(plan?.estimated_duration.day)}
              amount_qualifier={count}
              discount_value={
                Number(
                  getInstallmentValue(filteredPackageElite[0]?.cost_qualifier),
                ) > 0
                  ? 0
                  : filteredPackageElite[0]?.options_payment.filter(
                      (el) => el.amount_installment === 1,
                    )[0].percent_discount
              }
              discount_newClient_value={getDiscountTwenty()}
              final_value={parseInt(`${getFinalValue()}`)}
              value={`${getParseValue(
                filteredPackageElite[0]?.cost_qualifier,
              )}`}
              valueName={getTextInstallment()}
              valueForQualification={filteredPackageElite[0]?.cost_qualifier}
              amount_leads={count}
              amount_installment={getInstallment()}
              id={Number(plan?.id_qualifier_pack)}
              installment_value={Number(
                getInstallmentValue(filteredPackageElite[0]?.cost_qualifier),
              )}
            />
          </>
        );
      case 'details':
      case 'new':
        return (
          <>
            <DetailPackageSummary
              className={
                Number(isIcon) === plan?.id_qualifier_pack ? 'active' : ''
              }
              onClose={() => false}
              name={plan?.name}
              cost_qualifier={plan?.cost_qualifier}
              estimated_duration={Number(plan?.estimated_duration.month)}
              estimated_days={Number(plan?.estimated_duration.day)}
              amount_qualifier={plan?.amount_qualifier}
              discount_twenty_value={getDiscountTwenty()}
              discount_value={getDiscount()}
              final_value={getFinalValue()}
              value={Number(fullCosts())}
              valueName={getTextInstallment()}
              amount_installment={getInstallment().toString()}
              id_pack={plan?.id_qualifier_pack.toString()}
              installment_value={Number(getInstallmentValue())}
            />
          </>
        );
      default:
        return (
          <>
            <Dialog.Root>
              <Dialog.Trigger asChild>
                <DetailPackageSummary
                  className={
                    Number(isIcon) === plan?.id_qualifier_pack ? 'active' : ''
                  }
                  onClose={() => false}
                  name={plan?.name}
                  cost_qualifier={plan?.cost_qualifier}
                  estimated_duration={Number(plan?.estimated_duration.month)}
                  estimated_days={Number(plan?.estimated_duration.day)}
                  amount_qualifier={plan?.amount_qualifier}
                  discount_twenty_value={getDiscountTwenty()}
                  discount_value={getDiscount()}
                  final_value={getFinalValue()}
                  value={Number(fullCosts())}
                  valueName={getTextInstallment()}
                  amount_installment={getInstallment().toString()}
                  id_pack={plan?.id_qualifier_pack.toString()}
                  installment_value={Number(getInstallmentValue())}
                />
              </Dialog.Trigger>
            </Dialog.Root>
          </>
        );
    }
  };

  useEffect(() => {
    const planDefaultPage = packageDetails.find(
      (element) => element.id_qualifier_pack === 6,
    );

    if (planDefaultPage) {
      handleIcon(planDefaultPage?.id_qualifier_pack);
      setPlan(planDefaultPage);
      planDefaultPage.options_payment.map((element: any) => {
        if (element.amount_installment === 1) {
          setValue(element);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageDetails]);

  return (
    <>
      <MainHeader />
      {/* {!isLoading && <Loading />} */}
      <BaseMain>
        <S.HeaderWrapper>
          <Link
            to={
              type === 'details' || type === 'renew'
                ? `/QualifierDetails/${id}/${type}`
                : `/ClientArea`
            }
          >
            <BackButton>
              <ArrowLeft />
            </BackButton>
          </Link>
          <BaseContainer>
            <Heading title="Comprar pacote" weight="600" />
          </BaseContainer>
        </S.HeaderWrapper>
        <S.BodyContentWrapper>
          <S.WrapperAside>
            <S.Aside>
              <Heading title="Situação atual" weight="700" />
              <S.WrapperDataContent marginTop="3rem">
                {getAside()}
              </S.WrapperDataContent>
            </S.Aside>
            {type !== 'renew' && (
              <S.Aside>
                <S.SubHeader>
                  <Gift />
                  <Heading
                    title="Ganhe benefícios exclusivos!"
                    weight="600"
                    size="large"
                    color="#1A1A1A"
                  />
                </S.SubHeader>

                <div>
                  <S.TextCustomized
                    color={theme.colors.neutral.neutral40}
                    fontSize={theme.font.sizes.medium}
                    lineHeight="24px"
                  >
                    Investindo entre R$ 2.000 e R$ 10.000
                  </S.TextCustomized>
                  <br />
                  <S.TextCustomized
                    color={theme.colors.neutral.neutral60}
                    fontSize={'16px'}
                    lineHeight="24px"
                    fontWeight={500}
                  >
                    Ganhe um cupom de R$3.500 para realizar um Treinamento
                    Online Individual sobre a LIA.
                  </S.TextCustomized>
                </div>
                <hr style={{ width: '3rem' }} />
                <div>
                  <S.TextCustomized
                    color={theme.colors.neutral.neutral40}
                    fontSize={theme.font.sizes.medium}
                    lineHeight="24px"
                  >
                    Investindo acima de R$ 10.000
                  </S.TextCustomized>
                  <br />
                  <S.TextCustomized
                    color={theme.colors.neutral.neutral60}
                    fontSize={'16px'}
                    lineHeight="24px"
                    fontWeight={500}
                  >
                    Ganhe um cupom de até R$10.000 para realizar um Treinamento
                    Presencial Individual sobre o ecossistema Kenlo, com o
                    deslocamento incluso.
                  </S.TextCustomized>
                </div>
              </S.Aside>
            )}
          </S.WrapperAside>
          <S.WrapperMain>
            <S.ListValueWrapper>
              {type === 'renew' && (
                <S.WrapperTitleNewPlan>
                  <Heading title="Novo Pacote LIA" weight="700" />
                </S.WrapperTitleNewPlan>
              )}

              {getBodyNewPackage()}
            </S.ListValueWrapper>
            {type !== 'renew' && (
              <>
                <S.BenefitWrapper>
                  <S.WrapperFooter>
                    <div style={{ width: '100%' }}>
                      <S.TextCustomized
                        fontWeight={600}
                        color={theme.colors.neutral.neutral120}
                        lineHeight={'24px'}
                        fontSize="2.0rem"
                      >
                        Principais benefícios
                      </S.TextCustomized>
                      <S.Row margin="3rem" gap="2rem">
                        <S.Row JustifyContent="flex-start">
                          <BoxContent
                            color={theme.colors.neutral.neutral10}
                            style={{
                              justifyContent: 'flex-start',
                              padding: '16px',
                            }}
                          >
                            <BoxContent
                              color="transparent"
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginBottom: '1rem',
                              }}
                            >
                              <S.DivIcon>
                                <HexagonCaretDouble />
                              </S.DivIcon>
                              <S.TextCustomized
                                fontWeight={700}
                                color={theme.colors.neutral.neutral40}
                                lineHeight={'24px'}
                                fontSize={'16px'}
                              >
                                Identificação de leads prioritários
                              </S.TextCustomized>
                            </BoxContent>
                            <S.TextCustomized
                              fontWeight={500}
                              color={theme.colors.neutral.neutral140}
                              lineHeight={'16px'}
                              fontSize={'12px'}
                            >
                              A LIA distingue e prioriza o atendimento dos
                              &quot;leads quentes &quot;, que tem 50% de chance
                              de fechar negócio.
                            </S.TextCustomized>
                          </BoxContent>
                        </S.Row>
                        <S.Row JustifyContent="flex-start">
                          <BoxContent
                            color={theme.colors.neutral.neutral10}
                            style={{
                              justifyContent: 'flex-start',
                              padding: '16px',
                            }}
                          >
                            <BoxContent
                              color="transparent"
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginBottom: '1rem',
                              }}
                            >
                              <S.DivIcon>
                                <ChatDouble />
                              </S.DivIcon>
                              <S.TextCustomized
                                fontWeight={700}
                                color={theme.colors.neutral.neutral140}
                                lineHeight={'24px'}
                                fontSize={'16px'}
                              >
                                Respostas rápidas aos leads
                              </S.TextCustomized>
                            </BoxContent>
                            <S.TextCustomized
                              fontWeight={500}
                              color={theme.colors.neutral.neutral140}
                              lineHeight={'16px'}
                              fontSize={'12px'}
                            >
                              A LIA os responde automaticamente pelo WhatsApp,
                              reduzindo o tempo de resposta inicial.
                            </S.TextCustomized>
                          </BoxContent>
                        </S.Row>
                      </S.Row>
                      <S.Row gap="2rem">
                        <S.Row JustifyContent="flex-start">
                          <BoxContent
                            color={theme.colors.neutral.neutral10}
                            style={{
                              justifyContent: 'flex-start',
                              padding: '16px',
                            }}
                          >
                            <BoxContent
                              color="transparent"
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginBottom: '1rem',
                              }}
                            >
                              <S.DivIcon>
                                <ArrowTrendingNeutral />
                              </S.DivIcon>
                              <S.TextCustomized
                                fontWeight={700}
                                color={theme.colors.neutral.neutral140}
                                lineHeight={'16px'}
                                fontSize={'16px'}
                              >
                                Aumento na taxa de conversão
                              </S.TextCustomized>
                            </BoxContent>
                            <S.TextCustomized
                              fontWeight={500}
                              color={theme.colors.neutral.neutral140}
                              lineHeight={'16px'}
                              fontSize={'12px'}
                            >
                              Com foco nos leads corretos e resposta rápida, a
                              LIA pode elevar sua taxa de conversão em até 10%.
                            </S.TextCustomized>
                          </BoxContent>
                        </S.Row>
                        <S.Row JustifyContent="flex-start">
                          <BoxContent
                            color={theme.colors.neutral.neutral10}
                            style={{
                              justifyContent: 'flex-start',
                              padding: '16px',
                            }}
                          >
                            <BoxContent
                              color="transparent"
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginBottom: '1rem',
                              }}
                            >
                              <S.DivIcon>
                                <DesktopPulse />
                              </S.DivIcon>
                              <S.TextCustomized
                                fontWeight={700}
                                color={theme.colors.neutral.neutral140}
                                lineHeight={'24px'}
                                fontSize={'16px'}
                              >
                                Avaliação da eficácia dos canais
                              </S.TextCustomized>
                            </BoxContent>
                            <S.TextCustomized
                              fontWeight={500}
                              color={theme.colors.neutral.neutral140}
                              lineHeight={'16px'}
                              fontSize={'12px'}
                            >
                              A LIA realiza a análise da qualidade dos leads de
                              cada canal de origem em tempo real.
                            </S.TextCustomized>
                          </BoxContent>
                        </S.Row>
                      </S.Row>
                    </div>
                  </S.WrapperFooter>
                </S.BenefitWrapper>
              </>
            )}
            {type === 'renew' && (
              <>
                <S.BenefitWrapper>
                  <S.WrapperFooter>
                    <div style={{ width: '100%' }}>
                      <S.TextCustomized
                        fontWeight={600}
                        color={theme.colors.neutral.neutral120}
                        lineHeight={'24px'}
                        fontSize="2.0rem"
                      >
                        Principais benefícios
                      </S.TextCustomized>
                      <S.Row margin="2rem">
                        <S.Row JustifyContent="flex-start">
                          <S.DivIcon>
                            <HexagonCaretDouble />
                          </S.DivIcon>
                          <S.TextCustomized
                            fontWeight={500}
                            color={theme.colors.neutral.neutral140}
                            lineHeight={'24px'}
                            fontSize={theme.font.sizes.medium}
                          >
                            Descubra o potencial do lead fechar negócio
                          </S.TextCustomized>
                        </S.Row>
                        <S.Row JustifyContent="flex-start">
                          <S.DivIcon>
                            <Robo />
                          </S.DivIcon>
                          <S.TextCustomized
                            fontWeight={500}
                            color={theme.colors.neutral.neutral140}
                            lineHeight={'24px'}
                            fontSize={theme.font.sizes.medium}
                          >
                            Whatsapp Bot automatiza o pré-atendimento
                          </S.TextCustomized>
                        </S.Row>
                      </S.Row>
                      <S.Row margin="1.5rem">
                        <S.Row JustifyContent="flex-start">
                          <S.DivIcon>
                            <BatteryCheck />
                          </S.DivIcon>
                          <S.TextCustomized
                            fontWeight={500}
                            color={theme.colors.neutral.neutral140}
                            lineHeight={'24px'}
                            fontSize={theme.font.sizes.medium}
                          >
                            Veja o perfil de busca por imóveis em portais
                          </S.TextCustomized>
                        </S.Row>
                        <S.Row JustifyContent="flex-start">
                          <S.DivIcon>
                            <ChatDouble />
                          </S.DivIcon>
                          <S.TextCustomized
                            fontWeight={500}
                            color={theme.colors.neutral.neutral140}
                            lineHeight={'24px'}
                            fontSize={theme.font.sizes.medium}
                          >
                            LIA não deixa leads sem retorno no 1° contato
                          </S.TextCustomized>
                        </S.Row>
                      </S.Row>
                      <S.Row margin="1.5rem">
                        <S.Row JustifyContent="flex-start">
                          <S.DivIcon>
                            <PersonClock />
                          </S.DivIcon>
                          <S.TextCustomized
                            fontWeight={500}
                            color={theme.colors.neutral.neutral140}
                            lineHeight={'24px'}
                            fontSize={theme.font.sizes.medium}
                          >
                            Acesse o histórico da evolução do potencial
                          </S.TextCustomized>
                        </S.Row>
                        <S.Row JustifyContent="flex-start">
                          <S.DivIcon>
                            <AlertOn />
                          </S.DivIcon>
                          <S.TextCustomized
                            fontWeight={500}
                            color={theme.colors.neutral.neutral140}
                            lineHeight={'24px'}
                            fontSize={theme.font.sizes.medium}
                          >
                            Aviso de novo cliente no whatsapp do corretor
                          </S.TextCustomized>
                        </S.Row>
                      </S.Row>
                    </div>
                  </S.WrapperFooter>
                </S.BenefitWrapper>
                <S.BenefitWrapper>
                  <S.WrapperFooter>
                    <div>
                      <S.WrapperPayment>
                        <S.TextCustomized
                          fontWeight={500}
                          color={theme.colors.neutral.neutral40}
                          lineHeight={'24px'}
                          fontSize={theme.font.sizes.large}
                        >
                          Pagamento
                        </S.TextCustomized>
                      </S.WrapperPayment>
                      <S.WrapperPaymentTotal>
                        <S.TextCustomized
                          fontWeight={700}
                          color={theme.colors.neutral.neutral120}
                          lineHeight={'30px'}
                          fontSize={theme.font.sizes.xlarge}
                        >
                          {getValue()}
                        </S.TextCustomized>
                        <S.TextCustomized
                          fontWeight={700}
                          color={theme.colors.neutral.neutral40}
                          lineHeight={'24px'}
                          fontSize={theme.font.sizes.medium}
                        >
                          {getTextInstallment()} no boleto
                        </S.TextCustomized>
                        <PaymentOptions
                          el={plan}
                          setValue={setValue}
                          value={count * 5}
                          type={type}
                          count={count}
                          color="#F82E52"
                        />
                      </S.WrapperPaymentTotal>
                    </div>
                    {getButtonConfirmValue()}
                  </S.WrapperFooter>
                </S.BenefitWrapper>
              </>
            )}
          </S.WrapperMain>
        </S.BodyContentWrapper>
      </BaseMain>
    </>
  );
};
