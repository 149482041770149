import styled, { css } from 'styled-components';

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

export const Leads = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 1.6rem;

    > div {
      p {
        font-size: ${theme.font.sizes.large};
        font-weight: ${theme.font.semiBold};
        margin-bottom: 0;
      }

      span {
        font-size: ${theme.font.sizes.xsmall};
        font-weight: ${theme.font.normal};
      }
    }
  `}
`;
export const Title = styled.div<{
  color: string;
  fontWeight?: number;
  lineHeight?: string;
  fontSize?: string;
}>`
  ${({ theme, color, fontWeight, lineHeight, fontSize }) => css`
    font-size: ${fontSize || theme.font.sizes.medium};
    font-weight: ${fontWeight || theme.font.bold};
    color: ${color};
    line-height: ${lineHeight || '16px'};
  `}
`;
export const Subtitle = styled.span<{
  color?: string;
  fontWeight?: number;
  lineHeight?: string;
}>`
  ${({ theme, color, fontWeight, lineHeight }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${color || theme.colors.neutral.neutral130};
    font-weight: ${fontWeight || 700};
    line-height: ${lineHeight || '16px'};
  `}
`;

export const Commission = styled.div`
  margin-top: 1.6rem;
`;

export const Potential = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
`;
export const Qualify = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;
export const Performance = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
`;
export const RowPerformance = styled.div<{ marginBottom?: string }>`
  ${({ marginBottom }) => css`
    margin-top: 1.6rem;
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: row;
    margin-bottom: ${marginBottom || 0};
  `}
`;
export const TextOne = styled.div<{ marginLeft?: string; textAlign?: string }>`
  ${({ marginLeft, textAlign }) => css`
    margin-right: 1rem;
    align-items: center;
    margin-left: ${marginLeft || 0};
    text-align: ${textAlign || 'start'};
  `}
`;
export const Status = styled.div`
  margin-left: 1rem;
  align-items: center;
`;

export const StatusTwo = styled.div`
  align-items: center;
  margin-left: 1rem;
`;

export const ToolTip = styled.div`
  margin-left: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const RowCommission = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
`;

export const StatusQualifier = styled.div`
  margin-left: 4rem;
  text-align: initial;
`;
