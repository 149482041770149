const DocumentCheckMark = ({
  width,
  height,
}: {
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      width={width || '48'}
      height={height || '48'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2 18.4C17.2 18.62 17.0208 18.8 16.8 18.8H12.2142C11.9844 19.2408 11.6934 19.6446 11.3523 20H16.8C17.6832 20 18.4 19.2832 18.4 18.4V10.2624C18.4 9.8384 18.2312 9.4312 17.9312 9.1312L13.268 4.4688C13.256 4.45684 13.2424 4.44646 13.229 4.43616C13.219 4.42854 13.2091 4.42096 13.2 4.4128C13.1432 4.3616 13.0872 4.3112 13.0248 4.2688C13.0045 4.25515 12.9824 4.24438 12.9604 4.23366C12.9475 4.22741 12.9347 4.22117 12.9224 4.2144C12.909 4.20688 12.8957 4.19917 12.8824 4.19146C12.839 4.16638 12.7954 4.14118 12.7496 4.1216C12.592 4.056 12.4224 4.0232 12.2504 4.0112C12.2346 4.01019 12.219 4.00806 12.2033 4.00593C12.1816 4.00297 12.1599 4 12.1376 4H7.19995C6.31675 4 5.59995 4.7168 5.59995 5.6V11.5985C5.97929 11.4403 6.38138 11.3258 6.79995 11.2612V5.6C6.79995 5.38 6.97915 5.2 7.19995 5.2H12V8.8C12 9.6832 12.7168 10.4 13.6 10.4H17.2V18.4ZM13.2 6.0968L16.3024 9.2H13.6C13.3792 9.2 13.2 9.02 13.2 8.8V6.0968Z"
        fill="#2183D9"
      />
      <path
        d="M12 16.4C12 18.8301 10.03 20.8 7.59995 20.8C5.1699 20.8 3.19995 18.8301 3.19995 16.4C3.19995 13.9699 5.1699 12 7.59995 12C10.03 12 12 13.9699 12 16.4ZM10.2828 14.5172C10.1266 14.3609 9.87332 14.3609 9.71711 14.5172L6.79995 17.4343L5.48279 16.1172C5.32658 15.9609 5.07332 15.9609 4.91711 16.1172C4.7609 16.2734 4.7609 16.5266 4.91711 16.6828L6.51711 18.2828C6.67332 18.4391 6.92658 18.4391 7.08279 18.2828L10.2828 15.0828C10.439 14.9266 10.439 14.6734 10.2828 14.5172Z"
        fill="#2183D9"
      />
    </svg>
  );
};
export default DocumentCheckMark;
