const AveragePotential = ({
  color,
  width,
  height,
}: {
  color?: string;
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3334 8.00002L8.00008 4.66669L4.66675 8.00002"
        stroke={color || '#127CC9'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3334 11.3333L8.00008 8L4.66675 11.3333"
        stroke={color || '#127CC9'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default AveragePotential;
