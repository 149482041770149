import { Loading } from 'components/Loading';
import { ClientInfoContext } from 'contexts/ClientInfoContext';
import { FeatureContext } from 'contexts/FeaturesContext';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { api } from 'services/api';
export const Auth = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { fetchDataAgency, setIdImob } = useContext(FeatureContext);
  const { fetchDataClientInfo, setClearDataClientInfo } =
    useContext(ClientInfoContext);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tokenAccount = searchParams.get('accountToken');
  const redirectURL = searchParams.get('redirectUrl');

  const history = useHistory();

  const fetchDataSignInToken = async (token: string) => {
    const authorization = token;
    setIsLoading(true);
    setClearDataClientInfo();
    try {
      const { data } = await api.post(
        `login/account`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            authorization,
          },
        },
      );
      return data;
    } catch (error: any) {
      history.push('/');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loginAccount(tokenAccount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Ver dependencia do useEffect

  async function loginAccount(data: any): Promise<void> {
    try {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('idToken');
      localStorage.removeItem('refreshToken');
      data = await fetchDataSignInToken(data);
      setIdImob(data.data.user.company_id);
      fetchDataAgency(data.data.token.accessToken, data.data.token.idToken);
      localStorage.setItem('accessToken', data.data.token.accessToken);
      localStorage.setItem('idToken', data.data.token.idToken);
      localStorage.setItem('refreshToken', data.data.token.refreshToken);
      localStorage.setItem('name', data.data.user.name);
      localStorage.setItem('profile', data.data.user.profile);
      localStorage.setItem('picture', data.data.user.picture);
      localStorage.setItem('useImobId', data.data.user.user_imob_id);
      localStorage.setItem('company_id', data.data.user.company_id);
      fetchDataAgency(data.data.token.accessToken, data.data.token.idToken);
      fetchDataClientInfo(data.data.user.company_id);
      if (redirectURL) {
        history.push(redirectURL);
      } else {
        history.push('/ClientArea');
      }
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  }

  return <>{isLoading && <Loading />}</>;
};
