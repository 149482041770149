const TransferManagement = () => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="#4C4C4C"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.7828 0.219653L15.2778 4.71441C15.5439 4.98047 15.5683 5.39676 15.3509 5.69038L15.2784 5.77451L10.7834 10.2794C10.4908 10.5726 10.0159 10.5731 9.72269 10.2805C9.45614 10.0145 9.43148 9.59791 9.64901 9.30406L9.72154 9.21986L12.9384 5.997L1.24255 5.99766C0.862858 5.99766 0.549063 5.71551 0.499401 5.34943L0.492554 5.24766C0.492554 4.86797 0.774708 4.55417 1.14078 4.50451L1.24255 4.49766L12.9404 4.497L9.72213 1.28035C9.45586 1.01409 9.43164 0.597427 9.64948 0.303808L9.7221 0.219687C9.98836 -0.046588 10.405 -0.0708077 10.6986 0.147037L10.7828 0.219653L15.2778 4.71441L10.7828 0.219653ZM15.5005 14.6491L15.5074 14.7509C15.5074 15.1306 15.2252 15.4444 14.8592 15.494L14.7574 15.5009L3.06139 15.5L6.28255 18.7194C6.54893 18.9856 6.57331 19.4023 6.35558 19.696L6.283 19.7801C6.01684 20.0465 5.60019 20.0709 5.30649 19.8531L5.22234 19.7806L0.722338 15.2843C0.45596 15.0182 0.431579 14.6015 0.649311 14.3078L0.721894 14.2237L5.22189 9.71989C5.51467 9.42688 5.98954 9.42668 6.28255 9.71945C6.54893 9.9856 6.57331 10.4023 6.35558 10.696L6.283 10.7801L3.06539 14L14.7574 14.0009C15.1371 14.0009 15.4509 14.283 15.5005 14.6491L15.5074 14.7509L15.5005 14.6491Z" />
    </svg>
  );
};
export default TransferManagement;
