import * as Dialog from '@radix-ui/react-dialog';
import * as S from './styles';
import { BoxContent } from 'components/BoxContent';
import Button from 'components/Button';
import { useContext, useState } from 'react';
import { BaseModal } from '../BaseModal';
import { theme } from 'styles/theme';
import { Line } from 'components/Line';
import { NotificationBannerContext } from 'contexts/NotificationBannerContext';
import { formattedValue } from 'helpers/util';
import { api } from 'services/api';
import { useHistory } from 'react-router-dom';
import { MessageModalError } from 'components/MessageModalError';
import close from 'assets/image/close_white.png';

type IModalProps = {
  name?: string | undefined;
  cost_qualifier?: number | string | undefined;
  estimated_days?: number | string | undefined;
  amount_qualifier?: number | string | undefined;
  discount_value: number;
  discount_newClient_value?: number | string | undefined;
  className?: number | string | undefined;
  estimated_duration: number;
  final_value: number;
  value: string;
  valueName: string;
  id: number | undefined;
  installment_value: number;
  amount_installment: string;
  amount_leads: number | undefined;
  valueForQualification: string | number;
  onClose?: () => void;
};

export const DetailPackageSummaryNewClient = ({
  // cost_qualifier,
  amount_qualifier,
  value,
  id,
  amount_installment,
  installment_value,
  valueForQualification,
  amount_leads,
  discount_value,
  valueName,
}: // discount_newClient_value,
IModalProps) => {
  const [showDetails, setShowDetails] = useState(false);
  const accessToken = localStorage.getItem('accessToken');
  const idToken = localStorage.getItem('idToken');
  const [statusError, setStatusError] = useState(200);
  const [modalError, setModalError] = useState(true);
  const history = useHistory();
  const { setDataBanner } = useContext(NotificationBannerContext);

  const onClickHandle = async () => {
    if (
      Number(amount_installment) !== undefined ||
      Number(amount_installment) > 0
    ) {
      await confirmPurchase();
    }
    setShowDetails(true);
  };

  const confirmPurchase = async () => {
    try {
      const { data } = await api.post(
        'qualifier/bought',
        {
          id_qualifier_pack: id,
          id_qualifier_installment: amount_installment,
          amount_leads: amount_leads,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            idToken,
          },
        },
      );
      setStatusError(data.success);
      if (Array.isArray(data.data)) {
        setDataBanner(data.data[0]);
      }
      history.push(`/ClientArea`);
    } catch (error: any) {
      if (error.response) {
        setStatusError(error.response.status);
      }
    }
  };

  const getData = () => {
    return (
      <>
        <BoxContent
          color={theme.colors.neutral.neutral10}
          style={{ padding: '1.6rem 2.4rem 1.6rem 2.4rem' }}
        >
          <S.Row>
            <S.Qualify>
              <S.TexTwo>
                <S.TitleTwo color={theme.colors.neutral.neutral120}>
                  <b>Pacote Novo Cliente</b>
                </S.TitleTwo>
                <S.Subtitle color={theme.colors.neutral.neutral130}>
                  {` ${formattedValue(valueForQualification)}`} por lead{' '}
                </S.Subtitle>
              </S.TexTwo>
              <S.TextOne>
                <S.TitleFour color={theme.colors.neutral.neutral40}>
                  {amount_qualifier === 0 || amount_qualifier === 1
                    ? `${amount_qualifier} qualificacão`
                    : `${amount_qualifier} qualificações`}
                </S.TitleFour>
                <S.Subtitle color={theme.colors.neutral.neutral130}>
                  Duração estimada indisponível
                </S.Subtitle>
              </S.TextOne>
            </S.Qualify>
          </S.Row>
        </BoxContent>
      </>
    );
  };

  const getTable = () => {
    return (
      <>
        {statusError !== 200 && (
          <MessageModalError
            img={close}
            description="Tivemos um erro na tentativa de compra de pacote, tente novamente."
            isOpen={modalError}
            setIsOpen={setModalError}
          />
        )}
        <BoxContent
          color={theme.colors.neutral.neutral10}
          style={{ padding: '1.6rem 2.4rem 1.6rem 2.4rem' }}
        >
          <S.Row>
            <S.Qualify>
              <S.TextFooter>
                <S.TitleTree color={theme.colors.neutral.neutral130}>
                  Valor do pacote
                </S.TitleTree>
                <S.TitleTree color={theme.colors.neutral.neutral130}>
                  Desconto de novo cliente
                </S.TitleTree>
                {/* {discount_newClient_value !== 0 && (
                  <>
                    <S.TitleTree color={theme.colors.neutral.neutral130}>
                      Desconto à vista
                    </S.TitleTree>
                  </>
                )} */}
              </S.TextFooter>

              <S.TexTree>
                <S.Title color={theme.colors.neutral.neutral70}>
                  <b>{formattedValue(value)}</b>
                </S.Title>
                <S.Title color={theme.colors.neutral.neutral70}>
                  <b>{`- ${formattedValue(
                    (Number(value) * discount_value) / 100,
                  )}`}</b>
                </S.Title>
                {/* {discount_newClient_value !== 0 && (
                  <>
                    <S.Title color={theme.colors.neutral.neutral70}>
                      <b>{`- ${formattedValue(discount_newClient_value)}`}</b>
                    </S.Title>
                  </>
                )} */}
              </S.TexTree>
            </S.Qualify>
          </S.Row>
          <Line width="100%" border="0.1rem" />
          <S.Footer>
            <S.TitleTwo color={theme.colors.neutral.neutral120}>
              <b>{` ${formattedValue(
                Number(value) - (Number(value) * discount_value) / 100,
              )}`}</b>
            </S.TitleTwo>
            <S.Title color={theme.colors.neutral.neutral100}>
              {installment_value === 0
                ? `${valueName} no boleto`
                : `${valueName} de ${formattedValue(
                    installment_value,
                  )} no boleto `}
            </S.Title>
          </S.Footer>
        </BoxContent>
        <S.Footer>
          <div>
            <Dialog.Close asChild>
              <Button minimal={false} border height="5.6rem">
                Cancelar
              </Button>
            </Dialog.Close>
            <Button
              minimal={false}
              height="5.6rem"
              onClick={() => onClickHandle()}
            >
              Comprar pacote
            </Button>
          </div>
        </S.Footer>
      </>
    );
  };

  return (
    <>
      <Dialog.Root>
        <Dialog.Trigger asChild>
          <Button
            minimal={false}
            size="medium"
            height="5.6rem"
            disabled={id === undefined}
            onClick={() => setShowDetails(true)}
          >
            Confirmar valores
          </Button>
        </Dialog.Trigger>
        {showDetails && (
          <BaseModal title="Resumo do pacote">
            {getData()}
            {getTable()}
          </BaseModal>
        )}
      </Dialog.Root>
    </>
  );
};
