import styled, { css } from 'styled-components';
import * as Dialog from '@radix-ui/react-dialog';

export const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  inset: 0;
  background: rgba(0, 0, 0, 0.75);
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 60rem;
    background: ${theme.colors.neutral.neutral0};
    border-radius: 8px;
    box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.08);
    padding: 3.2rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};
    font-weight: ${theme.font.semiBold};
    color: ${theme.colors.neutral.neutral100};
    margin-bottom: 2.4rem;
  `}
`;

export const CloseButton = styled(Dialog.Close)`
  ${({ theme }) => css`
    position: absolute;
    background: transparent;
    border: 0;
    top: 4rem;
    right: 3.5rem;
    line-height: 0;
    cursor: pointer;
    color: ${theme.colors.neutral.neutral100};
  `}
`;

export const BaseBorder = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.border.radius};
    border: 1px solid ${theme.colors.neutral.neutral30};
    padding: 2.4rem;
  `}
`;

export const PlanContent = styled(BaseBorder)`
  ${({ theme }) => css`
    margin-bottom: 2.4rem;
    ul {
      list-style: none;
      padding-left: 0;
      li {
        div {
          display: flex;
          align-items: center;
          justify-content: space-between;

          h2 {
            font-size: ${theme.font.sizes.large};
            color: ${theme.colors.neutral.neutral100};
            font-weight: 600;
          }

          p {
            background: ${theme.colors.primary50};
            padding: 4px 7px;
            border-radius: 8px;
            color: ${theme.colors.neutral.neutral0};
            font-size: ${theme.font.sizes.xsmall};
            font-weight: 600;
          }
        }
      }
      ul {
        display: flex;
        justify-content: space-between;
        margin-top: 1.6rem;
        padding: 0;

        li {
          font-size: ${theme.font.sizes.medium};
          div {
            gap: 1rem;
            svg {
              fill: ${theme.colors.neutral.neutral70};
            }
            span {
              color: ${theme.colors.neutral.neutral70};
            }
          }
        }
      }
    }
  `}
`;

export const ValuesContent = styled(BaseBorder)`
  ${({ theme }) => css`
    ul {
      display: flex;
      flex-direction: column;
      font-size: ${theme.font.sizes.medium};
      margin-bottom: 2.4rem;
      padding-left: 0;

      li {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.8rem;

        p {
          color: ${theme.colors.neutral.neutral50};
          font-weight: 500;
        }
        span {
          color: ${theme.colors.neutral.neutral70};
          font-weight: 700;
        }
      }
    }

    > div {
      display: inline-block;
      text-align: right;
      width: 100%;
      margin-top: 2.4rem;

      > span {
        font-size: ${theme.font.sizes.large};
        color: ${theme.colors.neutral.neutral100};
        font-weight: 600;
      }

      p {
        font-size: ${theme.font.sizes.medium};
        font-weight: 500;
        color: ${theme.colors.neutral.neutral70};
      }
    }
  `}
`;

export const Footer = styled.div`
  ${({ theme }) => css`
    p {
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.neutral.neutral70};
      font-weight: 500;
      letter-spacing: -0.4px;
      margin: 2.4rem 0;
      a {
        color: ${theme.colors.primary50};
        font-weight: 700;
        text-decoration: none;
        margin: 4px;
        letter-spacing: -0.4px;
      }
    }

    div {
      display: flex;
      justify-content: flex-end;
      gap: 1.6rem;
    }
  `}
`;

export const WrapperPaymentOptions = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: baseline;
    justify-content: end;
    gap: 0.8rem;
    span {
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.neutral.neutral70};
      font-weight: 500;
      margin-left: 0;
    }
    h2 {
      font-size: ${theme.font.sizes.xlarge};
    }
  `}
`;
