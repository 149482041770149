import * as S from './styles';
import Button from 'components/Button';
import { movideskChatWidget } from 'contexts/MovideskLogin';

type MainAdditionalsProps = {
  iconAdditional1: React.ReactNode;
  descriptionAdditional1: string;
  iconAdditional2: React.ReactNode;
  descriptionAdditional2: string;
};

const handleSolicitarAlteracao = () => {
  const chatLine = document.querySelector('.md-chat-widget-container');
  if (chatLine?.classList.contains('md-fade-to-invisible')) {
    movideskChatWidget.maximize();
  } else {
    movideskChatWidget.minimize();
  }
};

export const MainAdditionals = ({
  iconAdditional1,
  descriptionAdditional1,
  iconAdditional2,
  descriptionAdditional2,
}: MainAdditionalsProps) => {
  return (
    <S.Wrapper>
      <S.WrapperAdditional>
        <ul>
          <li>
            <div>
              {iconAdditional1}
              <p>{descriptionAdditional1}</p>
            </div>
            <p>
              <Button onClick={handleSolicitarAlteracao}>Saber mais</Button>
            </p>
          </li>
          <li>
            <div>
              {iconAdditional2}
              <p>{descriptionAdditional2}</p>
            </div>
            <p>
              <Button onClick={handleSolicitarAlteracao}>Saber mais</Button>
            </p>
          </li>
        </ul>
      </S.WrapperAdditional>
    </S.Wrapper>
  );
};
