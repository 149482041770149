import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { ClientArea } from '../pages/ClienteArea';
import { PlanDetails } from 'pages/PlanDetails/';
import { HistoryOfInvoices } from 'pages/HistoryOfInvoices';
import { ChangeOfPlan } from 'pages/ChangeOfPlan';
import { RenewPlan } from 'pages/RenewPlan';
import { SignIn } from 'pages/SignIn';
import { ForgotPassword } from 'pages/ForgotPassword';
import { QualifierDetails } from 'pages/QualifierDetails';
import { ForgotPasswordCod } from 'pages/ForgotPasswordCod';
import { NewPassword } from 'pages/NewPassword';
import { BuyPackage } from '../pages/BuyPackage';
import { Auth } from 'pages/Auth';

export const Routes = () => (
  <Router>
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/Forgot-password" exact component={ForgotPassword} />
      <Route path="/ClientArea" component={ClientArea} />
      <Route path="/PlanDetails/:id" component={PlanDetails} />
      <Route path="/HistoryOfInvoices/:id" component={HistoryOfInvoices} />
      <Route path="/ChangeOfPlan/:id" component={ChangeOfPlan} />
      <Route path="/RenewPlan/:id" component={RenewPlan} />
      <Route path="/QualifierDetails/:id/:type" component={QualifierDetails} />
      <Route path="/Forgot-password-cod" component={ForgotPasswordCod} />
      <Route path="/New-password" component={NewPassword} />
      <Route path="/Buy-package/:id/:type" component={BuyPackage} />
      <Route path="/Auth" component={Auth} />
    </Switch>
  </Router>
);
