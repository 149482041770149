/* eslint-disable react/jsx-no-target-blank */
import * as S from './styles';
import { BackButton } from 'components/BackButton';
import { BaseContainer } from 'components/BaseContainer';
import { BaseMain } from 'components/BaseMain';
import Button from 'components/Button';
import { Heading } from 'components/Heading';
import ArrowLeft from 'components/Icons/ArrowLeft';
import QuestionCircle from 'components/Icons/QuestionCircle';
import { Loading } from 'components/Loading';
import { MainHeader } from 'components/MainHeader';
import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { PerformaceDetail } from 'components/Modals/PerformaceDetailModal';
import { BaseAside } from 'components/BaseAside';
import {
  CaretDoubleUp,
  CaretTripleUp,
  CaretUp,
  CheckBoard,
  HexagonMinus,
} from 'components/Icons';
import { BaseContentMain } from 'components/BaseContentMain';
import { BoxContent } from 'components/BoxContent';
import { AmountQualification } from 'components/AmountQualification';
import { DataDetails } from 'components/DataDetails';
import { HistoryOfQualification } from 'components/HistoryOfQualification';
import { Line } from 'components/Line';
import { Banner } from 'components/Banner';
import WarningTriangle from 'components/Icons/WarningTriangle';
import { PerformaceRetroactiveDetailModal } from 'components/Modals/PerformaceRetroactiveDetailModal';
import BuyPackage from 'components/Icons/BuyPackage';
import { theme } from 'styles/theme';
import { DetailPackageNewClient } from 'components/Modals/PerformaceDetailPackageNewClient';
import { QualificationContext } from 'contexts/AvailableQualification';
import moment from 'moment';
import 'moment/locale/pt-br';
import { api } from 'services/api';
import { NotificationBannerContext } from 'contexts/NotificationBannerContext';
import { DailyQualificationsModal } from 'components/Modals/DailyQualificationsModal';
import { PerfomanceDetailPackage } from 'components/Modals/PerformaceDetailPackage';
import { MovtoDailyQualifierContext } from 'contexts/MovtoDailyQualifier';
import { ClientInfoContext } from 'contexts/ClientInfoContext';

export type indicatorsProps = {
  perc_mes_atual_atendimentos_convertidos: number;
  mes: number;
  ano: number;
  perc_contratos_fechados_alto_potencial_mes_atual: number;
  perc_contratos_fechados_alto_potencial_mes_anterior: number;
  perc_cliente_alto_potencial_originacao_propria_mes_atual: number;
  perc_cliente_alto_potencial_originacao_propria_mes_anterior: number;
  comissao_estimada_mes_atual_atendimentos_venda: number;
  comissao_estimada_mes_atual_atendimentos_locacao: number;
  comissao_recebida_atual_venda: number;
  comissao_recebida_atual_locacao: number;
  qtd_leads_qualificados_mes_alto: number;
  qtd_leads_qualificados_mes_medio: number;
  qtd_leads_qualificados_mes_baixo: number;
  tot_leads_qualificados_mes: number;
};

export type HistoryOfQualificationProps = {
  date: string;
  value: number;
  id_qualifier_package: number;
  id_qualifier_bought: string;
  type_transaction: string;
  transaction: string;
  qualififer_package: {
    id_qualifier_pack: string;
    name: string;
    type: number;
  };
  children: React.ReactNode;
};

export type DailyQualification = {
  date?: string;
  name_broker: string;
  name_client: string;
};

export const QualifierDetails = () => {
  const [indicatorsPerPage, setIindicatorsPerPage] = useState(3);
  const itemsPerPage = 5;
  const accessToken = localStorage.getItem('accessToken');
  const idToken = localStorage.getItem('idToken');
  const [indicators, setIndicators] = useState<indicatorsProps[]>([]);
  const [qualificationHistory, setQualificationHistory] = useState<
    HistoryOfQualificationProps[]
  >([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const { dataMovtoDailyQualifier, fetchDataMovtoDailyQualifier } = useContext(
    MovtoDailyQualifierContext,
  );
  const [isLoading] = useState(true);
  const { type, id } = useParams<{
    id: string;
    type: string;
  }>();
  const { setDataBanner, dataBanner } = useContext(NotificationBannerContext);
  const { fetchDataClientInfo, dataClientInfo } = useContext(ClientInfoContext);

  const idClientImob = localStorage.getItem('useImobId');

  const showMoreHistory = () => {
    setCurrentPage((currentPage) => currentPage + 1);
  };

  const showMoreIndicators = () => {
    setIindicatorsPerPage((indicatorsPerPage) => indicatorsPerPage + 3);
  };

  const { dataQualifierBalance, fetchDataQualifierBalance } =
    useContext(QualificationContext);

  useEffect(() => {
    fetchDataQualifierBalance(id);
    if (Number(dataClientInfo?.id_realstate) > 0) {
      fetchDataMovtoDailyQualifier(dataClientInfo?.id_realstate);
    }
  }, [
    id,
    fetchDataQualifierBalance,
    dataClientInfo?.id_realstate,
    fetchDataClientInfo,
    fetchDataMovtoDailyQualifier,
  ]);

  const startDate = moment()
    .subtract(1, 'year')
    .startOf('day')
    .format('YYYY-MM-DD');
  const endDate = moment().endOf('day').format('YYYY-MM-DD');
  const disableButtonVerMais = currentPage >= totalPages;

  const disableButtonVerMaisIndicators = indicatorsPerPage >= indicators.length;

  function capitalize(str: any): string {
    if (typeof str === 'string') {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return '';
    }
  }

  useEffect(() => {
    const fetchDataQualificationHistory = async () => {
      try {
        const { data } = await api.get(
          `qualifier/movto/daily/${dataClientInfo?.id_realstate}?startDate=${startDate}&endDate=${endDate}&page=${currentPage}&limit=${itemsPerPage}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
              idToken,
            },
          },
        );
        const qualificationHistoryData = data.data;
        setTotalPages(data.pagination.pages);
        setQualificationHistory((prevState) => [
          ...prevState,
          ...qualificationHistoryData,
        ]);
      } catch (error) {
        console.log(error);
      }
    };
    if (dataClientInfo?.id_realstate) {
      fetchDataQualificationHistory();
    }
  }, [
    accessToken,
    idToken,
    startDate,
    endDate,
    currentPage,
    itemsPerPage,
    dataClientInfo,
  ]);

  useEffect(() => {
    const fetchDataIndicadores = async () => {
      try {
        const { data } = await api.get(`client/indicadores`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            idToken,
          },
        });
        const qualificationIndicatorsData = data.data.historia;

        setIndicators(
          qualificationIndicatorsData.sort((a: any, b: any) => {
            if (b.ano === a.ano) {
              return b.mes - a.mes;
            } else {
              return b.ano - a.ano;
            }
          }),
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchDataIndicadores();
  }, [accessToken, id, idToken]);

  const getAside = () => {
    switch (type) {
      case 'details':
        return (
          <>
            <BaseAside
              title={
                <>
                  <p>Saldo</p>
                  <Heading
                    title={`${
                      dataQualifierBalance?.balance_premium === 0 ||
                      dataQualifierBalance?.balance_premium === 1
                        ? `${dataQualifierBalance?.balance_premium} qualificacão disponível`
                        : `${dataQualifierBalance?.balance_premium} qualificações disponíveis`
                    } `}
                    weight="700"
                    size="large"
                    color="#1A1A1A"
                  />
                </>
              }
            >
              <S.Ul>
                <li>
                  Duração estimada
                  <p>{`${
                    dataQualifierBalance?.days_estimated === 1 ||
                    dataQualifierBalance?.days_estimated === 0
                      ? `${dataQualifierBalance?.days_estimated} dia`
                      : `${dataQualifierBalance?.days_estimated} dias`
                  }`}</p>
                </li>
                <li>
                  Última contratação
                  <p>{`${
                    dataQualifierBalance?.last_bought?.date
                      ? moment(dataQualifierBalance?.last_bought?.date).format(
                          'L',
                        )
                      : 'Indisponível'
                  }`}</p>
                </li>
                <li>
                  Média de leads
                  <p>
                    {`${dataQualifierBalance?.average_monthy_leads}`} leads/mês
                  </p>
                </li>
              </S.Ul>
            </BaseAside>
            <BaseAside
              title={
                <Heading
                  title="Desempenho geral "
                  weight="600"
                  size="large"
                  color="#1A1A1A"
                />
              }
            >
              <S.WrapperPerformance>
                <CheckBoard />
                <div>
                  <Heading
                    title={`${
                      indicators[0]?.perc_mes_atual_atendimentos_convertidos > 0
                        ? `${indicators[0]?.perc_mes_atual_atendimentos_convertidos}`
                        : '0'
                    } %`}
                    weight="600"
                    size="large"
                    color="#1A1A1A"
                  />

                  <p>
                    dos contratos fechados são de leads qualificados como
                    quente.
                  </p>
                </div>
              </S.WrapperPerformance>
            </BaseAside>
          </>
        );
      case 'renew':
        return (
          <>
            <BaseAside
              title={
                <>
                  <p>Saldo</p>
                  <Heading
                    title={`${
                      dataQualifierBalance?.balance_premium === 0 ||
                      dataQualifierBalance?.balance_premium === 1
                        ? `${dataQualifierBalance?.balance_premium} qualificacão disponível`
                        : `${dataQualifierBalance?.balance_premium} qualificações disponíveis`
                    } `}
                    weight="700"
                    size="xlarge"
                    color="#1A1A1A"
                  />
                </>
              }
            >
              <S.Ul>
                {!dataQualifierBalance?.last_bought?.date ? (
                  <li>
                    Última contratação
                    <S.CustomText
                      color={theme.colors.neutral.neutral130}
                      fontSize={theme.font.sizes.medium}
                      fontWeight="700"
                      lineHeight="24px"
                    >
                      <b>Indisponível</b>
                    </S.CustomText>
                  </li>
                ) : (
                  <li>
                    Última contratação
                    <S.CustomText
                      color={theme.colors.neutral.neutral130}
                      fontSize={theme.font.sizes.medium}
                      fontWeight="700"
                      lineHeight="24px"
                    >
                      <b>
                        {moment(dataQualifierBalance?.last_bought.date).format(
                          'L',
                        )}
                      </b>
                    </S.CustomText>
                  </li>
                )}
              </S.Ul>
            </BaseAside>
            <BaseAside
              title={
                <Heading
                  title="Bem vindo a Kenlo"
                  weight="600"
                  size="large"
                  color="#1A1A1A"
                />
              }
            >
              <S.WrapperPerformance>
                <div>
                  <S.CustomText
                    color={theme.colors.neutral.neutral40}
                    fontSize={theme.font.sizes.small}
                    fontWeight="700"
                    lineHeight="24px"
                  >
                    Aproveite os valores promocionais de novo cliente na
                    contratação de pacotes da LIA por mais{' '}
                    <b>
                      {`${dataClientInfo?.daysToTerminateOffer || 0}`} dia
                      {(dataClientInfo?.daysToTerminateOffer || 0) === 1 && 's'}
                    </b>
                    .
                  </S.CustomText>
                </div>
              </S.WrapperPerformance>
            </BaseAside>
          </>
        );
      default:
        break;
    }
  };

  const getBodyPerformance = () => {
    switch (type) {
      case 'details':
        return (
          <>
            {indicators
              ?.slice(0, indicatorsPerPage)
              .reverse()
              .map((indicator: any) => {
                return (
                  <BoxContent
                    key={`${indicator.mes}-${indicator.ano}`}
                    style={{
                      display: 'flex',
                      padding: '1.6rem 2.4rem 1.6rem 2.4rem',
                    }}
                    color="#E5E5E5"
                  >
                    <DataDetails
                      title={`${
                        indicator.mes === moment().month() + 1
                          ? 'Este mês'
                          : capitalize(
                              `${moment(
                                `${indicator.ano}-${indicator.mes}-01`,
                                ['YYYY-M-DD', 'YYYY-MM-DD'],
                              ).format('MMMM')} de ${indicator.ano}`,
                            )
                      }`}
                      subtitle={`${indicator.tot_leads_qualificados_mes} qualificações`}
                    />
                    <S.WrapperAmountQualification>
                      <AmountQualification
                        icon={<CaretDoubleUp />}
                        text={indicator.qtd_leads_qualificados_mes_alto}
                      />
                      <AmountQualification
                        icon={<CaretUp />}
                        text={indicator.qtd_leads_qualificados_mes_medio}
                      />
                      <AmountQualification
                        icon={<HexagonMinus />}
                        text={indicator.qtd_leads_qualificados_mes_baixo}
                      />
                    </S.WrapperAmountQualification>
                    {indicator.mes === moment().month() + 1 ? (
                      <PerformaceDetail el={indicator} />
                    ) : (
                      <PerformaceRetroactiveDetailModal el={indicator} />
                    )}
                    {/* {PerformaceDetail} */}
                  </BoxContent>
                );
              })}
            <S.WrapperButtonVerMais>
              <Button
                onClick={showMoreIndicators}
                disabled={disableButtonVerMaisIndicators}
              >
                Ver mais
              </Button>
            </S.WrapperButtonVerMais>
          </>
        );
      case 'renew':
        return (
          <>
            {indicators?.slice(0, indicatorsPerPage).map((indicator: any) => (
              <BoxContent
                key={`${indicator.mes}-${indicator.ano}`}
                style={{
                  display: 'flex',
                  padding: '1.6rem 2.4rem',
                }}
                color="#E5E5E5"
              >
                <DataDetails
                  title={
                    indicator.mes === moment().month() + 1
                      ? 'Este mês'
                      : capitalize(
                          moment(`${indicator.ano}-${indicator.mes}-01`, [
                            'YYYY-M-DD',
                            'YYYY-MM-DD',
                          ]).format('MMMM') + ` de ${indicator.ano}`,
                        )
                  }
                  subtitle={`${indicator.tot_leads_qualificados_mes} qualificações`}
                />
                <S.WrapperAmountQualification>
                  <AmountQualification
                    icon={<CaretTripleUp />}
                    text={indicator.qtd_leads_qualificados_mes_alto}
                  />
                  <AmountQualification
                    icon={<CaretDoubleUp />}
                    text={indicator.qtd_leads_qualificados_mes_medio}
                  />
                  <AmountQualification
                    icon={<CaretUp />}
                    text={indicator.qtd_leads_qualificados_mes_baixo}
                  />
                </S.WrapperAmountQualification>
                {indicator.mes === moment().month() + 1 ? (
                  <PerformaceDetail el={indicator} />
                ) : (
                  <PerformaceRetroactiveDetailModal el={indicator} />
                )}
              </BoxContent>
            ))}
            <S.WrapperButtonVerMais>
              <Button
                onClick={showMoreIndicators}
                disabled={disableButtonVerMaisIndicators}
              >
                Ver mais
              </Button>
            </S.WrapperButtonVerMais>
          </>
        );
      default:
        break;
    }
  };
  const getBodyHistory = () => {
    switch (type) {
      case 'details':
        return (
          <>
            {qualificationHistory.map((element, index) => (
              <>
                <HistoryOfQualification
                  key={`${element?.qualififer_package?.name}${index}`}
                  element={element}
                  modal={
                    element?.transaction === 'debit' ? (
                      <DailyQualificationsModal element={element} />
                    ) : element?.qualififer_package?.type === 0 ? (
                      <DetailPackageNewClient
                        element={{ total: element.value }}
                        item={{
                          date: element.date,
                          name_broker: element.qualififer_package.name,
                          name_client: '',
                        }}
                      />
                    ) : (
                      <PerfomanceDetailPackage
                        el="miguel"
                        id_qualifier_bought={element.id_qualifier_bought}
                      />
                    )
                  }
                />
              </>
            ))}
            <S.WrapperButtonVerMais>
              <Button onClick={showMoreHistory} disabled={disableButtonVerMais}>
                Ver mais
              </Button>
            </S.WrapperButtonVerMais>
          </>
        );
      case 'renew':
        return (
          <>
            {qualificationHistory.map((element, index) => (
              <>
                <HistoryOfQualification
                  key={`${element?.qualififer_package?.name}${index}`}
                  element={element}
                  modal={
                    element?.transaction === 'debit' ? (
                      <DailyQualificationsModal element={element} />
                    ) : element?.qualififer_package?.type === 0 ? (
                      <DetailPackageNewClient
                        element={{ total: element.value }}
                        item={{
                          date: element.date,
                          name_broker: element.qualififer_package.name,
                          name_client: '',
                        }}
                      />
                    ) : (
                      <PerfomanceDetailPackage
                        el="element"
                        id_qualifier_bought={element.id_qualifier_bought}
                      />
                    )
                  }
                />
              </>
            ))}
            <S.WrapperButtonVerMais>
              <Button onClick={showMoreHistory} disabled={disableButtonVerMais}>
                Ver mais
              </Button>
            </S.WrapperButtonVerMais>
          </>
        );

      default:
        break;
    }
  };
  return (
    <>
      <MainHeader />
      {!isLoading && <Loading />}
      <BaseMain>
        {dataQualifierBalance?.balance_premium === 0 &&
          dataMovtoDailyQualifier && (
            <S.WrapperBanner>
              <Banner
                icon={<WarningTriangle />}
                text="Em breve você não terá mais acesso aos detalhes da LIA. Contrate um pacote e continue acompanhando a evolução da sua imobiliária."
              />
            </S.WrapperBanner>
          )}
        {dataBanner && (
          <S.WrapperBanner>
            <Banner
              icon={<WarningTriangle />}
              text="O boleto do novo pacote contratado já está disponível. O saldo de qualificações será atualizado assim que recebermos o pagamento."
            >
              <a
                href={dataBanner.link2via}
                target="_blank"
                onClick={() => setDataBanner(undefined)}
              >
                Ver boleto
              </a>
            </Banner>
          </S.WrapperBanner>
        )}
        <S.HeaderWrapper>
          <Link to="/ClientArea">
            <BackButton>
              <ArrowLeft />
            </BackButton>
          </Link>
          <BaseContainer>
            <Heading title="Detalhes da LIA" weight="600" size="large" />
            <S.ButtonsWrapper>
              {Number(idClientImob) > 0 && (
                <Link to={`/Buy-package/${id}/${type}`}>
                  <Button icon={<BuyPackage />}>Comprar pacote </Button>
                </Link>
              )}
              <Button
                as="a"
                href="https://suporte.kenlo.com.br/pt-BR/collections/3795647-kenlo-area-do-cliente"
                target="blank"
                icon={<QuestionCircle />}
              >
                Ajuda
              </Button>
            </S.ButtonsWrapper>
          </BaseContainer>
        </S.HeaderWrapper>
        <S.BodyContentWrapper>
          <S.WrapperAside>{getAside()}</S.WrapperAside>
          <S.WrapperMain>
            <BaseContentMain
              padding="2.4rem 2.4rem 2.4rem 2.4rem"
              title={
                <S.WrapperTitle>
                  <Heading
                    title="Desempenho mensal"
                    weight="600"
                    size="large"
                    color="#1A1A1A"
                  />
                </S.WrapperTitle>
              }
            >
              {getBodyPerformance()}
            </BaseContentMain>

            <BaseContentMain
              padding="2.4rem 2.4rem 2.4rem 2.4rem"
              title={
                <S.WrapperTitle>
                  <Heading
                    title="Histórico"
                    weight="600"
                    size="large"
                    color="#242424"
                  />
                </S.WrapperTitle>
              }
            >
              <S.WrapperDescription>
                <p>Data</p>
                <p>Descrição</p>
                <p>Qualificações</p>
              </S.WrapperDescription>
              <Line width="100%" border="1px" />
              <S.WrapperHistoryOfQualification>
                {getBodyHistory()}
              </S.WrapperHistoryOfQualification>
            </BaseContentMain>
          </S.WrapperMain>
        </S.BodyContentWrapper>
      </BaseMain>
    </>
  );
};
