import styled, { css } from 'styled-components';
import { CounterButtonProps } from '.';

type WidthProps = Pick<CounterButtonProps, 'width'>;

export const Container = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.4rem;
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};
    color: ${theme.colors.neutral.neutral70};
    font-weight: 600;
    letter-spacing: -0.4px;
    line-height: 24px;
  `}
`;

export const WrapperButton = styled.div<WidthProps>`
  ${({ theme, width }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8rem;
    border: 1px solid ${theme.colors.neutral.neutral30};
    padding: 0.8rem 1.6rem;
    width: ${width};
    height: 4rem;
    position: relative;

    button {
      border: 0;
      background: transparent;
      color: ${theme.colors.neutral.neutral100};
      height: 3rem;
      svg {
        display: flex;
        align-items: center;
      }
      p {
        font-size: ${theme.font.sizes.medium};
        color: ${theme.colors.neutral.neutral70};
      }
    }

    span {
      color: ${theme.colors.neutral.neutral70};
      font-size: ${theme.font.sizes.medium};
      font-size: 1.6rem;
      padding: 0.5rem;
    }
  `}
`;
