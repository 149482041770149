import { BackButton } from 'components/BackButton';
import { BaseContainer } from 'components/BaseContainer';
import Button from 'components/Button';
import { FilterButton } from 'components/FilterButton';
import { Heading } from 'components/Heading';
import { ArrowBottom, ArrowLeft, QuestionCircle } from 'components/Icons';
import { Invoice } from 'components/Invoice/Invoice';
import { lastsInvoicesProps } from 'pages/ClienteArea';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { api } from 'services/api';
import { Loading } from 'components/Loading';
import * as S from './styles';

import moment from 'moment';
import 'moment/locale/pt-br';
import { capitalizeFirstLetter, formattedValue } from 'helpers/util';
import { MainHeader } from 'components/MainHeader';
import { BaseMain } from 'components/BaseMain';
import { Dropdown } from 'components/Dropdown';
import { DropdownLink } from 'components/DropdownLink/DropdownLink';

type signaturesDataNameProps = {
  name: string;
};

export const HistoryOfInvoices = () => {
  const [signaturesDataName, setSignaturesDataName] =
    useState<signaturesDataNameProps | null>(null);
  // const limitPerPage = 7;
  // const inititalLimit = limitPerPage;
  const [postsInvoices, setPostsInvoices] = useState<lastsInvoicesProps[]>([]);
  const [postsPerPage, setPostsPerPage] = useState(7);
  const [isLoading, setIsLoading] = useState(false);
  const [filterStatus, setFilterStatus] = useState('');
  const [FilterForDateBegin, setFilterForDateBegin] = useState('');
  const [FilterFoDateEnd, setFilterForDateEnd] = useState('');
  const [moreRecent, setMoreRecent] = useState('');
  const [totalInvoice, setTotalInvoices] = useState(0);

  const accessToken = localStorage.getItem('accessToken');
  const idToken = localStorage.getItem('idToken');

  const { id } = useParams<{ id: string }>();

  const handleFilterStatus = (status: string) => {
    switch (status) {
      case 'aberta':
        setFilterStatus('aberta');
        break;
      case 'paga':
        setFilterStatus('paga');
        break;
      case 'vencida':
        setFilterStatus('vencida');
        break;
      default:
        setFilterStatus('');
    }
  };

  const handleFilterForDate = (date: string) => {
    switch (date) {
      case '2022':
        setFilterForDateBegin('2022-01-01');
        setFilterForDateEnd('2022-12-31');
        break;
      case '2023':
        setFilterForDateBegin('2023-01-01');
        setFilterForDateEnd('2023-12-31');
        break;
      default:
        setFilterForDateBegin('');
        setFilterForDateEnd('');
    }
  };

  const showMoreInvoices = () => {
    setPostsPerPage((postsPerPage) => postsPerPage + 10);
  };
  const handleMoreRecent = (status: string) => {
    status === 'mais recente' ? setMoreRecent('desc') : setMoreRecent('asc');
  };

  const disableButtonCarregarMais = postsPerPage >= totalInvoice;

  useEffect(() => {
    const fetchDataPlan = async () => {
      try {
        const { data } = await api.get(`client/signatures/${id}`, {
          headers: {
            'content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            idToken,
          },
        });
        setSignaturesDataName(data.data.plan);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDataPlan();
  }, [accessToken, idToken, id]);

  useEffect(() => {
    const fetchDataBillings = async () => {
      try {
        const { data } = await api.get(
          `client/billings?page=1&limit=${postsPerPage}&sort=${moreRecent}&status=${filterStatus}&dueDateBegin=${FilterForDateBegin}&dueDateEnd=${FilterFoDateEnd}`,
          {
            headers: {
              'content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
              idToken,
            },
          },
        );
        // setPostsInvoices((lastsInvoicesProps) => [
        //   ...previousInvoices,
        //   ...data.data,
        // ]);

        setPostsInvoices(data.data);
        setTotalInvoices(data.pagination.total);
        setIsLoading(true);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDataBillings();
  }, [
    filterStatus,
    moreRecent,
    accessToken,
    idToken,
    FilterForDateBegin,
    FilterFoDateEnd,
    postsPerPage,
    // page,
  ]);

  return (
    <>
      <MainHeader />
      {!isLoading && <Loading />}
      <BaseMain>
        <S.HeaderWrapper>
          <Link to="/ClientArea">
            <BackButton>
              <ArrowLeft />
            </BackButton>
          </Link>
          <BaseContainer>
            <Heading title="Histórico de faturas" weight="600" size="large" />
            <Button
              as="a"
              href="https://suporte.kenlo.com.br/pt-BR/collections/3795647-kenlo-area-do-cliente"
              target="blank"
              icon={<QuestionCircle />}
            >
              Ajuda
            </Button>
          </BaseContainer>
        </S.HeaderWrapper>
        <S.FilterButtonWrapper>
          <span>
            {`
            ${totalInvoice}
            ${
              postsInvoices.length > 1
                ? 'faturas encontradas'
                : 'fatura encontrada'
            }`}
          </span>
          <Dropdown
            title={false}
            width="24rem"
            element={
              <FilterButton text="Status" size="10.7rem">
                <ArrowBottom />
              </FilterButton>
            }
            height="4.5rem"
            position="-13.5"
          >
            <DropdownLink
              arrowRight={false}
              newTab={false}
              handleClick={() => handleFilterStatus('aberta')}
            >
              Aberta
            </DropdownLink>
            <DropdownLink
              arrowRight={false}
              newTab={false}
              handleClick={() => handleFilterStatus('paga')}
            >
              Paga
            </DropdownLink>
            <DropdownLink
              arrowRight={false}
              newTab={false}
              handleClick={() => handleFilterStatus('vencida')}
            >
              Vencida
            </DropdownLink>
            <DropdownLink
              arrowRight={false}
              newTab={false}
              handleClick={() => handleFilterStatus('ver-todos')}
            >
              Ver Todos
            </DropdownLink>
          </Dropdown>
          <Dropdown
            title={false}
            width="24rem"
            element={
              <FilterButton text="Período" size="11.4rem">
                <ArrowBottom />
              </FilterButton>
            }
            height="4.5rem"
            position="-10"
          >
            <DropdownLink
              arrowRight={false}
              newTab={false}
              handleClick={() => handleFilterForDate('2023')}
            >
              2023
            </DropdownLink>
            <DropdownLink
              arrowRight={false}
              newTab={false}
              handleClick={() => handleFilterForDate('2022')}
            >
              2022
            </DropdownLink>
            <DropdownLink
              arrowRight={false}
              newTab={false}
              handleClick={() => handleFilterForDate('ver-todos')}
            >
              Ver Todos
            </DropdownLink>
          </Dropdown>
          <Dropdown
            title={false}
            width="24rem"
            element={
              <FilterButton text="Mais recente" size="15.1rem">
                <ArrowBottom />
              </FilterButton>
            }
            height="4.5rem"
            position="-.3"
          >
            <DropdownLink
              arrowRight={false}
              newTab={false}
              handleClick={() => handleMoreRecent('mais recente')}
            >
              Mais recentes
            </DropdownLink>
            <DropdownLink
              arrowRight={false}
              newTab={false}
              handleClick={() => handleMoreRecent('mais antigo')}
            >
              Mais antigo
            </DropdownLink>
          </Dropdown>
        </S.FilterButtonWrapper>
        <S.ElementWrapper>
          {postsInvoices.map((item) => {
            return (
              <Invoice
                key={item.id_billing}
                title={`${capitalizeFirstLetter(
                  moment(item.invoiceMonth).format(`MMMM [de] YYYY`),
                )}`}
                subtitle={signaturesDataName?.name}
                value={`${formattedValue(item.value)}`}
                paidValue={`${formattedValue(item.paidValue)}`}
                invoiceDateTitle={`${capitalizeFirstLetter(item.status)}`}
                invoiceDate={`${moment(item.dueDate).format('L')} `}
                paymentDate={`${
                  item.paymentDate ? moment(item.paymentDate).format('L') : '-'
                } `}
                invoiceStatusText={`${capitalizeFirstLetter(item.status)}`}
                idBilling={item.id_billing}
                invoicesBillings={item.invoices_billings}
                invoicesStatus={item.status}
                idTypePay={item.id_typePay}
                creditCardNumber={item.creditCardNumber}
                description={item.description}
              />
            );
          })}
        </S.ElementWrapper>
        {totalInvoice > 7 && (
          <S.ButtonWrapper>
            <Button
              minimal={false}
              border={true}
              size="16.2rem"
              onClick={showMoreInvoices}
              type="submit"
              height="5.6rem"
              disabled={disableButtonCarregarMais}
            >
              Carregar mais
            </Button>
          </S.ButtonWrapper>
        )}
      </BaseMain>
    </>
  );
};
