const Gift = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 2C16.2949 2 17.75 3.45507 17.75 5.25C17.75 5.89455 17.5624 6.49528 17.2387 7.00054L19.75 7C20.4404 7 21 7.55964 21 8.25V11.75C21 12.3544 20.571 12.8586 20.0009 12.9748L20 18.75C20 20.483 18.6435 21.8992 16.9344 21.9949L16.75 22H7.25C5.51697 22 4.10075 20.6435 4.00514 18.9344L4 18.75L4.00005 12.975C3.42947 12.8592 3 12.3548 3 11.75V8.25C3 7.55964 3.55964 7 4.25 7L6.76126 7.00054C6.43763 6.49528 6.25 5.89455 6.25 5.25C6.25 3.45507 7.70507 2 9.5 2C10.5055 2 11.4044 2.45666 12.0006 3.17391C12.5956 2.45666 13.4945 2 14.5 2ZM11.25 12.999H5.5V18.75C5.5 19.6682 6.20711 20.4212 7.10647 20.4942L7.25 20.5H11.25V12.999ZM18.5 12.999H12.75V20.5H16.75C17.6682 20.5 18.4212 19.7929 18.4942 18.8935L18.5 18.75V12.999ZM11.25 8.5H4.5V11.5L11.25 11.499V8.5ZM19.5 11.5V8.5H12.75V11.499L19.5 11.5ZM14.5 3.5C13.5335 3.5 12.75 4.2835 12.75 5.25V6.999H14.524L14.6435 6.9942C15.5429 6.92119 16.25 6.16817 16.25 5.25C16.25 4.2835 15.4665 3.5 14.5 3.5ZM9.5 3.5C8.5335 3.5 7.75 4.2835 7.75 5.25C7.75 6.16817 8.45711 6.92119 9.35647 6.9942L9.476 6.999H11.25V5.25L11.2442 5.10647C11.1712 4.20711 10.4182 3.5 9.5 3.5Z"
        fill="#262626"
      />
    </svg>
  );
};
export default Gift;
