import styled, { css } from 'styled-components';
import { RadioProps } from '.';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Input = styled.input`
  ${({ theme }) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    width: 1.8rem;
    height: 1.8rem;
    border: 0.1rem solid ${theme.colors.neutral.neutral30};
    border-radius: 100%;
    background: transparent;
    transition: background border ${theme.transition.fast};
    outline: none;

    &:before {
      content: '';
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: ${theme.colors.neutral.neutral0};
      transition: ${theme.transition.fast};
      opacity: 0;
      position: absolute;
    }

    /*Com bordas*/
    &:checked {
      border-color: ${theme.colors.primary50};
      background-color: ${theme.colors.primary50};

      &:before {
        opacity: 1;
      }
    }

    /* &:checked {
      &:before {
        opacity: 1;
      }
    } */
  `}
`;

export const Label = styled.label<Pick<RadioProps, 'labelColor'>>`
  ${({ theme, labelColor }) => css`
    cursor: pointer;
    padding-left: ${theme.spacings.xsmall};
    color: ${theme.colors.neutral[labelColor!]};
    line-height: 1;
    font-size: 1.6rem;
  `}
`;
