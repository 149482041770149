import styled, { css } from 'styled-components';

export const Content = styled.div<{ padding: string }>`
  ${({ theme, padding }) => css`
    background: ${theme.colors.neutral.neutral0};
    border-radius: 8px;
    padding: ${padding};
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.neutral120};
  `}
`;
