/* eslint-disable @typescript-eslint/no-unused-vars */
const CaretUp = ({
  width = '19',
  height = '20',
}: {
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.30171 1.071C9.04303 0.642999 9.95639 0.642999 10.6977 1.071L16.6334 4.498C17.3748 4.92601 17.8315 5.71699 17.8315 6.573V13.427C17.8315 14.283 17.3748 15.074 16.6334 15.502L10.6977 18.929C9.95639 19.357 9.04303 19.357 8.30171 18.929L2.36597 15.502C1.62465 15.074 1.16797 14.283 1.16797 13.427V6.573C1.16797 5.71699 1.62465 4.92601 2.36597 4.498L8.30171 1.071Z"
        stroke="#2183D9"
        strokeWidth="1.5"
      />
      <path
        d="M13 11.3333L9.5 8L6 11.3333"
        stroke="#2183D9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default CaretUp;
