import styled, { css } from 'styled-components';
import * as Dialog from '@radix-ui/react-dialog';

export const WrapperInvoiceDetail = styled.div`
  ${({ theme }) => css`
    display: flex;
    border: 1px solid ${theme.colors.neutral.neutral30};
    border-radius: ${theme.border.radius};
    padding: 16px 24px 16px 24px;
    gap: 2.4rem;
    margin: 2.4rem 0 2.4rem 0;
  `}
`;

export const Container = styled.div`
  display: flex;
`;

export const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  inset: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 2;
`;

export const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 60rem;
    background: ${theme.colors.neutral.neutral0};
    border-radius: 8px;
    box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.08);
    padding: 3.2rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
  `}
`;

export const Title = styled(Dialog.Title)`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};
    font-weight: ${theme.font.semiBold};
    color: ${theme.colors.neutral.neutral100};
  `}
`;

export const CloseButton = styled(Dialog.Close)`
  ${({ theme }) => css`
    position: absolute;
    background: transparent;
    border: 0;
    top: 4rem;
    right: 3.5rem;
    line-height: 0;
    cursor: pointer;
    color: ${theme.colors.neutral.neutral100};
  `}
`;

// export const BaseListModal = styled.ul`
//   ${({ theme }) => css`

//   `}
// `

export const ContentDetails = styled.div`
  ${({ theme }) => css`
    header {
      display: grid;
      grid-template-columns: 24rem 12rem 12rem;
      gap: 2.8rem;
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.neutral.neutral50};
      margin-bottom: 1rem;
    }
    .contentPlan {
      margin: 2rem 0 2rem 0;
    }
    ul + ul {
      margin-top: 2rem;
    }
    .planName {
      display: grid;
      grid-template-columns: 24rem 12rem 12rem;
      column-gap: 2.8rem;
      grid-template-rows: min-content;
      row-gap: 1.6rem;
      list-style: none;
      font-size: ${theme.font.sizes.medium};
      padding-left: 0;
      &-row {
        color: ${theme.colors.neutral.neutral100};
        font-weight: ${theme.font.normal};
        &__value {
          color: ${theme.colors.neutral.neutral100};
          font-weight: ${theme.font.bold};
        }
      }
    }
    .additionalPlan {
      display: grid;
      grid-template-columns: 24rem 12rem 12rem;
      column-gap: 2.8rem;
      grid-template-rows: min-content;
      row-gap: 1.6rem;
      list-style: none;
      font-size: ${theme.font.sizes.medium};
      padding-left: 0;
      &-row {
        color: ${theme.colors.neutral.neutral50};
        &__value {
          color: ${theme.colors.neutral.neutral100};
          font-weight: ${theme.font.bold};
        }
      }
      li:first-child {
        margin-left: 1.6rem;
      }
    }
    footer {
      display: grid;
      grid-template-columns: max-content 12rem;
      align-items: center;
      justify-content: space-between;
      margin: inherit;
      span {
        font-size: ${theme.font.sizes.medium};
        font-weight: ${theme.font.bold};
        color: ${theme.colors.neutral.neutral120};
      }
      > button {
        justify-content: flex-start;
      }
    }
  `}
`;
