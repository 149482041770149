import * as Dialog from '@radix-ui/react-dialog';
import * as S from './styles';
import { BoxContent } from 'components/BoxContent';
import Button from 'components/Button';
import { useContext, useEffect, useState } from 'react';
import { BaseModal } from '../BaseModal';
import { theme } from 'styles/theme';
import { Line } from 'components/Line';
import { formattedValue } from 'helpers/util';
import { api } from 'services/api';
import { NotificationBannerContext } from 'contexts/NotificationBannerContext';
import { useHistory, useParams } from 'react-router-dom';
import InfoShape from 'components/Icons/InfoShape';
import { QualificationContext } from 'contexts/AvailableQualification';
import { FeatureContext } from 'contexts/FeaturesContext';
import close from 'assets/image/close_white.png';
import { MessageModalError } from 'components/MessageModalError';
import LoadingSpinnerInButton from 'components/SpinnerLoadingInButton';

type IModalProps = {
  name?: string | undefined;
  cost_qualifier?: number | string | undefined;
  estimated_days?: number | string | undefined;
  amount_qualifier?: number | string | undefined;
  discount_twenty_value?: number;
  discount_value?: string;
  amount_installment: string | number;
  className?: string | undefined;
  estimated_duration: number;
  final_value?: string | number;
  installment_value: number;
  value: number;
  valueName: string;
  id_pack: string | undefined;
  onClose?: () => void;
};

export const DetailPackageSummary = ({
  name,
  id_pack,
  cost_qualifier,
  estimated_duration,
  amount_qualifier,
  amount_installment,
  value,
  installment_value,
  final_value,
  discount_value,
  discount_twenty_value,
  estimated_days,
  valueName,
}: IModalProps) => {
  const [showDetails, setShowDetails] = useState(false);
  const { id, type } = useParams<{
    id: string;
    type: string;
  }>();
  const accessToken = localStorage.getItem('accessToken');
  const idToken = localStorage.getItem('idToken');
  const [statusError, setStatusError] = useState(200);
  const [modalError, setModalError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { setDataBanner } = useContext(NotificationBannerContext);
  const { dataAgency } = useContext(FeatureContext);
  const { dataQualifierBalance, fetchDataQualifierBalance } =
    useContext(QualificationContext);
  const history = useHistory();

  const onClickHandle = async () => {
    if (
      Number(amount_installment) !== undefined ||
      Number(amount_installment) > 0
    ) {
      await confirmPurchase();
    }
  };

  useEffect(() => {
    if (dataQualifierBalance?.balance_light !== 0) {
      dataAgency?.id_client && fetchDataQualifierBalance(dataAgency.id_client);
    }
  }, [
    fetchDataQualifierBalance,
    dataAgency,
    dataQualifierBalance?.balance_light,
  ]);

  const confirmPurchase = async () => {
    try {
      setModalError(false);
      setIsLoading(true);
      const { data } = await api.post(
        'qualifier/bought',
        {
          id_qualifier_pack: id_pack,
          id_qualifier_installment: amount_installment,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            idToken,
          },
        },
      );
      setStatusError(data.success);
      if (Array.isArray(data.data)) {
        setDataBanner(data.data[0]);
      }
      history.push(`/QualifierDetails/${dataAgency?.id_client}/details`);
    } catch (error: any) {
      if (error.response) {
        setStatusError(error.response.status);
        setModalError(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getData = () => {
    return (
      <>
        <BoxContent
          color={theme.colors.neutral.neutral10}
          style={{ padding: '1.6rem 2.4rem 1.6rem 2.4rem' }}
        >
          <S.Row>
            <S.Qualify>
              <S.TexTwo>
                <S.TitleTwo color={theme.colors.neutral.neutral120}>
                  <b>Pacote {name}</b>
                </S.TitleTwo>
                <S.Subtitle color={theme.colors.neutral.neutral130}>
                  {` ${formattedValue(cost_qualifier)}`} por lead{' '}
                </S.Subtitle>
              </S.TexTwo>
              <S.TextOne>
                <S.TitleFour color={theme.colors.neutral.neutral40}>
                  {amount_qualifier} qualificações{' '}
                </S.TitleFour>
                <S.Subtitle color={theme.colors.neutral.neutral130}>
                  <S.Subtitle color={theme.colors.neutral.neutral130}>
                    {id === '3' ? (
                      <span>Duração estimada em 30 dias</span>
                    ) : (
                      <span>
                        Duração estimada em {estimated_duration}{' '}
                        {estimated_duration === 1 ? 'mês' : 'meses'}
                        {estimated_days !== 0 &&
                          ` e ${estimated_days} ${
                            estimated_days === 1 ? 'dia' : 'dias'
                          }`}
                      </span>
                    )}
                  </S.Subtitle>
                </S.Subtitle>
              </S.TextOne>
            </S.Qualify>
          </S.Row>
        </BoxContent>
      </>
    );
  };

  const getTable = () => {
    return (
      <>
        {statusError !== 200 && (
          <MessageModalError
            img={close}
            description="Tivemos um erro na tentativa de compra de pacote, tente novamente."
            isOpen={modalError}
            setIsOpen={setModalError}
          />
        )}
        <BoxContent
          color={theme.colors.neutral.neutral10}
          style={{ padding: '2.4rem' }}
        >
          <S.Row>
            <S.Qualify>
              <S.TextFooter>
                <S.TitleTree color={theme.colors.neutral.neutral130}>
                  Valor do pacote
                </S.TitleTree>
                {Number(id) !== 3 && (
                  <>
                    <S.TitleTree color={theme.colors.neutral.neutral130}>
                      Desconto do pacote
                    </S.TitleTree>
                  </>
                )}
                {Number(discount_value) !== 0 && (
                  <>
                    <S.TitleTree color={theme.colors.neutral.neutral130}>
                      Desconto à vista
                    </S.TitleTree>
                  </>
                )}
              </S.TextFooter>
              <S.TexTree>
                <S.Title color={theme.colors.neutral.neutral70}>
                  <b>{` ${formattedValue(value)}`}</b>
                </S.Title>

                {Number(id) !== 3 && (
                  <>
                    <S.Title color={theme.colors.neutral.neutral70}>
                      <b>{` ${formattedValue(discount_twenty_value)}`}</b>
                    </S.Title>
                  </>
                )}
                {Number(discount_value) !== 0 && (
                  <>
                    <S.Title color={theme.colors.neutral.neutral70}>
                      <b>{`- ${formattedValue(discount_value)}`}</b>
                    </S.Title>
                  </>
                )}
              </S.TexTree>
            </S.Qualify>
          </S.Row>
          <Line width="100%" border="0.1rem" />
          <S.Footer>
            <S.TitleTwo color={theme.colors.neutral.neutral120}>
              <b>
                {Number(discount_value) > 0
                  ? formattedValue(Number(final_value) - Number(discount_value))
                  : formattedValue(final_value)}
              </b>
            </S.TitleTwo>
            <S.Title color={theme.colors.neutral.neutral100}>
              <S.Title color={theme.colors.neutral.neutral100}>
                {installment_value === 0
                  ? `${valueName} no boleto`
                  : `${valueName} de ${formattedValue(
                      installment_value,
                    )} no boleto`}
              </S.Title>
            </S.Title>
          </S.Footer>
        </BoxContent>
        {dataQualifierBalance?.balance_light !== 0 && (
          <BoxContent
            color={theme.colors.neutral.neutral15}
            style={{ padding: '1.6rem' }}
            background={theme.colors.neutral.neutral15}
          >
            <S.TextTool color={theme.colors.neutral.neutral120}>
              <InfoShape />
              <p>
                Se houver saldo das qualificações gratuitas, será substituído
                pelo pacote contratado.
              </p>
            </S.TextTool>
          </BoxContent>
        )}

        <S.Footer>
          <div>
            <Dialog.Close asChild>
              <Button minimal={false} border height="5.6rem">
                Cancelar
              </Button>
            </Dialog.Close>
            {type === 'details' || type === 'new' ? (
              <Button
                minimal={false}
                height="5.6rem"
                disabled={isLoading ? true : false}
                onClick={() => onClickHandle()}
              >
                {isLoading ? (
                  <LoadingSpinnerInButton text="Comprar pacote" />
                ) : (
                  'Comprar pacote'
                )}
              </Button>
            ) : (
              <Button
                minimal={false}
                height="5.6rem"
                disabled={isLoading ? true : false}
                onClick={() => {
                  onClickHandle();
                  history.push(`/ClientArea`);
                }}
              >
                {isLoading ? (
                  <LoadingSpinnerInButton text="Comprar pacote" />
                ) : (
                  'Comprar pacote'
                )}
              </Button>
            )}
          </div>
        </S.Footer>
      </>
    );
  };
  return (
    <>
      <Dialog.Root>
        <Dialog.Trigger asChild>
          <Button
            minimal={false}
            size="medium"
            height="5.6rem"
            disabled={id === undefined}
            onClick={() => setShowDetails(true)}
          >
            Confirmar valores
          </Button>
        </Dialog.Trigger>
        {showDetails && (
          <BaseModal title="Resumo do pacote">
            {getData()}
            {getTable()}
          </BaseModal>
        )}
      </Dialog.Root>
    </>
  );
};
