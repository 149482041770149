import styled, { css } from 'styled-components';
import { LineProps } from '.';

type lineProps = Pick<LineProps, 'border' | 'width'>;

export const WrapperLine = styled.hr<lineProps>`
  ${({ theme, border, width }) => css`
    border: ${border} solid ${theme.colors.neutral.neutral30};
    border-radius: 2px;
    width: ${width};
  `}
`;
