const HexagonMinus = ({
  width = '25',
  height = '24',
}: {
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5307 3.07491L19.4665 6.50191C20.2078 6.92991 20.6645 7.7209 20.6645 8.57691V15.4309C20.6645 16.2869 20.2078 17.0779 19.4665 17.5059L13.5307 20.9329C12.7894 21.3609 11.876 21.3609 11.1347 20.9329L5.19898 17.5059C4.45765 17.0779 4.00098 16.2869 4.00098 15.4309V8.57691C4.00098 7.7209 4.45765 6.92991 5.19898 6.50191L11.1347 3.07491C11.876 2.6469 12.7894 2.6469 13.5307 3.07491Z"
        stroke="#808080"
        strokeWidth="1.5"
      />
      <path
        d="M15.333 12L9.33301 12"
        stroke="#808080"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default HexagonMinus;
