import React from 'react';
import styled, { css } from 'styled-components';

export const WrapperMessage: React.FC = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.feedback.feedbackAlert10};
    border-radius: ${theme.border.radiustwo};
    padding: 0.4rem 2.2rem;
    display: grid;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    }
  `}
`;

export const TextMessage: React.FC = styled.p`
  ${() => css`
    text-color: #black
    }
  `}
`;
