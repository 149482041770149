import { Decrease, DecreaseDisabled, Increase } from 'components/Icons';
import * as S from './styles';
import { InputEdit } from './InputEdit';
import { useEffect } from 'react';
//import { useState } from 'react';
//import { useState } from 'react';

export type CounterButtonProps = {
  disabled?: boolean;
  title?: string;
  count: number;
  width?: string;
  decCount: () => void;
  inCount: () => void;
  setCount?: (value: any) => void;
  handleSetCount: (e: any) => void;
  descriptionQualification: number;
};

export const CustomQualifier = ({
  disabled = false,
  count,
  setCount,
  decCount,
  handleSetCount,
  inCount,
  title,
  width,
  descriptionQualification,
}: CounterButtonProps) => {
  useEffect(() => {
    if (
      Number(count) <= descriptionQualification ||
      isNaN(count) ||
      Number(count) >= 30000
    ) {
      setCount?.(descriptionQualification);
    }
    //eslint-disable-next-line
  }, [count, setCount]);

  return (
    <S.Container>
      <S.WrapperButton width={width}>
        <button
          onClick={decCount}
          disabled={count <= descriptionQualification && disabled}
        >
          {disabled === true ? <DecreaseDisabled /> : <Decrease />}
        </button>
        <InputEdit
          value={count}
          onChange={(e) => setCount?.(e)}
          inputValue={count}
          decCount={decCount}
          inCount={inCount}
          count={count}
          handleSetCount={handleSetCount}
          descriptionQualification={descriptionQualification}
        />
        <button onClick={inCount}>{<Increase />}</button>
      </S.WrapperButton>
      <S.Title>{title}</S.Title>
    </S.Container>
  );
};
