import { Price } from '../Price';
import * as S from './styles';

import { PriceProps } from '../Price/index';
// import { theme } from 'styles/theme';
import { CounterButton } from '../CounterBotton';
import { useState } from 'react';

type CardProps = {
  title: string;
  percentage: number;
  icon: React.ReactNode;
  price: string;
  installments: number;
  final_value: string;
  value: string;
  valueForQualification: string;
  isPromo: boolean;
  id: string;
  className: string;
  handleClick: () => void;
  count: number;
  setCount?: (value: any) => void;
  handleCountIncrease: () => void;
  handleCountDecrease: () => void;
  handleSetCount: (e: any) => void;
} & PriceProps;

export const CardNewPackage = ({
  id,
  className,
  isPromo,
  valueForQualification,
  handleCountDecrease,
  handleCountIncrease,
  handleSetCount,
  count,
  setCount,
  ...PriceProps
}: CardProps) => {
  const [minimumAmountOfLeads] = useState(count);
  return (
    <S.Wrapper className={className} id={id}>
      <S.Row JustifyContent="flex-start">
        <CounterButton
          disabled={count === minimumAmountOfLeads && true}
          width="18rem"
          title="Qualificações"
          count={count}
          setCount={setCount}
          handleSetCount={handleSetCount}
          inCount={() => handleCountIncrease()}
          decCount={() => handleCountDecrease()}
        />
      </S.Row>
      <S.Row JustifyContent="flex-end">
        <S.TextOne marginRight="5rem">
          <S.TextCustomized
            fontWeight={700}
            color={`${!isPromo ? '#2183D9' : '#404040'}`}
            lineHeight={'20px'}
            fontSize="1.2rem"
          >
            {valueForQualification} por lead
          </S.TextCustomized>

          {isPromo && (
            <S.TextCustomized
              fontWeight={700}
              color={'#2183D9'}
              lineHeight={'20px'}
              fontSize="1.2rem"
            >
              Valor promocional!
            </S.TextCustomized>
          )}
        </S.TextOne>
        <Price {...PriceProps} />
      </S.Row>
    </S.Wrapper>
  );
};
