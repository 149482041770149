import styled, { css } from 'styled-components';

import { generateMedia } from 'styled-media-query';

const customMedia = generateMedia({
  bigDesktop: '85.4375em',
});

export const HeaderWrapper = styled.div`
  ${() => css`
    display: flex;
    gap: 0.8rem;
  `}
`;

export const ButtonsWrapper = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    gap: 2.6rem;
  `}
`;

export const BodyContentWrapper = styled.div`
  ${() => css`
    display: grid;
    grid-template-columns: 41.8rem 1fr;
    gap: 2.4rem;
    margin-top: 1.6rem;

    ${customMedia.lessThan('bigDesktop')`
      grid-template-columns: 39.8rem 1fr;
      gap: 2.8rem;
    `}
  `}
`;
export const WrapperAside = styled.div`
  ${() => css`
    height: auto;
  `}
`;

export const Ul = styled.ul`
  ${({ theme }) => css`
    list-style: none;
    display: contents;
    li {
      display: flex;
      justify-content: space-between;
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.neutral.neutral50};
      line-height: 2.4rem;
      letter-spacing: -0.4px;
      font-weight: 500;
      &:not(:last-child) {
        margin-bottom: 0.3rem;
      }

      p {
        color: ${theme.colors.neutral.neutral70};
        font-weight: 700;
      }
    }
  `}
`;

export const Aside = styled.aside`
  ${({ theme }) => css`
    background: ${theme.colors.neutral.neutral0};
    border-radius: ${theme.border.radius};
    padding: 2.4rem;

    > h1 {
      width: 12.3rem;
      font-size: ${theme.font.sizes.xlarge};
      font-weight: 700;
      line-height: 3.2rem;
      letter-spacing: -0.8px;
      color: ${theme.colors.neutral.neutral100};
    }
  `}
`;

export const TitleWrapper = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2.4rem;
    margin-bottom: 0.8rem;
  `}
`;

export const WrapperTitleContent = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
  `}
`;

export const WrapperDataContent = styled.div`
  ${() => css`
    > div:not(:last-child) {
      margin-bottom: 0.8rem;
    }
    .line {
      margin: 2.4rem 0 !important;
    }
  `}
`;

export const WrapperMain = styled.main`
  ${() => css`
    width: 86rem;
  `}
`;

export const ListValueWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.neutral.neutral0};
    border-radius: ${theme.border.radius};
    padding: 2.4rem;
  `}
`;

export const WrapperTitleNewPlan = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`;

export const WrapperParagraph = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.neutral.neutral70};
    font-weight: 500;
    margin-top: 3.2rem;
  `}
`;

export const WrapperQuantityAndFrequency = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 3.2rem 0;
  `}
`;

export const WrapperQuantity = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    gap: 2.4rem;
  `}
`;

export const WrapperQuantityTitle = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.neutral.neutral70};
  `}
`;

export const WrapperQuantityDecreaseAndIncrease = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8rem;
    border: 1px solid ${theme.colors.neutral.neutral30};
    padding: 0.8rem 1.6rem;
    width: 13.8rem;
    height: 4rem;

    button {
      border: 0;
      background: transparent;
      color: ${theme.colors.neutral.neutral100};
      svg {
        display: flex;
        align-items: center;
      }
    }

    span {
      color: ${theme.colors.neutral.neutral70};
      font-size: ${theme.font.sizes.medium};
      font-size: 1.6rem;
    }
  `}
`;

export const WrapperQuantityMonthAndYear = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8rem;
    border: 1px solid ${theme.colors.neutral.neutral30};
    padding: 0.8rem 1.6rem;
    width: 16.6rem;
    height: 4rem;
    position: relative;

    button {
      border: 0;
      background: transparent;
      color: ${theme.colors.neutral.neutral100};
      svg {
        display: flex;
        align-items: center;
      }
      p {
        font-size: ${theme.font.sizes.medium};
        color: ${theme.colors.neutral.neutral70};
      }
    }

    span {
      color: ${theme.colors.neutral.neutral70};
      font-size: ${theme.font.sizes.medium};
      font-size: 1.6rem;
      position: absolute;
      width: 9rem;
      right: 70px;
      top: 3px;
      padding: 0.5rem;
    }
  `}
`;

export const WrapperButtonColor = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.neutral0}!important;
    background: ${theme.colors.primary50};
    border-radius: 1.6rem;
    font-size: 1.6rem;
  `}
`;

export const WrapperPlan = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.4rem;
    border-radius: 1.2rem;
    border: 2px solid ${theme.colors.neutral.neutral10};
  `}
`;

export const WrapperUser = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: start;
    flex-direction: column;
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.neutral.neutral70};
    > div {
      display: flex;
      gap: 0.8rem;
      & + div {
        margin-top: 0.8rem;
      }
    }
  `}
`;

export const WrappeMonthValue = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    font-size: ${theme.font.sizes.xlarge};
    font-weight: 700;
    color: ${theme.colors.neutral.neutral100};

    span + span {
      font-size: ${theme.font.sizes.large};
      font-weight: 500;
      color: ${theme.colors.neutral.neutral100};
    }

    h1 {
      font-size: ${theme.font.sizes.xsmall};
      font-weight: 500;
      color: ${theme.colors.neutral.neutral100};
    }

    p {
      font-size: 1.2rem;
      color: ${theme.colors.feedback.feedbackError50};
      font-weight: 600;
    }
  `}
`;

export const BenefitWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.neutral.neutral0};
    border-radius: ${theme.border.radius};
    margin: 1.6rem 0;
    padding: 2.4rem;
  `}
`;

export const BenefitTitle = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2.7rem;
    margin-bottom: 2.4rem;
  `}
`;

export const BenefitDescriptionsWrapper = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${customMedia.greaterThan('bigDesktop')`
      margin-right: 3.5rem;
      display: flex;
      justify-content: none;
    `}
  `}
`;

export const BenefitDescriptions = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 1.1rem;
    //justify-content: space-between;
    span {
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.neutral.neutral100};
      font-weight: 500;
    }
    ${customMedia.greaterThan('bigDesktop')`
        &:not(:last-child) {
          margin-right: 3.5rem;
          display: flex;
          justify-content: none;
        }
    `}
  `}
`;

export const Line = styled.hr`
  ${({ theme }) => css`
    border: 2px solid ${theme.colors.neutral.neutral30};
    width: 4rem;
    margin: 2.4rem 0;
  `}
`;

export const WrapperPayment = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 0.6rem;
    span {
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.neutral.neutral70};
    }
  `}
`;

export const WrapperPaymentOptions = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: baseline;
    gap: 0.8rem;
    span {
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.neutral.neutral70};
      font-weight: 700;
      margin-left: 0;
    }
    h2 {
      font-size: ${theme.font.sizes.xlarge};
    }
  `}
`;

export const WrapperPaymentTotal = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: baseline;
    gap: 0.8rem;
    height: 3.2rem;
    span {
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.neutral.neutral70};
      font-weight: 700;
      margin-left: 0;
    }
    h2 {
      font-size: ${theme.font.sizes.xlarge};
    }
    a {
      text-decoration: none;
      color: ${theme.colors.primary50};
      font-size: ${theme.font.sizes.medium};
      font-weight: 700;

      &:hover {
        color: ${theme.colors.primary70};
      }
    }

    .buttonOpcao {
      span {
        color: ${theme.colors.primary50};
      }
    }
  `}
`;

export const WrapperBanner = styled.div`
  ${() => css`
    margin-top: 1.6rem;
  `}
`;

export const WrapperFooter = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`;

export const WrapperToolTip = styled.div`
  ${() => css`
    position: relative;
    span {
      position: absolute;
      top: 0.3rem;
      left: 0.8rem;
    }
  `}
`;
