import { DataDetails } from 'components/DataDetails';
import { Heading } from 'components/Heading';
import { BaseContainer } from 'components/BaseContainer';
import * as S from './styles';
import Button from 'components/Button';
import { Info } from 'components/Icons';
import { movideskChatWidget } from '../../contexts/MovideskLogin';

type RealStateProps = {
  img?: string;
  title: string | undefined;
  subtitle?: string | undefined;
  cnpj: string | undefined;
  creci: string | undefined;
  email?: Array<string>;
};

export const RealState = ({
  img,
  title,
  subtitle,
  cnpj,
  creci,
  email,
}: RealStateProps) => {
  const listEmail = email;
  const cnpjFormated = cnpj
    ? cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
    : '';

  const creciFormated = creci
    ? creci.replace(/^(\d{5})([A-Za-z])/, '$1-$2')
    : '';

  const handleSolicitarAlteracao = () => {
    const chatLine = document.querySelector('.md-chat-widget-container');
    if (chatLine?.classList.contains('md-fade-to-invisible')) {
      movideskChatWidget.maximize();
    } else {
      movideskChatWidget.minimize();
    }
  };

  return (
    <BaseContainer>
      <S.Wrapper>
        <S.WrapperImg>
          <img src={img} alt={`Logo ${title}`} />
        </S.WrapperImg>
        <Heading
          title={title}
          subtitle={subtitle}
          size="xlarge"
          subtitleSize="medium"
          weight="700"
          color="#1A1A1A"
        />
      </S.Wrapper>
      <DataDetails title="CNPJ" subtitle={cnpjFormated} color="neutral70" />
      {!!creci && (
        <DataDetails title="CRECI" subtitle={creciFormated} color="neutral70" />
      )}
      <S.HoverWrapper>
        <S.EmailWrapper>
          <DataDetails
            title="E-mail de cobrança"
            subtitle={listEmail?.[0]}
            uppercase={false}
            color="neutral70"
          />
          {listEmail && listEmail.length > 1 && (
            <S.Count> +{listEmail?.length - 1}</S.Count>
          )}
        </S.EmailWrapper>
        {listEmail && listEmail.length > 1 && (
          <S.UlWrapper>
            {email?.map((item, index) => {
              if (index != 0) {
                return <li key={index}>{item}</li>;
              }
            })}
          </S.UlWrapper>
        )}
      </S.HoverWrapper>
      <Button icon={<Info />} onClick={handleSolicitarAlteracao}>
        Solicitar alteração
      </Button>
    </BaseContainer>
  );
};
