import * as S from './styles';

// import { useState } from 'react';

type ToggleButtonProps = {
  title?: string;
  frequency: () => void;
  isToggled?: boolean;
};

export const ToggleButton = ({
  title,
  frequency,
  isToggled = false,
}: ToggleButtonProps) => {
  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      <S.Content>
        <S.SpanTitle className={`mensal ${!isToggled ? 'active' : ''}`}>
          Mensal
        </S.SpanTitle>
        <S.Label>
          <input
            className="label"
            type="checkbox"
            name="planoAnual"
            onChange={frequency}
            checked={isToggled}
            onClick={frequency}
          />
          <span className="slider round"></span>
        </S.Label>
        <S.SpanTitle className={`anual ${isToggled ? 'active' : ''}`}>
          Anual
        </S.SpanTitle>
      </S.Content>
    </S.Container>
  );
};
