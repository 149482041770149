import * as S from './styles';
export type InvoiceStatusProps = {
  text: React.ReactNode;
  status: 'aberta' | 'paga' | 'cancelada' | 'vencida' | string | boolean;
  icon?: React.ReactNode;
  colortText?: string;
  fontWeight?: string;
};
export const InvoiceStatus = ({
  status,
  text,
  icon,
  colortText,
  fontWeight,
}: InvoiceStatusProps) => {
  return (
    <S.Wrapper status={status}>
      {icon}
      <S.Text color={colortText} fontWeight={fontWeight}>
        {text}
      </S.Text>
    </S.Wrapper>
  );
};
