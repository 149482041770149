const Robo = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7534 13.9999C18.9961 13.9999 20.0034 15.0073 20.0034 16.2499V17.155C20.0034 18.2487 19.526 19.2879 18.6961 20.0003C17.1307 21.3442 14.8904 22.0011 12.0004 22.0011C9.11087 22.0011 6.87205 21.3445 5.30918 20.0008C4.48056 19.2884 4.00391 18.25 4.00391 17.1572V16.2499C4.00391 15.0073 5.01127 13.9999 6.25391 13.9999H17.7534ZM17.7534 15.4999H6.25391C5.83969 15.4999 5.50391 15.8357 5.50391 16.2499V17.1572C5.50391 17.8129 5.7899 18.4359 6.28707 18.8634C7.54516 19.945 9.44117 20.5011 12.0004 20.5011C14.5603 20.5011 16.4582 19.9447 17.7191 18.8622C18.2169 18.4347 18.5034 17.8112 18.5034 17.155V16.2499C18.5034 15.8357 18.1676 15.4999 17.7534 15.4999ZM11.8989 2.00733L12.0007 2.00049C12.3804 2.00049 12.6942 2.28264 12.7438 2.64872L12.7507 2.75049L12.7499 3.49949L16.2504 3.49999C17.493 3.49999 18.5004 4.50735 18.5004 5.74999V10.2546C18.5004 11.4972 17.493 12.5046 16.2504 12.5046H7.75036C6.50772 12.5046 5.50036 11.4972 5.50036 10.2546V5.74999C5.50036 4.50735 6.50772 3.49999 7.75036 3.49999L11.2499 3.49949L11.2507 2.75049C11.2507 2.37079 11.5328 2.057 11.8989 2.00733L12.0007 2.00049L11.8989 2.00733ZM16.2504 4.99999H7.75036C7.33615 4.99999 7.00036 5.33578 7.00036 5.74999V10.2546C7.00036 10.6688 7.33615 11.0046 7.75036 11.0046H16.2504C16.6646 11.0046 17.0004 10.6688 17.0004 10.2546V5.74999C17.0004 5.33578 16.6646 4.99999 16.2504 4.99999ZM9.74965 6.49999C10.4396 6.49999 10.9989 7.05932 10.9989 7.74928C10.9989 8.43925 10.4396 8.99857 9.74965 8.99857C9.05969 8.99857 8.50036 8.43925 8.50036 7.74928C8.50036 7.05932 9.05969 6.49999 9.74965 6.49999ZM14.2424 6.49999C14.9324 6.49999 15.4917 7.05932 15.4917 7.74928C15.4917 8.43925 14.9324 8.99857 14.2424 8.99857C13.5524 8.99857 12.9931 8.43925 12.9931 7.74928C12.9931 7.05932 13.5524 6.49999 14.2424 6.49999Z"
        fill="#404040"
      />
    </svg>
  );
};
export default Robo;
