import styled, { css } from 'styled-components';

import { generateMedia } from 'styled-media-query';
import LIA from '../../assets/image/LIA.png';

export const DataDetailsLia = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    h2 {
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.neutral.neutral50};
      font-weight: 500;
      letter-spacing: -0.4px;
    }
    span {
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.neutral.neutral140};
      font-weight: 700;
    }
  `}
`;

export const Button = styled.button<{
  background?: string;
  color?: string;
  borderColor?: string;
  isButtuonOutline?: boolean;
}>`
  ${({ theme, background, color, borderColor, isButtuonOutline }) => css`
    background-color: ${background || 'transparent'};
    border: 2px solid ${borderColor || 'transparent'};
    border-radius: 0.8rem;
    padding: ${background ? '8px 16px' : '8px 0px'};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: 700;
    line-height: '24px';
    color: ${color || 'white'};
    cursor: pointer;
    &:hover {
      background: ${background && isButtuonOutline ? '#114879' : 'transparent'};
      color: ${background && isButtuonOutline ? '#fff' : '#114879'};
      border: 2px solid #114879;
    }
  `}
`;
export const ButtonInstallments = styled.button<{
  background?: string;
  color?: string;
  borderColor?: string;
}>`
  ${({ theme, background, color, borderColor }) => css`
    background-color: ${background || 'transparent'};
    border: 2px solid ${borderColor || 'transparent'};
    border-radius: 0.8rem;
    padding: ${background ? '8px 16px' : '8px 0px'};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: 700;
    line-height: '24px';
    color: ${color || 'white'};
    cursor: pointer;
    &:hover {
      color: #114879;
    }
  `}
`;
const customMedia = generateMedia({
  bigDesktop: '85.4375em',
});

export const Row = styled.div<{
  JustifyContent?: string;
  margin?: string;
  gap?: string;
}>`
  ${({ JustifyContent, margin, gap }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: row;
    justify-content: ${JustifyContent || 'space-between'};
    margin-top: ${margin || 0};
    gap: ${gap || 0};
  `}
`;
export const DivIcon = styled.div`
  margin-right: 1rem;
`;

export const TextCustomized = styled.span<{
  color?: string;
  fontWeight?: number;
  lineHeight?: string;
  fontSize?: string;
}>`
  ${({ theme, color, fontWeight, lineHeight, fontSize }) => css`
    font-size: ${fontSize || theme.font.sizes.xsmall};
    color: ${color || theme.colors.neutral.neutral130};
    font-weight: ${fontWeight || 700};
    line-height: ${lineHeight || '24px'};
  `}
`;
export const HeaderWrapper = styled.div`
  ${() => css`
    display: flex;
    gap: 0.8rem;
  `}
`;

export const ButtonsWrapper = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    gap: 2.6rem;
  `}
`;
export const SubHeader = styled.div`
  display: flex;
  gap: 0.8rem;
  margin-bottom: 3rem;
`;
export const BodyContentWrapper = styled.div`
  ${() => css`
    display: grid;
    grid-template-columns: 41.8rem 1fr;
    gap: 2.4rem;
    margin-top: 1.6rem;

    ${customMedia.lessThan('bigDesktop')`
      grid-template-columns: 39.8rem 1fr;
      gap: 2.4rem;
    `}
  `}
`;

export const WrapperAside = styled.div`
  ${() => css`
    height: auto;
  `}
`;

export const Aside = styled.aside`
  ${({ theme }) => css`
    background: ${theme.colors.neutral.neutral0};
    border-radius: ${theme.border.radius};
    padding: 2.4rem;
    margin-bottom: 3rem;
  `}
`;

export const TitleWrapper = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2.4rem;
    margin-bottom: 0.8rem;
  `}
`;

export const WrapperTitleContent = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
  `}
`;

export const WrapperDataContent = styled.div<{ marginTop?: string }>`
  ${({ marginTop }) => css`
    margin-top: ${marginTop || '0'};
    div:not(:last-child) {
      margin-bottom: 0.8rem;
    }
  `}
`;

export const ImageLia = styled.div`
  background: url(${LIA}) no-repeat center;
  width: 40%;
  height: 150px;
  border-radius: 1rem;
`;
export const WrapperPerformance = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 2rem;
    align-items: center;

    span {
      font-size: ${theme.font.sizes.large};
      font-weight: 600;
      line-height: 2.4rem;
      letter-spacing: -0.4px;
    }

    p {
      display: flex;
      justify-content: space-between;
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.neutral.neutral130};
      line-height: 2.4rem;
      letter-spacing: -0.4px;
      font-weight: 500;
    }
  `}
`;

export const WrapperMain = styled.main`
  ${() => css``}
`;

export const ListValueWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.neutral.neutral0};
    border-radius: ${theme.border.radius};
    padding: 2.4rem;
  `}
`;

export const WrapperTitleNewPlan = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3.2rem;
  `}
`;

export const WrapperBenefit = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > .active {
      border: 2px solid ${theme.colors.primary50};
      border-radius: ${theme.border.radius};
    }
  `}
`;
export const DivPLans = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 50% 1fr;
    gap: 1rem;
    & > .active {
      border: 2px solid #7ab5e8;
      border-radius: ${theme.border.radius};
    }
  `}
`;
export const WrapperParagraph = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.neutral.neutral70};
    font-weight: 500;
    margin-top: 3.2rem;
  `}
`;

export const WrapperQuantityAndFrequency = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    gap: 2.4rem;
  `}
`;

export const WrapperQuantity = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    gap: 2.4rem;
  `}
`;

export const WrapperQuantityTitle = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.neutral.neutral70};
  `}
`;

export const WrapperQuantityDecreaseAndIncrease = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8rem;
    border: 1px solid ${theme.colors.neutral.neutral30};
    padding: 0.8rem 1.6rem;
    width: 13.8rem;
    height: 4rem;

    button {
      border: 0;
      background: transparent;
      color: ${theme.colors.neutral.neutral100};
      svg {
        display: flex;
        align-items: center;
      }
    }

    span {
      color: ${theme.colors.neutral.neutral70};
      font-size: ${theme.font.sizes.medium};
      font-size: 1.6rem;
    }
  `}
`;

export const WrapperQuantityMonthAndYear = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8rem;
    border: 1px solid ${theme.colors.neutral.neutral30};
    padding: 0.8rem 1.6rem;
    width: 16.6rem;
    height: 4rem;
    position: relative;

    button {
      border: 0;
      background: transparent;
      color: ${theme.colors.neutral.neutral100};
      svg {
        display: flex;
        align-items: center;
      }
      p {
        font-size: ${theme.font.sizes.medium};
        color: ${theme.colors.neutral.neutral70};
      }
    }

    span {
      color: ${theme.colors.neutral.neutral70};
      font-size: ${theme.font.sizes.medium};
      font-size: 1.6rem;
      position: absolute;
      width: 9rem;
      right: 70px;
      top: 3px;
      padding: 0.5rem;
    }
  `}
`;

export const WrapperButtonColor = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.neutral0}!important;
    background: ${theme.colors.primary50};
    border-radius: 1.6rem;
    font-size: 1.6rem;
  `}
`;

export const WrapperPlan = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.4rem;
    border-radius: 1.2rem;
    border: 2px solid ${theme.colors.neutral.neutral10};
  `}
`;

export const WrapperUser = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: start;
    flex-direction: column;
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.neutral.neutral70};
    > div {
      display: flex;
      gap: 0.8rem;
      & + div {
        margin-top: 0.8rem;
      }
    }
  `}
`;

export const WrappeMonthValue = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    font-size: ${theme.font.sizes.xlarge};
    font-weight: 700;
    color: ${theme.colors.neutral.neutral100};

    > span + span {
      font-size: ${theme.font.sizes.large};
      font-weight: 500;
      color: ${theme.colors.neutral.neutral100};
    }
  `}
`;

export const BenefitWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.neutral.neutral0};
    border-radius: ${theme.border.radius};
    margin-top: 1.6rem;
    padding: 2.4rem;
  `}
`;

export const WrapperContracts = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.neutral.neutral5};
    border-radius: 3.5rem;
    padding: 0.8rem 1.6rem;
    height: 4rem;
  `}
`;

export const BenefitTitle = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2.7rem;
    margin-bottom: 2.4rem;
  `}
`;

export const BenefitDescriptionsWrapper = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${customMedia.greaterThan('bigDesktop')`
      margin-right: 3.5rem;
      display: flex;
      justify-content: none;
    `}
  `}
`;

export const BenefitDescriptions = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 1.1rem;
    //justify-content: space-between;
    span {
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.neutral.neutral100};
      font-weight: 500;
    }
    ${customMedia.greaterThan('bigDesktop')`
        &:not(:last-child) {
          margin-right: 3.5rem;
          display: flex;
          justify-content: none;
        }
    `}
  `}
`;

export const Line = styled.hr`
  ${({ theme }) => css`
    border: 2px solid ${theme.colors.neutral.neutral30};
    width: 4rem;
    margin: 2.4rem 0;
  `}
`;

export const WrapperPayment = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 0.6rem;
    span {
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.neutral.neutral70};
    }
  `}
`;

export const WrapperPaymentTotal = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 0.8rem;
    align-items: baseline;

    > h2 {
      font-size: ${theme.font.sizes.xlarge};
    }
    a {
      text-decoration: none;
      color: ${theme.colors.primary50};
      font-size: ${theme.font.sizes.medium};
      font-weight: 700;

      &:hover {
        color: ${theme.colors.primary70};
      }
    }
    .buttonOpcao {
      color: ${theme.colors.primary50};
    }
  `}
`;

export const WrapperFooter = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`;

export const WrapperBanner = styled.main`
  ${() => css`
    margin-top: 2.4rem;
  `}
`;
