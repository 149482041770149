import React from 'react';
import styled, { css } from 'styled-components';

export const Wrapper: React.FC = styled.div`
  ${({ theme }) => css`
    background: #deedf9;
    border-radius: ${theme.border.radius};
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    text-size-adjust: 100%;
    margin-bottom: 2rem;
    svg {
      margin-right: 23px;
    }
  `}
`;
export const TextCustomized = styled.span<{
  color?: string;
  fontWeight?: number;
  lineHeight?: string;
  fontSize?: string;
}>`
  ${({ theme, color, fontWeight, lineHeight, fontSize }) => css`
    font-size: ${fontSize || theme.font.sizes.xsmall};
    color: ${color || theme.colors.neutral.neutral130};
    font-weight: ${fontWeight || 700};
    line-height: ${lineHeight || '24px'};
  `}
`;
