import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    border: 2px solid ${theme.colors.neutral.neutral5};
    border-radius: ${theme.border.radius};
    width: 26rem;
    /* height: 35.2rem; */
    padding: 2.4rem;
    cursor: pointer;
  `}
`;

export const WrapperHeader = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    letter-spacing: -1px;
    gap: 1rem;
    svg {
      flex: 1;
    }
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.neutral.neutral140};
    font-weight: 500;
    margin: 1.6rem 0 3.2rem 0;
  `}
`;

export const Benefit = styled.div`
  ${({ theme }) => css`
    margin-bottom: 3.2rem;
    div + div {
      margin-top: 1.6rem;
    }
    span {
      color: ${theme.colors.neutral.neutral140} !important;
    }
  `}
`;
