const WarningTriangle = () => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="#242424"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.90873 0.782164C10.9485 0.206251 12.2465 0.540893 12.8844 1.5224L12.9672 1.66023L20.7261 15.6685C20.9109 16.0021 21.0079 16.3773 21.0079 16.7587C21.0079 17.9495 20.0827 18.9243 18.9119 19.0035L18.7579 19.0087H3.24211C2.8608 19.0087 2.48575 18.9118 2.15216 18.7271C1.11232 18.1513 0.707282 16.8734 1.20084 15.812L1.27373 15.6687L9.03053 1.66046C9.23506 1.2911 9.53939 0.986735 9.90873 0.782164ZM11.0007 14.0018C10.4491 14.0018 10.002 14.4489 10.002 15.0004C10.002 15.552 10.4491 15.9991 11.0007 15.9991C11.5522 15.9991 11.9993 15.552 11.9993 15.0004C11.9993 14.4489 11.5522 14.0018 11.0007 14.0018ZM10.9985 5.99806C10.4857 5.99825 10.0632 6.38444 10.0056 6.8818L9.99888 6.99842L10.0007 11.9993L10.0075 12.1159C10.0654 12.6132 10.4882 12.9991 11.001 12.9989C11.5139 12.9988 11.9364 12.6126 11.994 12.1152L12.0007 11.9986L11.9989 6.9977L11.9921 6.88108C11.9342 6.38376 11.5114 5.99788 10.9985 5.99806Z" />
    </svg>
  );
};
export default WarningTriangle;
