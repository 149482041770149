import styled, { css } from 'styled-components';
import { ToolTipProps } from '.';

export type WrapperProps = Pick<ToolTipProps, 'height' | 'right'>;

export const Link = styled.span<WrapperProps>`
  ${({ theme, height, right }) => css`
    display: flex;
    align-items: center;
    text-decoration: none;
    position: relative;
    cursor: pointer;

    svg {
      stroke: ${theme.colors.neutral.neutral70};
      fill: none;
      display: block;

      &:hover {
        stroke: ${theme.colors.neutral.neutral0};
        fill: ${theme.colors.neutral.neutral70};
      }
    }

    &::after {
      content: attr(aria-label);
      background: ${theme.colors.neutral.neutral70};
      box-shadow: 0px 0px 16px rgba(36, 36, 36, 0.12);
      white-space: pre-wrap;
      color: ${theme.colors.neutral.neutral0};
      border-radius: ${theme.border.radius};
      transition: all 0.2s ease;
      padding: 1.2rem;
      opacity: 0;
      top: ${height};
      right: ${right};
      position: absolute;
      font-size: 1.2rem;
      pointer-events: none;
      width: 28.4rem;
      z-index: 9999;
      text-align: left;
    }

    &:hover::after {
      pointer-events: all;
      opacity: 1;
    }
  `}
`;
