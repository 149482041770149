import styled, { css } from 'styled-components';

export const Overlay = styled.div`
  ${() => css`
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    width: 100vw;
    height: 100vh;
    inset: 0;
    z-index: 999999999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    min-width: 40rem;
    height: 8rem;
    border-radius: ${theme.border.radius};
    padding: 1.6rem;
    background: ${theme.colors.neutral.neutral40};
    box-shadow: 0px 0px 2px rgba(26, 26, 26, 0.15),
      0px 0px 16px rgba(26, 26, 26, 0.2);

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    p {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.neutral.neutral100};
      font-weight: 600;
    }

    img {
      cursor: pointer;
    }

    .wrapperTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    span {
      display: inline-block;
      color: ${theme.colors.neutral.neutral0};
      font-size: ${theme.font.sizes.medium};
      width: 27.2rem;
      height: 4.8rem;
      letter-spacing: -0.4px;
    }
  `}
`;
