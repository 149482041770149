import moment from 'moment';
import { createContext, useCallback, useState } from 'react';
import { api } from 'services/api';

type ChildrenProps = {
  children: React.ReactNode;
};

export type movtoDailyProps = {
  date: string;
  value: number;
  id_qualifier_package: number;
  id_qualifier_bought: number;
  type_transaction: string;
  transaction: string;
  qualififer_package: {
    id_qualifier_pack: number;
    name: string;
    has_bot: boolean;
    type: number;
    descType: string;
  };
};

type MovtoDailyQualifierContextType = {
  dataMovtoDailyQualifier: movtoDailyProps[];
  fetchDataMovtoDailyQualifier: (id: string | undefined) => void;
  setClearDataMovtoDaily: () => void;
};

export const MovtoDailyQualifierContext = createContext(
  {} as MovtoDailyQualifierContextType,
);

export const MovtoDailyQualifierProvider = ({ children }: ChildrenProps) => {
  const [dataMovtoDailyQualifier, setDataMovtoDailyQualifier] = useState<
    movtoDailyProps[]
  >([]);

  const accessToken = localStorage.getItem('accessToken');
  const idToken = localStorage.getItem('idToken');

  const setClearDataMovtoDaily = () => {
    setDataMovtoDailyQualifier([]);
  };

  const fetchDataMovtoDailyQualifier = useCallback(
    async (id_realstate) => {
      try {
        const dateInitial = moment().subtract(15, 'days').format('YYYY-MM-DD');
        const dateFinal = moment().format('YYYY-MM-DD');

        const { data } = await api.get(
          `/qualifier/movto/daily/${id_realstate}?startDate=${dateInitial}&endDate=${dateFinal}&page=1&limit=10`,
          {
            headers: {
              'content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
              idToken,
            },
          },
        );
        setDataMovtoDailyQualifier(
          data.data.filter(
            (item: movtoDailyProps) => item?.transaction === 'debit',
          ),
        );
      } catch (error) {
        console.log(error);
      }
    },
    [accessToken, idToken],
  );
  return (
    <MovtoDailyQualifierContext.Provider
      value={{
        dataMovtoDailyQualifier,
        fetchDataMovtoDailyQualifier,
        setClearDataMovtoDaily,
      }}
    >
      {children}
    </MovtoDailyQualifierContext.Provider>
  );
};
