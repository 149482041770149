import { Heading } from 'components/Heading';
import * as S from './styles';

export type PlanProps = {
  title: string;
  infoLeft?: string;
  infoRight?: string;
  border?: boolean;
  borderColor?: string;
  backgroundColor?: string;
  link?: string;
  children: React.ReactNode;
  type?: 'default' | 'new';
};

export const Plan = ({
  border = false,
  title,
  infoLeft,
  infoRight,
  backgroundColor,
  borderColor,
  children,
  type = 'default',
}: PlanProps) => {
  const shouldApplyNewStyle = type === 'new';

  const appliedBackgroundColor = shouldApplyNewStyle
    ? '#F5F5F5'
    : backgroundColor;
  const appliedBorderColor = shouldApplyNewStyle
    ? '#2183D9'
    : borderColor || '#808080';

  return (
    <S.Wrapper
      border={border}
      backgroundColor={appliedBackgroundColor}
      borderColor={appliedBorderColor}
    >
      <Heading
        title={title}
        size="xlarge"
        detailInfoLeft={infoLeft}
        detailInfoRight={infoRight}
        weight="700"
        color="#1A1A1A"
      />
      {children}
    </S.Wrapper>
  );
};
