import * as S from './styles';

export type ToolTipProps = {
  icon?: React.ReactNode;
  text?: string;
  content: string;
  height?: string;
  right?: string;
};

export const ToolTip = ({
  icon,
  text,
  content,
  height = '-7.9rem',
  right,
}: ToolTipProps) => {
  return (
    <S.Link role="tooltip" aria-label={content} height={height} right={right}>
      {text} {icon}
    </S.Link>
  );
};
