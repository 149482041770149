const DoubleArrowNeutral = () => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="#212121"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.7828 0.219653L15.2778 4.71441C15.5439 4.98047 15.5683 5.39676 15.3509 5.69038L15.2784 5.77451L10.7834 10.2794C10.4908 10.5726 10.0159 10.5731 9.72272 10.2805C9.45617 10.0145 9.43151 9.59791 9.64904 9.30406L9.72157 9.21986L12.9384 5.997L1.24258 5.99766C0.862888 5.99766 0.549094 5.71551 0.499431 5.34943L0.492584 5.24766C0.492584 4.86797 0.774738 4.55417 1.14081 4.50451L1.24258 4.49766L12.9404 4.497L9.72216 1.28035C9.45589 1.01409 9.43167 0.597427 9.64951 0.303808L9.72213 0.219687C9.98839 -0.046588 10.405 -0.0708077 10.6987 0.147037L10.7828 0.219653L15.2778 4.71441L10.7828 0.219653ZM15.5006 14.6491L15.5074 14.7509C15.5074 15.1306 15.2253 15.4444 14.8592 15.494L14.7574 15.5009L3.06142 15.5L6.28258 18.7194C6.54896 18.9856 6.57334 19.4023 6.35561 19.696L6.28303 19.7801C6.01687 20.0465 5.60022 20.0709 5.30652 19.8531L5.22237 19.7806L0.722368 15.2843C0.45599 15.0182 0.43161 14.6015 0.649342 14.3078L0.721925 14.2237L5.22193 9.71989C5.5147 9.42688 5.98957 9.42668 6.28258 9.71945C6.54896 9.9856 6.57334 10.4023 6.35561 10.696L6.28303 10.7801L3.06542 14L14.7574 14.0009C15.1371 14.0009 15.4509 14.283 15.5006 14.6491L15.5074 14.7509L15.5006 14.6491Z" />
    </svg>
  );
};
export default DoubleArrowNeutral;
