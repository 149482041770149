const InfoShape = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0ZM6.74905 3.50003C6.74905 3.91371 6.41369 4.24907 6.00001 4.24907C5.58633 4.24907 5.25098 3.91371 5.25098 3.50003C5.25098 3.08635 5.58633 2.751 6.00001 2.751C6.41369 2.751 6.74905 3.08635 6.74905 3.50003ZM6 5C6.27614 5 6.5 5.22386 6.5 5.5V8.5C6.5 8.77614 6.27614 9 6 9C5.72386 9 5.5 8.77614 5.5 8.5V5.5C5.5 5.22386 5.72386 5 6 5Z"
        fill="#1A1A1A"
      />
    </svg>
  );
};
export default InfoShape;
