const KeptIcon = () => {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 12C4 11.7239 4.22386 11.5 4.5 11.5H19.5C19.7761 11.5 20 11.7239 20 12C20 12.2761 19.7761 12.5 19.5 12.5H4.5C4.22386 12.5 4 12.2761 4 12Z"
        fill="#808080"
      />
    </svg>
  );
};
export default KeptIcon;
