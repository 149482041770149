import * as S from './styles';

import logoSign from 'assets/image/logo-signin.svg';
import Button from 'components/Button';
import { Link } from 'react-router-dom';
import { Online } from 'components/Icons';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { signInFormSchema } from './schema';
import { Eye, EyeOff } from '@styled-icons/feather';
import { theme } from 'styles/theme';
// import { api } from 'services/api';
import { useEffect, useState } from 'react';
import SpinnerLoading from 'components/SpinnerLoading';

import { BoxMessage } from 'components/BoxMessage';

export const NewPassword = () => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [isLoading] = useState(false);
  const [statusError] = useState(undefined);
  const [statusError1] = useState(200);
  const [disableLoginButton, setDisableLoginButton] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(signInFormSchema),
  });

  const newPassword = watch('newPassword');
  const confirmNewPassword = watch('confirmNewPassword');

  useEffect(() => {
    if (newPassword && confirmNewPassword) {
      setDisableLoginButton(false);
    } else {
      setDisableLoginButton(true);
    }
  }, [newPassword, confirmNewPassword]);

  // const history = useHistory();

  // const fetchDataSignIn = async (email: string, password: string) => {
  //   setIsLoading(true);

  //   try {
  //     const { data } = await api.post(
  //       `/login`,
  //       {
  //         email: email,
  //         password: password,
  //       },
  //       {
  //         headers: {
  //           'content-Type': 'application/json',
  //         },
  //       },
  //     );
  //     //notifySuccess();
  //     return data;
  //   } catch (error: any) {
  //     if (
  //       error.response &&
  //       error.response.data &&
  //       error.response.data.message
  //     ) {
  //       setStatusError2(error.response.data.message);
  //     }
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleSubmitNewPassword = (data: any, event: any) => {
    event.preventDefault();
    console.log(data);
  };
  // async function login(data: any, event: any): Promise<void> {
  //   event.preventDefault();

  //   try {
  //     localStorage.removeItem('accessToken');
  //     localStorage.removeItem('idToken');
  //     localStorage.removeItem('refreshToken');
  //     data = await fetchDataSignIn(data.email, data.password);
  //     localStorage.setItem('accessToken', data.data.token.accessToken);
  //     localStorage.setItem('idToken', data.data.token.idToken);
  //     localStorage.setItem('refreshToken', data.data.token.refreshToken);
  //     localStorage.setItem('name', data.data.user.name);
  //     localStorage.setItem('profile', data.data.user.profile);
  //     localStorage.setItem('picture', data.data.user.picture);
  //     localStorage.setItem('company_id', data.data.user.company_id);
  //     history.push('/ClientArea');
  //   } catch (error) {
  //     setStatusError(data);
  //     setStatusError2(data);
  //     console.log(error);
  //   }

  /*
      try {
        let idToken = null;

        if (data.email === 'client-area-test.iqi@mailinator.com') {
          idToken = parseJwt(token69618);
        } else if (data.email === 'client-area-test.4gu@mailinator.com') {
          idToken = parseJwt(token68841);
        } else if (data.email === 'client-area-test.piz@mailinator.com') {
          idToken = parseJwt(token70375);
        } else {
          alert('Estamos em desenvolvimento');
          return;
        }

        history.push('/ClientArea');
        localStorage.setItem('email', data.email);
        localStorage.setItem('password', data.password);
        localStorage.setItem('companyId', idToken['custom:agency_id']);
        localStorage.setItem('profile', idToken.profile);
        localStorage.setItem('name', idToken.name);
        localStorage.setItem('familyName', idToken.family_name);
      } catch (error) {
        console.log(error);
      }
      */
  // }

  // function parseJwt(token: any) {
  //   const base64Url = token.split('.')[1];
  //   const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  //   const jsonPayload = decodeURIComponent(
  //     window
  //       .atob(base64)
  //       .split('')
  //       .map(function (c) {
  //         return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  //       })
  //       .join(''),
  //   );

  //   return JSON.parse(jsonPayload);
  // }

  return (
    <S.Container>
      <S.Background />
      <S.Content>
        <div>
          <h1>Boas-vindas!</h1>
          <img src={logoSign} alt="Logo Kenlo" />
        </div>
        <form onSubmit={handleSubmit(handleSubmitNewPassword)}>
          <S.WrapperInputShowPassword>
            <input
              type={showNewPassword ? 'text' : 'password'}
              placeholder="Nova senha"
              {...register('newPassword')}
            />
            <S.WrapperShowPassword>
              <S.ShowPassword
                type="button"
                onClick={() => setShowNewPassword(!showNewPassword)}
              >
                {!showNewPassword ? (
                  <EyeOff
                    size={19}
                    strokeWidth={2}
                    color={theme.colors.neutral.neutral70}
                  />
                ) : (
                  <Eye
                    size={19}
                    strokeWidth={2}
                    color={theme.colors.neutral.neutral70}
                  />
                )}
              </S.ShowPassword>
            </S.WrapperShowPassword>
          </S.WrapperInputShowPassword>
          {errors.newPassword && <p>{errors.newPassword.message}</p>}
          <S.WrapperInputShowPassword>
            <input
              type={showConfirmNewPassword ? 'text' : 'password'}
              placeholder="Confirmação de nova senha"
              {...register('confirmNewPassword')}
            />
            <S.WrapperShowPassword>
              <S.ShowPassword
                type="button"
                onClick={() =>
                  setShowConfirmNewPassword(!showConfirmNewPassword)
                }
              >
                {!showConfirmNewPassword ? (
                  <EyeOff
                    size={19}
                    strokeWidth={2}
                    color={theme.colors.neutral.neutral70}
                  />
                ) : (
                  <Eye
                    size={19}
                    strokeWidth={2}
                    color={theme.colors.neutral.neutral70}
                  />
                )}
              </S.ShowPassword>
            </S.WrapperShowPassword>
          </S.WrapperInputShowPassword>
          {statusError !== 200 && <p>{errors.newPassword?.message}</p>}
          <Button
            size="100%"
            minimal={false}
            height="5.6rem"
            disabled={disableLoginButton} // desabilita o botão caso disableLoginButton seja verdadeiro
          >
            {isLoading ? <SpinnerLoading /> : 'Alterar minha senha'}
          </Button>
          {statusError1 !== 200 && (
            <S.WrapperBanners>
              <br />
              <BoxMessage text="Usuário ou senha inválidos" />
            </S.WrapperBanners>
          )}
          <Link to="/">
            <S.WrapperLink>
              <b>Voltar para o login</b>
            </S.WrapperLink>
          </Link>
        </form>
        <S.Footer>
          <div className="status">
            <p>
              <span>Área do cliente</span>
              está on-line
              <Online />
            </p>
            <p>
              Não está conseguindo entrar?
              <span className="link">Converse com o nosso time.</span>
            </p>
          </div>
        </S.Footer>
      </S.Content>
    </S.Container>
  );
};
