import * as Dialog from '@radix-ui/react-dialog';
import styled, { css } from 'styled-components';

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

export const TitleTree = styled.div<{ color: string }>`
  ${({ theme, color }) => css`
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.normal};
    color: ${color};
  `}
`;

export const TitleTwo = styled.div<{ color: string }>`
  ${({ theme, color }) => css`
    font-size: ${theme.font.sizes.large};
    font-weight: ${theme.font.semiBold};
    color: ${color};
  `}
`;
export const Title = styled.div<{ color: string }>`
  ${({ theme, color }) => css`
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.normal};
    color: ${color};
  `}
`;

export const TitleFour = styled.div<{ color: string }>`
  ${({ theme, color }) => css`
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.bold};
    color: ${color};
  `}
`;

export const Subtitle = styled.span`
  ${({ theme, color }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${color};
    font-weight: ${theme.font.normal};
  `}
`;

export const Qualify = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;
export const RowQualify = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
export const RowTable = styled.div`
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: flex-start;
`;
export const RowTwoColumn = styled.div`
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: flex-start;
`;
export const ColOne = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-start;
`;

export const TextOne = styled.div`
  margin-right: 1rem;
  text-align: end;
  line-height: 2.4rem;
`;

export const TexTwo = styled.div`
  margin-right: 2rem;
  text-align: start;
  line-height: 2.4rem;
`;

export const TexTree = styled.div`
  margin-right: 1rem;
  text-align: end;
  line-height: 32px;
`;

export const TextFour = styled.div`
  margin-right: 2rem;
  text-align: start;
  line-height: 4rem;
`;

export const ToolTip = styled.div`
  margin-left: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const BodyTable = styled.div`
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const IconRight = styled.div`
  width: 2.8rem;
  text-align: end;
`;

export const TextFooter = styled.div`
  margin-right: 2rem;
  text-align: start;
  line-height: 3.2rem;
`;

export const CloseButton = styled(Dialog.Close)`
  ${({ theme }) => css`
    position: absolute;
    background: transparent;
    border: 0;
    top: 4rem;
    right: 3.5rem;
    line-height: 0;
    cursor: pointer;
    color: ${theme.colors.neutral.neutral100};
  `}
`;

export const Footer = styled.div`
  ${({ theme }) => css`
    p {
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.neutral.neutral70};
      font-weight: 500;
      letter-spacing: -0.4px;
      margin: 2.4rem 0;
      a {
        color: ${theme.colors.primary50};
        font-weight: 700;
        text-decoration: none;
        margin: 4px;
        letter-spacing: -0.4px;
      }
    }

    div {
      display: flex;
      justify-content: flex-end;
      gap: 1.6rem;
      height: 3.2rem;
    }
  `}
`;

export const TextTool = styled.div`
  ${({ theme }) => css`
    align-items: center;
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.normal};
    color: ${theme.colors.neutral.neutral120};
    display: flex;
     {
      svg {
        margin-right: 1rem;
      }
    }
    p {
      margin: 0;
      color: ${theme.colors.neutral.neutral120};
    }
  `}
`;
