import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;
export const Installments = styled.div`
  display: flex;
`;

export const TextCustomized = styled.p<{
  color?: string;
  fontWeight?: number;
  lineHeight?: string;
  fontSize?: string;
  marginTop?: string;
  marginBottom?: string;
}>`
  ${({
    theme,
    color,
    fontWeight,
    lineHeight,
    fontSize,
    marginTop,
    marginBottom,
  }) => css`
    font-size: ${fontSize || theme.font.sizes.xsmall};
    color: ${color || theme.colors.neutral.neutral130};
    font-weight: ${fontWeight || 700};
    line-height: ${lineHeight || '24px'};
    margin-top: ${marginTop || 0};
    margin-bottom: ${marginBottom || 0};
  `}
`;
