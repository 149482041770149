import * as S from './styles';

type MessageModalProps = {
  text?: string;
  description?: string;
  img: string;
  isOpen: boolean;
  setIsOpen?: (value: boolean) => void;
};

export const MessageModal = ({
  text,
  description,
  img,
  setIsOpen,
  isOpen,
}: MessageModalProps) => {
  if (!isOpen) return null;
  return (
    <S.Overlay>
      <S.Content>
        <div className="wrapperTitle">
          <p>{text}</p>
          <img src={img} onClick={() => setIsOpen?.(false)} />
        </div>
        <span>{description}</span>
      </S.Content>
    </S.Overlay>
  );
};
