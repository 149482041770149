const BatteryCheck = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.75 2C14.9089 2 15.8631 2.87609 15.9865 4.00209C15.9864 4.0012 15.9863 4.00032 15.9862 3.99944L17.75 4C18.9926 4 20 5.00736 20 6.25V11.4982C19.5258 11.3004 19.0232 11.1572 18.5 11.0764V6.25C18.5 5.83579 18.1642 5.5 17.75 5.5L15.621 5.5002C15.2173 6.1031 14.53 6.5 13.75 6.5H10.25C9.46997 6.5 8.78264 6.10307 8.379 5.50016L6.25 5.5C5.83579 5.5 5.5 5.83579 5.5 6.25V19.75C5.5 20.1642 5.83579 20.5 6.25 20.5H11.7322C12.0194 21.051 12.3832 21.5557 12.8096 22H6.25C5.00736 22 4 20.9926 4 19.75V6.25C4 5.00736 5.00736 4 6.25 4L8.01379 3.99944C8.13841 2.87472 9.09205 2 10.25 2H13.75ZM15.9948 4.09595L16 4.25C16 4.19822 15.9983 4.14685 15.9948 4.09595ZM13.75 3.5H10.25C9.83579 3.5 9.5 3.83579 9.5 4.25C9.5 4.66421 9.83579 5 10.25 5H13.75C14.1642 5 14.5 4.66421 14.5 4.25C14.5 3.83579 14.1642 3.5 13.75 3.5ZM23 17.5C23 20.5376 20.5376 23 17.5 23C14.4624 23 12 20.5376 12 17.5C12 14.4624 14.4624 12 17.5 12C20.5376 12 23 14.4624 23 17.5ZM20.8536 15.1464C20.6583 14.9512 20.3417 14.9512 20.1464 15.1464L16.5 18.7929L14.8536 17.1464C14.6583 16.9512 14.3417 16.9512 14.1464 17.1464C13.9512 17.3417 13.9512 17.6583 14.1464 17.8536L16.1464 19.8536C16.3417 20.0488 16.6583 20.0488 16.8536 19.8536L20.8536 15.8536C21.0488 15.6583 21.0488 15.3417 20.8536 15.1464Z"
        fill="#404040"
      />
    </svg>
  );
};
export default BatteryCheck;
