import styled, { css } from 'styled-components';

export const WrapperAside = styled.aside`
  ${({ theme }) => css`
    background: ${theme.colors.neutral.neutral0};
    border-radius: ${theme.border.radius};
    padding: 2.4rem;

    span {
      font-size: ${theme.font.sizes.medium};
      font-weight: 500;
      line-height: 2.4rem;
      letter-spacing: -0.4px;
    }
  `}
`;

export const Title = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    margin-bottom: 2.4rem;
  `}
`;
