import styled, { css, DefaultTheme } from 'styled-components';
import { PlanProps } from '.';

type WrapperProps = {
  border?: boolean;
  borderColor?: string;
  backgroundColor?: string;
} & Pick<PlanProps, 'border'>;

type WrapperModifiers = {
  [key: string]: (theme: DefaultTheme) => ReturnType<typeof css>;
};

const wrapperModifiers: WrapperModifiers = {
  border: (theme: DefaultTheme) => css`
    border: 1px solid ${theme.colors.neutral.neutral50};
    background: transparent;
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, border, backgroundColor, borderColor }) => css`
    background: ${backgroundColor || theme.colors.neutral.neutral0};
    border-radius: ${theme.border.radius};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.6rem 2.4rem;
    flex: 1;
    height: 8.8rem;

    a {
      text-decoration: none;
    }

    ${!!border && wrapperModifiers.border(theme)}
    ${borderColor &&
    css`
      border-color: ${borderColor};
    `}
  `}
`;
