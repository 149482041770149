import { Heading } from 'components/Heading';
// import { Price } from '../Price';
import * as S from './styles';

import { PriceProps } from '../Price/index';
import { theme } from 'styles/theme';
import { InvoiceStatus } from 'components/InvoiceStatus';
import { BoxContent } from 'components/BoxContent';
import { CustomQualifier } from '../CustomQualifier';
import { formattedValue } from 'helpers/util';

type CardProps = {
  key: number;
  title: string;
  subtitle?: string;
  descriptionQualification: number;
  valueForQualification: string;
  percentage: number;
  id: string;
  icon: React.ReactNode;
  className: string;
  price?: string;
  installments: number;
  value: string;
  estimated_duration: string;
  HandleClick: React.ReactNode;
  HandlePaymentInstallments: React.ReactNode;
  valueInstallments: string;
  count: number;
  numberOfInstallments?: number;
  setCount?: (value: any) => void;
  handleCountIncrease: () => void;
  handleCountDecrease: () => void;
  handleSetCount: (e: any) => void;
  promoPrice: any;
  valueForQualificationInCash: string;
} & PriceProps;

export const Card = ({
  valueForQualificationInCash,
  valueInstallments,
  count,
  setCount,
  handleCountIncrease,
  handleCountDecrease,
  handleSetCount,
  HandleClick,
  HandlePaymentInstallments,
  title,
  subtitle,
  className,
  descriptionQualification,
  valueForQualification,
  price,
  promoPrice,
  numberOfInstallments,
  //installments,
  // value,
  estimated_duration,
}: CardProps) => {
  const getValueFixed = (value: string) => {
    switch (title) {
      case 'Basic':
        if (value === 'parcelado') return `R$ ${promoPrice.installment.basic}`;
        else return `R$ ${promoPrice.inCash.basic}`;
      case 'Growth':
        if (value === 'parcelado') return `R$ ${promoPrice.installment.growth}`;
        else return `R$ ${promoPrice.inCash.growth}`;
      case 'Elite':
        if (value === 'parcelado') return `R$ ${promoPrice.installment.elite}`;
        else return `R$ ${promoPrice.inCash.elite}`;
      default:
        if (value === 'parcelado') return `R$ ${promoPrice.installment.elite}`;
        else return `R$ ${promoPrice.inCash.elite}`;
    }
  };
  return (
    <S.Wrapper className={className}>
      <S.WrapperHeader>
        <Heading
          title={
            <div style={{ display: 'flex', gap: '1rem' }}>
              {title === 'custom' ? 'Personalizado' : title}
              {title === 'Elite' && (
                <InvoiceStatus
                  text={
                    <S.TextCustomized
                      fontWeight={500}
                      color={theme.colors.neutral.neutral120}
                      lineHeight={'16px'}
                      fontSize={'12px'}
                    >
                      Lead mais barato!
                    </S.TextCustomized>
                  }
                  status={'quali'}
                />
              )}
            </div>
          }
          weight={'600'}
          size="large"
          color="#1A1A1A"
        />
      </S.WrapperHeader>

      {title !== 'custom' ? (
        <S.WrapperTextCustomized>
          <S.TextCustomized
            fontWeight={700}
            color={theme.colors.neutral.neutral40}
            lineHeight={'24px'}
            fontSize="1.5rem"
          >
            {descriptionQualification} qualificações
          </S.TextCustomized>
          <S.TextCustomized
            fontWeight={500}
            color={theme.colors.neutral.neutral130}
            lineHeight={'16px'}
            fontSize="1.2rem"
          >
            Duração estimada de {estimated_duration}
          </S.TextCustomized>
        </S.WrapperTextCustomized>
      ) : (
        <S.WrapperTextCustomized>
          <CustomQualifier
            disabled={count <= descriptionQualification && true}
            width="18rem"
            title="qualificações"
            count={count}
            setCount={setCount}
            handleSetCount={handleSetCount}
            inCount={() => handleCountIncrease()}
            decCount={() => handleCountDecrease()}
            descriptionQualification={descriptionQualification}
          />
        </S.WrapperTextCustomized>
      )}

      <S.Row margin="2rem" gap="2rem">
        <S.Row JustifyContent="flex-start">
          <BoxContent
            color={'transparent'}
            style={{
              justifyContent: 'flex-start',
              margin: 0,
              borderRight: '1px solid #F5F5F5',
              borderRadius: 0,
            }}
          >
            <S.TextCustomized
              fontWeight={500}
              color={theme.colors.neutral.neutral130}
              lineHeight={'16px'}
              fontSize="1.2rem"
            >
              {promoPrice && <s>{getValueFixed('parcelado')}</s>}
            </S.TextCustomized>
            <S.TextCustomized
              fontWeight={700}
              color={`#2183D9`}
              lineHeight={'16px'}
              fontSize={theme.font.sizes.medium}
            >
              {valueForQualification} por lead
            </S.TextCustomized>
            <br />
            <S.TextCustomized
              fontWeight={700}
              color={theme.colors.neutral.neutral120}
              lineHeight={'3.2rem'}
              fontSize="2.4rem"
            >
              {formattedValue(valueInstallments)}
            </S.TextCustomized>
            <S.TextCustomized
              fontWeight={500}
              color={theme.colors.neutral.neutral130}
              lineHeight={'1.6rem'}
              fontSize="1.2rem"
            >
              {title !== 'custom' ? subtitle : ''}
              {title === 'custom'
                ? `${subtitle?.slice(0, 9)} ${formattedValue(
                    Number(valueInstallments) / Number(numberOfInstallments),
                  )}`
                : ''}
              {/* Ou em até <b>{installments}</b>x de <b>{value}</b> */}
            </S.TextCustomized>
            <br />
            {HandlePaymentInstallments}
          </BoxContent>
        </S.Row>
        <S.Row JustifyContent="flex-end">
          <BoxContent
            color={'transparent'}
            style={{
              justifyContent: 'flex-end',
              margin: 0,
            }}
          >
            <S.TextCustomized
              fontWeight={500}
              color={theme.colors.neutral.neutral130}
              lineHeight={'16px'}
              fontSize="1.2rem"
            >
              {promoPrice && <s>{getValueFixed('avista')}</s>}
            </S.TextCustomized>
            <S.TextCustomized
              fontWeight={700}
              color={`#2183D9`}
              lineHeight={'16px'}
              fontSize={theme.font.sizes.medium}
            >
              {valueForQualificationInCash} por lead
            </S.TextCustomized>
            <br />
            <S.TextCustomized
              fontWeight={700}
              color={theme.colors.neutral.neutral120}
              lineHeight={'3.2rem'}
              fontSize="2.4rem"
            >
              {price}
            </S.TextCustomized>
            <S.TextCustomized
              fontWeight={500}
              color={theme.colors.neutral.neutral130}
              lineHeight={'1.6rem'}
              fontSize="1.2rem"
            >
              à vista no boleto
              {/* Ou em até <b>{installments}</b>x de <b>{value}</b> */}
            </S.TextCustomized>
            <br />
            {HandleClick}
          </BoxContent>
        </S.Row>
      </S.Row>
    </S.Wrapper>
  );
};
