/* eslint-disable */
import { Decrease, Increase } from 'components/Icons';
import * as S from './styles';

export type CounterButtonProps = {
  title?: string;
  count: number;
  width?: string;
  decCount: () => void;
  inCount: () => void;
};

export const CounterButton = ({ count, decCount, inCount, title, width } : CounterButtonProps) => {
  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      <S.WrapperButton width={width}>
        <button onClick={decCount}>{<Decrease />}</button>
        <span>{count}</span>
        <button onClick={inCount}>{<Increase />}</button>
      </S.WrapperButton>
    </S.Container>
  );
};
