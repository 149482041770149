const SmartTickets = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="#4C4C4C"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 2.75C0 1.23122 1.23122 0 2.75 0H4.25C4.66421 0 5 0.335786 5 0.75C5 1.16421 4.66421 1.5 4.25 1.5H2.75C2.05964 1.5 1.5 2.05964 1.5 2.75V4.25C1.5 4.66421 1.16421 5 0.75 5C0.335786 5 0 4.66421 0 4.25V2.75ZM15 0.75C15 0.335786 15.3358 0 15.75 0H17.25C18.7688 0 20 1.23122 20 2.75V4.25C20 4.66421 19.6642 5 19.25 5C18.8358 5 18.5 4.66421 18.5 4.25V2.75C18.5 2.05964 17.9404 1.5 17.25 1.5H15.75C15.3358 1.5 15 1.16421 15 0.75ZM0.75 13C1.16421 13 1.5 13.3358 1.5 13.75V15.25C1.5 15.9404 2.05964 16.5 2.75 16.5H4.25C4.66421 16.5 5 16.8358 5 17.25C5 17.6642 4.66421 18 4.25 18H2.75C1.23122 18 0 16.7688 0 15.25V13.75C0 13.3358 0.335786 13 0.75 13ZM19.25 13C19.6642 13 20 13.3358 20 13.75V15.25C20 16.7688 18.7688 18 17.25 18H15.75C15.3358 18 15 17.6642 15 17.25C15 16.8358 15.3358 16.5 15.75 16.5H17.25C17.9404 16.5 18.5 15.9404 18.5 15.25V13.75C18.5 13.3358 18.8358 13 19.25 13ZM3.75 4C4.16421 4 4.5 4.33579 4.5 4.75V13.25C4.5 13.6642 4.16421 14 3.75 14C3.33579 14 3 13.6642 3 13.25V4.75C3 4.33579 3.33579 4 3.75 4ZM8.5 4.75C8.5 4.33579 8.16421 4 7.75 4C7.33579 4 7 4.33579 7 4.75V13.25C7 13.6642 7.33579 14 7.75 14C8.16421 14 8.5 13.6642 8.5 13.25V4.75ZM11.75 4C12.1642 4 12.5 4.33579 12.5 4.75V13.25C12.5 13.6642 12.1642 14 11.75 14C11.3358 14 11 13.6642 11 13.25V4.75C11 4.33579 11.3358 4 11.75 4ZM16.5 4.75C16.5 4.33579 16.1642 4 15.75 4C15.3358 4 15 4.33579 15 4.75V13.25C15 13.6642 15.3358 14 15.75 14C16.1642 14 16.5 13.6642 16.5 13.25V4.75Z" />
    </svg>
  );
};
export default SmartTickets;
