import * as S from './styles';

export type PriceProps = {
  descriptionPrice: string;
  price: string;
  frequency: string;
};

export const Price = ({ descriptionPrice, frequency, price }: PriceProps) => {
  return (
    <S.Wrapper>
      <p>{descriptionPrice}</p>
      <span>{price}</span>
      <span>{frequency === 'mensal' ? ' /mês' : ' /ano'}</span>
    </S.Wrapper>
  );
};
