import styled, { css } from 'styled-components';
import { generateMedia } from 'styled-media-query';

const customMedia = generateMedia({
  bigDesktop: '85.4375em',
});

export const CustomText = styled.span<{
  color: string;
  margin?: string;
  lineHeight?: string;
  fontSize?: string;
  fontWeight?: string;
}>`
  ${({ theme, color, fontWeight, lineHeight, fontSize }) => css`
    font-size: ${fontSize || theme.font.sizes.small};
    color: ${color};
    font-weight: ${fontWeight || 700};
    line-height: ${lineHeight || '24px'};
  `}
`;
export const HeaderWrapper = styled.div`
  ${() => css`
    display: flex;
    gap: 0.8rem;
  `}
`;

export const WrapperTitle = styled.div`
  ${() => css`
    margin-bottom: 2.4rem;
  `}
`;

export const WrapperBanner = styled.div`
  ${() => css`
    margin: 0 0 1.6rem 0;
  `}
`;

export const BodyContentWrapper = styled.div`
  ${() => css`
    display: grid;
    grid-template-columns: 41.8rem 86rem;
    gap: 2.4rem;
    margin-top: 1.6rem;

    ${customMedia.lessThan('bigDesktop')`
      grid-template-columns: 39.8rem 1fr;
      gap: 2.4rem;
    `}
  `}
`;

export const WrapperHistoryOfQualification = styled.div`
  ${() => css`
    margin-bottom: 2.4rem;
  `}
`;

export const WrapperDescription = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 9.6rem 24rem 10.4rem;
    gap: 9.3rem;
    p {
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.neutral.neutral130};
      font-weight: 500;
    }
  `}
`;

export const Ul = styled.ul`
  ${({ theme }) => css`
    list-style: none;
    padding: 0;
    li {
      display: flex;
      justify-content: space-between;
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.neutral.neutral130};
      line-height: 2.4rem;
      letter-spacing: -0.4px;
      font-weight: 500;

      p {
        color: ${theme.colors.neutral.neutral40};
        font-weight: 700;
      }
    }
  `}
`;

export const ButtonsWrapper = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    gap: 2.6rem;
  `}
`;

export const WrapperPerformance = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 2rem;
    align-items: center;

    span {
      font-size: ${theme.font.sizes.large};
      font-weight: 600;
      line-height: 2.4rem;
      letter-spacing: -0.4px;
    }

    p {
      display: flex;
      justify-content: space-between;
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.neutral.neutral130};
      line-height: 2.4rem;
      letter-spacing: -0.4px;
      font-weight: 500;
    }
  `}
`;

export const WrapperAside = styled.div`
  ${({ theme }) => css`
    > aside:first-child {
      margin-bottom: 1.6rem;
    }
    p {
      display: flex;
      justify-content: space-between;
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.neutral.neutral130};
      line-height: 2.4rem;
      letter-spacing: -0.4px;
      font-weight: 500;
    }
  `}
`;

export const WrapperMain = styled.main`
  ${() => css`
    width: 100%;

    > div {
      margin-bottom: 2rem;
    }
  `}
`;

export const WrapperButtonVerMais = styled.div`
  ${() => css`
    display: flex;
    justify-content: center;
  `}
`;

export const WrapperAmountQualification = styled.div`
  ${() => css`
    display: flex;
    gap: 5.9rem;
  `}
`;

export const MyComponent = styled.div`
  ${() => css`
    fontSize: 2rem,
    font-weight: 600;
    `}
`;
