const CaretDoubleUpEmpty = ({
  width = '25',
  height = '24',
}: {
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8719_31919)">
        <path
          d="M10.8017 3.071C11.543 2.643 12.4564 2.643 13.1977 3.071L19.1334 6.498C19.8748 6.92601 20.3315 7.71699 20.3315 8.573V15.427C20.3315 16.283 19.8748 17.074 19.1334 17.502L13.1977 20.929C12.4564 21.357 11.543 21.357 10.8017 20.929L4.86597 17.502C4.12465 17.074 3.66797 16.283 3.66797 15.427V8.573C3.66797 7.71699 4.12465 6.92601 4.86597 6.498L10.8017 3.071Z"
          stroke="#105DA0"
          strokeWidth="1.5"
        />
        <path
          d="M15.5 11.3333L12 8L8.5 11.3333"
          stroke="#105DA0"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.5 15.3333L12 12L8.5 15.3333"
          stroke="#105DA0"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8719_31919">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default CaretDoubleUpEmpty;
