const ArrowTrendingNeutral = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7479 5.5L21.3018 5.50052L21.402 5.51444L21.5008 5.54205L21.5611 5.5676C21.6403 5.60246 21.7146 5.65315 21.7798 5.71836L21.8205 5.7624L21.864 5.81878L21.9182 5.9089L21.9569 5.99922L21.976 6.0633L21.9896 6.1273L21.999 6.2215L21.9995 13.7539C21.9995 14.1681 21.6637 14.5039 21.2495 14.5039C20.8698 14.5039 20.556 14.2217 20.5063 13.8557L20.4995 13.7539L20.499 8.059L12.7802 15.7793C12.5139 16.0455 12.0973 16.0698 11.8036 15.8519L11.7195 15.7793L8.74903 12.8089L3.27935 18.2786C2.98646 18.5715 2.51159 18.5715 2.21869 18.2786C1.95243 18.0123 1.92822 17.5957 2.14608 17.3021L2.21869 17.2179L8.21869 11.2179C8.48496 10.9517 8.90161 10.9275 9.19522 11.1453L9.27934 11.2179L12.2498 14.1883L19.437 7H13.7479C13.3682 7 13.0545 6.71785 13.0048 6.35177L12.9979 6.25C12.9979 5.8703 13.2801 5.55651 13.6462 5.50685L13.7479 5.5Z"
        fill="#404040"
      />
    </svg>
  );
};
export default ArrowTrendingNeutral;
