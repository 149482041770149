import styled, { css } from 'styled-components';

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

export const TitleTree = styled.div<{ color: string }>`
  ${({ theme, color }) => css`
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.normal};
    color: ${color};
  `}
`;

export const TitleTwo = styled.div<{ color: string }>`
  ${({ theme, color }) => css`
    font-size: ${theme.font.sizes.large};
    font-weight: ${theme.font.semiBold};
    color: ${color};
  `}
`;
export const Title = styled.div<{ color: string }>`
  ${({ theme, color }) => css`
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.semiBold};
    color: ${color};
  }
  b {
    font-weight: ${theme.font.bold};
    `}
`;
export const Subtitle = styled.span`
  ${({ theme, color }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${color};
    font-weight: ${theme.font.bold};
    line-height: 1.6rem;
  `}
`;

export const SubtitleContract = styled.span`
  ${({ theme, color }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${color};
    font-weight: ${theme.font.normal};
    line-height: 1.6rem;
  `}
`;

export const Qualify = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;
export const RowQualify = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
export const RowTable = styled.div`
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: flex-start;
`;
export const RowTwoColumn = styled.div`
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: flex-start;
`;
export const ColOne = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-start;
`;

export const TextOne = styled.div`
  margin-right: 1rem;
  text-align: end;
`;

export const TexTwo = styled.div`
  margin-right: 2rem;
  text-align: start;
  line-height: 3.5rem;
`;

export const TextFooter = styled.div`
  margin-right: 2rem;
  text-align: start;
  line-height: 3.2rem;
`;

export const TexTwo_ = styled.div`
  margin-right: 2rem;
  text-align: start;
  line-height: 2rem;
`;

export const TexTree = styled.div`
  margin-right: 1rem;
  text-align: end;
  line-height: 32px;
`;

export const ToolTip = styled.div`
  margin-left: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const BodyTable = styled.div`
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const IconRight = styled.div`
  width: 2.8rem;
  text-align: end;
`;

export const Footer = styled.div`
  margin-right: 1rem;
  text-align: end;
  margin-top: 2rem;
`;
