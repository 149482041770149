import * as S from './styles';

type BaseContentMainProsp = {
  title: React.ReactNode;
  children: React.ReactNode;
  padding: string;
};

export const BaseContentMain = ({
  children,
  padding,
  title,
}: BaseContentMainProsp) => {
  return (
    <S.Content padding={padding}>
      <S.Title>{title}</S.Title>
      {children}
    </S.Content>
  );
};
