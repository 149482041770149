import styled, { css } from 'styled-components';
export const Container = styled.div`
  ${() => css`
    max-width: 53.6rem;
    span {
      display: inline-block;
      margin: 2.4rem 0 1.6rem;
      font-size: 1.6rem;
      font-weight: 500;
    }
  `}
`;
export const Wrapper = styled.div`
  ${() => css`
    display: flex;
    gap: 1.6rem;
    & + div {
      margin-top: 1.6rem;
    }
  `}
`;
export const BackgroundRadio = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    background: ${theme.colors.neutral.neutral5};
    width: 26rem;
    height: 5.6rem;
    padding: 12px 16px 12px 16px;
    border-radius: 1.2rem;
  `}
`;

export const FooterButton = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.6rem;

    button {
      background: transparent;
      border: 0;
      height: 5.6rem;
      font-size: 1.6rem;
    }

    .cancelar {
      border: 2px solid ${theme.colors.primary50};
      border-radius: 12px;
      width: 11.5rem;
      color: #f82e52;
    }
    .cancelar:hover {
      background: #af031b;
      border: 2px solid #af031b;
      color: #fff;
    }

    .salvar {
      border: 2px solid ${theme.colors.primary50};
      border-radius: 12px;
      width: 14.3rem;
      color: #fff;
      background: #f82e52;
    }
  `}
`;

import * as Dialog from '@radix-ui/react-dialog';

export const Overlay = styled(Dialog.Overlay)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  inset: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1;
`;

export const Content = styled(Dialog.Content)`
  ${({ theme }) => css`
    min-width: 60rem;
    border-radius: ${theme.border.radius};
    padding: 2.5rem 3rem;
    background: ${theme.colors.neutral.neutral0};
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  `}
`;

export const WrapperSelect = styled.select`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.neutral70};
    background-color: ${theme.colors.neutral.neutral5};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.4rem 1.4rem;
    gap: 139px;
    border: 0;
    border-radius: 12px;
    width: 100%;
    box-sizing: content-box;
    margin-bottom: 3rem;
    font-size: ${theme.font.sizes.medium};

    option {
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.neutral.neutral70};
    }
  `}
`;

export const Title = styled(Dialog.Title)`
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 3.2rem;
`;

export const CloseButton = styled(Dialog.Close)`
  position: absolute;
  background: transparent;
  border: 0;
  top: 3.5rem;
  right: 3rem;
  line-height: 0;
  cursor: pointer;
`;
