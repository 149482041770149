import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns:
      minmax(16.9rem, 23.375rem)
      minmax(13.4rem, max-content)
      minmax(13.4rem, max-content)
      max-content
      minmax(13.4rem, max-content);
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.neutral.neutral100};
    padding: 1rem 2.4rem;
    width: 100%;
    height: 88px;
    background: ${theme.colors.neutral.neutral0};
    border-radius: 12px;
    font-family: 'Manrope';
    margin-bottom: 1.6rem;

    .bold {
      font-weight: 700;
    }
    .lightColor {
      color: ${theme.colors.neutral.neutral50};
    }

    .wrapperInfoContent {
      display: flex;
      align-items: center;
      span {
        margin-left: 1rem;
        color: ${theme.colors.primary50};
        font-weight: 700;
        cursor: pointer;
      }
    }

    .buttonDownload {
      display: grid;
      grid-template-columns: minmax(1.4rem, 1.4rem) max-content;
      align-items: center;
      border: 0;
      background: transparent;
      color: ${theme.colors.primary50};
      font-size: 1.6rem;
      font-weight: ${theme.font.bold};
      gap: 1rem;
      &:hover {
        color: ${theme.colors.primary70};
        svg {
          fill: ${theme.colors.primary70};
        }
      }
    }

    .wrapperbutton {
      display: grid;
      grid-template-columns: minmax(18.4rem, 1.4rem) max-content;
      align-items: center;
      gap: 3.2rem;
      justify-items: end;
      color: ${theme.colors.primary50};
      font-size: 1.6rem;
      font-weight: ${theme.font.bold};
      > span {
        color: ${theme.colors.primary50};
      }
      button {
        > span {
          color: ${theme.colors.primary50};
        }
      }
    }
  `}
`;
