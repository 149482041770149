import styled, { css } from 'styled-components';

type WrapperHeadingProps = {
  size?: 'medium' | 'large' | 'xlarge' | 'xxlarge';
  uppercase?: true | false;
  subtitleContent?: string;
  subtitleSize?: 'small' | 'medium';
  subtitle?: string;
  detailInfoRight?: string;
  detailInfoLeft?: string;
  weight?: '500' | '600' | '700';
  color?: string;
};

export const Wrapper = styled.div`
  ${() => css`
    display: inline-block;
  `}
`;

export const Title = styled.h1<WrapperHeadingProps>`
  ${({ theme, size, weight, color }) => css`
    font-size: ${theme.font.sizes[size!]};
    color: ${color};
    font-weight: ${weight};
    letter-spacing: -1.2px;
    margin: 0;
  `}
`;

export const Subtitle = styled.h3<WrapperHeadingProps>`
  ${({ theme, subtitleSize }) => css`
    display: inline-block;
    font-size: ${theme.font.sizes[subtitleSize!]};
    color: ${theme.colors.neutral.neutral100};
    font-weight: 500;
    letter-spacing: -0.4px;
    line-height: 24px;
  `}
`;

export const InfoDetails = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 0.8rem;
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.neutral.neutral100};
    line-height: 24px;
    align-items: center;
    span {
      color: ${theme.colors.neutral.neutral70};
    }
  `}
`;
