const HexagonCaretDouble = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1977 1.071L17.1334 4.498C17.8748 4.92601 18.3315 5.71699 18.3315 6.573V13.427C18.3315 14.283 17.8748 15.074 17.1334 15.502L11.1977 18.929C10.4564 19.357 9.54303 19.357 8.80171 18.929L2.86597 15.502C2.12465 15.074 1.66797 14.283 1.66797 13.427V6.573C1.66797 5.71699 2.12465 4.92601 2.86597 4.498L8.80171 1.071C9.54303 0.642999 10.4564 0.642999 11.1977 1.071Z"
        stroke="#404040"
        strokeWidth="1.5"
      />
      <path
        d="M13.5 9.33333L10 6L6.5 9.33333"
        stroke="#404040"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 13.3333L10 10L6.5 13.3333"
        stroke="#404040"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default HexagonCaretDouble;
