import styled, { css } from 'styled-components';
import { DataDetailsProps } from '.';

type WrapperTitleProps = {
  uppercase?: boolean;
  subtitle?: string | number | Array<string>;
  fontSize?: string;
} & Pick<DataDetailsProps, 'uppercase' | 'fontSize'>;

type WrapperSubtitleProps = {
  color?:
    | 'neutral70'
    | 'neutral100'
    | 'neutral110'
    | 'neutral120'
    | 'neutral130';
  fontWeight?: number;
} & Pick<DataDetailsProps, 'color' | 'fontWeight'>;

type WrapperProps = {
  row?: boolean;
  flexNumber?: string | number;
} & Pick<DataDetailsProps, 'row' | 'flexNumber'>;

export const wrapperModifier = {
  row: () => css`
    display: flex;
    align-items: center;
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ row, flexNumber }) => css`
    display: flex;
    flex-direction: column;
    flex: ${flexNumber};
    color: #1a1a1a;
    ${!!row && wrapperModifier.row()};
  `}
`;

export const Title = styled.h2<WrapperTitleProps>`
  ${({ theme, uppercase, fontSize }) => css`
    font-size: ${fontSize};
    color: ${theme.colors.neutral.neutral50};
    font-weight: 500;
    letter-spacing: -0.4px;
    text-transform: ${uppercase ? 'uppercase' : 'none'};
    margin-bottom: 0;
  `}
`;

export const subtitle = styled.span<WrapperSubtitleProps>`
  ${({ theme, color, fontWeight }) => css`
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.neutral[color!]};
    font-weight: ${fontWeight};
  `}
`;
