import styled, { css } from 'styled-components';
type WrapperProps = {
  status:
    | 'aberta'
    | 'alto'
    | 'paga'
    | 'cancelada'
    | 'vencida'
    | 'venda'
    | 'average'
    | 'high'
    | 'success'
    | 'error'
    | string
    | boolean;
};

const wrapperModifiers = {
  linearGradient: () => css`
    background: linear-gradient(
      141deg,
      rgba(33, 131, 217, 1) 35%,
      rgba(167, 68, 244, 1) 100%
    );
  `,
};
export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, status }) => css`
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    gap: 5px;

    background: ${(status === 'aberta' &&
      theme.colors.feedback.feedbackAlert10) ||
    (status === 'paga' && theme.colors.feedback.feedbackSuccess60) ||
    (status === 'cancelada' && theme.colors.feedback.feedbackError15) ||
    (status === 'vencida' && theme.colors.feedback.feedbackError15) ||
    (status === 'venda' && theme.colors.neutral.neutral5) ||
    (status === 'average' && theme.colors.neutral.neutral150) ||
    (status === 'high' && theme.colors.neutral.neutral160) ||
    (status === 'success' && theme.colors.feedback.feedbackSuccess20) ||
    (status === 'error' && theme.colors.feedback.feedbackAlert70) ||
    (status === 'quali' && '#DEEDF9')};
    ${status === 'alto' && wrapperModifiers.linearGradient()}
  `}
`;
export const Text = styled.span<{ color?: string; fontWeight?: string }>`
  ${({ theme, color, fontWeight }) => css`
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${fontWeight || theme.font.bold};
    color: ${color || theme.colors.neutral.neutral120};
    line-height: 1.6rem;
  `}
`;
