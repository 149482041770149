import styled, { css } from 'styled-components';

import { generateMedia } from 'styled-media-query';

const customMedia = generateMedia({
  bigDesktop: '85.4375em',
});

export const HeaderWrapper = styled.div`
  ${() => css`
    display: flex;
    gap: 0.8rem;
  `}
`;

export const ButtonsWrapper = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    gap: 2.6rem;
  `}
`;

export const TitleWrapper = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.4rem;
  `}
`;

export const WrapperTitleContent = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
  `}
`;

export const WrapperDataContent = styled.div`
  ${() => css`
    div:not(:last-child) {
      margin-bottom: 0.8rem;
    }
  `}
`;

export const Ul = styled.ul`
  ${({ theme }) => css`
    list-style: none;
    padding: 0;
    li {
      display: flex;
      justify-content: space-between;
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.neutral.neutral50};
      line-height: 2.4rem;
      letter-spacing: -0.4px;
      font-weight: 500;
      &:not(:last-child) {
        margin-bottom: 0.8rem;
      }

      p {
        color: ${theme.colors.neutral.neutral70};
        font-weight: 700;
      }
    }
  `}
`;

export const WrapperAside = styled.div`
  ${() => css`
    height: auto;
  `}
`;

export const WrapperTitleValue = styled.div`
  ${() => css`
    div {
      margin-bottom: 1.6rem;
    }
  `}
`;

export const WrapperFooterValue = styled.div`
  ${({ theme }) => css`
    display: inline-block;
    text-align: right;
    width: 100%;

    p {
      color: ${theme.colors.neutral.neutral100};
      font-size: ${theme.font.sizes.large};
      font-weight: 600;
      line-height: 2.4rem;
      letter-spacing: -0.4px;
    }
    span {
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.neutral.neutral70};
      font-weight: 500;
      line-height: 2.4rem;
      letter-spacing: -0.4px;
    }
  `}
`;

export const BodyContentWrapper = styled.div`
  ${() => css`
    display: grid;
    grid-template-columns: 41.8rem 86rem;
    gap: 2.4rem;
    margin-top: 1.6rem;

    ${customMedia.lessThan('bigDesktop')`
      grid-template-columns: 39.8rem 1fr;
      gap: 2.4rem;
    `}
  `}
`;

export const ListValueWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.neutral.neutral0};
    border-radius: ${theme.border.radius};
    padding: 2.4rem;
  `}
`;

export const WrapperToolTip = styled.div`
  ${() => css`
    position: relative;
    span {
      position: absolute;
      top: 0.4rem;
      left: 9rem;
    }
  `}
`;

export const WrapperMain = styled.main`
  ${() => css`
    width: 100%;
  `}
`;

export const BenefitWrapper = styled.div`
  ${() => css`
    margin: 1.6rem 0;
  `}
`;

export const AdditionalServices = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.neutral.neutral0};
    border-radius: ${theme.border.radius};
    padding: 2.4rem;
    h2 {
      font-size: ${theme.font.sizes.large};
      line-height: 2.4rem;
      letter-spacing: -0.4px;
      font-weight: 600;
      color: ${theme.colors.neutral.neutral100};
    }
    .AdditionalServicesContainer {
      display: flex;
      justify-content: space-between;
      margin-top: 2.4rem;

      &__content {
        width: 39.8rem;
        padding: 2.4rem;
        display: flex;
        border-radius: ${theme.border.radius};
        border: 1px solid ${theme.colors.neutral.neutral10};
        font-size: ${theme.font.sizes.medium};
        justify-content: space-between;
        align-items: center;
      }

      &__box {
        display: flex;
        align-items: center;
        gap: 1rem;
      }

      &__link {
        text-decoration: none;
        color: ${theme.colors.primary50};
        font-weight: 700;
      }
    }
  `}
`;

export const WrapperBanners = styled.div`
  ${() => css`
    margin-bottom: 2.4rem;
  `}
`;

export const BenefitTitle = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2.7rem;
    margin-bottom: 2.4rem;
  `}
  .banner-transition-enter {
    opacity: 0;
  }

  .banner-transition-enter-active {
    opacity: 1;
    transition: opacity 1200ms ease-in-out;
  }

  .banner-transition-exit {
    opacity: 1;
  }

  .banner-transition-exit-active {
    opacity: 0;
    transition: opacity 1200ms ease-in-out;
  }
`;

export const BenefitDescriptions = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 1.1rem;
    //justify-content: space-between;
    span {
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.neutral.neutral100};
      font-weight: 500;
    }
    ${customMedia.greaterThan('bigDesktop')`
        &:not(:last-child) {
          margin-right: 3.5rem;
          display: flex;
          justify-content: none;
        }
    `}
  `}
`;

export const WrapperLine = styled.div`
  ${() => css`
    margin: 2.4rem 0;
  `}
`;

export const BenefitTitleAdd = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.4rem;
  `}
`;

export const BenefitDescriptionsAdd = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 1rem;

    span {
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.neutral.neutral100};
      font-weight: 500;
    }
    ${customMedia.greaterThan('bigDesktop')`
      flex-direction: row;;
    `}
  `}
`;

export const BenefitDescriptionsBorder = styled.div`
  ${({ theme }) => css`
    display: flex;
    border: 1px solid ${theme.colors.neutral.neutral5};
    border-radius: ${theme.border.radius};
    width: 39.8rem;
    justify-content: space-between;
    padding: 2.4rem;
  `}
  ${customMedia.greaterThan('bigDesktop')`
      align-items: center;
    `}
`;

export const BenefitDescriptionsWrapper = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${customMedia.greaterThan('bigDesktop')`
      margin-right: 3.5rem;
      display: flex;
      justify-content: none;
    `}
  `}
`;

export const BenefitDescriptionsWrapperMain = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
  `}
`;
