const ArrowTrending = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3984 6.80005L19.4415 6.80046L19.5216 6.8116L19.6007 6.83369L19.6489 6.85413C19.7123 6.88202 19.7717 6.92257 19.8239 6.97473L19.8564 7.00997L19.8913 7.05507L19.9346 7.12717L19.9656 7.19943L19.9809 7.25069L19.9918 7.30189L19.9992 7.37725L19.9996 13.4032C19.9996 13.7345 19.731 14.0032 19.3996 14.0032C19.0959 14.0032 18.8448 13.7774 18.8051 13.4846L18.7996 13.4032L18.7993 8.84725L12.6242 15.0235C12.4112 15.2365 12.0779 15.2559 11.843 15.0816L11.7757 15.0235L9.39928 12.6472L5.02353 17.0229C4.78922 17.2572 4.40932 17.2572 4.175 17.0229C3.96199 16.8099 3.94263 16.4766 4.11691 16.2417L4.175 16.1744L8.975 11.3744C9.18801 11.1614 9.52134 11.142 9.75623 11.3163L9.82352 11.3744L12.1999 13.7507L17.9497 8.00005H13.3984C13.0946 8.00005 12.8436 7.77433 12.8039 7.48147L12.7984 7.40005C12.7984 7.09629 13.0241 6.84526 13.317 6.80553L13.3984 6.80005Z"
        fill="#239867"
      />
    </svg>
  );
};
export default ArrowTrending;
