import * as S from './styles';

export type DataDetailsProps = {
  color?:
    | 'neutral70'
    | 'neutral100'
    | 'neutral110'
    | 'neutral120'
    | 'neutral130';
  title?: React.ReactNode;
  subtitle?: string | number | Array<string>;
  uppercase?: boolean;
  row?: boolean;
  fontWeight?: number;
  fontSize?: string;
  flexNumber?: string | number;
};

export const DataDetails = ({
  color,
  title,
  subtitle,
  uppercase = false,
  row = false,
  fontWeight = 700,
  fontSize = '1.6rem',
  flexNumber = 'none',
}: DataDetailsProps) => {
  return (
    <S.Wrapper row={row} flexNumber={flexNumber}>
      <S.Title uppercase={uppercase} subtitle={subtitle} fontSize={fontSize}>
        {title}
      </S.Title>
      <S.subtitle color={color} fontWeight={fontWeight}>
        {subtitle}
      </S.subtitle>
    </S.Wrapper>
  );
};
