import { IconAlert } from 'components/Icons';
import * as S from './styles';

type MessageModalErrorProps = {
  description?: string;
  img: string;
  isOpen: boolean;
  setIsOpen?: (value: boolean) => void;
};

export const MessageModalError = ({
  description,
  img,
  setIsOpen,
  isOpen,
}: MessageModalErrorProps) => {
  if (!isOpen) return null;
  return (
    <S.Overlay>
      <S.Content>
        <div className="wrapperTitle">
          <IconAlert />
          <span>{description}</span>
          <img src={img} onClick={() => setIsOpen?.(false)} />
        </div>
      </S.Content>
    </S.Overlay>
  );
};
