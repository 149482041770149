import styled, { css } from 'styled-components';
import { DropdownLinkProps } from './DropdownLink';

type LinkProps = Pick<DropdownLinkProps, 'size' | 'color'>;

export const WrapperA = styled.a<LinkProps>`
  ${({ theme, size, color }) => css`
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: ${size};
    color: ${color};
    transition: all 0.2s ease-in-out;
    padding: 1rem 2.4rem;
    width: 100%;
    cursor: pointer;
    z-index: ${theme.layers.alwaysOnTop};
    height: 4.8rem;
    font-weight: 500;
    font-family: 'Manrope';

    svg {
      transition: all 0.2s ease-in-out;
      transform: translateX(0);
      opacity: 0;
      margin-left: auto;
    }

    &:hover svg {
      display: flex;
      transition: all 0.2s ease-in-out;
      transform: translateX(0.5rem);
      opacity: 1;
    }

    &:hover {
      background: ${theme.colors.neutral.neutral5};
      color: ${theme.colors.neutral.neutral100};
    }
  `}
`;
