const MetricsScreen = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.58337 1.5C5.16916 1.5 4.83337 1.83579 4.83337 2.25V9.62734C4.39588 9.32218 3.8854 9.11441 3.33337 9.03544V2.25C3.33337 1.00736 4.34073 0 5.58337 0H9.96453C10.5613 0 11.1336 0.237053 11.5555 0.65901L16.1744 5.27786C16.5963 5.69981 16.8334 6.27211 16.8334 6.86885V15.25C16.8334 16.4926 15.826 17.5 14.5834 17.5H12.1884C12.017 16.9238 11.7011 16.4099 11.2829 16H14.5834C14.9976 16 15.3334 15.6642 15.3334 15.25V6.99753H12.0873C10.8446 6.99753 9.83728 5.99017 9.83728 4.74753V1.5H5.58337ZM11.3373 2.56209V4.74753C11.3373 5.16174 11.6731 5.49753 12.0873 5.49753H14.2727L11.3373 2.56209Z"
        fill="#212121"
      />
      <path
        d="M14.5843 20H11.9965C12.2125 19.5454 12.3334 19.0368 12.3334 18.5H14.5843C16.3792 18.5 17.8343 17.0449 17.8343 15.25V6.93689L18.6752 7.77788C19.0972 8.19984 19.3343 8.77213 19.3343 9.36887V15.25C19.3343 17.8734 17.2076 20 14.5843 20Z"
        fill="#212121"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.83337 15C4.21409 15 5.33337 13.8807 5.33337 12.5C5.33337 11.1193 4.21409 10 2.83337 10C1.45266 10 0.333374 11.1193 0.333374 12.5C0.333374 13.8807 1.45266 15 2.83337 15ZM2.83337 11.5C3.38566 11.5 3.83337 11.9477 3.83337 12.5C3.83337 13.0523 3.38566 13.5 2.83337 13.5C2.28109 13.5 1.83337 13.0523 1.83337 12.5C1.83337 11.9477 2.28109 11.5 2.83337 11.5Z"
        fill="#212121"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.83337 21C10.2141 21 11.3334 19.8807 11.3334 18.5C11.3334 17.1193 10.2141 16 8.83337 16C7.45266 16 6.33337 17.1193 6.33337 18.5C6.33337 19.8807 7.45266 21 8.83337 21ZM8.83337 17.5C9.38566 17.5 9.83337 17.9477 9.83337 18.5C9.83337 19.0523 9.38566 19.5 8.83337 19.5C8.28109 19.5 7.83337 19.0523 7.83337 18.5C7.83337 17.9477 8.28109 17.5 8.83337 17.5Z"
        fill="#212121"
      />
      <path
        d="M10.1137 12.2803C10.4066 11.9874 10.4066 11.5126 10.1137 11.2197C9.82081 10.9268 9.34594 10.9268 9.05304 11.2197L1.55304 18.7197C1.26015 19.0126 1.26015 19.4874 1.55304 19.7803C1.84594 20.0732 2.32081 20.0732 2.6137 19.7803L10.1137 12.2803Z"
        fill="#212121"
      />
    </svg>
  );
};
export default MetricsScreen;
