import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 24rem;
    padding: 2rem 2.4rem 0;

    > div {
      display: flex;
      align-items: flex-start;
      gap: 1.6rem;
    }

    strong {
      color: ${theme.colors.neutral.neutral100};
      font-size: 16px;
      font-weight: 700;
      line-height: 2.4rem;
      letter-spacing: -0.4px;
      line-height: 1.6rem;
    }

    img {
      width: 4.8rem;
      height: 4.8rem;
      border-radius: 50%;
    }

    span {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.neutral.neutral70};
      font-weight: 600;
      line-height: 1.6rem;
      margin: 0.6rem 0;
    }

    hr {
      margin: 1.6rem 0;
      color: ${theme.colors.neutral.neutral10};
      border-style: ridge;
    }

    .wrapperUser {
      display: flex;
      flex-direction: column;
    }
  `}
`;
