const Contracts = () => {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="#4C4C4C"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.5 18.0023C16.5 18.2774 16.276 18.5024 16 18.5024H7V20.0026H16C17.104 20.0026 18 19.1065 18 18.0023V7.82901C18 7.29894 17.789 6.78987 17.414 6.41483L11.585 0.586075C11.57 0.571122 11.5531 0.558152 11.5363 0.545265C11.5238 0.535743 11.5115 0.526266 11.5 0.516067C11.429 0.452058 11.359 0.38905 11.281 0.336043C11.2557 0.318982 11.2281 0.305515 11.2005 0.292109C11.1845 0.284296 11.1685 0.276504 11.153 0.268034C11.1363 0.258627 11.1197 0.248999 11.103 0.239357C11.0488 0.207997 10.9944 0.176498 10.937 0.15202C10.74 0.070009 10.528 0.0290038 10.313 0.0140017C10.2933 0.0127439 10.2738 0.0100802 10.2542 0.00741029C10.2271 0.00371099 10.1999 0 10.172 0H4C2.896 0 2 0.896115 2 2.00026V9.25725C2.46917 9.0914 2.97405 9.00116 3.5 9.00116V2.00026C3.5 1.72522 3.724 1.50019 4 1.50019H10V6.00077C10 7.10492 10.896 8.00103 12 8.00103H16.5V18.0023ZM11.5 2.62134L15.378 6.50084H12C11.724 6.50084 11.5 6.27581 11.5 6.00077V2.62134ZM7 13.5017C7 15.435 5.433 17.0022 3.5 17.0022C1.567 17.0022 0 15.435 0 13.5017C0 11.5685 1.567 10.0013 3.5 10.0013C5.433 10.0013 7 11.5685 7 13.5017ZM1 17.2444V20.2885C1 20.9211 1.76477 21.2379 2.21204 20.7906L3.5 19.5025L4.78795 20.7906C5.23523 21.2379 6 20.9211 6 20.2885V17.2444C5.28495 17.7231 4.42507 18.0023 3.5 18.0023C2.57493 18.0023 1.71505 17.7231 1 17.2444Z" />
    </svg>
  );
};
export default Contracts;
