import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    border: 2px solid ${theme.colors.neutral.neutral5};
    border-radius: ${theme.border.radius};
    width: 100%;
    padding: 2.4rem;
    display: flex;
    margin-bottom: 1.2rem;
  `}
`;

export const WrapperHeader = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    letter-spacing: -1px;
    gap: 1rem;
    margin-right: 10rem;
  `}
`;
export const TextOne = styled.div<{
  marginLeft?: string;
  marginRight?: string;
}>`
  ${({ marginLeft, marginRight }) => css`
    margin-right: 1rem;
    align-items: center;
    margin-left: ${marginLeft || 0};
    margin-right: ${marginRight || 0};
  `}
`;
export const Description = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.neutral.neutral140};
    font-weight: 500;
    margin: 1.6rem 0 3.2rem 0;
  `}
`;
export const TextCustomized = styled.p<{
  color?: string;
  fontWeight?: number;
  lineHeight?: string;
  fontSize?: string;
  marginTop?: string;
  marginBottom?: string;
}>`
  ${({
    theme,
    color,
    fontWeight,
    lineHeight,
    fontSize,
    marginTop,
    marginBottom,
  }) => css`
    font-size: ${fontSize || theme.font.sizes.xsmall};
    color: ${color || theme.colors.neutral.neutral130};
    font-weight: ${fontWeight || 700};
    line-height: ${lineHeight || '24px'};
    margin-top: ${marginTop || 0};
    margin-bottom: ${marginBottom || 0};
  `}
`;
export const Row = styled.div<{ JustifyContent?: string; margin?: string }>`
  ${({ JustifyContent, margin }) => css`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: ${JustifyContent || 'space-between'};
    margin-top: ${margin || 0};
    align-items: center;
  `}
`;
export const Benefit = styled.div`
  ${({ theme }) => css`
    margin-bottom: 3.2rem;
    div + div {
      margin-top: 1.6rem;
    }
    span {
      color: ${theme.colors.neutral.neutral140} !important;
    }
  `}
`;
