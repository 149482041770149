import * as Dialog from '@radix-ui/react-dialog';
import * as S from './styles';
import { BoxContent } from 'components/BoxContent';
import Button from 'components/Button';
import { useEffect, useState } from 'react';
import { BaseModal } from '../BaseModal';
import { theme } from 'styles/theme';
import { Line } from 'components/Line';
import LowPotential from 'components/Icons/LowPotential';
import { InvoiceStatus } from 'components/InvoiceStatus';
import ArrowTrending from 'components/Icons/ArrowTrending';
import ArrowTrendingDown from 'components/Icons/ArrowTrendingDown';
import {
  ArrowRightNeutral,
  CaretDoubleUp,
  CaretUp,
  HexagonMinus,
  KeptIcon,
} from 'components/Icons';
import Sparkle from 'components/Icons/Sparkle';
import moment from 'moment';
import { DailyQualification } from '../PerformaceDetailPackageNewClient';
import { api } from 'services/api';
//import { Loading } from 'components/Loading';

interface IModalProps {
  elHistory?: HistoryProps[];
  elQualifier?: DailyQualification[];
  id_lead: string | null;
  setShowContent: (boolean: boolean) => void;
  setShowNextContent?: (boolean: boolean) => void;
  handleShowBackButton?: (boolean: boolean) => void;
  setTitleName?: (string: string) => void;
  showNextContent?: boolean;
  setOpen?: (boolean: boolean) => void;
}

export type HistoryProps = {
  date: string;
  scoringkenlo: string;
  changedirection: number;
  desc_change_direction: string;
};

export type LinkProps = {
  url: string | null;
};

// eslint-disable-next-line no-empty-pattern
export const EvolutionQualificationModal = ({
  elHistory,
  elQualifier,
  id_lead,
  handleShowBackButton,
  //setTitleName,
  setShowContent,
  showNextContent,
  setShowNextContent,
  setOpen,
}: IModalProps) => {
  // const [showDetails, setShowDetails] = useState(false);
  const [, setIsLoading] = useState(false);
  const [link, setLink] = useState<LinkProps | null>(null);
  const [showBackButton, setShowBackButton] = useState(false);
  const [titleName, setTitleName] = useState('');

  const accessToken = localStorage.getItem('accessToken');
  const idToken = localStorage.getItem('idToken');
  const onClickHandle = async () => {
    setOpen?.(true);
    setShowContent(true);
    setShowNextContent?.(true);
    handleShowBackButton?.(true);
    setShowBackButton(true);
    setTitleName?.('Evolução da qualificação');
    //setShowDetails(true);
    await fecthDataLink();
  };

  useEffect(() => {
    setShowContent(true);
  }, [setShowContent]);

  const fecthDataLink = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get(`qualifier/lead/link/atend/${id_lead}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          idToken,
        },
      });
      setLink(data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getIconQuality = (el: string) => {
    switch (el) {
      case 'alto':
        return (
          <CaretDoubleUp width="18" height="18" fill="#fff" stroke="#2183D9" />
        );
      case 'medio':
      case 'médio':
        return <CaretUp width="16" height="16" />;
      case 'baixo':
        return <HexagonMinus width="20" height="20" />;
      case 'manteve':
        return <LowPotential width="18" height="18" />;
      case 'new':
        return <Sparkle />;
      case null:
        return <Sparkle />;
      default:
        break;
    }
  };

  const getIconStatus = (el: string) => {
    switch (el) {
      case 'alto':
        return <ArrowTrending />;
      case 'baixo':
        return <ArrowTrendingDown />;
      case 'subiu':
        return <ArrowTrending />;
      case 'desceu':
        return <ArrowTrendingDown />;
      case 'médio':
        return <ArrowTrendingDown />;
      case 'manteve':
        return <KeptIcon />;
      default:
        break;
    }
  };

  const getTextStatus = (el: string) => {
    switch (el) {
      case 'alto':
        return 'Subiu para';
      case 'subiu':
        return 'Subiu para';
      case 'baixo':
        return 'Desceu para';
      case 'desceu':
        return 'Desceu para';
      case 'manteve':
        return 'Manteve como';
      default:
        break;
    }
  };

  const getTextQuality = (el: string | null) => {
    switch (el) {
      case 'alto':
        return 'Lead quente';
      case 'medio':
      case 'médio':
        return 'Lead morno';
      case 'baixo':
        return 'Lead frio';
      case 'new':
        return 'Novo cliente';
      case null:
        return 'Novo cliente';
      default:
        break;
    }
  };

  const getTextTypePretense = (el: any) => {
    switch (el) {
      case 'locacao':
        return 'Locação';
      case 'venda':
        return 'Venda';
      case 'new':
        return 'Novo cliente';
      case null:
        return 'Novo cliente';
      default:
        break;
    }
  };

  const getQualified = (elQualifier: any) => {
    return (
      <>
        <BoxContent
          color={theme.colors.neutral.neutral10}
          style={{ padding: '1.6rem 2.4rem 1.6rem 2.4rem' }}
        >
          <S.Row>
            <S.Qualify>
              <S.RowTable>
                <S.TextOne>
                  <S.Title
                    color={theme.colors.neutral.neutral120}
                    fontWeight="700"
                  >
                    {elQualifier?.name_client}
                  </S.Title>
                  <S.Subtitle
                    color={theme.colors.neutral.neutral40}
                    lineHeight="16px"
                    fontWeight="600"
                    fontSize="12px"
                  >
                    Resp. {elQualifier?.name_broker}
                  </S.Subtitle>
                </S.TextOne>
              </S.RowTable>
              <S.RowTable>
                <S.Subtitle color={theme.colors.neutral.neutral120}>
                  {getTextTypePretense(elQualifier?.type_pretense_lead)}
                </S.Subtitle>
                {link && link?.url !== null && (
                  <Button as="a" href={`${link?.url}`} target="__blank">
                    Abrir detalhes
                  </Button>
                )}
              </S.RowTable>
            </S.Qualify>
          </S.Row>
        </BoxContent>
      </>
    );
  };

  const getTable = () => {
    return (
      <BoxContent color={theme.colors.neutral.neutral10}>
        <S.BodyTable>
          {elHistory?.map((el, index) => getBodyTable(el, index))}
        </S.BodyTable>
      </BoxContent>
    );
  };

  const getBodyTable = (el: any, index: number) => {
    return (
      <>
        <S.Row
          key={index}
          marginTop={elHistory?.length !== index ? '24px' : '0'}
        >
          <S.Qualify>
            <S.ColOne>
              <S.Title color={theme.colors.neutral.neutral120} self="end">
                {getIconStatus(el?.desc_change_direction)}
              </S.Title>
              <S.Title
                color={theme.colors.neutral.neutral120}
                margin="1.5rem"
                self="end"
              >
                {getTextStatus(el?.desc_change_direction)}
              </S.Title>
              <S.Subtitle color={theme.colors.neutral.neutral120}>
                <InvoiceStatus
                  status={
                    (el?.scoringkenlo === 'baixo' && 'venda') ||
                    (el?.scoringkenlo === 'médio' && 'average') ||
                    el?.scoringKenlo === 'alto' ||
                    el?.scoringkenlo
                  }
                  text={getTextQuality(el?.scoringkenlo)}
                  icon={getIconQuality(el?.scoringkenlo)}
                  colortText={
                    el?.scoringkenlo === 'alto'
                      ? theme.colors.neutral.neutral0
                      : theme.colors.neutral.neutral120
                  }
                  fontWeight="500"
                />
              </S.Subtitle>
            </S.ColOne>
            <S.RowQualify>
              <S.Subtitle
                color={theme.colors.neutral.neutral130}
                lineHeight="24px"
                fontWeight="500"
                fontSize="16px"
              >
                {el?.date && (
                  <>
                    &nbsp;
                    {el.date ? (index === 0 ? 'Atual • ' : '') : ''}
                    {moment(el.date, 'YYYY-MM-DD').fromNow()}
                  </>
                )}
                {el.resp}
              </S.Subtitle>
            </S.RowQualify>
          </S.Qualify>
        </S.Row>
        {elHistory?.length !== index && <Line width="100%" border="1px" />}
      </>
    );
  };

  return (
    <>
      {/* {isLoading && <Loading background="rgba(0, 0, 0, 0.3)" />} */}
      <Dialog.Root>
        <Dialog.Trigger asChild>
          <Button onClick={() => onClickHandle()}>
            <ArrowRightNeutral />
          </Button>
        </Dialog.Trigger>
        <BaseModal
          title={titleName}
          onBackButton={showBackButton}
          onCloseAll={() => setOpen?.(false)}
        >
          {showNextContent && (
            <>
              {getQualified(elQualifier)}
              {getTable()}
            </>
          )}
        </BaseModal>
      </Dialog.Root>
    </>
  );
};
