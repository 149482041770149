const MetricsScreen = () => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="#4C4C4C"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.7489 0L18.3027 0.000517368L18.403 0.0144424L18.5018 0.0420465L18.5621 0.0675979C18.6413 0.102462 18.7155 0.15315 18.7808 0.218356L18.8215 0.2624L18.865 0.318781L18.9192 0.408904L18.9579 0.499222L18.977 0.563297L18.9906 0.6273L19 0.721503L19.0004 5.25389C19.0004 5.6681 18.6647 6.00389 18.2504 6.00389C17.8708 6.00389 17.557 5.72174 17.5073 5.35566L17.5004 5.25389L17.5 2.559L11.0607 8.99975C10.7944 9.26603 10.3777 9.29025 10.0841 9.07239L10 8.99978L6.96969 6.08924L1.28033 11.7786C0.987437 12.0715 0.512563 12.0715 0.21967 11.7786C-0.0465966 11.5123 -0.0708027 11.0957 0.147052 10.8021L0.21967 10.7179L6.43935 4.49826C6.70561 4.23199 7.12227 4.20779 7.41588 4.42563L7.5 4.49825L10.5303 7.40877L16.438 1.5H13.7489C13.3692 1.5 13.0554 1.21785 13.0058 0.85177L12.9989 0.75C12.9989 0.370304 13.2811 0.0565088 13.6472 0.00684643L13.7489 0ZM0.75 15C1.16421 15 1.5 15.3358 1.5 15.75V19.25C1.5 19.6642 1.16421 20 0.75 20C0.335786 20 0 19.6642 0 19.25V15.75C0 15.3358 0.335786 15 0.75 15ZM6.5 11.75C6.5 11.3358 6.16421 11 5.75 11C5.33579 11 5 11.3358 5 11.75V19.25C5 19.6642 5.33579 20 5.75 20C6.16421 20 6.5 19.6642 6.5 19.25V11.75ZM10.75 13C11.1642 13 11.5 13.3358 11.5 13.75V19.25C11.5 19.6642 11.1642 20 10.75 20C10.3358 20 10 19.6642 10 19.25V13.75C10 13.3358 10.3358 13 10.75 13ZM16.5 8.75C16.5 8.33579 16.1642 8 15.75 8C15.3358 8 15 8.33579 15 8.75V19.25C15 19.6642 15.3358 20 15.75 20C16.1642 20 16.5 19.6642 16.5 19.25V8.75Z" />
    </svg>
  );
};
export default MetricsScreen;
