import * as S from './styles';
import loading from 'assets/image/loading.svg';

export type LoadingProps = {
  background?: string;
};

export const Loading = ({ background = '#fbfcff' }: LoadingProps) => {
  return (
    <S.Wrapper background={background}>
      <img src={loading} alt="Loading" />
    </S.Wrapper>
  );
};
