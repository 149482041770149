const AlertOn = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 1.99622C16.0499 1.99622 19.3567 5.19096 19.4958 9.24527L19.5 9.49622V13.5932L20.88 16.7492C20.9491 16.9071 20.9847 17.0776 20.9847 17.25C20.9847 17.9403 20.4251 18.5 19.7347 18.5L15 18.5015C15 20.1583 13.6569 21.5015 12 21.5015C10.4023 21.5015 9.09634 20.2526 9.00509 18.6778L8.99955 18.4992L4.27486 18.5C4.10353 18.5 3.93402 18.4648 3.77686 18.3965C3.14366 18.1215 2.85331 17.3852 3.12835 16.752L4.5 13.5941V9.49611C4.5006 5.34132 7.85209 1.99622 12 1.99622ZM13.4995 18.4992L10.5 18.5015C10.5 19.3299 11.1716 20.0015 12 20.0015C12.7797 20.0015 13.4204 19.4066 13.4931 18.6459L13.4995 18.4992ZM12 3.49622C8.67984 3.49622 6.00048 6.17047 6 9.49622V13.9058L4.65602 17H19.3525L18 13.9068L18.0001 9.50907L17.9964 9.28387C17.8853 6.0504 15.2416 3.49622 12 3.49622ZM21 8.24999H23C23.4142 8.24999 23.75 8.58578 23.75 8.99999C23.75 9.37969 23.4678 9.69348 23.1018 9.74315L23 9.74999H21C20.5858 9.74999 20.25 9.41421 20.25 8.99999C20.25 8.6203 20.5322 8.3065 20.8982 8.25684L21 8.24999ZM1 8.24999H3C3.41421 8.24999 3.75 8.58578 3.75 8.99999C3.75 9.37969 3.46785 9.69348 3.10177 9.74315L3 9.74999H1C0.585786 9.74999 0.25 9.41421 0.25 8.99999C0.25 8.6203 0.532154 8.3065 0.898229 8.25684L1 8.24999ZM22.6 2.54999C22.8259 2.85124 22.791 3.26714 22.5338 3.52699L22.45 3.59999L20.45 5.09999C20.1186 5.34852 19.6485 5.28136 19.4 4.94999C19.1741 4.64875 19.209 4.23285 19.4662 3.973L19.55 3.89999L21.55 2.39999C21.8814 2.15146 22.3515 2.21862 22.6 2.54999ZM2.45 2.39999L4.45 3.89999C4.78137 4.14852 4.84853 4.61862 4.6 4.94999C4.35147 5.28136 3.88137 5.34852 3.55 5.09999L1.55 3.59999C1.21863 3.35146 1.15147 2.88136 1.4 2.54999C1.64853 2.21862 2.11863 2.15146 2.45 2.39999Z"
        fill="#404040"
      />
    </svg>
  );
};
export default AlertOn;
