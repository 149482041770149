const Check = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2 14.4C14.2 14.62 14.0208 14.8 13.8 14.8H9.21418C8.98443 15.2408 8.69337 15.6446 8.35229 16H13.8C14.6832 16 15.4 15.2832 15.4 14.4V6.2624C15.4 5.8384 15.2312 5.4312 14.9312 5.1312L10.268 0.4688C10.256 0.456838 10.2424 0.446464 10.229 0.436156C10.219 0.428539 10.2091 0.420959 10.2 0.4128C10.1432 0.3616 10.0872 0.3112 10.0248 0.2688C10.0045 0.255153 9.9824 0.24438 9.96037 0.233657C9.94753 0.227407 9.93472 0.221175 9.92235 0.2144C9.90899 0.206875 9.89568 0.199173 9.88235 0.191461C9.83899 0.166376 9.79544 0.14118 9.74955 0.1216C9.59195 0.056 9.42235 0.0232 9.25035 0.0112C9.23459 0.0101938 9.21897 0.00806313 9.20331 0.00592747C9.18162 0.00296841 9.15985 0 9.13755 0H4.19995C3.31675 0 2.59995 0.7168 2.59995 1.6V7.59853C2.97929 7.44034 3.38138 7.32579 3.79995 7.26116V1.6C3.79995 1.38 3.97915 1.2 4.19995 1.2H8.99995V4.8C8.99995 5.6832 9.71675 6.4 10.6 6.4H14.2V14.4ZM10.2 2.0968L13.3024 5.2H10.6C10.3792 5.2 10.2 5.02 10.2 4.8V2.0968Z"
        fill="#2183D9"
      />
      <path
        d="M8.99995 12.4C8.99995 14.8301 7.03 16.8 4.59995 16.8C2.1699 16.8 0.199951 14.8301 0.199951 12.4C0.199951 9.96995 2.1699 8 4.59995 8C7.03 8 8.99995 9.96995 8.99995 12.4ZM7.28279 10.5172C7.12658 10.3609 6.87332 10.3609 6.71711 10.5172L3.79995 13.4343L2.48279 12.1172C2.32658 11.9609 2.07332 11.9609 1.91711 12.1172C1.7609 12.2734 1.7609 12.5266 1.91711 12.6828L3.51711 14.2828C3.67332 14.4391 3.92658 14.4391 4.08279 14.2828L7.28279 11.0828C7.439 10.9266 7.439 10.6734 7.28279 10.5172Z"
        fill="#2183D9"
      />
    </svg>
  );
};
export default Check;
