import { PlanCustomProvider } from 'contexts/PlanCustomContext';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import './i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { FeaturesProvider } from 'contexts/FeaturesContext';
import { QualificationProvider } from 'contexts/AvailableQualification';
import { NotificationBannerProvider } from 'contexts/NotificationBannerContext';
import { MovtoDailyQualifierProvider } from 'contexts/MovtoDailyQualifier';
import { ClientInfoProvider } from 'contexts/ClientInfoContext';

ReactDOM.render(
  <React.StrictMode>
    <ClientInfoProvider>
      <MovtoDailyQualifierProvider>
        <NotificationBannerProvider>
          <QualificationProvider>
            <FeaturesProvider>
              <PlanCustomProvider>
                <App />
              </PlanCustomProvider>
            </FeaturesProvider>
          </QualificationProvider>
        </NotificationBannerProvider>
      </MovtoDailyQualifierProvider>
    </ClientInfoProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
