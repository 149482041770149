import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${theme.colors.neutral.neutral0};
    border-radius: ${theme.border.radius};
    height: 8rem;
    width: 8rem;
    padding: 2.8rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: ${theme.colors.neutral.neutral10};
      svg {
        fill: ${theme.colors.primary50};
      }
    }
  `}
`;
