import * as Dialog from '@radix-ui/react-dialog';
import * as S from './styles';
import { BoxContent } from 'components/BoxContent';
import Button from 'components/Button';
import { useState } from 'react';
import { BaseModal } from '../BaseModal';
import { theme } from 'styles/theme';
import { Line } from 'components/Line';
import { ArrowRightNeutral } from 'components/Icons';
import { api } from 'services/api';
import moment from 'moment';
import 'moment/locale/pt-br';
import { formattedValue } from 'helpers/util';
import { uncapitalizeFirstLetter } from 'helpers/util';
import { Loading } from 'components/Loading';

interface IModalProps {
  el: string;
  id_qualifier_bought: string;
}

type QualifierBoughtInstallmentProps = {
  name?: string;
  amount: number;
  type_payment: { description: string };
};

type QualifierBoughtProps = {
  date: string;
  value: string;
  discount_value: string;
  final_value: string;
  amount_leads: number;
  qualifier_installment: { installment: QualifierBoughtInstallmentProps };
  qualifier_pack: { name: string };
  name_user: string;
};

export const PerfomanceDetailPackage = ({
  el,
  id_qualifier_bought,
}: IModalProps) => {
  const [showDetails, setShowDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [qualifierBought, setQualifierBought] = useState<
    QualifierBoughtProps[]
  >([]);

  const accessToken = localStorage.getItem('accessToken');
  const idToken = localStorage.getItem('idToken');

  const onClickHandle = async () => {
    if (Number(id_qualifier_bought) > 0) {
      await fetchDataQualifierBought();
    }
    setShowDetails(true);
  };

  const fetchDataQualifierBought = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get(
        `qualifier/bought/${id_qualifier_bought} `,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            idToken,
          },
        },
      );
      setQualifierBought(data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getQualified = () => {
    return (
      <>
        <BoxContent
          color={theme.colors.neutral.neutral10}
          style={{ padding: '1.6rem 2.4rem 1.6rem 2.4rem' }}
        >
          <S.Row>
            <S.Qualify>
              <S.TexTwo_>
                <S.Subtitle color={theme.colors.neutral.neutral50}>
                  {`Pacote ${qualifierBought[0]?.qualifier_pack?.name}`}
                </S.Subtitle>
                <S.TitleTwo color={theme.colors.neutral.neutral100}>
                  <b>{`${qualifierBought[0]?.amount_leads} qualificações`}</b>
                </S.TitleTwo>
              </S.TexTwo_>
              <S.TextOne>
                <S.SubtitleContract color={theme.colors.neutral.neutral50}>
                  Contratado por
                </S.SubtitleContract>
                <S.Title color={theme.colors.neutral.neutral120}>
                  <b>{qualifierBought[0]?.name_user}</b> em{' '}
                  {`${moment(qualifierBought[0]?.date).format('L')}`}
                </S.Title>
              </S.TextOne>
            </S.Qualify>
          </S.Row>
        </BoxContent>
      </>
    );
  };

  const getTable = () => {
    return (
      <>
        <BoxContent
          color={theme.colors.neutral.neutral10}
          style={{ padding: '1.6rem 2.4rem 1.8rem 2.4rem' }}
        >
          <S.Row>
            <S.Qualify>
              <S.TextFooter>
                <S.TitleTree color={theme.colors.neutral.neutral130}>
                  Valor do pacote
                </S.TitleTree>
                <S.TitleTree color={theme.colors.neutral.neutral130}>
                  Descontos
                </S.TitleTree>
              </S.TextFooter>
              <S.TexTree>
                <S.Title color={theme.colors.neutral.neutral70}>
                  <b>{`${formattedValue(qualifierBought[0]?.value)}`}</b>
                </S.Title>
                <S.Title color={theme.colors.neutral.neutral70}>
                  <b>{`${
                    qualifierBought[0]?.discount_value !== ''
                      ? `${formattedValue(qualifierBought[0]?.discount_value)}`
                      : '0,00'
                  }
                    `}</b>
                </S.Title>
              </S.TexTree>
            </S.Qualify>
          </S.Row>
          <Line width="100%" border="0.1rem" />
          <S.Footer>
            <S.TitleTwo color={theme.colors.neutral.neutral120}>
              <b>{`${formattedValue(qualifierBought[0]?.final_value)}`}</b>
            </S.TitleTwo>
            <S.Title color={theme.colors.neutral.neutral100}>
              Em{' '}
              {`${
                qualifierBought[0]?.qualifier_installment.installment.amount
              }x no ${uncapitalizeFirstLetter(
                qualifierBought[0]?.qualifier_installment.installment
                  .type_payment.description,
              )}`}
            </S.Title>
          </S.Footer>
        </BoxContent>
      </>
    );
  };
  return (
    <>
      {isLoading && <Loading background="rgba(0, 0, 0, 0.3)" />}
      <Dialog.Root>
        <Dialog.Trigger asChild id={el}>
          <Button margin="1px" onClick={() => onClickHandle()}>
            <ArrowRightNeutral />
          </Button>
        </Dialog.Trigger>
        {showDetails && !isLoading && (
          <BaseModal title="Detalhe do pacote">
            {getQualified()}
            {getTable()}
          </BaseModal>
        )}
      </Dialog.Root>
    </>
  );
};
