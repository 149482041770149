import * as S from './styles';

type LogoProps = {
  srcImg: string;
  text: string;
  onClick: () => void;
};

export const Logo = ({ srcImg, text, onClick }: LogoProps) => {
  return (
    <S.Wrapper onClick={onClick}>
      <img src={srcImg} alt={text} />
    </S.Wrapper>
  );
};
