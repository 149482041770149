import styled, { css } from 'styled-components';

export const Input = styled.input`
  ${({ theme }) => css`
    text-align: center;
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.neutral.neutral70};
    border: 0;
    width: 100%;
    padding: 0 1rem 0 1rem;
    outline: none;
  `}
`;
