const CircleWavyCheck = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="#242424"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.83607 1.03398C8.94859 1.07226 9.0585 1.11779 9.16513 1.17028L10.4479 1.80183C10.796 1.97323 11.204 1.97323 11.5521 1.80183L12.8349 1.17028C14.1975 0.499421 15.8459 1.06018 16.5168 2.42276L16.5902 2.58509L16.6531 2.75183L17.1135 4.10543C17.2385 4.4728 17.527 4.7613 17.8944 4.88627L19.248 5.34673C20.6858 5.83585 21.4549 7.39799 20.9658 8.83585C20.9275 8.94837 20.882 9.05829 20.8295 9.16492L20.198 10.4477C20.0266 10.7958 20.0266 11.2038 20.198 11.5519L20.8295 12.8347C21.5004 14.1972 20.9396 15.8457 19.577 16.5165C19.4704 16.569 19.3605 16.6146 19.248 16.6528L17.8944 17.1133C17.527 17.2383 17.2385 17.5268 17.1135 17.8941L16.6531 19.2477C16.1639 20.6856 14.6018 21.4547 13.1639 20.9656C13.0514 20.9273 12.9415 20.8818 12.8349 20.8293L11.5521 20.1977C11.204 20.0263 10.796 20.0263 10.4479 20.1977L9.16513 20.8293C7.80254 21.5002 6.15411 20.9394 5.48324 19.5768C5.43075 19.4702 5.38522 19.3603 5.34694 19.2477L4.88648 17.8941C4.76151 17.5268 4.47301 17.2383 4.10564 17.1133L2.75204 16.6528C1.31418 16.1637 0.545069 14.6016 1.03419 13.1637C1.07247 13.0512 1.118 12.9413 1.1705 12.8347L1.80204 11.5519C1.97345 11.2038 1.97345 10.7958 1.80204 10.4477L1.1705 9.16492C0.499634 7.80233 1.06039 6.15389 2.42298 5.48303C2.52961 5.43053 2.63952 5.385 2.75204 5.34673L4.10564 4.88627C4.47301 4.7613 4.76151 4.4728 4.88648 4.10543L5.34694 2.75183C5.83607 1.31396 7.3982 0.544855 8.83607 1.03398ZM14.4697 7.96946L9.0504 13.3887L7.07617 11.0197C6.811 10.7014 6.33807 10.6584 6.01986 10.9236C5.70166 11.1888 5.65866 11.6617 5.92384 11.9799L8.42384 14.9799C8.70617 15.3187 9.21848 15.342 9.53033 15.0301L15.5303 9.03012C15.8232 8.73722 15.8232 8.26235 15.5303 7.96946C15.2374 7.67657 14.7626 7.67657 14.4697 7.96946Z" />
    </svg>
  );
};
export default CircleWavyCheck;
