import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 1rem;
    text-decoration: none;
    position: relative;
    height: 1.6rem;

    p {
      color: ${theme.colors.neutral.neutral70};
      margin-bottom: 0rem;
    }

    &::after {
      content: attr(aria-label);
      background: ${theme.colors.neutral.neutral70};
      box-shadow: 0px 0px 16px rgba(36, 36, 36, 0.12);
      color: ${theme.colors.neutral.neutral0};
      border-radius: ${theme.border.radius};
      transition: all 0.2s ease;
      padding: 1.2rem;
      opacity: 0;
      top: -7.5rem;
      position: absolute;
      pointer-events: none;
      font-size: 1.2rem;
      width: 22.4rem;
      white-space: pre-wrap;
    }

    &:hover::after {
      pointer-events: all;
      opacity: 1;
    }
  `}
`;
