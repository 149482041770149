const BuyPackage = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 6.5C22 3.46243 19.5376 1 16.5 1C13.4624 1 11 3.46243 11 6.5C11 9.53757 13.4624 12 16.5 12C19.5376 12 22 9.53757 22 6.5ZM17.0006 7.00001L17.0011 9.50352C17.0011 9.77966 16.7773 10.0035 16.5011 10.0035C16.225 10.0035 16.0011 9.77966 16.0011 9.50352L16.0006 7.00001H13.4961C13.22 7.00001 12.9961 6.77615 12.9961 6.50001C12.9961 6.22387 13.22 6.00001 13.4961 6.00001H16.0005L16 3.49927C16 3.22313 16.2239 2.99927 16.5 2.99927C16.7761 2.99927 17 3.22313 17 3.49927L17.0005 6.00001H19.503C19.7792 6.00001 20.003 6.22387 20.003 6.50001C20.003 6.77615 19.7792 7.00001 19.503 7.00001H17.0006ZM19.5 14V12.2678C20.051 11.9806 20.5557 11.6168 21 11.1904V18.75C21 20.483 19.6435 21.8992 17.9344 21.9949L17.75 22H6.25C4.51697 22 3.10075 20.6435 3.00514 18.9344L3 18.75V7.25C3 5.51697 4.35645 4.10075 6.06558 4.00514L6.25 4H10.4982C10.3004 4.47417 10.1572 4.97679 10.0764 5.5H6.25C5.33183 5.5 4.57881 6.20711 4.5058 7.10647L4.5 7.25V14H9C9.3797 14 9.69349 14.2822 9.74315 14.6482L9.75 14.75C9.75 15.9926 10.7574 17 12 17C13.1909 17 14.1656 16.0748 14.2448 14.904L14.25 14.75C14.25 14.3703 14.5322 14.0565 14.8982 14.0068L15 14H19.5ZM4.5 15.5V18.75C4.5 19.6682 5.20711 20.4212 6.10647 20.4942L6.25 20.5H17.75C18.6682 20.5 19.4212 19.7929 19.4942 18.8935L19.5 18.75V15.5H15.675C15.3404 17.1483 13.9247 18.404 12.2003 18.4947L12 18.5C10.253 18.5 8.78498 17.3053 8.36837 15.6884L8.32501 15.5H4.5Z"
        fill="#F82E52"
      />
    </svg>
  );
};
export default BuyPackage;
