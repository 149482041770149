import * as S from './styles';
import { Close, ErrorCircle } from 'components/Icons';
import { Line } from 'components/Line';
import Button from 'components/Button';
import { useEffect, useState } from 'react';
import { api } from 'services/api';
import { formattedValue, SumArrayOfNumbers, trimString } from 'helpers/util';
import loadingSmall from 'assets/image/loading-small.gif';
import { ToolTip } from 'components/ToolTip';
import { DataDetails } from 'components/DataDetails';

type InvoiceDetailsProps = {
  title?: string;
  invoiceDate?: string;
  paymentDate?: string;
  idBilling: string | undefined;
  onClose?: () => void;
};

type ProductsProps = {
  ds_product: string;
  value: string;
  id_compo_billing: string;
  complement: string | null;
};

type BillingProps = {
  id_billing: number;
  complement?: string;
};

type CompoBillingProps = {
  name: string;
  products: ProductsProps[];
  id_product: string;
  complement: string | null;
  id_plan: string;
  id_signature: number;
};

export const InvoiceDetailsModal = ({
  idBilling,
  invoiceDate,
  paymentDate,
  onClose,
  title,
}: InvoiceDetailsProps) => {
  const [billing, setBilling] = useState<BillingProps>();
  const [compoBilling, setCompoBilling] = useState<CompoBillingProps[]>([]);
  const accessToken = localStorage.getItem('accessToken');
  const idToken = localStorage.getItem('idToken');
  const [isLoading, setIsLoading] = useState(false);

  const numberValues = compoBilling?.map((item) =>
    item?.products?.map((value) => Number(value.value)),
  );

  const newArrayProductValues =
    numberValues && ([] as number[])?.concat(...numberValues);

  useEffect(() => {
    fetchDataBillings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataBillings = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get(`client/billings/${idBilling}`, {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          idToken,
        },
      });
      setBilling(data.data);
      setCompoBilling(data.data.compo_billing);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const CloseButton = () => {
    return (
      <S.CloseButton onClick={onClose}>
        <Close />
      </S.CloseButton>
    );
  };

  const Loader = () => {
    return (
      <S.Loader>
        <img src={loadingSmall} />
      </S.Loader>
    );
  };

  const formatComplementBilling = (text: string | null) => {
    if (text && text.toLowerCase().includes('parcela'))
      return trimString(text.replace(' de ', '/'), 16);
    return '-';
  };

  return (
    <S.Container>
      <S.Overlay />
      <S.Content>
        <S.Title>Detalhe da fatura</S.Title>
        <CloseButton />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <S.WrapperInvoiceDetail>
              <DataDetails
                title="Referência"
                subtitle={title}
                fontSize="1.2rem"
                fontWeight={500}
                flexNumber={1}
              />
              <DataDetails
                title="Data de vencimento"
                subtitle={invoiceDate}
                fontSize="1.2rem"
                fontWeight={500}
                flexNumber={1}
              />
              <DataDetails
                title="Data de pagamento"
                subtitle={paymentDate}
                fontSize="1.2rem"
                fontWeight={500}
                flexNumber={1}
              />
            </S.WrapperInvoiceDetail>
            <S.ContentDetails>
              <header>
                <span>Descrição</span>
                <span>Cobrança</span>
                <span>Valor</span>
              </header>
              <Line width="100%" border="1px" />
              <div className="contentPlan">
                {compoBilling?.map((item, index) => {
                  return (
                    <span key={item?.id_plan}>
                      <ul className="planName">
                        <li className="planName-row">
                          {item?.name ? item.name : '-'}
                        </li>
                        <li className="planName-row">
                          {billing?.complement ? (
                            <ToolTip
                              content={billing.complement}
                              text={formatComplementBilling(billing.complement)}
                            />
                          ) : (
                            '-'
                          )}
                        </li>
                        <li className="planName-row__value">
                          {formattedValue(
                            SumArrayOfNumbers(numberValues[index]),
                          )}
                        </li>
                      </ul>
                      {item.products.map((products: ProductsProps) => {
                        return (
                          <ul
                            className="additionalPlan"
                            key={products.id_compo_billing}
                          >
                            <li className="additionalPlan-row">
                              {products.ds_product}
                            </li>
                            <li className="additionalPlan-row">
                              {products.complement ? (
                                <ToolTip
                                  content={products.complement}
                                  text={trimString(products.complement, 14)}
                                />
                              ) : (
                                '-'
                              )}
                            </li>
                            <li className="additionalPlan-row__value">
                              {formattedValue(Number(products.value))}
                            </li>
                          </ul>
                        );
                      })}
                    </span>
                  );
                })}
              </div>
              <Line width="100%" border="1px" />
              <footer>
                <Button icon={<ErrorCircle />}>Obter ajuda</Button>
                <span className="totalValue">
                  {formattedValue(SumArrayOfNumbers(newArrayProductValues))}
                </span>
              </footer>
            </S.ContentDetails>
          </>
        )}
      </S.Content>
    </S.Container>
  );
};
