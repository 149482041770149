import styled, { css } from 'styled-components';
type WrapperProps = {
  status: 'ativo' | string;
};
export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, status }) => css`
    border-radius: 8px;
    display: inline-block;
    background: ${status === 'ativo' &&
    theme.colors.feedback.feedbackSuccess60};
  `}
`;
export const Text = styled.span`
  ${({ theme }) => css`
    display: block;
    font-size: ${theme.font.sizes.xsmall};
    font-weight: 600;
    color: ${theme.colors.neutral.neutral100};
    padding: 4px 7px;
  `}
`;
