import styled, { css } from 'styled-components';

export const BaseContainer = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.neutral.neutral0};
    border-radius: ${theme.border.radius};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.6rem 2.4rem;
    flex: 1;
    height: 8rem;

    .buttonDownload {
      display: flex;
      align-items: center;
      border: 0;
      background: transparent;
      color: ${theme.colors.primary50};
      font-size: 1.6rem;
      font-weight: ${theme.font.bold};
      gap: 1rem;
      &:hover {
        color: ${theme.colors.primary70};
        svg {
          fill: ${theme.colors.primary70};
        }
      }
    }

    a {
      text-decoration: none;
    }
  `}
`;
