import IconLoading from 'assets/image/loadingSpinner.gif';
import { Wrapper } from './styles';

type TextProp = {
  text: string;
};

export default function LoadingSpinnerInButton({ text }: TextProp) {
  return (
    <Wrapper>
      <img src={IconLoading} alt="loading" />
      <p>{text}</p>
    </Wrapper>
  );
}
