import * as Dialog from '@radix-ui/react-dialog';
import * as S from './styles';
import { BoxContent } from 'components/BoxContent';
import Button from 'components/Button';
import { useContext, useEffect, useState } from 'react';
import { BaseModal } from '../BaseModal';
import { theme } from 'styles/theme';
import { Line } from 'components/Line';
import { formattedValue } from 'helpers/util';
import { api } from 'services/api';
import { NotificationBannerContext } from 'contexts/NotificationBannerContext';
import { useHistory, useParams } from 'react-router-dom';
import { QualificationContext } from 'contexts/AvailableQualification';
import { FeatureContext } from 'contexts/FeaturesContext';
import close from 'assets/image/close_white.png';
import { MessageModalError } from 'components/MessageModalError';
import LoadingSpinnerInButton from 'components/SpinnerLoadingInButton';

type IModalProps = {
  name?: string | undefined;
  cost_qualifier?: number | string | undefined;
  estimated_days?: number | string | undefined;
  amount_qualifier?: number | string | undefined;
  discount_twenty_value?: number;
  discount_value?: string;
  amount_installment: string | number;
  className?: string | undefined;
  estimated_duration: number;
  final_value?: string | number;
  installment_value?: number;
  value?: number;
  valueName?: string;
  id_pack?: string | undefined;
  onReturn?: () => void;
  onClose?: () => void;
  percent_discount?: string;
  amount_leads: number;
  titleButton?: string;
  isInstallment?: boolean;
};

export const DetailPackageSummaryQualifier = ({
  onReturn,
  amount_leads,
  name,
  id_pack,
  cost_qualifier,
  percent_discount,
  estimated_duration,
  amount_qualifier,
  amount_installment,
  value,
  installment_value,
  final_value,
  discount_value,
  estimated_days,
  valueName,
  onClose,
  isInstallment,
  titleButton = 'Cancelar',
}: IModalProps) => {
  const { id, type } = useParams<{
    id: string;
    type: string;
  }>();
  const accessToken = localStorage.getItem('accessToken');
  const idToken = localStorage.getItem('idToken');
  const [statusError, setStatusError] = useState(200);
  const [modalError, setModalError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { setDataBanner } = useContext(NotificationBannerContext);
  const { dataAgency } = useContext(FeatureContext);
  const { dataQualifierBalance, fetchDataQualifierBalance } =
    useContext(QualificationContext);
  const history = useHistory();
  const onClickHandle = async () => {
    if (
      Number(amount_installment) !== undefined ||
      Number(amount_installment) > 0
    ) {
      await confirmPurchase();
    }
  };

  useEffect(() => {
    if (dataQualifierBalance?.balance_light !== 0) {
      dataAgency?.id_client && fetchDataQualifierBalance(dataAgency.id_client);
    }
  }, [
    fetchDataQualifierBalance,
    dataAgency,
    dataQualifierBalance?.balance_light,
  ]);

  const confirmPurchase = async () => {
    if (name === 'custom') {
      try {
        setModalError(false);
        setIsLoading(true);
        const { data } = await api.post(
          'qualifier/bought',
          {
            id_qualifier_pack: id_pack,
            id_qualifier_installment: amount_installment,
            is_custom: true,
            amount_leads: Number(amount_qualifier),
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
              idToken,
            },
          },
        );
        setStatusError(data.success);
        if (Array.isArray(data.data)) {
          setDataBanner(data.data[0]);
        }
        history.push(`/QualifierDetails/${dataAgency?.id_client}/details`);
      } catch (error: any) {
        if (error.response) {
          setStatusError(error.response.status);
          setModalError(true);
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        setModalError(false);
        setIsLoading(true);
        const { data } = await api.post(
          'qualifier/bought',
          {
            id_qualifier_pack: id_pack,
            id_qualifier_installment: amount_installment,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
              idToken,
            },
          },
        );
        setStatusError(data.success);
        if (Array.isArray(data.data)) {
          setDataBanner(data.data[0]);
        }
        history.push(`/QualifierDetails/${dataAgency?.id_client}/details`);
      } catch (error: any) {
        if (error.response) {
          setStatusError(error.response.status);
          setModalError(true);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getData = () => {
    return (
      <>
        <BoxContent
          color={theme.colors.neutral.neutral10}
          style={{ padding: '1.6rem 2.4rem 1.6rem 2.4rem' }}
        >
          <S.Row>
            <S.Qualify>
              <S.TexTwo>
                <S.TitleTwo color={theme.colors.neutral.neutral120}>
                  <b>Pacote {name !== 'custom' ? name : 'Personalizado'}</b>
                </S.TitleTwo>
                <S.Subtitle color={theme.colors.neutral.neutral130}>
                  {` ${formattedValue(cost_qualifier)}`} por lead{' '}
                </S.Subtitle>
              </S.TexTwo>
              <S.TextOne>
                <S.TitleFour color={theme.colors.neutral.neutral40}>
                  {amount_qualifier} qualificações{' '}
                </S.TitleFour>
                <S.Subtitle color={theme.colors.neutral.neutral130}>
                  <S.Subtitle color={theme.colors.neutral.neutral130}>
                    {id === '3' ? (
                      <span>Duração estimada de 30 dias</span>
                    ) : (
                      <span>
                        Duração estimada de{' '}
                        {Math.floor(Number(amount_qualifier) / amount_leads)}{' '}
                        {estimated_duration === 1 ? 'mês' : 'meses'}
                        {estimated_days !== 0 &&
                          ` e ${Math.ceil(
                            ((Number(amount_qualifier) % amount_leads) /
                              amount_leads) *
                              30,
                          )} ${estimated_days === 1 ? 'dia' : 'dias'}`}
                      </span>
                    )}
                  </S.Subtitle>
                </S.Subtitle>
              </S.TextOne>
            </S.Qualify>
          </S.Row>
        </BoxContent>
      </>
    );
  };

  const getTable = () => {
    return (
      <>
        {statusError !== 200 && (
          <MessageModalError
            img={close}
            description="Tivemos um erro na tentativa de compra de pacote, tente novamente."
            isOpen={modalError}
            setIsOpen={setModalError}
          />
        )}
        <BoxContent
          color={theme.colors.neutral.neutral10}
          style={{ padding: '2.4rem' }}
        >
          <S.Row>
            <S.Qualify>
              <S.TextFooter>
                <S.TitleTree color={theme.colors.neutral.neutral130}>
                  Valor do pacote
                </S.TitleTree>

                <S.TitleTree color={theme.colors.neutral.neutral130}>
                  Desconto à vista{' '}
                  {percent_discount && `(${percent_discount}%)`}
                </S.TitleTree>
              </S.TextFooter>
              <S.TexTree>
                <S.Title color={theme.colors.neutral.neutral70}>
                  <b>{` ${formattedValue(value)}`}</b>
                </S.Title>

                <S.Title color={theme.colors.neutral.neutral70}>
                  <b>{`- ${
                    name === 'custom' && isInstallment
                      ? formattedValue(0)
                      : name === 'custom' && value && final_value
                      ? formattedValue(value - (final_value as number))
                      : formattedValue(discount_value)
                  }`}</b>
                </S.Title>
              </S.TexTree>
            </S.Qualify>
          </S.Row>
          <Line width="100%" border="0.1rem" />
          <S.Footer>
            <S.TitleTwo color={theme.colors.neutral.neutral120}>
              <b>
                {name === 'custom' && isInstallment
                  ? formattedValue(Number(value))
                  : Number(discount_value) > 0
                  ? formattedValue(Number(final_value) - Number(discount_value))
                  : formattedValue(final_value)}
              </b>
            </S.TitleTwo>
            <S.Title color={theme.colors.neutral.neutral100}>
              <S.Title color={theme.colors.neutral.neutral100}>
                {installment_value === 0
                  ? `${valueName} no boleto`
                  : `${valueName} de ${formattedValue(
                      installment_value,
                    )} no boleto`}
              </S.Title>
            </S.Title>
          </S.Footer>
        </BoxContent>
        <S.Footer>
          <div>
            <Dialog.Close asChild>
              <Button
                minimal={false}
                border
                borderColor="#2183D9"
                color="#2183D9"
                height="5.6rem"
                hover="#FFFFFF"
                hoverText="#114879"
                borderHover="#114879"
                onClick={() =>
                  titleButton === 'Voltar'
                    ? onReturn && onReturn()
                    : onClose && onClose()
                }
              >
                {titleButton}
              </Button>
            </Dialog.Close>
            {type === 'details' || type === 'new' ? (
              <Button
                minimal={false}
                height="5.6rem"
                disabled={isLoading ? true : false}
                onClick={() => onClickHandle()}
                background="#2183D9"
                hover="#114879"
              >
                {isLoading ? (
                  <LoadingSpinnerInButton text="Comprar pacote" />
                ) : (
                  'Comprar pacote'
                )}
              </Button>
            ) : (
              <Button
                minimal={false}
                height="5.6rem"
                disabled={isLoading ? true : false}
                onClick={() => {
                  onClickHandle();
                  history.push(`/ClientArea`);
                }}
              >
                {isLoading ? (
                  <LoadingSpinnerInButton text="Comprar pacote" />
                ) : (
                  'Comprar pacote'
                )}
              </Button>
            )}
          </div>
        </S.Footer>
      </>
    );
  };
  return (
    <>
      <BaseModal title="Resumo do pacote" onCloseX={onClose}>
        <>
          {getData()}
          {getTable()}
        </>
      </BaseModal>
    </>
  );
};
