import { BackButton } from 'components/BackButton';
import { BaseContainer } from 'components/BaseContainer';
import Button from 'components/Button';
import { Heading } from 'components/Heading';
import {
  ArrowLeft,
  CircleWavyCheck,
  Home,
  People,
  PlusUser,
  QuestionCircle,
  Screen,
  TwoUsers,
} from 'components/Icons';
import { capitalizeFirstLetter, formattedValue } from 'helpers/util';
import { PlanDetailsValuesProps, PlanDetailsProps } from 'pages/PlanDetails';
import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { api } from 'services/api';
import { Loading } from 'components/Loading';
import * as Dialog from '@radix-ui/react-dialog';
import * as S from './styles';

import moment from 'moment';
import 'moment/locale/pt-br';

import { ToggleButton } from './components/ToggleButton';
import { CounterButton } from './components/CounterBotton';
import { MainHeader } from 'components/MainHeader';
import { BaseMain } from 'components/BaseMain';
import { PaymentOptionsDetais } from 'components/PaymentOptionsDetails';
import { PlanSummaryModal } from 'components/PlanSummaryModal';
import { Banner } from 'components/Banner';
import { PlanCustomContext } from 'contexts/PlanCustomContext';
import { ToolTip } from 'components/ToolTip';
import { ToolTipIcon } from 'components/Icons';
import { Line } from 'components/Line';
import { DataDetails } from 'components/DataDetails';

type PremiumProps = {
  plan: {
    name: string;
  };
  finalValue: string;
  frequency: string;
  terminationDate: string;
  totalCost: string;
  id_plan: number;
};

// type SignatureProps = {
//   frequency: string;
//   totalUserCount: number;
//   activationDate: string;
//   terminationDate: number;
//   costTotalPlan?: number | string;
//   totalCost: number;
//   discountValue: number;
//   count?: number;
//   plan?: number;
// };

type signaturePlanDataProps = {
  name: string;
  frequency: string;
};

export const ChangeOfPlan = () => {
  const [planDetails, setPlanDetails] = useState<PlanDetailsProps | null>(null);
  const [planDetailsValues, setPlanDetailsValues] =
    useState<PlanDetailsValuesProps | null>(null);
  const [signaturePlanData, setSignaturePlanData] =
    useState<signaturePlanDataProps | null>(null);
  //const [signature, setSignature] = useState<SignatureProps | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [totalValue, setTotalValue] = useState(0);
  const [premiumPlan, setPremiumPlan] = useState<PremiumProps[]>([]);
  const [isCloseModal, setIsCloseModal] = useState(false);

  const {
    getAmountInstallment,
    open,
    balance,
    amount,
    handleCloseMoldal,
    handleSetTypeProduct,
    paymentOptions,
    count,
    frequency,
    plan,
    isFrequencyToggled,
    newTotalPlan,
    handleFrequency,
    handleCountDecrease,
    handleCountIncrease,
    getTotalUserCount,
    handleNewPaymentOptions,
    handleGetAmountInstallment,
    handleFrequencyAutomatic,
    handleSetIsFrequencyToggled,
    handleSetAmount,
    handleSetBalance,
    newPaymentOptions,
  } = useContext(PlanCustomContext);

  useEffect(() => {
    const newCalculatePayment = () => {
      const total =
        plan[0]?.frequency === 'anual'
          ? Number(plan[0]?.costTotalPlan) - amount
          : Number(plan[0]?.costTotalPlan);

      const newPayment = paymentOptions.map((item) => {
        const totalTest = Number(total) / item.amountInstallment;

        return {
          amountInstallment: item.amountInstallment,
          valueInstallment: totalTest,
        };
      });
      handleNewPaymentOptions(newPayment);
      handleGetAmountInstallment(newPayment[0]);
    };
    newCalculatePayment();
  }, [
    amount,
    paymentOptions,
    plan,
    handleNewPaymentOptions,
    handleGetAmountInstallment,
  ]);

  const accessToken = localStorage.getItem('accessToken');
  const idToken = localStorage.getItem('idToken');

  const { id } = useParams<{ id: string }>();

  const setCountUserNewPlan = (signature: PlanDetailsProps): number => {
    const userCount = signature.maxUserCount;
    const userExtra = signature.totalUserExtra;
    if (userCount == null && userExtra == null) return 2;
    const totUser = userCount + userExtra;
    return totUser >= 2 ? totUser : 2;
  };

  useEffect(() => {
    const fetchDataPlan = async () => {
      try {
        const { data } = await api.get(`client/signatures/${id}`, {
          headers: {
            'content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            idToken,
          },
        });
        getTotalUserCount(setCountUserNewPlan(data.data));
        handleFrequencyAutomatic(
          data.data.frequency ? data.data.frequency : 'mensal',
        );
        data.data && data.data.frequency === 'mensal'
          ? handleSetIsFrequencyToggled(false)
          : handleSetIsFrequencyToggled(true);
        setPlanDetails(data.data);
        setPlanDetailsValues(data.data);
        setSignaturePlanData(data.data.plan);
        handleSetTypeProduct('imob');
        //setSignature(data.data);
        setIsLoading(true);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDataPlan();
  }, [
    accessToken,
    idToken,
    id,
    getTotalUserCount,
    handleFrequencyAutomatic,
    handleSetIsFrequencyToggled,
    handleSetTypeProduct,
  ]);

  useEffect(() => {
    const fetchDataPlan = async () => {
      try {
        const { data } = await api.get('client/signatures/site', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            idToken,
          },
        });
        Array.isArray(data.data) ? setPremiumPlan(data.data) : [];
      } catch (error) {
        console.log(error);
      }
    };
    fetchDataPlan();
  }, [accessToken, idToken, planDetails]);

  useEffect(() => {
    const fetchMigrationBalance = async () => {
      try {
        const { data } = await api.get(
          `client/signatures/migrationbalance/${id}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
              idToken,
            },
          },
        );

        handleSetBalance(data.data);

        handleSetAmount(data.data.amount);
      } catch (error) {
        console.log('Migration', error);
      }
    };
    if (
      planDetailsValues?.frequency === 'anual' &&
      (planDetailsValues.typeProduct === 'imob' ||
        planDetailsValues.typeProduct === 'locacao')
    ) {
      fetchMigrationBalance();
    }
  }, [
    accessToken,
    idToken,
    id,
    planDetailsValues,
    handleSetAmount,
    handleSetBalance,
  ]);

  useEffect(() => {
    const valueSignature = parseFloat(planDetailsValues?.finalValue as string);
    const totalCostUserExtra = planDetails?.totalCostUserExtra;
    const valueSitePremium = premiumPlan.reduce(
      (ac, item) => (ac + item.finalValue ? parseFloat(item.finalValue) : 0),
      0,
    );
    const total =
      valueSignature + valueSitePremium + Number(totalCostUserExtra);
    setTotalValue(total);
  }, [planDetailsValues, premiumPlan, planDetails]);

  return (
    <>
      <MainHeader />
      {!isLoading && <Loading />}

      <BaseMain>
        <S.HeaderWrapper>
          <Link to={`/PlanDetails/${id}`}>
            <BackButton>
              <ArrowLeft />
            </BackButton>
          </Link>
          <BaseContainer>
            <Heading title="Trocar de plano" weight="600" size="large" />
            <S.ButtonsWrapper>
              <Button
                as="a"
                href="https://suporte.kenlo.com.br/pt-BR/collections/3795647-kenlo-area-do-cliente"
                target="blank"
                icon={<QuestionCircle />}
              >
                Ajuda
              </Button>
            </S.ButtonsWrapper>
          </BaseContainer>
        </S.HeaderWrapper>
        <S.BodyContentWrapper>
          <S.WrapperAside>
            <S.Aside>
              <h1>Plano atual</h1>
              <S.TitleWrapper>
                <Heading
                  title={signaturePlanData?.name}
                  weight="600"
                  size="large"
                  color="#4C4C4C"
                />
              </S.TitleWrapper>
              {planDetailsValues && planDetailsValues.typeProduct === 'imob' && (
                <S.Ul key={planDetails?.id_client}>
                  <li>
                    Usuários do plano
                    <p>{planDetails?.maxUserCount}</p>
                  </li>
                  <li>
                    Usuários extras
                    <p>{planDetails?.totalUserExtra}</p>
                  </li>
                  <li>
                    Frequência
                    <p>
                      {planDetails?.frequency
                        ? capitalizeFirstLetter(planDetails?.frequency)
                        : null}
                    </p>
                  </li>
                  {planDetailsValues.frequency === 'anual' && (
                    <>
                      <li>
                        Fim da vigência
                        <p>{`${moment(planDetails?.terminationDate).format(
                          'L',
                        )}`}</p>
                      </li>
                    </>
                  )}
                  {planDetailsValues.frequency === 'mensal' && (
                    <li>
                      Data de renovação
                      <p>{`${moment(planDetails?.terminationDate).format(
                        'L',
                      )}`}</p>
                    </li>
                  )}
                  {planDetailsValues?.finalValue && (
                    <li>
                      Valor do Plano
                      <p>{formattedValue(planDetailsValues?.finalValue)}</p>
                    </li>
                  )}
                  {Number(planDetails?.totalCostUserExtra) > 0 && (
                    <li>
                      Valor de Usuários Extras
                      <p>{`${
                        Number(planDetails?.totalCostUserExtra) > 0
                          ? formattedValue(
                              Number(planDetails?.totalCostUserExtra),
                            )
                          : null
                      }`}</p>
                    </li>
                  )}
                </S.Ul>
              )}
              {planDetailsValues &&
                planDetailsValues.typeProduct === 'locacao' && (
                  <S.Ul key={planDetails?.id_client}>
                    <li>
                      Contratos
                      <p>{planDetails?.totalLeasingContractCount}</p>
                    </li>
                    <li>
                      Frequência
                      <p>{planDetails?.frequency}</p>
                    </li>
                    {planDetailsValues.frequency === 'anual' && (
                      <>
                        <li>
                          Início da vigência
                          <p>{`${moment(planDetails?.activationDate).format(
                            'L',
                          )}`}</p>
                        </li>
                        <li>
                          Fim da vigência
                          <p>{`${moment(planDetails?.terminationDate).format(
                            'L',
                          )}`}</p>
                        </li>
                      </>
                    )}
                    {planDetailsValues.frequency === 'mensal' && (
                      <li>
                        Data de renovação
                        <p>{`${moment(planDetails?.terminationDate).format(
                          'L',
                        )}`}</p>
                      </li>
                    )}
                    {/* <DataDetails
                      color="neutral70"
                      uppercase={false}
                      row={true}
                      title="Valor"
                      subtitle={formattedValue(planDetailsValues?.finalValue)}
                    /> */}
                  </S.Ul>
                )}
              {planDetailsValues && planDetailsValues.typeProduct === 'outro' && (
                <S.Ul key={planDetails?.id_client}>
                  <li>
                    Frequência
                    <p>{planDetails?.frequency}</p>
                  </li>
                  {planDetailsValues.frequency === 'anual' && (
                    <>
                      <li>
                        Fim da vigência
                        <p>{`${moment(planDetails?.terminationDate).format(
                          'L',
                        )}`}</p>
                      </li>
                    </>
                  )}
                  {planDetailsValues.frequency === 'mensal' && (
                    <li>
                      Data de renovação
                      <p>{`${moment(planDetails?.terminationDate).format(
                        'L',
                      )}`}</p>
                    </li>
                  )}
                  <DataDetails
                    color="neutral70"
                    uppercase={false}
                    row={true}
                    title="Valor"
                    subtitle={formattedValue(planDetailsValues?.finalValue)}
                  />
                </S.Ul>
              )}
              {/* {signatures.map((signatures) => {
                return (
                  <S.WrapperDataContent key={signatures.id_client}>
                    <DataDetails
                      color="neutral70"
                      uppercase={false}
                      row={true}
                      title="Usuários"
                      subtitle={signatures.totalUserCount}
                    />
                    <DataDetails
                      color="neutral70"
                      uppercase={false}
                      row={true}
                      title="Frequência"
                      subtitle={`${capitalizeFirstLetter(signatures.frequency)}
                      `}
                    />
                    {!!signatures.terminationDate && (
                      <DataDetails
                        color="neutral70"
                        uppercase={false}
                        row={true}
                        title="Fim da vigência"
                        subtitle={`${moment(signatures.terminationDate).format(
                          'L',
                        )}`}
                      />
                    )}
                    <DataDetails
                      color="neutral70"
                      uppercase={false}
                      row={true}
                      title="Valor"
                      subtitle={formattedValue(signaturesValues[0]?.finalValue)}
                    />
                  </S.WrapperDataContent>
                );
              })} */}
              {!!premiumPlan &&
                premiumPlan.map((item) => {
                  return (
                    <S.WrapperDataContent key={item.frequency}>
                      <div className="line">
                        <Line border="2px" />
                      </div>
                      <Heading
                        size="large"
                        color="#4C4C4C"
                        title={item.plan.name}
                      />
                      <DataDetails
                        color="neutral70"
                        uppercase={false}
                        row={true}
                        title="Frequência"
                        subtitle={`${capitalizeFirstLetter(item.frequency)}`}
                      />
                      {!!item.terminationDate && (
                        <DataDetails
                          color="neutral70"
                          uppercase={false}
                          row={true}
                          title="Fim da vigência"
                          subtitle={`
                            ${moment(item.terminationDate).format('L')}
                          `}
                        />
                      )}
                      <DataDetails
                        color="neutral70"
                        uppercase={false}
                        row={true}
                        title="Valor"
                        subtitle={`${formattedValue(item.finalValue)}`}
                      />
                    </S.WrapperDataContent>
                  );
                })}
              {/* {Number(planDetails?.totalCostUserExtra) > 0 && (
                <>
                  <br></br>
                  <div className="line">
                    <Line border="2px" />
                  </div>
                  <br></br>
                  <DataDetails
                    color="neutral70"
                    uppercase={false}
                    row={true}
                    title="Valor de Usuários Extras"
                    subtitle={`${
                      Number(planDetails?.totalCostUserExtra) > 0
                        ? formattedValue(
                            Number(planDetails?.totalCostUserExtra),
                          )
                        : null
                    }`}
                  />
                </>
              )} */}
              <br></br>
              <div className="line">
                <Line border="2px" />
              </div>
              <br></br>
              <DataDetails
                color="neutral70"
                uppercase={false}
                row={true}
                title="Valor total"
                subtitle={`${formattedValue(totalValue)}`}
              />
            </S.Aside>
          </S.WrapperAside>
          <S.WrapperMain>
            <S.ListValueWrapper>
              <S.WrapperTitleNewPlan>
                <Heading title="Novo plano" weight="700" />
                {planDetailsValues &&
                  planDetailsValues.typeProduct === 'locacao' && (
                    <Button
                      as="a"
                      href="https://www.kenlo.com.br/planos-locacao"
                      target="blank"
                    >
                      Ver outros planos
                    </Button>
                  )}
                {planDetailsValues && planDetailsValues.typeProduct === 'imob' && (
                  <Button
                    as="a"
                    href="https://www.kenlo.com.br/planos-imob"
                    target="blank"
                  >
                    Ver outros planos
                  </Button>
                )}
                {planDetailsValues &&
                  planDetailsValues.typeProduct === 'outro' && (
                    <Button
                      as="a"
                      href="https://www.kenlo.com.br/planos-imob"
                      target="blank"
                    >
                      Ver outros planos
                    </Button>
                  )}
              </S.WrapperTitleNewPlan>
              <S.WrapperParagraph>
                É possível ajustar seu plano de acordo com o tamanho da sua
                imobiliária. Selecione abaixo o número de usuários que deseja
                ter no plano e a frequência que gostaria de realizar os
                pagamentos.
              </S.WrapperParagraph>
              <S.WrapperQuantityAndFrequency>
                <S.WrapperQuantity>
                  <CounterButton
                    width="13.8rem"
                    title="Quantidade de usuários"
                    count={count}
                    inCount={() => handleCountIncrease()}
                    decCount={() => handleCountDecrease()}
                  />
                </S.WrapperQuantity>
                <S.WrapperQuantity>
                  <ToggleButton
                    frequency={() => handleFrequency()}
                    title="Frequência"
                    isToggled={isFrequencyToggled}
                  />
                </S.WrapperQuantity>
              </S.WrapperQuantityAndFrequency>
              <S.WrapperPlan>
                {count >= 2 && (
                  <Heading title={plan[0]?.name} weight="600" size="large" />
                )}
                {plan.map((item) => {
                  return (
                    <>
                      <S.WrapperUser key={item.name}>
                        {count >= 2 && item.qtUserAdditional > 0 ? (
                          <>
                            <div>
                              <TwoUsers />
                              <span>{item.qtUserPlan} usuários do plano</span>
                            </div>
                            <div>
                              <PlusUser />
                              <span>
                                {item.qtUserAdditional} usuários adicionais
                              </span>
                              <S.WrapperToolTip>
                                <ToolTip
                                  content={`É cobrado R$ ${item.costUserAdditionalSingle}/mês para cada usuário adicional.`}
                                  icon={<ToolTipIcon />}
                                />
                              </S.WrapperToolTip>
                            </div>
                          </>
                        ) : (
                          <div>
                            <TwoUsers />
                            <span>{item.qtUserPlan} usuários do plano</span>
                          </div>
                        )}
                      </S.WrapperUser>
                      <S.WrappeMonthValue>
                        <div>
                          <span>
                            {`${formattedValue(item?.costMonthlyPlan)}`}
                          </span>
                          {frequency === 'mensal' ? (
                            <span> /mês</span>
                          ) : (
                            <span> /mês</span>
                          )}
                        </div>
                        {frequency === 'mensal' && (
                          <h1>Renovado automaticamente.</h1>
                        )}
                        {frequency === 'anual' && parseInt(item?.savecost) > 0 && (
                          <p>
                            {`Economize ${formattedValue(item?.savecost)}
                                anualmente.`}
                          </p>
                        )}
                      </S.WrappeMonthValue>
                    </>
                  );
                })}
              </S.WrapperPlan>
            </S.ListValueWrapper>
            <S.BenefitWrapper>
              <S.BenefitTitle>
                <Heading
                  title="Principais benefícios"
                  size="large"
                  weight="600"
                />
                {planDetailsValues &&
                  planDetailsValues.typeProduct === 'locacao' && (
                    <Button
                      as="a"
                      href="https://www.kenlo.com.br/administracao-de-imoveis"
                      target="blank"
                    >
                      Ver todos
                    </Button>
                  )}
                {planDetailsValues && planDetailsValues.typeProduct === 'imob' && (
                  <Button
                    as="a"
                    href="https://www.kenlo.com.br/crm-para-imobiliarias"
                    target="blank"
                  >
                    Ver todos
                  </Button>
                )}
              </S.BenefitTitle>
              <S.BenefitDescriptionsWrapper>
                <S.BenefitDescriptions>
                  <People />
                  <span>Gestão de leads completa</span>
                </S.BenefitDescriptions>
                <S.BenefitDescriptions>
                  <Home />
                  <span>Cadastro ilimitado de imóveis</span>
                </S.BenefitDescriptions>
                <S.BenefitDescriptions>
                  <Screen />
                  <span>Site integrado e otimizado</span>
                </S.BenefitDescriptions>
              </S.BenefitDescriptionsWrapper>
            </S.BenefitWrapper>

            {balance?.signatureBalance &&
            Number(balance?.amount) > 0 &&
            balance.sitePremiumBalance ? (
              <Banner
                icon={<CircleWavyCheck />}
                text={`Saldo de antecipação da renovação do plano ${
                  signaturePlanData?.name
                } e Sites Premium no valor de ${formattedValue(
                  balance.amount,
                )}.`}
              />
            ) : (
              balance?.signatureBalance &&
              Number(balance?.amount) > 0 && (
                <Banner
                  icon={<CircleWavyCheck />}
                  text={`Saldo de antecipação da renovação do plano ${
                    signaturePlanData?.name
                  } no valor de ${formattedValue(balance?.amount)}.`}
                />
              )
            )}

            <S.BenefitWrapper>
              <S.WrapperFooter>
                <div>
                  <S.WrapperPayment>
                    <span>Pagamento</span>
                  </S.WrapperPayment>

                  <S.WrapperPaymentTotal>
                    {/* {!!getAmountInstallment && (
                      <>
                        {frequency === 'anual' ? (
                          <>
                            <h2>{`${formattedValue(
                              newTotalPlan - amount,
                            )}`}</h2>
                            {getAmountInstallment.amountInstallment > 1 ? (
                              <span>{`Em ${getAmountInstallment.amountInstallment}x no boleto`}</span>
                            ) : (
                              <span>À vista no boleto</span>
                            )}
                          </>
                        ) : (
                          <>
                            <h2>{`${formattedValue(newTotalPlan)}`}</h2>
                            <span>No boleto mensal</span>
                          </>
                        )}
                      </>
                    )} */}

                    {!!getAmountInstallment && (
                      <S.WrapperPaymentOptions>
                        <h2>{`${formattedValue(
                          newPaymentOptions[0]?.valueInstallment,
                        )}`}</h2>
                        {/* <span>{`Em ${getAmountInstallment.amountInstallment}x no boleto`}</span> */}
                        {getAmountInstallment.amountInstallment > 1 ? (
                          <span>{`Em ${getAmountInstallment.amountInstallment}x no boleto`}</span>
                        ) : (
                          <span>À vista no boleto</span>
                        )}
                      </S.WrapperPaymentOptions>
                    )}
                    {!getAmountInstallment && (
                      <>
                        {frequency === 'anual' ? (
                          <>
                            <h2>{`${formattedValue(
                              newTotalPlan - amount,
                            )}`}</h2>
                            <span>À vista no boleto</span>
                          </>
                        ) : (
                          <>
                            <h2>{`${formattedValue(newTotalPlan)}`}</h2>
                            <span>No boleto mensal</span>
                          </>
                        )}
                      </>
                    )}
                    <Dialog.Root open={open} onOpenChange={handleCloseMoldal}>
                      <Dialog.Trigger asChild>
                        {frequency === 'anual' && (
                          <Button className="buttonOpcao">
                            Opções de pagamento
                          </Button>
                        )}
                      </Dialog.Trigger>

                      <PaymentOptionsDetais />
                    </Dialog.Root>
                  </S.WrapperPaymentTotal>
                </div>
                <Dialog.Root>
                  <Dialog.Trigger asChild>
                    <Button
                      onClick={() => {
                        setIsCloseModal(true);
                      }}
                      minimal={false}
                      size="medium"
                      height="5.6rem"
                    >
                      Confirmar valores
                    </Button>
                  </Dialog.Trigger>
                  {isCloseModal && (
                    <PlanSummaryModal
                      onClose={() => setIsCloseModal(false)}
                      id={Number(id)}
                      planId={plan[0]?.id_plan}
                      name={plan[0].name}
                      frequency={plan[0].frequency}
                      amountProductPlan={Number(plan[0].qtUserPlan)}
                      amountProductExtra={Number(plan[0]?.qtUserAdditional)}
                      terminationDate={`${moment(
                        planDetails?.terminationDate,
                      ).format('L')}`}
                      costTotalPlan={plan[0]?.costTotalPlan}
                      migrationBalance={amount}
                    />
                  )}
                </Dialog.Root>
              </S.WrapperFooter>
            </S.BenefitWrapper>
          </S.WrapperMain>
        </S.BodyContentWrapper>
      </BaseMain>
    </>
  );
};
