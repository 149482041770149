import * as S from './styles';

type AmountQualificationProps = {
  icon?: React.ReactNode;
  text: number;
};

export const AmountQualification = ({
  icon,
  text,
}: AmountQualificationProps) => {
  return (
    <S.Wrapper>
      {icon}
      <p>{text}</p>
    </S.Wrapper>
  );
};
