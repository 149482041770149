import * as S from './styles';

import logoSign from 'assets/image/logo-signin.svg';
import Button from 'components/Button';
import { Link, useHistory } from 'react-router-dom';
import { Online } from 'components/Icons';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { signInFormSchema } from './schema';
import { Eye, EyeOff } from '@styled-icons/feather';
import { theme } from 'styles/theme';
import { api } from 'services/api';
import { useContext, useEffect, useState } from 'react';
import SpinnerLoading from 'components/SpinnerLoading';

import { BoxMessage } from 'components/BoxMessage';
import { FeatureContext } from 'contexts/FeaturesContext';
import { ClientInfoContext } from 'contexts/ClientInfoContext';
import { MovtoDailyQualifierContext } from 'contexts/MovtoDailyQualifier';
//import { ListRealEstate } from './components/ListRealEstate';

export const SignIn = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [statusError, setStatusError] = useState(undefined);
  const [statusError1, setStatusError2] = useState(200);
  const [disableLoginButton, setDisableLoginButton] = useState(true);
  const { fetchDataAgency, setIdImob } = useContext(FeatureContext);
  const { fetchDataClientInfo, setClearDataClientInfo } =
    useContext(ClientInfoContext);
  const { setClearDataMovtoDaily } = useContext(MovtoDailyQualifierContext);

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signInFormSchema),
  });

  useEffect(() => {
    if (getValues('email') && getValues('password')) {
      setDisableLoginButton(false);
    } else {
      setDisableLoginButton(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch()]);

  const history = useHistory();

  localStorage.clear();

  const fetchDataSignIn = async (email: string, password: string) => {
    setIsLoading(true);
    setClearDataClientInfo();
    setClearDataMovtoDaily();
    try {
      const { data } = await api.post(
        `/login`,
        {
          email: email,
          password: password,
        },
        {
          headers: {
            'content-Type': 'application/json',
          },
        },
      );
      //notifySuccess();
      return data;
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setStatusError2(error.response.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const navigate = () => {
    window.location.href = 'https://kenlo.movidesk.com/Account/Login';
  };
  async function login(data: any, event: any): Promise<void> {
    event.preventDefault();

    try {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('idToken');
      localStorage.removeItem('refreshToken');
      data = await fetchDataSignIn(data.email, data.password);
      setIdImob(data.data.user.company_id);
      fetchDataAgency(data.data.token.accessToken, data.data.token.idToken);
      localStorage.setItem('accessToken', data.data.token.accessToken);
      localStorage.setItem('idToken', data.data.token.idToken);
      localStorage.setItem('refreshToken', data.data.token.refreshToken);
      localStorage.setItem('name', data.data.user.name);
      localStorage.setItem('profile', data.data.user.profile);
      localStorage.setItem('picture', data.data.user.picture);
      localStorage.setItem('useImobId', data.data.user.user_imob_id);
      localStorage.setItem('company_id', data.data.user.company_id);
      fetchDataAgency(data.data.token.accessToken, data.data.token.idToken);
      fetchDataClientInfo(data.data.user.company_id);
      history.push('/ClientArea');
      window.location.reload();
    } catch (error) {
      setStatusError(data);
      setStatusError2(data);
      console.log(error);
    }

    /*
      try {
        let idToken = null;

        if (data.email === 'client-area-test.iqi@mailinator.com') {
          idToken = parseJwt(token69618);
        } else if (data.email === 'client-area-test.4gu@mailinator.com') {
          idToken = parseJwt(token68841);
        } else if (data.email === 'client-area-test.piz@mailinator.com') {
          idToken = parseJwt(token70375);
        } else {
          alert('Estamos em desenvolvimento');
          return;
        }

        history.push('/ClientArea');
        localStorage.setItem('email', data.email);
        localStorage.setItem('password', data.password);
        localStorage.setItem('companyId', idToken['custom:agency_id']);
        localStorage.setItem('profile', idToken.profile);
        localStorage.setItem('name', idToken.name);
        localStorage.setItem('familyName', idToken.family_name);
      } catch (error) {
        console.log(error);
      }
      */
  }

  // function parseJwt(token: any) {
  //   const base64Url = token.split('.')[1];
  //   const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  //   const jsonPayload = decodeURIComponent(
  //     window
  //       .atob(base64)
  //       .split('')
  //       .map(function (c) {
  //         return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  //       })
  //       .join(''),
  //   );

  //   return JSON.parse(jsonPayload);
  // }

  // const Mock = [
  //   {
  //     img: 'https://cdn1.valuegaia.com.br/gaiasite/watermark/471/614f39191beb63c50fe8c58f07aaf62d-Novo%20Logo%20D.%20Lange%20fundo%20transparente.png',
  //     name: 'D.Lange Imóveis',
  //     estado: 'Campinas-SP',
  //   },
  //   {
  //     img: 'https://cdn1.valuegaia.com.br/gaiasite/44498/CONFIG.FAVICON/a009aa04cc5eabaf0124ec9b601e1b97-Imovit_ICON.png',
  //     name: 'Imovit Imobiliária',
  //     estado: 'Campinas-SP',
  //   },
  //   {
  //     img: 'https://cdn1.valuegaia.com.br/gaiasite/1395/CONFIG.FAVICON/c1eccb459c41ac31b49e30bcae5ab4e5-ICON.jpg',
  //     name: 'Rogerio Moura Imobiliária',
  //     estado: 'Campinas-SP',
  //   },
  // ];

  return (
    <S.Container>
      <S.Background />
      <S.Content>
        <S.LogoContent>
          <h1>Boas-vindas!</h1>
          <img src={logoSign} alt="Logo Kenlo" />
        </S.LogoContent>
        {/* <S.ListRealEstateContent>
          <span>Selecione a imobiliária que deseja acessar.</span>
          {Mock.map((item) => {
            return (
              <ListRealEstate
                key={item.name}
                img={item.img}
                name={item.name}
                estado={item.estado}
              />
            );
          })}
        </S.ListRealEstateContent> */}
        <form onSubmit={handleSubmit(login)}>
          <input placeholder="E-mail" {...register('email')} />
          <p>{errors.email?.message}</p>
          <S.WrapperInputShowPassword>
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder="Senha"
              {...register('password')}
            />
            <S.WrapperShowPassword>
              <S.ShowPassword
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {!showPassword ? (
                  <EyeOff
                    size={19}
                    strokeWidth={2}
                    color={theme.colors.neutral.neutral70}
                  />
                ) : (
                  <Eye
                    size={19}
                    strokeWidth={2}
                    color={theme.colors.neutral.neutral70}
                  />
                )}
              </S.ShowPassword>
            </S.WrapperShowPassword>
          </S.WrapperInputShowPassword>
          {statusError !== 200 && <p>{errors.password?.message}</p>}
          <Button
            size="100%"
            minimal={false}
            height="5.6rem"
            disabled={disableLoginButton}
          >
            {isLoading ? <SpinnerLoading /> : 'Entrar'}
          </Button>
          {statusError1 !== 200 && (
            <S.WrapperBanners>
              <br />
              <BoxMessage text="Usuário ou senha inválidos" />
            </S.WrapperBanners>
          )}
          <Link to="/Forgot-password">
            <S.WrapperLink>Esqueci a senha</S.WrapperLink>
          </Link>
        </form>
        <S.Footer>
          <div className="status">
            <p>
              <span>Área do cliente</span>
              está on-line
              <Online />
            </p>
            <p>
              Não está conseguindo entrar?
              <span className="link" onClick={() => navigate()}>
                Converse com o nosso time.
              </span>
            </p>
          </div>
        </S.Footer>
      </S.Content>
    </S.Container>
  );
};
