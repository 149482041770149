import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationBR from './locales/pt-BR';
i18n.use(initReactI18next).init({
  resources: {
    'pt-BR': {
      translation: translationBR,
    },
  },
  lng: 'pt-BR',
  fallbackLng: ['pt-BR'],
  interpolation: {
    escapeValue: false,
  },
});
