const Box = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="#212121"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.38459 1.06801C10.4244 0.668085 11.5756 0.668085 12.6154 1.06801L20.0577 3.93043C20.9266 4.26464 21.5 5.09947 21.5 6.03046V15.9701C21.5 16.901 20.9266 17.7359 20.0577 18.0701L12.6154 20.9325C11.5756 21.3324 10.4244 21.3324 9.38459 20.9325L1.9423 18.0701C1.07337 17.7359 0.5 16.901 0.5 15.9701V6.03046C0.5 5.09947 1.07336 4.26464 1.9423 3.93043L9.38459 1.06801ZM12.0769 2.46803C11.3837 2.20141 10.6163 2.20141 9.92306 2.46803L7.84636 3.26676L16.2328 6.4416L19.1536 5.18983L12.0769 2.46803ZM20 6.45905L11.75 9.99476V19.6372C11.8604 19.6087 11.9696 19.5738 12.0769 19.5325L19.5192 16.6701C19.8089 16.5587 20 16.2804 20 15.9701V6.45905ZM10.25 19.6372V9.99476L2 6.45905V15.9701C2 16.2804 2.19112 16.5587 2.48077 16.6701L9.92306 19.5325C10.0304 19.5738 10.1396 19.6087 10.25 19.6372ZM2.84638 5.18983L11 8.68424L14.2457 7.29323L5.74479 4.07506L2.84638 5.18983Z"
        fill="#212121"
      />
    </svg>
  );
};
export default Box;
