import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(60px, 1fr));
    row-gap: 1rem;
    background: ${theme.colors.neutral.neutral0};
    border-radius: ${theme.border.radius};
    padding: 1.6rem 2.4rem;
    width: 100%;
  `}
`;

export const Wrapper = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    gap: 2.4rem;
  `}
`;

export const WrapperImg = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5.6rem;
    height: 5.6rem;
    border-radius: 50%;
    background: ${theme.colors.primary50};

    svg {
      fill: white;
    }
  `}
`;

export const UlWrapper = styled.ul`
  ${({ theme }) => css`
    position: absolute;
    top: 7rem;
    right: -0.5rem;
    display: inline-block;
    opacity: 0;
    background: ${theme.colors.neutral.neutral70};
    border-radius: ${theme.border.radius};
    padding: 1.2rem 2.4rem;
    text-align: left;
    transition: all 0.5s ease-in-out;
    pointer-events: none;

    li {
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.neutral.neutral0};
      list-style: none;
      text-align: left;
      margin-bottom: 1rem;
    }
  `};
`;

export const HoverWrapper = styled.div`
  ${() => css`
    position: relative;
    z-index: 0;
    &:hover ${UlWrapper} {
      opacity: 1;
    }
  `}
`;

export const EmailWrapper = styled.div`
  ${() => css`
    display: flex;
    align-items: flex-end;
    gap: 0.8rem;
  `}
`;

export const Count = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.primary50};
    font-size: ${theme.font.sizes.medium};
  `}
`;
