import * as S from './styles';
import logoKenlo from 'assets/image/kenlo.svg';
import { ArrowDown, ArrowUp, GridDots } from 'components/Icons';
import { Dropdown } from 'components/Dropdown/';
import { DropdownLink } from 'components/DropdownLink/DropdownLink';
import { UserIcon } from 'components/UserIcon';
import { UserContent } from 'components/UserContent';

import userP from 'assets/image/userPic.png';
import { Logo } from '../Logo';
import { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

export const MainHeader = () => {
  const [dropDownIsOpen, setDropDownIsOpen] = useState(false);
  const history = useHistory();

  const nameUser = localStorage.getItem('name')
    ? localStorage.getItem('name')
    : 'User not found';

  const profileUser = localStorage.getItem('profile')
    ? localStorage.getItem('profile')
    : '';

  const idToken = localStorage.getItem('idToken');

  const ref = useRef<HTMLButtonElement | null>(null);

  const selectvalue = (e: any) => {
    if (ref.current != null) {
      ref.current.value = e.target.outerText;
      setDropDownIsOpen(false);
    }
  };

  function parseJwt(token: any) {
    const base64Url = token && token?.split('.')[1];
    const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  }

  const user = parseJwt(idToken);

  const picture: string | null = localStorage.getItem('picture');

  const pictureUser: string = picture && picture != 'null' ? picture : userP;

  const companyUser = localStorage.getItem('company_id')
    ? localStorage.getItem('company_id')
    : '';
  const navigate = () => {
    history.push('/ClientArea');
  };

  return (
    <S.HeaderWrapper>
      <S.Wrapper>
        <Logo srcImg={logoKenlo} text="logo" onClick={() => navigate()} />
        <div>
          <Dropdown
            element={<GridDots />}
            height="5.3rem"
            position="-6.5"
            width="24rem"
          >
            <DropdownLink
              link="http://signin.ingaia.com.br/?provider=imob&_ga=2.17629497.102847676.1658267011-768362178.1658267011"
              newTab={true}
              arrowRight={false}
            >
              Kenlo Imob
            </DropdownLink>
            <DropdownLink
              link="http://signin.ingaia.com.br/?provider=imob&_ga=2.17629497.102847676.1658267011-768362178.1658267011"
              newTab={true}
              arrowRight={false}
            >
              Kenlo Sites
            </DropdownLink>
            <DropdownLink
              link="https://signin.valuegaia.com.br/?provider=locacao"
              newTab={true}
              arrowRight={false}
            >
              Kenlo Locação
            </DropdownLink>
            <DropdownLink
              link="https://garante.kenlo.io/sign-in?callbackUrl=/"
              newTab={true}
              arrowRight={false}
            >
              Kenlo Garante
            </DropdownLink>
          </Dropdown>

          <Dropdown
            element={<UserIcon username={pictureUser} quantity={1} />}
            title={false}
            height="5.3rem"
          >
            <UserContent
              image={pictureUser}
              text="image User"
              name={nameUser}
              occupation={profileUser}
              identifier={companyUser}
            />
            <DropdownLink link="/ClientArea" arrowRight={false}>
              Área do cliente
            </DropdownLink>

            <S.ButtonAjuda
              onClick={() => setDropDownIsOpen((prev) => !prev)}
              ref={ref}
            >
              <span>Ajuda</span>
              {dropDownIsOpen === true ? <ArrowUp /> : <ArrowDown />}
            </S.ButtonAjuda>

            {dropDownIsOpen === true && (
              <S.Nav>
                <ul>
                  <li onClick={selectvalue}>
                    <a
                      target="blank"
                      href={`https://sso-movidesk.kenlo.io/?email=${user.email}`}
                    >
                      Chamados e suporte
                    </a>
                  </li>
                  <li onClick={selectvalue}>
                    <a href="https://kenlo.movidesk.com/kb" target="blank">
                      Perguntas frequentes
                    </a>
                  </li>
                  <li onClick={selectvalue}>
                    <a href="https://status.kenlo.com.br" target="blank">
                      Status do sistema
                    </a>
                  </li>
                  <li onClick={selectvalue}>
                    <a
                      href="https://kenlo.movidesk.com/kb/article/370463/treinamentos"
                      target="blank"
                    >
                      Treinamentos
                    </a>
                  </li>
                </ul>
              </S.Nav>
            )}
            <DropdownLink
              link="/"
              size="1.2rem"
              color="#595959"
              arrowRight={false}
            >
              Sair
            </DropdownLink>
          </Dropdown>
        </div>
      </S.Wrapper>
    </S.HeaderWrapper>
  );
};
