import * as S from './styles';

export type FilterButtonProps = {
  children: React.ReactNode;
  text: string;
  size?: string;
};

export const FilterButton = ({ text, children, size }: FilterButtonProps) => {
  return (
    <S.Wrapper size={size}>
      <span>{text}</span>
      {children}
    </S.Wrapper>
  );
};
