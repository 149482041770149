import * as S from './styles';
import * as Dialog from '@radix-ui/react-dialog';
import { ArrowLeft, Close } from 'components/Icons';
import Button from 'components/Button';
// import { useEffect, useRef } from 'react';
export interface IBaseModalProps {
  title?: string;
  cancelLabel?: string | React.ReactElement;
  confirmLabel?: string | React.ReactElement;
  children?: string | React.ReactNode;
  disableConfirm?: boolean;
  disableCancel?: boolean;
  isFooter?: boolean;
  isReturn?: boolean;
  background?: string;
  hoverBackground?: string;
  hover?: string;
  hoverText?: string;
  borderHover?: string;
  borderColor?: string;
  color?: string;
  onConfirm?: () => void;
  onClose?: (boolean: boolean) => void;
  onCloseX?: () => void;
  onCloseAll?: (boolean: boolean) => void;
  onBackButton?: boolean;
}
export const BaseModal = ({
  title,
  cancelLabel,
  confirmLabel = 'Salvar opção',
  children,
  disableConfirm,
  disableCancel,
  isFooter,
  background,
  hoverBackground,
  hover,
  hoverText,
  borderColor,
  borderHover,
  isReturn,
  color,
  onConfirm,
  onClose,
  onCloseAll,
  onCloseX,
  onBackButton,
}: IBaseModalProps) => {
  //const backButton = false;
  const closeAll = false;

  // const handleBackButton = (boolen: boolean) => {
  //   onClose?.(boolen);
  // };

  const handleOnCloseAll = (boolean: boolean) => {
    onCloseAll?.(boolean);
  };

  // const CloseButton = () => {
  //   return (
  //     <S.CloseButton>
  //       <Close />
  //     </S.CloseButton>
  //   );
  // };
  return (
    <>
      <Dialog.Portal>
        <S.Overlay className="overlay" />
        <S.Content className="dailyModal modal-1">
          <S.Header>
            <S.HeaderInitial>
              {onBackButton && (
                <S.CloseButton margin="3rem">
                  <ArrowLeft />
                </S.CloseButton>
                // <S.BackButton onClick={() => handleBackButton(backButton)}>
                //   <ArrowLeft />
                // </S.BackButton>
              )}
              <S.Title>{title}</S.Title>
            </S.HeaderInitial>
            <S.CloseButton
              onClick={() => {
                handleOnCloseAll(closeAll);
                onCloseX && onCloseX();
              }}
            >
              <Close />
            </S.CloseButton>
          </S.Header>
          <S.PlanContent>{children}</S.PlanContent>
          {isFooter && (
            <S.Footer>
              <div>
                <Dialog.Close asChild>
                  <Button
                    minimal={false}
                    border
                    height="5.6rem"
                    disabled={disableCancel}
                    hover={hover}
                    hoverText={hoverText}
                    borderColor={borderColor}
                    borderHover={borderHover}
                    color={color}
                    onClick={() => {
                      onClose && onClose(true);
                    }}
                  >
                    {cancelLabel}
                  </Button>
                </Dialog.Close>
                <Button
                  minimal={false}
                  height="5.6rem"
                  disabled={disableConfirm}
                  background={background}
                  hoverBackground={hoverBackground}
                  onClick={() => {
                    onConfirm && onConfirm();
                    !isReturn && onClose && onClose(true);
                  }}
                >
                  {confirmLabel}
                </Button>
              </div>
            </S.Footer>
          )}
        </S.Content>
      </Dialog.Portal>
    </>
  );
};
