import * as S from './styles';

import Button from 'components/Button';
import { DataDetails } from 'components/DataDetails';
import { InvoiceStatus } from 'components/InvoiceStatus';
import Ticket from '../Icons/Ticket';

import * as Dialog from '@radix-ui/react-dialog';
import { NoteCheck } from 'components/Icons';
import { useState } from 'react';
import { triggerBase64Download } from 'common-base64-downloader-react';
import { api } from 'services/api';
import { MessageModal } from './components/MessageModal';
import loadingSmall from 'assets/image/loading-small.gif';
import close from 'assets/image/close.svg';
import { InvoiceDetailsModal } from 'components/InvoiceDetailsModal';
import { MessageTypePayment } from 'components/InvoiceT/components/MessageTypePayment';
import BarCode from 'components/Icons/BarCode';
import CreditCard from 'components/Icons/CreditCard';

type InvoiceProps = {
  title: string;
  subtitle: string | undefined;
  value: string;
  paidValue: string;
  invoiceDateTitle: string;
  invoiceDate: string;
  paymentDate: string;
  invoiceStatusText: string;
  idBilling?: any;
  idInvoice?: any;
  invoicesBillings?: any;
  invoicesStatus?: any;
  idTypePay: number;
  creditCardNumber: string;
  description: string;
  hasCompoBilling?: boolean;
};

export const Invoice = ({
  title,
  value,
  paidValue,
  paymentDate,
  invoiceDateTitle,
  invoiceDate,
  invoiceStatusText,
  idBilling,
  invoicesBillings,
  invoicesStatus,
  idTypePay,
  creditCardNumber,
  description,
  hasCompoBilling,
}: InvoiceProps) => {
  const [linkBoleto, setLinkBoleto] = useState('');
  const [anchor, setAnchor] = useState('');
  const [statusError, setStatusError] = useState(200);
  const [blank, setBlank] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [modalError, setModalError] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const accessToken = localStorage.getItem('accessToken');
  const idToken = localStorage.getItem('idToken');

  const id_billing = invoicesBillings[0]?.id_billing;
  const id_invoice = invoicesBillings[0]?.id_invoice;

  const disableButton = hasCompoBilling === false;

  const fetchDataPlan = async () => {
    try {
      const { data } = await api.get(
        `client/billings/${id_billing}/invoice/${id_invoice}`,
        {
          headers: {
            'content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            idToken,
          },
        },
      );
      return data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDataBillings = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(
        `client/billings/${idBilling}/linkboleto`,
        {
          headers: {
            'content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            idToken,
          },
        },
      );
      setStatusError(response.status);
      return response.data.data.link2via;
    } catch (error: any) {
      if (error.response) {
        setStatusError(error.response.status);
      }
    } finally {
      setIsLoading(false);
    }
  };

  function redirectBoleto(link: string) {
    window.open(`${link}`);
    return null;
  }

  const onClickHandlerBoleto = async () => {
    const link = await fetchDataBillings();
    if (!link) {
      setStatusError(400);
      return;
    }
    setAnchor('a');
    setLinkBoleto(link);
    setBlank('target');
    redirectBoleto(link);
  };

  // function changeStatus() {
  //   setAnchor('button');
  // }

  // useEffect(() => {
  //   if (statusError !== 200) {
  //     console.log('oiiii');
  //     setAnchor('button');
  //   }
  // }, [setAnchor, statusError]);

  // useEffect(() => {
  //   if (
  //     invoicesStatus == 'aberta' ||
  //     invoicesStatus === 'atrasada' ||
  //     invoicesStatus === 'vencida'
  //   ) {
  //   }
  // }, [idBilling, invoicesStatus]);
  //console.log('Status Text', invoiceStatusText);

  const ButtonVerDetalhes = () => {
    return (
      <>
        {isLoading && (
          <MessageModal
            img={loadingSmall}
            text="O boleto será aberto em breve"
            isOpen
          />
        )}
        {statusError !== 200 && (
          <MessageModal
            img={close}
            text="Tente novamente mais tarde"
            description="Tivemos um problema ao buscar o boleto, aguarde e tente novamente."
            isOpen={modalError}
            setIsOpen={setModalError}
          />
        )}
        <Dialog.Root>
          <Dialog.Trigger asChild id={idBilling}>
            <Button
              disabled={disableButton}
              onClick={() => setShowDetails(true)}
            >
              Ver detalhes
            </Button>
          </Dialog.Trigger>
          {showDetails && (
            <InvoiceDetailsModal
              title={title}
              invoiceDate={invoiceDate}
              paymentDate={paymentDate}
              idBilling={idBilling}
              onClose={() => setShowDetails(false)}
            />
          )}
        </Dialog.Root>
      </>
    );
  };
  const InvoiceDownload = async () => {
    const invoice = await fetchDataPlan();
    if (invoice) {
      return await triggerBase64Download(
        'data:application/pdf;base64,' + invoice,
        'pdfDownload',
      );
    }
  };
  const InvoiceDownloadButton = () => {
    return (
      <Button onClick={() => InvoiceDownload()} icon={<NoteCheck />}>
        Nota fiscal
      </Button>
    );
  };

  const ListBillings = () => {
    if (idTypePay === 1) {
      //tratativas sobre Boleto
      if (invoiceStatusText === 'Paga') {
        if (invoicesBillings.length === 0) {
          return (
            <div className="wrapperbutton">
              <MessageTypePayment
                icon={<BarCode />}
                text="Boleto bancário"
                content="A nota fiscal dessa fatura ficará disponível em breve."
              />
              <ButtonVerDetalhes />
            </div>
          );
        } else {
          return (
            <div className="wrapperbutton">
              <InvoiceDownloadButton />
              <ButtonVerDetalhes />
            </div>
          );
        }
      } else if (
        invoiceStatusText === 'Aberta' ||
        invoiceStatusText === 'Vencida'
      ) {
        return (
          <div className="wrapperbutton">
            <Button
              icon={<Ticket />}
              href={linkBoleto}
              as={anchor}
              target={blank}
              onClick={onClickHandlerBoleto}
            >
              Ver boleto
            </Button>
            <ButtonVerDetalhes />
          </div>
        );
      }
      return (
        <div className="wrapperbutton">
          <MessageTypePayment
            icon={<BarCode />}
            text="Boleto bancário"
            content={`Cobrança indisponível para download`}
          />
          <ButtonVerDetalhes />
        </div>
      );
    } else if (idTypePay === 4) {
      //Tratativas sobre Cartão de Crédito
      if (invoiceStatusText === 'Paga') {
        if (invoicesBillings.length === 0) {
          let lastNumbersCreditCard = ' de Crédito';
          if (creditCardNumber.length > 0) {
            lastNumbersCreditCard = ` final ${creditCardNumber.substring(
              creditCardNumber.length - 4,
              creditCardNumber.length,
            )}`;
          }
          return (
            <div className="wrapperbutton">
              <MessageTypePayment
                icon={<CreditCard />}
                text={`Cartão ${lastNumbersCreditCard}`}
                content="A nota fiscal dessa fatura ficará disponível em breve."
              />
              <ButtonVerDetalhes />
            </div>
          );
        } else {
          return (
            <div className="wrapperbutton">
              <InvoiceDownloadButton />
              <ButtonVerDetalhes />
            </div>
          );
        }
      } else if (
        invoiceStatusText === 'Aberta' ||
        invoiceStatusText === 'Vencida'
      ) {
        return (
          <div className="wrapperbutton">
            <MessageTypePayment
              icon={<CreditCard />}
              text="Cartão de crédito"
              content="A cobrança será debitada no Cartão de Crédito cadastrado."
            />
            <ButtonVerDetalhes></ButtonVerDetalhes>
          </div>
        );
      }
      return (
        <div className="wrapperbutton">
          <MessageTypePayment
            icon={<CreditCard />}
            text="Cartão de crédito"
            content="Cobrança Indisponível."
          />
          <ButtonVerDetalhes></ButtonVerDetalhes>
        </div>
      );
    }
    return <span style={{ width: '100px' }}></span>;
  };

  return (
    <S.Wrapper>
      <DataDetails title={title} subtitle={description} uppercase={false} />
      {/* {invoiceDateTitle === 'Aberta' || invoiceDateTitle === 'Vencida' ? ( */}
      {invoiceDateTitle === 'Paga' ? (
        <>
          <DataDetails
            title="Valor Total"
            subtitle={paidValue}
            uppercase={false}
            color="neutral120"
          />
          <DataDetails
            title="Data de pagamento"
            subtitle={paymentDate}
            uppercase={false}
          />
        </>
      ) : (
        <>
          <DataDetails title="Valor total" subtitle={value} uppercase={false} />
          <DataDetails
            title="Data de vencimento"
            subtitle={invoiceDate}
            uppercase={false}
          />
        </>
      )}
      {invoiceStatusText === 'Paga' ? (
        <InvoiceStatus text={invoiceStatusText} status={invoicesStatus} />
      ) : (
        <InvoiceStatus text={invoiceStatusText} status={invoicesStatus} />
      )}
      <ListBillings></ListBillings>
    </S.Wrapper>
  );
};
