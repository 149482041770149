import styled, { css } from 'styled-components';

export const ButtonsWrapper = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    gap: 2.6rem;
  `}
`;

export const HeaderWrapper = styled.div`
  ${() => css`
    display: flex;
    gap: 0.8rem;
  `}
`;

export const FilterButtonWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 1.6rem;
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;

    > span {
      font-size: 1.6rem;
      margin-right: auto;
      color: ${theme.colors.neutral.neutral100};
    }
  `}
`;

export const ElementWrapper = styled.div`
  ${() => css`
    margin-bottom: 1.6rem;
    > div + div {
      margin-top: 1.6rem;
    }
  `}
`;

export const ButtonWrapper = styled.div`
  ${() => css`
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 16rem 2rem;
  `}
`;

export const ButtonWrapperDisabled = styled.div`
  ${() => css`
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 16rem 2rem;
  `}
`;
