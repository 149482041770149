const Ticket = () => {
  return (
    <svg
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="#F82E52"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon"
    >
      <path d="M17.5 18C17.5 18.275 17.276 18.5 17 18.5H12.7711C12.5995 19.0464 12.3366 19.5523 12.0004 20H17C18.104 20 19 19.104 19 18V7.828C19 7.298 18.789 6.789 18.414 6.414L12.585 0.586C12.57 0.571048 12.5531 0.55808 12.5363 0.545195C12.5238 0.535674 12.5115 0.526198 12.5 0.516C12.429 0.452 12.359 0.389 12.281 0.336C12.2557 0.318941 12.2281 0.305475 12.2005 0.292071C12.1845 0.284259 12.1685 0.276469 12.153 0.268C12.1363 0.258594 12.1197 0.248967 12.103 0.239326C12.0488 0.20797 11.9944 0.176475 11.937 0.152C11.74 0.0699999 11.528 0.0289999 11.313 0.0139999C11.2933 0.0127423 11.2738 0.0100789 11.2542 0.00740928C11.2271 0.00371057 11.1999 0 11.172 0H5C3.896 0 3 0.896 3 2V12H4.5V2C4.5 1.725 4.724 1.5 5 1.5H11V6C11 7.104 11.896 8 13 8H17.5V18ZM12.5 2.621L16.378 6.5H13C12.724 6.5 12.5 6.275 12.5 6V2.621ZM4.75 13.75C4.75 13.3358 4.41421 13 4 13L3.80036 13.0049C1.68397 13.1089 0 14.8578 0 17C0 19.2091 1.79086 21 4 21L4.10177 20.9932C4.46785 20.9435 4.75 20.6297 4.75 20.25C4.75 19.8358 4.41421 19.5 4 19.5L3.83562 19.4947C2.53154 19.41 1.5 18.3255 1.5 17C1.5 15.6193 2.61929 14.5 4 14.5L4.10177 14.4932C4.46785 14.4435 4.75 14.1297 4.75 13.75ZM12 17C12 14.7909 10.2091 13 8 13L7.89823 13.0068C7.53215 13.0565 7.25 13.3703 7.25 13.75C7.25 14.1642 7.58579 14.5 8 14.5L8.16438 14.5053C9.46846 14.59 10.5 15.6745 10.5 17C10.5 18.3807 9.38071 19.5 8 19.5L7.89823 19.5068C7.53215 19.5565 7.25 19.8703 7.25 20.25C7.25 20.6642 7.58579 21 8 21L8.19964 20.9951C10.316 20.8911 12 19.1422 12 17ZM7.75 16.25H4.25L4.14823 16.2568C3.78215 16.3065 3.5 16.6203 3.5 17C3.5 17.4142 3.83579 17.75 4.25 17.75H7.75L7.85177 17.7432C8.21785 17.6935 8.5 17.3797 8.5 17C8.5 16.5858 8.16421 16.25 7.75 16.25Z" />
    </svg>
  );
};
export default Ticket;
