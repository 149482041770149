const PersonClock = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3135 15.4999C11.4859 14.9665 11.7253 14.4631 12.0219 13.9999H4.25278C3.01076 13.9999 2.00391 15.0068 2.00391 16.2488V16.8265C2.00391 17.7193 2.32242 18.5828 2.90219 19.2617C4.46849 21.0959 6.8545 22.0011 10.0004 22.0011C10.9314 22.0011 11.7961 21.9218 12.5927 21.7626C12.2335 21.3494 11.9256 20.8903 11.6789 20.3954C11.1555 20.4658 10.5962 20.5011 10.0004 20.5011C7.26206 20.5011 5.29618 19.7553 4.04287 18.2876C3.69502 17.8802 3.50391 17.3622 3.50391 16.8265V16.2488C3.50391 15.8352 3.83919 15.4999 4.25278 15.4999H11.3135ZM10.0004 2.00464C12.7618 2.00464 15.0004 4.24321 15.0004 7.00464C15.0004 9.76606 12.7618 12.0046 10.0004 12.0046C7.23894 12.0046 5.00036 9.76606 5.00036 7.00464C5.00036 4.24321 7.23894 2.00464 10.0004 2.00464ZM10.0004 3.50464C8.06737 3.50464 6.50036 5.07164 6.50036 7.00464C6.50036 8.93764 8.06737 10.5046 10.0004 10.5046C11.9334 10.5046 13.5004 8.93764 13.5004 7.00464C13.5004 5.07164 11.9334 3.50464 10.0004 3.50464ZM17.5 12C20.5376 12 23 14.4624 23 17.5C23 20.5376 20.5376 23 17.5 23C14.4624 23 12 20.5376 12 17.5C12 14.4624 14.4624 12 17.5 12ZM19.5 17.5001H17.5L17.5 14.9999C17.5 14.7238 17.2761 14.4999 17 14.4999C16.7239 14.4999 16.5 14.7238 16.5 14.9999L16.5 17.9985L16.5 18.0001C16.5 18.2762 16.7239 18.5001 17 18.5001H19.5C19.7761 18.5001 20 18.2762 20 18.0001C20 17.7239 19.7761 17.5001 19.5 17.5001Z"
        fill="#404040"
      />
    </svg>
  );
};
export default PersonClock;
