import { BackButton } from 'components/BackButton';
import { BaseContainer } from 'components/BaseContainer';
import Button from 'components/Button';
import { Heading } from 'components/Heading';
import {
  ArrowLeft,
  Box,
  DoubleArrow,
  DoubleArrowNeutral,
  Home,
  NotePercent,
  People,
  QuestionCircle,
  Screen,
  SmartTickets,
  ToolTipIcon,
  Learning,
} from 'components/Icons';
import { Link, useParams } from 'react-router-dom';

import * as S from './styles';

import { useContext, useEffect, useState } from 'react';
import { api } from 'services/api';
import { Loading } from 'components/Loading';
import { ToolTip } from 'components/ToolTip';
import { Banner } from 'components/Banner';
import moment from 'moment';
import 'moment/locale/pt-br';
import {
  capitalizeFirstLetter,
  formattedValue,
  handleDiscount,
  handlePlanValueDivide,
} from 'helpers/util';
import { MainHeader } from 'components/MainHeader';
import { BaseMain } from 'components/BaseMain';
import { Aside } from 'components/Aside';
import { Line } from 'components/Line';
import { SignaturesStatus } from 'components/SignaturesStatus';
import { MainBenefits } from 'components/MainBenefits';
import { MainAdditionals } from 'components/MainAdditionalService';
import WarningTriangle from 'components/Icons/WarningTriangle';
import { CSSTransition } from 'react-transition-group';
import { FeatureContext } from 'contexts/FeaturesContext';

export type DataProps = {
  totalCost: string;
  discountValue: string;
  installmentAmount: number;
  totalUserCount: number;
  maxUserCount: number;
  frequency: string;
  startTrialDate: string;
  activationDate: string;
  terminationDate: string;
  id_client: string;
  name: string;
  typeProduct: string;
  totalLeasingContractCount: string;
  finalValue: string;
};

export type PlanDetailsProps = {
  activationDate: string;
  totalUserCount: number;
  maxUserCount: number;
  totalUserExtra: number;
  costUserExtra: number | undefined;
  totalCostUserExtra: number | undefined;
  frequency: string;
  startTrialDate: string;
  terminationDate: string;
  name: string;
  id_plan: number;
  id_client: string;
  plan: {
    name: string;
  };
  status: string;
  typeProduct: string;
  totalLeasingContractCount: string;
};

export type PlanDetailsValuesProps = {
  activationDate: string;
  terminationDate: string;
  additionalUserCost: string;
  frequency: string;
  totalCost: string;
  discountValue: string;
  finalValue: string;
  installmentAmount: number;
  installmentValue: string;
  status: string;
  typeProduct: string;
  type_payment: {
    description: string;
  };
};

export const PlanDetails = () => {
  const [planDetails, setPlanDetails] = useState<PlanDetailsProps | null>(null);
  const [planDetailsValues, setPlanDetailsValues] =
    useState<PlanDetailsValuesProps | null>(null);

  const [signatures, setSignatures] = useState<DataProps[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [coupon, setCoupon] = useState('');
  const [finalDateCoupon, setFinalDateCoupon] = useState('');
  //eslint-disable-next-line
  const [typeProduct, setTypeProduct] = useState('');
  const [isValidChangePlan, setIsValidChangePlan] = useState(true);
  const [showBanner, setShowBanner] = useState(false);
  const [useChangePlan, setUseChangePlan] = useState(false);

  const { dataAgency } = useContext(FeatureContext);

  useEffect(() => {
    if (!isValidChangePlan) {
      setShowBanner(true);
    }
  }, [isValidChangePlan]);

  const { id } = useParams<{ id: string }>();

  const accessToken = localStorage.getItem('accessToken');
  const idToken = localStorage.getItem('idToken');

  const totalDiscount = handleDiscount(
    Number(signatures[0]?.totalCost),
    Number(signatures[0]?.discountValue),
  );

  const planValueDivide = handlePlanValueDivide(
    totalDiscount,
    signatures[0]?.installmentAmount,
  );

  const minusSign = Number(planDetailsValues?.discountValue) > 0 ? '-' : '';

  useEffect(() => {
    const fetchDataChangePlan = async () => {
      try {
        const { data } = await api.get(
          `client/signatures/change/validate/${id}
        `,
          {
            headers: {
              'content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
              idToken,
            },
          },
        );
        setIsLoading(true);
        setIsValidChangePlan(data.isValid);
        setUseChangePlan(Boolean(process.env.REACT_APP_USE_CHANGE_PLAN));
      } catch (error) {
        console.log(error);
      }
    };
    fetchDataChangePlan();
  }, [accessToken, idToken, id]);

  useEffect(() => {
    const fetchDataSignatures = async () => {
      try {
        const { data } = await api.get(`client/signatures/${id}`, {
          headers: {
            'content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            idToken,
          },
        });
        setPlanDetails(data.data);
        setPlanDetailsValues(data.data);
        setTypeProduct(data.data.typeProduct);
        setSignatures([data.data]);
        setIsLoading(true);
        setFinalDateCoupon(data.data.finalDateCoupon);
        setCoupon(data.data.coupon);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDataSignatures();
  }, [accessToken, idToken, id]);

  return (
    <>
      <MainHeader />
      {!isLoading && <Loading />}
      <BaseMain>
        {isValidChangePlan === false && (
          <CSSTransition
            in={showBanner}
            timeout={1200}
            classNames="banner-transition"
            unmountOnExit
            onExited={() => setShowBanner(false)}
          >
            <S.WrapperBanners>
              <Banner
                icon={<WarningTriangle />}
                text={
                  'Sua solicitação para trocar de plano está sendo processada, mas não se preocupe, continue usando os produtos Kenlo normalmente.'
                }
              />
            </S.WrapperBanners>
          </CSSTransition>
        )}

        <S.HeaderWrapper>
          <Link to="/ClientArea">
            <BackButton>
              <ArrowLeft />
            </BackButton>
          </Link>
          <BaseContainer>
            <Heading title="Detalhes do plano" weight="600" size="large" />
            <S.ButtonsWrapper>
              {useChangePlan &&
                typeProduct === 'imob' &&
                isValidChangePlan &&
                dataAgency?.status === 'ativo' && (
                  <Link to={`/ChangeOfPlan/${id}`}>
                    <Button icon={<DoubleArrow />}>Trocar de plano</Button>
                  </Link>
                )}

              {useChangePlan &&
                typeProduct === 'locacao' &&
                isValidChangePlan &&
                dataAgency?.status === 'ativo' && (
                  <Link to={`/RenewPlan/${id}`}>
                    <Button icon={<DoubleArrow />}>Trocar de plano</Button>
                  </Link>
                )}
              <Button
                as="a"
                href="https://suporte.kenlo.com.br/pt-BR/collections/3795647-kenlo-area-do-cliente"
                target="blank"
                icon={<QuestionCircle />}
              >
                Ajuda
              </Button>
            </S.ButtonsWrapper>
          </BaseContainer>
        </S.HeaderWrapper>
        <S.BodyContentWrapper>
          {planDetails && (
            <S.WrapperAside>
              <Aside>
                <S.TitleWrapper>
                  {
                    <S.WrapperTitleContent key={planDetails.id_plan}>
                      <Heading
                        title={planDetails.plan?.name}
                        weight="600"
                        size="large"
                      />
                      <SignaturesStatus
                        text={`${capitalizeFirstLetter(planDetails.status)}`}
                        status={planDetails.status}
                      />
                    </S.WrapperTitleContent>
                  }
                </S.TitleWrapper>

                {planDetailsValues && planDetailsValues.typeProduct === 'imob' && (
                  <S.Ul key={planDetails.id_client}>
                    <li>
                      Usuários do plano
                      <p>{planDetails.maxUserCount}</p>
                    </li>
                    <li>
                      Usuários extras
                      <p>{planDetails.totalUserExtra}</p>
                    </li>
                    <li>
                      Frequência
                      <p>{capitalizeFirstLetter(planDetails.frequency)}</p>
                    </li>
                    {planDetailsValues.frequency === 'anual' && (
                      <>
                        <li>
                          Início da vigência
                          <p>{`${moment(planDetails.activationDate).format(
                            'L',
                          )}`}</p>
                        </li>
                        <li>
                          Fim da vigência
                          <p>{`${moment(planDetails.terminationDate).format(
                            'L',
                          )}`}</p>
                        </li>
                      </>
                    )}
                    {planDetailsValues?.frequency === 'mensal' && (
                      <li>
                        Data de renovação
                        <p>{`${moment(planDetails.terminationDate).format(
                          'L',
                        )}`}</p>
                      </li>
                    )}
                  </S.Ul>
                )}

                {planDetailsValues &&
                  planDetailsValues.typeProduct === 'locacao' && (
                    <S.Ul key={planDetails.id_client}>
                      <li>
                        Contratos
                        <p>{planDetails.totalLeasingContractCount}</p>
                      </li>
                      <li>
                        Frequência
                        <p>{planDetails.frequency}</p>
                      </li>
                      {planDetailsValues.frequency === 'anual' && (
                        <>
                          <li>
                            Início da vigência
                            <p>{`${moment(planDetails.activationDate).format(
                              'L',
                            )}`}</p>
                          </li>
                          <li>
                            Fim da vigência
                            <p>{`${moment(planDetails.terminationDate).format(
                              'L',
                            )}`}</p>
                          </li>
                        </>
                      )}
                      {planDetailsValues.frequency === 'mensal' && (
                        <li>
                          Data de renovação
                          <p>{`${moment(planDetails.terminationDate).format(
                            'L',
                          )}`}</p>
                        </li>
                      )}
                    </S.Ul>
                  )}

                {planDetailsValues &&
                  planDetailsValues.typeProduct === 'outro' && (
                    <S.Ul key={planDetails.id_client}>
                      <li>
                        Frequência
                        <p>{planDetails.frequency}</p>
                      </li>
                      {planDetailsValues.frequency === 'anual' && (
                        <>
                          <li>
                            Início da vigência
                            <p>{`${moment(planDetails.activationDate).format(
                              'L',
                            )}`}</p>
                          </li>
                          <li>
                            Fim da vigência
                            <p>{`${moment(planDetails.terminationDate).format(
                              'L',
                            )}`}</p>
                          </li>
                        </>
                      )}
                      {planDetailsValues.frequency === 'mensal' && (
                        <li>
                          Data de renovação
                          <p>{`${moment(planDetails.terminationDate).format(
                            'L',
                          )}`}</p>
                        </li>
                      )}
                    </S.Ul>
                  )}
              </Aside>
            </S.WrapperAside>
          )}

          <S.WrapperMain>
            <S.ListValueWrapper>
              <S.WrapperTitleValue>
                <Heading title="Valores" weight="600" size="large" />
              </S.WrapperTitleValue>
              {planDetailsValues && planDetailsValues.typeProduct === 'imob' && (
                <>
                  <S.Ul>
                    <li>
                      Valor do plano
                      <p>{`${formattedValue(planDetailsValues.totalCost)}`}</p>
                    </li>
                    {!!planDetailsValues.additionalUserCost && (
                      <li>
                        Valor dos usuários extras
                        <p>{`${formattedValue(
                          planDetailsValues.additionalUserCost,
                        )}`}</p>
                      </li>
                    )}
                    <S.WrapperToolTip>
                      <li>
                        Descontos
                        <p>{`${minusSign} ${formattedValue(
                          planDetailsValues.discountValue,
                        )}`}</p>
                      </li>
                      {planDetailsValues.discountValue !== null && (
                        <ToolTip
                          content={
                            finalDateCoupon
                              ? `Cupom de desconto ${coupon} até ${moment(
                                  finalDateCoupon,
                                ).format('L')}`
                              : `Cupom de desconto ${coupon}`
                          }
                          icon={<ToolTipIcon />}
                        />
                      )}
                    </S.WrapperToolTip>
                  </S.Ul>
                  <S.WrapperLine>
                    <Line width="100%" border="1px" />
                  </S.WrapperLine>
                  <S.WrapperFooterValue>
                    <p>{`${formattedValue(totalDiscount)}`}</p>
                    {planDetailsValues.type_payment.description === 'Boleto' ? (
                      <span>{`x${
                        planDetailsValues.installmentAmount
                      } de ${formattedValue(planValueDivide)} no boleto`}</span>
                    ) : (
                      <span>{`x${
                        planDetailsValues.installmentAmount
                      } de ${formattedValue(
                        planValueDivide,
                      )} no cartão de crédito final ????`}</span>
                    )}
                  </S.WrapperFooterValue>
                </>
              )}

              {planDetailsValues &&
                planDetailsValues.typeProduct === 'locacao' && (
                  <>
                    <S.Ul>
                      <li>
                        Valor do plano
                        <p>{`${formattedValue(
                          planDetailsValues.totalCost,
                        )}`}</p>
                      </li>
                      <S.WrapperToolTip>
                        <li>
                          Descontos
                          <p>{`${minusSign} ${formattedValue(
                            planDetailsValues.discountValue,
                          )}`}</p>
                        </li>
                        {planDetailsValues.discountValue !== null && (
                          <ToolTip
                            content={
                              finalDateCoupon
                                ? `Cupom de desconto ${coupon} até ${finalDateCoupon}`
                                : `Cupom de desconto ${coupon}`
                            }
                            icon={<ToolTipIcon />}
                          />
                        )}
                      </S.WrapperToolTip>
                    </S.Ul>
                    <S.WrapperLine>
                      <Line width="100%" border="1px" />
                    </S.WrapperLine>
                    <S.WrapperFooterValue>
                      <p>{`${formattedValue(totalDiscount)}`}</p>
                      {planDetailsValues.type_payment.description ===
                      'Boleto' ? (
                        <span>{`x${
                          planDetailsValues.installmentAmount
                        } de ${formattedValue(
                          planValueDivide,
                        )} no boleto`}</span>
                      ) : (
                        <span>{`x${
                          planDetailsValues.installmentAmount
                        } de ${formattedValue(
                          planValueDivide,
                        )} no cartão de crédito final ????`}</span>
                      )}
                    </S.WrapperFooterValue>
                  </>
                )}
              {planDetailsValues && planDetailsValues.typeProduct === 'outro' && (
                <>
                  <S.Ul>
                    <li>
                      Valor do plano
                      <p>{`${formattedValue(planDetailsValues.totalCost)}`}</p>
                    </li>
                    <S.WrapperToolTip>
                      <li>
                        Descontos
                        <p>{`${minusSign} ${formattedValue(
                          planDetailsValues.discountValue,
                        )}`}</p>
                      </li>
                      {planDetailsValues.discountValue !== null && (
                        <ToolTip
                          content={
                            finalDateCoupon
                              ? `Cupom de desconto ${coupon} até ${finalDateCoupon}`
                              : `Cupom de desconto ${coupon}`
                          }
                          icon={<ToolTipIcon />}
                        />
                      )}
                    </S.WrapperToolTip>
                  </S.Ul>
                  <S.WrapperLine>
                    <Line width="100%" border="1px" />
                  </S.WrapperLine>
                  <S.WrapperFooterValue>
                    <p>{`${formattedValue(totalDiscount)}`}</p>
                    {planDetailsValues.type_payment.description === 'Boleto' ? (
                      <span>{`x${
                        planDetailsValues.installmentAmount
                      } de ${formattedValue(planValueDivide)} no boleto`}</span>
                    ) : (
                      <span>{`x${
                        planDetailsValues.installmentAmount
                      } de ${formattedValue(
                        planValueDivide,
                      )} no cartão de crédito final ????`}</span>
                    )}
                  </S.WrapperFooterValue>
                </>
              )}
            </S.ListValueWrapper>
            <S.BenefitWrapper>
              {planDetailsValues &&
                planDetailsValues.typeProduct === 'imob' && (
                  <MainBenefits
                    title="Principais benefícios"
                    iconBenefit1={<People />}
                    descriptionBenefit1="Gestão de leads completa"
                    iconBenefit2={<Home />}
                    descriptionBenefit2="Cadastro ilimitado de imóveis"
                    iconBenefit3={<Screen />}
                    descriptionBenefit3="Site integrado e otimizado"
                    link="https://www.kenlo.com.br/crm-para-imobiliarias"
                  />
                )}
              {planDetailsValues &&
                planDetailsValues.typeProduct === 'locacao' && (
                  <MainBenefits
                    title="Principais benefícios"
                    iconBenefit1={<SmartTickets />}
                    descriptionBenefit1="Boletos inteligentes"
                    iconBenefit2={<DoubleArrowNeutral />}
                    descriptionBenefit2="Gestão de repasses"
                    iconBenefit3={<NotePercent />}
                    descriptionBenefit3="Relatórios gerenciais"
                    link="https://www.kenlo.com.br/administracao-de-imoveis"
                  />
                )}
            </S.BenefitWrapper>
            {planDetailsValues && planDetailsValues.typeProduct !== 'outro' && (
              <S.BenefitWrapper>
                <S.AdditionalServices>
                  <h2>Serviços Adicionais</h2>
                  {planDetailsValues &&
                    planDetailsValues.typeProduct === 'imob' && (
                      <MainAdditionals
                        iconAdditional1={<Box />}
                        descriptionAdditional1="Implantação do sistema"
                        iconAdditional2={<Learning />}
                        descriptionAdditional2="Treinamento Kenlo Imob"
                      />
                    )}
                  {planDetailsValues &&
                    planDetailsValues.typeProduct === 'locacao' && (
                      <MainAdditionals
                        iconAdditional1={<Box />}
                        descriptionAdditional1="Implantação do sistema"
                        iconAdditional2={<Learning />}
                        descriptionAdditional2="Treinamento Kenlo Locação"
                      />
                    )}
                  {planDetailsValues &&
                    planDetailsValues.typeProduct === 'outro' && (
                      <MainAdditionals
                        iconAdditional1={<Box />}
                        descriptionAdditional1="Implantação do sistema"
                        iconAdditional2={<Learning />}
                        descriptionAdditional2="Treinamento Kenlo"
                      />
                    )}
                </S.AdditionalServices>
              </S.BenefitWrapper>
            )}
          </S.WrapperMain>
        </S.BodyContentWrapper>
      </BaseMain>
    </>
  );
};
