import * as Dialog from '@radix-ui/react-dialog';
import * as S from './styles';
import { BoxContent } from 'components/BoxContent';
import Button from 'components/Button';
import { useState } from 'react';
import { BaseModal } from '../BaseModal';
import { theme } from 'styles/theme';
import HighPotential from 'components/Icons/HighPotential';
import AveragePotential from 'components/Icons/AveragePotential';
import LowPotential from 'components/Icons/LowPotential';
import { ToolTip } from 'components/ToolTip';
import {
  CaretDoubleUp,
  CaretUp,
  HexagonMinus,
  ToolTipIcon,
} from 'components/Icons';
import { Line } from 'components/Line';
import { InvoiceStatus } from 'components/InvoiceStatus';
import DocumentCheckMark from 'components/Icons/DocumentCheckMark';
import MegaPhone from 'components/Icons/MegaPhone';
import { indicatorsProps } from 'pages/QualifierDetails';
import moment from 'moment';
import { formattedValue } from 'helpers/util';

interface ILeadProps {
  el?: any;
  value?: indicatorsProps[];
  potential?: string;
  label?: string;
}

export const PerformaceRetroactiveDetailModal = ({ el }: ILeadProps) => {
  const [showDetails, setShowDetails] = useState(false);

  function capitalize(str: any): string {
    if (typeof str === 'string') {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return '';
    }
  }

  const getIcon = (potential: string) => {
    switch (potential) {
      case 'high':
        return <HighPotential />;
      case 'average':
        return <AveragePotential />;
      case 'low':
        return <LowPotential />;
      default:
        return null;
    }
  };
  const getQualified = () => {
    return (
      <>
        <BoxContent
          key={`${el?.mes}-${el?.ano}`}
          style={{ padding: '1.6rem 2.4rem 1.6rem 2.4rem' }}
          color="#E5E5E5"
        >
          <S.Row>
            <S.Qualify>
              <S.Title color={theme.colors.neutral.neutral120}>
                {`${el?.tot_leads_qualificados_mes} qualificações`}
              </S.Title>
              <S.TextOne textAlign="end">
                <S.Subtitle color={theme.colors.neutral.neutral130}>
                  Período
                </S.Subtitle>
                <S.Title color={theme.colors.neutral.neutral120}>
                  {`${capitalize(
                    `${moment(`${el?.ano}-${el?.mes}-01`, [
                      'YYYY-M-DD',
                      'YYYY-MM-DD',
                    ]).format('MMMM')} de ${el?.ano}`,
                  )}`}
                </S.Title>
              </S.TextOne>
            </S.Qualify>
          </S.Row>
          <Line width="100%" border="1px" />
          <S.Row>
            <S.Commission>
              <S.Title color={theme.colors.neutral.neutral120}>
                <S.Leads>
                  <CaretDoubleUp />
                  <div>
                    <p>{el?.qtd_leads_qualificados_mes_alto}</p>
                    {el?.qtd_leads_qualificados_mes_alto > 1 ? (
                      <span>Leads quentes</span>
                    ) : (
                      <span>Lead quente</span>
                    )}
                  </div>
                </S.Leads>
              </S.Title>
            </S.Commission>

            <S.Commission>
              <S.Title color={theme.colors.neutral.neutral120}>
                <S.Leads>
                  <CaretUp />
                  <div>
                    <p>{el?.qtd_leads_qualificados_mes_medio}</p>
                    {el?.qtd_leads_qualificados_mes_medio > 1 ? (
                      <span>Leads mornos</span>
                    ) : (
                      <span>Lead morno</span>
                    )}
                  </div>
                </S.Leads>
              </S.Title>
            </S.Commission>

            <S.Commission>
              <S.Title color={theme.colors.neutral.neutral120}>
                <S.Leads>
                  <HexagonMinus />
                  <div>
                    <p>{el?.qtd_leads_qualificados_mes_baixo}</p>
                    {el?.qtd_leads_qualificados_mes_baixo > 1 ? (
                      <span> Leads frios</span>
                    ) : (
                      <span> Lead frio</span>
                    )}
                  </div>
                </S.Leads>
              </S.Title>
            </S.Commission>
          </S.Row>
        </BoxContent>
      </>
    );
  };
  const getCommission = () => {
    return (
      <BoxContent
        color={theme.colors.neutral.neutral10}
        style={{ padding: '1.6rem 2.4rem 1.6rem 2.4rem' }}
      >
        <S.Row>
          <S.Title color={theme.colors.neutral.neutral120}>
            <ToolTip
              content="Comparamos a comissão estimada dos atendimentos com os contratos fechados durante o mês."
              icon={
                <S.ToolTip>
                  <ToolTipIcon />
                </S.ToolTip>
              }
              text="Comissão recebida"
            />
          </S.Title>
        </S.Row>
        <S.Row>
          <S.Commission>
            <S.Subtitle
              color={theme.colors.neutral.neutral140}
              fontWeight={500}
            >
              Venda
            </S.Subtitle>
            <S.RowCommission>
              <S.Title
                color={theme.colors.neutral.neutral120}
                fontSize="2rem"
                fontWeight={600}
              >
                {formattedValue(el?.comissao_recebida_atual_venda)}
              </S.Title>
              <S.StatusTwo>
                <InvoiceStatus
                  fontWeight="500"
                  text={`Converteu ${
                    el?.comissao_recebida_atual_venda -
                      el?.comissao_estimada_mes_atual_atendimentos_venda <
                    0
                      ? 0
                      : el?.comissao_recebida_atual_venda -
                        el?.comissao_estimada_mes_atual_atendimentos_venda
                  }%`}
                  status={'success'}
                />
              </S.StatusTwo>
            </S.RowCommission>
          </S.Commission>
          <S.Commission>
            <S.Subtitle
              color={theme.colors.neutral.neutral140}
              fontWeight={500}
            >
              Locação
            </S.Subtitle>
            <S.RowCommission>
              <S.Title
                color={theme.colors.neutral.neutral120}
                fontSize="2rem"
                fontWeight={600}
              >
                {formattedValue(el?.comissao_recebida_atual_locacao)}
              </S.Title>
              <S.StatusTwo>
                <InvoiceStatus
                  fontWeight="500"
                  text={`Converteu ${
                    el?.comissao_recebida_atual_locacao -
                      el?.comissao_estimada_mes_atual_atendimentos_locacao <
                    0
                      ? 0
                      : el?.comissao_recebida_atual_locacao -
                        el?.comissao_estimada_mes_atual_atendimentos_locacao
                  }%`}
                  status={'error'}
                />
              </S.StatusTwo>
            </S.RowCommission>
          </S.Commission>
        </S.Row>
        <S.Row>
          <S.Potential>
            {getIcon(el.perc_contratos_fechados_alto_potencial_mes_atual)}
            <S.TextOne marginLeft="1rem">
              <S.Title color={theme.colors.neutral.neutral120}>
                {el.value}
              </S.Title>
              <S.Subtitle color={theme.colors.neutral.neutral140}>
                {el.label}
              </S.Subtitle>
            </S.TextOne>
          </S.Potential>
        </S.Row>
      </BoxContent>
    );
  };
  const getPerformance = () => {
    return (
      <>
        <BoxContent
          color={theme.colors.neutral.neutral10}
          style={{ padding: '1.6rem 2.4rem 1.6rem 2.4rem' }}
        >
          <S.Row>
            <S.Title color={theme.colors.neutral.neutral120}>
              <ToolTip
                height="-6rem"
                content="Comparamos as estatísticas de desempenho com o mês anterior."
                icon={
                  <S.ToolTip>
                    <ToolTipIcon />
                  </S.ToolTip>
                }
                text="Desempenho"
              />
            </S.Title>
          </S.Row>
          <S.Row>
            <S.Performance>
              <DocumentCheckMark width="38" height="38" />
              <S.Status>
                <S.RowPerformance marginBottom="1rem">
                  <S.Title
                    color={theme.colors.neutral.neutral120}
                    fontWeight={600}
                    fontSize="2rem"
                  >
                    {el?.perc_contratos_fechados_alto_potencial_mes_atual}%
                  </S.Title>
                  <S.Status>
                    <InvoiceStatus
                      fontWeight="500"
                      text={`${
                        el?.perc_contratos_fechados_alto_potencial_mes_atual -
                        el?.perc_contratos_fechados_alto_potencial_mes_anterior
                      }%`}
                      status={'success'}
                    />
                  </S.Status>
                </S.RowPerformance>
                <S.Subtitle
                  color={theme.colors.neutral.neutral120}
                  fontWeight={500}
                >
                  dos contratos fechados são de leads quentes.
                </S.Subtitle>
              </S.Status>
            </S.Performance>
            <S.Performance>
              <MegaPhone width="38" height="38" />
              <S.Status>
                <S.RowPerformance marginBottom="1rem">
                  <S.Title
                    color={theme.colors.neutral.neutral120}
                    fontWeight={600}
                    fontSize="2rem"
                  >
                    {
                      el?.perc_cliente_alto_potencial_originacao_propria_mes_atual
                    }
                    %
                  </S.Title>
                  <S.Status>
                    <InvoiceStatus
                      fontWeight="500"
                      text={`${
                        el?.perc_cliente_alto_potencial_originacao_propria_mes_atual -
                        el?.perc_cliente_alto_potencial_originacao_propria_mes_anterior
                      }%`}
                      status={'error'}
                    />
                  </S.Status>
                </S.RowPerformance>
                <S.Subtitle
                  color={theme.colors.neutral.neutral120}
                  fontWeight={500}
                >
                  dos leads quentes vieram de originação própria.
                </S.Subtitle>
              </S.Status>
            </S.Performance>
          </S.Row>
        </BoxContent>
      </>
    );
  };
  return (
    <>
      <Dialog.Root>
        <Dialog.Trigger asChild id={PerformaceRetroactiveDetailModal.name}>
          <Button onClick={() => setShowDetails(true)}>Ver detalhes</Button>
        </Dialog.Trigger>
        {showDetails && (
          <BaseModal title="Detalhes do desempenho">
            {getQualified()}
            {getPerformance()}
            {getCommission()}
          </BaseModal>
        )}
      </Dialog.Root>
    </>
  );
};
