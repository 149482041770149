import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.border.radius};
    background: ${theme.colors.neutral.neutral0};
    padding: 2.4rem;
  `}
`;

export const WrapperTitle = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.neutral.neutral100};
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.4px;
    }

    a {
      font-size: ${theme.font.sizes.medium};
      font-weight: 700;
      color: ${theme.colors.primary50};
      line-height: 24px;
      letter-spacing: -0.4px;
      text-decoration: none;
    }
  `}
`;

export const WrapperBenefits = styled.div`
  ${({ theme }) => css`
    ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      list-style: none;
      justify-content: space-between;
      margin-top: 2.4rem;
      padding: 0;
      li {
        display: grid;
        grid-template-columns: min-content max-content;
        align-items: center;
        gap: 1rem;
      }

      p {
        font-size: ${theme.font.sizes.medium};
        font-weight: 500;
        letter-spacing: -0.4px;
        line-height: 24px;
        margin: 0;
      }
    }
  `}
`;
