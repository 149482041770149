import styled, { css } from 'styled-components';

export const Row = styled.div<{ marginTop?: string }>`
  ${({ marginTop }) => css`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-top: ${marginTop || 0};
  `}
`;
export const Title = styled.div<{
  color: string;
  margin?: string;
  self?: string;
  fontWeight?: string;
}>`
  ${({ theme, color, margin, self, fontWeight }) => css`
    font-size: ${theme.font.sizes.medium};
    font-weight: ${fontWeight || theme.font.semiBold};
    color: ${color};
    margin-left: ${margin || 0};
    margin-right: ${margin || 0};
    align-self: ${self || 0};
  `}
`;
export const Subtitle = styled.span<{
  color: string;
  margin?: string;
  lineHeight?: string;
  fontSize?: string;
  fontWeight?: string;
}>`
  ${({ theme, color, fontWeight, lineHeight, fontSize }) => css`
    font-size: ${fontSize || theme.font.sizes.small};
    color: ${color};
    font-weight: ${fontWeight || 700};
    line-height: ${lineHeight || '24px'};
  `}
`;

export const Qualify = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;
export const RowQualify = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;
export const RowTable = styled.div`
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: space-between;
`;
export const RowTwoColumn = styled.div`
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: flex-start;
`;
export const ColOne = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;

export const TextOne = styled.div`
  margin-right: 1rem;
  align-items: center;
`;
export const ToolTip = styled.div`
  margin-left: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const BodyTable = styled.div`
  max-height: 450px;
  height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 0.5rem; /* width of the entire scrollbar */
  }
  ::-webkit-scrollbar-thumb {
    background-color: #888888; /* color of the scroll thumb */
  }
`;

export const IconRight = styled.div`
  width: 2.8rem;
  text-align: end;
`;
