import React, { CSSProperties } from 'react';
import * as S from './styles';

export interface IBoxContent {
  color: string;
  children: React.ReactNode;
  style?: CSSProperties;
  background?: string;
}

export const BoxContent = ({
  color,
  children,
  style,
  background,
}: IBoxContent) => {
  return (
    <>
      <S.BoxContent color={color} style={style} background={background}>
        {children}
      </S.BoxContent>
    </>
  );
};
