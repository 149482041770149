import { ArrowRight } from '../Icons';
import * as S from './styles';

export type DropdownLinkProps = {
  children: React.ReactNode;
  link?: string;
  newTab?: boolean;
  arrowRight?: boolean;
  size?: string;
  color?: string;
  handleClick?: () => void;
};

export const DropdownLink = ({
  children,
  link,
  newTab = false,
  arrowRight = true,
  size = '1.6rem',
  color = '#242424',
  handleClick,
}: DropdownLinkProps) => {
  const target = newTab ? '_blank' : '_self';
  return (
    <S.WrapperA
      href={link}
      target={target}
      onClick={handleClick}
      size={size}
      color={color}
    >
      {children}
      {!!arrowRight && <ArrowRight />}
    </S.WrapperA>
  );
};
