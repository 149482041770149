import * as Dialog from '@radix-ui/react-dialog';
import * as S from './styles';
import { BoxContent } from 'components/BoxContent';
import Button from 'components/Button';
import { useContext, useState } from 'react';
import { BaseModal } from '../BaseModal';
import { theme } from 'styles/theme';
import Calendar from 'components/Icons/Calendar';
import { Line } from 'components/Line';
import {
  ArrowRightNeutral,
  CaretDoubleUp,
  CaretUp,
  HexagonMinus,
  Star,
} from 'components/Icons';
import { ToolTip } from 'components/ToolTip';
import { ToolTipIcon } from 'components/Icons';
import { EvolutionQualificationModal } from '../EvolutionQualificationModal';
import { HistoryOfQualificationProps } from 'pages/QualifierDetails';
import { api } from 'services/api';
import { ClientInfoContext } from 'contexts/ClientInfoContext';
import moment from 'moment';
import 'moment/locale/pt-br';
import { Loading } from 'components/Loading';

export interface ModalHandles {
  closeModal: () => void;
}

export interface IModalProps {
  element?: HistoryOfQualificationProps;
  elHistory?: HistoryProps;
  elQualifier?: DailyQualification[];
}

export type DailyQualification = {
  id_debit: string;
  type_pretense_lead: string;
  value: number;
  date: string;
  id_broker: number;
  id_client: number;
  id_lead: string | null;
  name_broker: string;
  name_client: string;
  potential: string | null;
  history: HistoryProps[];
};

export type HistoryProps = {
  date: string;
  scoringkenlo: string;
  changedirection: number;
  desc_change_direction: string;
};

type DailyQualificationTotal = {
  total: number;
};

export const DailyQualificationsModal = ({ element }: IModalProps) => {
  const [open, setOpen] = useState(false);
  const [showNextContent, setShowNextContent] = useState(false);
  const [showContent, setShowContent] = useState(true);
  const [, setShowBackButton] = useState(false);
  const [titleName, setTitlename] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [dailyQualification, setDailyQualification] = useState<
    DailyQualification[]
  >([]);
  const [totalQualification, setTotalQualification] =
    useState<DailyQualificationTotal | null>(null);

  const accessToken = localStorage.getItem('accessToken');
  const idToken = localStorage.getItem('idToken');
  const [currentPage] = useState<number>(1);

  const { dataClientInfo } = useContext(ClientInfoContext);

  const onClickHandle = async () => {
    if (Number(dataClientInfo?.id_realstate) > 0) {
      await fetchDataDailyQualification();
    }
    setShowContent(true);
    setShowNextContent(false);
    setShowBackButton(true);
    setTitlename('Qualificações diárias');
  };

  const handleShowBackButton = (boolean: boolean) => {
    setShowBackButton(boolean);
  };

  const fetchDataDailyQualification = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get(
        `qualifier/movto/debit/${
          dataClientInfo?.id_realstate
        }?startDate=${moment
          .parseZone(element?.date)
          .utcOffset(0, true)
          .format('YYYY-MM-DD')}&endDate=${moment
          .parseZone(element?.date)
          .utcOffset(0, true)
          .format('YYYY-MM-DD')}&page=${currentPage}&limit=${1000}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            idToken,
          },
        },
      );
      setDailyQualification(data.data);
      setTotalQualification(data.pagination);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getIconQuality = (el: string) => {
    switch (el) {
      case 'alto':
        return <CaretDoubleUp />;
      case 'medio':
      case 'médio':
        return <CaretUp />;
      case 'baixo':
        return <HexagonMinus />;
      case 'novo':
      case 'novo cliente':
        return <Star />;
      case null:
        return <Star />;
      default:
        break;
    }
  };

  const getTextQuality = (el: any) => {
    switch (el) {
      case 'alto':
        return `Lead quente`;
      case 'medio':
      case 'médio':
        return `Lead morno`;
      case 'baixo':
        return `Lead frio`;
      case `novo`:
      case `novo cliente`:
      case null:
      case undefined:
        return 'Novo lead';
      default:
        return '';
    }
  };

  const getTextTypePretense = (el: any) => {
    switch (el) {
      case 'locacao':
        return 'de locação';
      case 'venda':
        return ' de compra';
      default:
        return '';
    }
  };

  const getIconRight = (data: any, history: any) => {
    switch (data?.potential) {
      case 'novo':
      case 'novo cliente':
      case null:
      case undefined:
        return (
          <S.IconRight>
            <ToolTip
              right="2rem"
              height="0"
              content="Estamos analisando os dados e em breve geramos a qualificação."
              icon={
                <S.ToolTip>
                  <ToolTipIcon />
                </S.ToolTip>
              }
            />
          </S.IconRight>
        );

      default:
        return (
          <>
            <S.IconRight>
              <EvolutionQualificationModal
                elHistory={history}
                elQualifier={data}
                id_lead={data?.id_lead}
                setShowContent={setShowContent}
                showNextContent={showNextContent}
                setShowNextContent={setShowNextContent}
                handleShowBackButton={handleShowBackButton}
                setTitleName={setTitlename}
                setOpen={setOpen}
              />
            </S.IconRight>
          </>
        );
    }
  };

  const getQualified = () => {
    return (
      <>
        <BoxContent
          color={theme.colors.neutral.neutral10}
          style={{ padding: '1.6rem 2.4rem 1.6rem 2.4rem' }}
        >
          <S.Row>
            <S.Qualify>
              <S.Title color={theme.colors.neutral.neutral120}>
                {`${
                  totalQualification?.total && totalQualification?.total > 1
                    ? `${totalQualification?.total} qualificações`
                    : `${totalQualification?.total} qualificação`
                } `}
              </S.Title>
              <S.RowQualify>
                <S.TextOne>
                  <Calendar />
                </S.TextOne>
                <S.Subtitle
                  color={theme.colors.neutral.neutral40}
                  fontSize={theme.font.sizes.medium}
                >
                  {moment
                    .parseZone(element?.date)
                    .utcOffset(0, true)
                    .format('DD/MM/YYYY')}
                </S.Subtitle>
              </S.RowQualify>
            </S.Qualify>
          </S.Row>
        </BoxContent>
      </>
    );
  };

  const getTable = () => {
    return (
      <BoxContent color={theme.colors.neutral.neutral10}>
        <S.Row>
          <S.QualifyTable>
            <S.Title
              color={theme.colors.neutral.neutral130}
              fontSize={theme.font.sizes.medium}
            >
              Clientes
            </S.Title>
            <S.RowTable>
              <S.Title
                color={theme.colors.neutral.neutral130}
                fontSize={theme.font.sizes.medium}
              >
                Qualificação
              </S.Title>
            </S.RowTable>
          </S.QualifyTable>
        </S.Row>
        <Line width="100%" border="1px" />
        <S.BodyTable>
          {dailyQualification.map((el) => (
            <div key={el.id_debit}>
              <S.Row> {getBodyTable(el)} </S.Row>
              <Line width="100%" border="1px" />
            </div>
          ))}
        </S.BodyTable>
      </BoxContent>
    );
  };

  const getBodyTable = (el: any) => {
    return (
      <S.QualifyTable>
        <S.RowTable>
          <S.TextOne>
            {el.name_client && el.name_client && (
              <S.Title
                color={theme.colors.neutral.neutral120}
                fontSize={theme.font.sizes.medium}
              >
                {el.name_client}
              </S.Title>
            )}
            {el.name_broker && el.name_broker && (
              <S.Subtitle color={theme.colors.neutral.neutral130}>
                Resp. {el.name_broker}
              </S.Subtitle>
            )}
          </S.TextOne>
        </S.RowTable>
        <S.RowTable justifyContent="space-between">
          <S.ColOne width="90%">
            <S.DivIcon margin={el.potential === 'new' ? '1rem' : '0.5rem'}>
              {getIconQuality(el.potential)}
            </S.DivIcon>
            <S.Subtitle color={theme.colors.neutral.neutral40}>
              {`${getTextQuality(el?.potential)} ${
                getTextQuality(el?.potential) !== 'Novo lead'
                  ? getTextTypePretense(el?.type_pretense_lead)
                  : ''
              }`}
            </S.Subtitle>
          </S.ColOne>
          {getIconRight(el, el.history)}
        </S.RowTable>
      </S.QualifyTable>
    );
  };

  return (
    <>
      {isLoading && <Loading background="rgba(0, 0, 0, 0.3)" />}
      <Dialog.Root open={open} onOpenChange={setOpen}>
        <Dialog.Trigger asChild id={'el'}>
          <Button margin="0" onClick={() => onClickHandle()}>
            <ArrowRightNeutral />
          </Button>
        </Dialog.Trigger>
        {!isLoading && (
          <BaseModal title={titleName}>
            {showContent && (
              <>
                {getQualified()}
                {getTable()}
              </>
            )}
          </BaseModal>
        )}
      </Dialog.Root>
    </>
  );
};
