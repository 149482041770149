const Learning = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#212121"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.5 2C4.11929 2 3 3.11929 3 4.5V19.5C3 20.8807 4.11929 22 5.5 22H12.8096C12.3832 21.5557 12.0194 21.051 11.7322 20.5H5.5C4.94772 20.5 4.5 20.0523 4.5 19.5H11.3135C11.159 19.0218 11.0585 18.5195 11.0189 18H4.5V4.5C4.5 3.94772 4.94772 3.5 5.5 3.5H17C17.5523 3.5 18 3.94772 18 4.5V11.0189C18.5195 11.0585 19.0218 11.159 19.5 11.3135V4.5C19.5 3.11929 18.3807 2 17 2H5.5ZM23 17.5C23 14.4624 20.5376 12 17.5 12C14.4624 12 12 14.4624 12 17.5C12 20.5376 14.4624 23 17.5 23C20.5376 23 23 20.5376 23 17.5ZM16.9138 15.097L19.7203 16.9373C19.8073 16.9933 19.8757 17.0677 19.9254 17.1604C19.9751 17.251 20 17.3502 20 17.458C20 17.5614 19.9762 17.6606 19.9285 17.7555C19.8809 17.8504 19.8146 17.9258 19.7296 17.9819L16.9231 19.8965C16.8713 19.931 16.8184 19.9569 16.7646 19.9741C16.7128 19.9914 16.6568 20 16.5967 20C16.518 20 16.4424 19.9838 16.3699 19.9515C16.2973 19.917 16.2331 19.8717 16.1772 19.8157C16.1233 19.7574 16.0798 19.6906 16.0466 19.6151C16.0155 19.5397 16 19.461 16 19.379V15.621C16 15.5369 16.0155 15.4571 16.0466 15.3816C16.0798 15.3062 16.1233 15.2404 16.1772 15.1843C16.231 15.1283 16.2942 15.0841 16.3667 15.0517C16.4393 15.0172 16.5159 15 16.5967 15C16.7128 15 16.8184 15.0323 16.9138 15.097ZM6 6C6 5.44772 6.44772 5 7 5H15C15.5523 5 16 5.44772 16 6V8C16 8.55228 15.5523 9 15 9H7C6.44772 9 6 8.55228 6 8V6ZM7.5 7.5H14.5V6.5H7.5V7.5Z" />
    </svg>
  );
};
export default Learning;
