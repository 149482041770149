import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Routes } from './routes';

import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './styles/global';
import { theme } from './styles/theme';

export function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
        <GlobalStyles />
      </ThemeProvider>
    </>
  );
}
