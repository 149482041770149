const DocumentEndnote = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#4C4C4C"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.5 2.25C4.5 1.83579 4.83579 1.5 5.25 1.5H11V6C11 7.10457 11.8954 8 13 8H17.5V17.75C17.5 18.1642 17.1642 18.5 16.75 18.5H12V20H16.75C17.9926 20 19 18.9926 19 17.75V7.66421C19 7.20009 18.8156 6.75497 18.4874 6.42678L12.5732 0.512563C12.245 0.184374 11.7999 0 11.3358 0H5.25C4.00736 0 3 1.00736 3 2.25V10H4.5V2.25ZM12.5 6V2.56066L16.4393 6.5H13C12.7239 6.5 12.5 6.27614 12.5 6ZM3.5 11.75C3.5 12.1642 3.16421 12.5 2.75 12.5H1.5V19.5H2.75C3.16421 19.5 3.5 19.8358 3.5 20.25C3.5 20.6642 3.16421 21 2.75 21H1.5C0.671573 21 0 20.3284 0 19.5V12.5C0 11.6716 0.671573 11 1.5 11H2.75C3.16421 11 3.5 11.3358 3.5 11.75ZM8.25 12.5C7.83579 12.5 7.5 12.1642 7.5 11.75C7.5 11.3358 7.83579 11 8.25 11H9.5C10.3284 11 11 11.6716 11 12.5V19.5C11 20.3284 10.3284 21 9.5 21H8.25C7.83579 21 7.5 20.6642 7.5 20.25C7.5 19.8358 7.83579 19.5 8.25 19.5H9.5V12.5H8.25ZM5.5 14.75C5.91421 14.75 6.25 15.0858 6.25 15.5V19C6.25 19.4142 5.91421 19.75 5.5 19.75C5.08579 19.75 4.75 19.4142 4.75 19V15.5C4.75 15.0858 5.08579 14.75 5.5 14.75ZM5.5 14C5.91421 14 6.25 13.6642 6.25 13.25C6.25 12.8358 5.91421 12.5 5.5 12.5C5.08579 12.5 4.75 12.8358 4.75 13.25C4.75 13.6642 5.08579 14 5.5 14Z" />
    </svg>
  );
};
export default DocumentEndnote;
