const CaretDoubleUp = ({
  width = '25',
  height = '24',
  fill = 'url(#paint0_linear_8343_25562)',
  stroke = 'white',
}: {
  width?: string;
  height?: string;
  fill?: string;
  stroke?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8343_25562)">
        <path
          d="M10.9267 2.42149C11.9001 1.85951 13.0993 1.8595 14.0727 2.42148L20.0084 5.84848C20.9818 6.41046 21.5815 7.44904 21.5815 8.573V15.427C21.5815 16.551 20.9818 17.5895 20.0084 18.1515L14.0727 21.5785C13.0993 22.1405 11.9001 22.1405 10.9267 21.5785L4.99097 18.1515C4.01759 17.5895 3.41797 16.551 3.41797 15.427V8.573C3.41797 7.44904 4.01759 6.41046 4.99097 5.84848L10.9267 2.42149Z"
          fill={fill}
        />
        <path
          d="M16 11.3333L12.5 8L9 11.3333"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 15.3333L12.5 12L9 15.3333"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_8343_25562"
          x1="2.98958"
          y1="-12.1667"
          x2="32.3913"
          y2="-8.98822"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2183D9" />
          <stop offset="1" stopColor="#A744F4" />
        </linearGradient>
        <clipPath id="clip0_8343_25562">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default CaretDoubleUp;
