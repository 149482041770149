/* eslint-disable react/jsx-no-target-blank */
import { Heading } from '../../components/Heading';
import { Invoice } from '../../components/Invoice/Invoice';
import { Plan } from '../../components/Plan';
import { useTranslation } from 'react-i18next';
import { RealState } from 'components/RealEstate';
import Button from 'components/Button';
import { Link } from 'react-router-dom';
import * as S from './styles';

import { api } from 'services/api';
import { useContext, useEffect, useState } from 'react';

import img from '../../assets/image/k.svg';
import { Loading } from 'components/Loading';
import moment from 'moment';
import 'moment/locale/pt-br';
import {
  capitalizeFirstLetter,
  formattedValue,
  subtitleCapitalize,
} from 'helpers/util';
import { MainHeader } from 'components/MainHeader';
import { BaseMain } from 'components/BaseMain';
import WarningTriangle from 'components/Icons/WarningTriangle';
import { Banner } from 'components/Banner';
import { QuestionCircle } from 'components/Icons';
import { PlanCustomContext } from 'contexts/PlanCustomContext';
import { FeatureContext } from 'contexts/FeaturesContext';
import { QualificationContext } from 'contexts/AvailableQualification';
import { NotificationBannerContext } from 'contexts/NotificationBannerContext';
import { MovtoDailyQualifierContext } from 'contexts/MovtoDailyQualifier';
import { ClientInfoContext } from 'contexts/ClientInfoContext';

export type signaturesDataProps = {
  id_plan: number;
  name: string;
  plan: {
    name: string;
  };
  status: string;
  frequency: string;
  terminationDate: string;
  totalCost: string;
  finalValue: string;
  //typeProduct: string;
};

type InvoicesBillingsProps = {
  id_billing: string;
  id_invoice: string;
};

export type lastsInvoicesProps = {
  invoices_billings: any;
  issuanceDate: string;
  invoiceMonth: string;
  compo_billing: [{ name: string }];
  value: string;
  paidValue: string;
  dueDate: string;
  status: string;
  id_billing: string;
  id_typePay: number;
  creditCardNumber: string;
  paymentDate: string;
  description: string;
};

type lastsInvoicesProps2 = {
  invoices_billings: InvoicesBillingsProps[];
  invoiceMonth: string;
  issuanceDate: string;
  value: string;
  paidValue: string;
  dueDate: string;
  status: string;
  description: string;
  id_billing: string;
  id_typePay: number;
  creditCardNumber: string;
  paymentDate: string;
  hasCompoBilling: boolean;
};

export type PlansProps = {
  id_signature: number;
  id_plan: number;
  name: string;
  plan: {
    name: string;
  };
  status: string;
  totalCost: string;
  terminationDate: string;
  frequency: string;
  finalValue: string;
  costMonthly?: string;
};

interface Qualification {
  type: string;
  inforLeft: string;
  inforRight?: string;
  link: string;
  typeCardQualifier: string;
  textButton?: string | undefined;
}

type DataValidateStatusProps = {
  status: string;
};

export const ClientArea = () => {
  const { t } = useTranslation();

  const [signaturesData, setSignaturesData] = useState<signaturesDataProps[]>(
    [],
  );
  const [lastsInovices, setLastsInvoices] = useState<lastsInvoicesProps2[]>([]);
  const [plans, setPlans] = useState<PlansProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataValidateStatus, setDataValidateStatus] = useState<
    DataValidateStatusProps[]
  >([]);

  const { dataAgency } = useContext(FeatureContext);
  const { dataClientInfo } = useContext(ClientInfoContext);
  const { handleSetIdSignature } = useContext(PlanCustomContext);
  const { dataQualifierBalance, fetchDataQualifierBalance } =
    useContext(QualificationContext);
  const { setDataBanner, dataBanner } = useContext(NotificationBannerContext);
  const { dataMovtoDailyQualifier, fetchDataMovtoDailyQualifier } = useContext(
    MovtoDailyQualifierContext,
  );

  const accessToken = localStorage.getItem('accessToken');
  const idToken = localStorage.getItem('idToken');
  const idClientImob = localStorage.getItem('useImobId');

  const [arrMsgBanner, setArrMsgBanner] = useState<any[]>([]);
  const getQualifications = (el: Qualification) => {
    const backgroundColor =
      el.typeCardQualifier === 'new' ? '#F5F5F5' : '#FFFFFF';
    const borderColor = el.typeCardQualifier === 'new' ? '#2183D9' : '#888888';
    const border = el.typeCardQualifier === 'new';

    return (
      <Plan
        key={el.typeCardQualifier}
        title={'LIA'}
        infoLeft={el.inforLeft}
        infoRight={el.inforRight ? el.inforRight : ''}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        border={border}
        link={el.link}
      >
        <Link to={el.link}>
          <Button>{el.textButton}</Button>
        </Link>
      </Plan>
    );
  };

  const verificaAssinaturasVencendo = (arrAssinaturas: any[]): any[] => {
    const arrMsg: any[] = [];
    if (Array.isArray(arrAssinaturas)) {
      arrAssinaturas.map((item: any) => {
        if (
          item.frequency == 'anual' &&
          item.terminationDate != null &&
          (item.typeProduct === 'imob' ||
            item.typeProduct === 'locacao' ||
            item.typeProduct === '')
        ) {
          const dateFim = moment(item.terminationDate);
          const dateNow = moment();
          const dias = dateFim.diff(dateNow, 'days');
          if (dias <= 30) {
            const dateStr = dateFim.format('DD/MM/YYYY');
            let typeProduct = item.typeProduct;
            const originalTypeProduct = item.typeProduct;
            if (typeProduct === 'locacao') {
              typeProduct = 'locação';
            }
            if (typeProduct === '') {
              typeProduct = 'produto';
            }
            const msg = `O ${
              item.plan.name
            } termina em ${dias} dias (${dateStr}). Para não perder acesso ao produto Kenlo ${capitalizeFirstLetter(
              typeProduct,
            )} faça a renovação.`;
            const obj = {
              id: item.id_signature,
              originalTypeProduct: originalTypeProduct,
              typeProduct: originalTypeProduct,
              msg: msg,
            };
            arrMsg.push(obj);
          }
        }
      });
    }
    return arrMsg;
  };
  const setValorMensalAssinatura = (signatures: any[] = []): any[] => {
    signatures = signatures.map((item: any) => {
      item['costMonthly'] = item['finalValue'];
      if (item.frequency == 'mensal') return item;
      if (item.frequency == 'anual' && item.finalValue > 0) {
        item['costMonthly'] = (parseFloat(item['finalValue']) / 12).toFixed(2);
      }
      return item;
    });
    return signatures;
  };

  useEffect(() => {
    if (Number(dataClientInfo?.id_client) > 0) {
      dataAgency && fetchDataQualifierBalance(dataAgency.id_client);
    }
    if (Number(dataClientInfo?.id_realstate) > 0) {
      fetchDataMovtoDailyQualifier(dataClientInfo?.id_realstate);
    }
  }, [
    fetchDataQualifierBalance,
    dataAgency,
    dataClientInfo,
    fetchDataMovtoDailyQualifier,
  ]);

  useEffect(() => {
    const fetchValidateCondition = async () => {
      try {
        const { data } = await api.get(
          `qualifier/bought/pending/${dataClientInfo?.id_realstate}`,
          {
            headers: {
              'content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
              idToken,
            },
          },
        );
        setDataValidateStatus(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    if (dataClientInfo?.id_realstate) {
      fetchValidateCondition();
    }
  }, [accessToken, dataClientInfo?.id_realstate, idToken]);

  const getMockQualification = () => {
    const typeToBuyPackage =
      dataClientInfo?.howOldQualifier === 'old' ? 'new' : 'renew';
    const typeToDetails =
      dataClientInfo?.howOldQualifier === 'old' ? 'details' : 'renew';
    const isOpenDetailFrame =
      Number(dataQualifierBalance?.balance_premium) > 0 ||
      Number(dataMovtoDailyQualifier?.length) > 0
        ? true
        : false;
    if (isOpenDetailFrame) {
      if (Number(dataQualifierBalance?.balance_premium) > 0) {
        return {
          type: 'details',
          inforLeft: `${
            dataQualifierBalance?.balance_premium === 1
              ? `${dataQualifierBalance?.balance_premium} qualificacão disponível`
              : `${dataQualifierBalance?.balance_premium} qualificações disponíveis`
          }`,
          typeCardQualifier: 'old',
          inforRight: `Duração estimada em ${
            dataQualifierBalance?.days_estimated
          } ${
            dataQualifierBalance?.days_estimated &&
            dataQualifierBalance?.days_estimated > 1
              ? 'dias'
              : 'dia'
          }`,
          textButton: 'Ver detalhes ',
          link: `QualifierDetails/${dataAgency?.id_client}/${typeToDetails}`,
        };
      } else {
        return {
          type: 'details',
          typeCardQualifier: 'old',
          inforLeft: `Contrate um pacote e continue agilizando seus atendimentos.`,
          textButton: 'Ver detalhes ',
          link: `QualifierDetails/${dataAgency?.id_client}/${typeToDetails}`,
        };
      }
    } else if (
      dataValidateStatus[0]?.status === 'pendente' ||
      Number(idClientImob) === 0
    ) {
      return {
        type: 'new',
        typeCardQualifier: 'new',
        inforLeft:
          'Saia na vantagem e aumente a eficiência da sua imobiliária.',
        link: `/Buy-package/${dataAgency?.id_client}/${typeToBuyPackage}`,
      };
    } else {
      return {
        type: 'new',
        typeCardQualifier: 'new',
        inforLeft:
          'Saia na vantagem e aumente a eficiência da sua imobiliária.',
        textButton: 'Quero contratar',
        link: `/Buy-package/${dataAgency?.id_client}/${typeToBuyPackage}`,
      };
    }
  };

  useEffect(() => {
    handleSetIdSignature(plans[0]?.id_signature);
  }, [handleSetIdSignature, plans]);

  useEffect(() => {
    if (accessToken && idToken) {
      //fetchDataAgency();
      fetchDataPlan();
      fetchDataBillings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, idToken]);

  const fetchDataPlan = async () => {
    try {
      const { data } = await api.get('client/signatures', {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          idToken,
        },
      });
      setSignaturesData([data.data[0].plan]);
      setPlans(setValorMensalAssinatura(data.data));
      setArrMsgBanner(verificaAssinaturasVencendo(data.data));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDataBillings = async () => {
    try {
      const dateInitial = moment().subtract(12, 'M').format('YYYY-MM-DD');
      const dateFinal = moment().add(45, 'days').format('YYYY-MM-DD');
      const response = await api.get(
        `client/billings?page=1&limit=10&dueDateBegin=${dateInitial}&dueDateEnd=${dateFinal}&sort=desc`,
        {
          headers: {
            'content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            idToken,
          },
        },
      );
      const data = response.data.data;
      const lastData = data.slice(0, 3);
      setLastsInvoices(lastData);
      setIsLoading(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <MainHeader />
      {!isLoading && <Loading />}
      <BaseMain>
        {arrMsgBanner.map((item, index) => (
          <>
            <S.WrapperBanners>
              <Banner
                key={index}
                icon={<WarningTriangle />}
                button
                text={item.msg}
                id={item.id}
                typeProduct={item.typeProduct}
              />
            </S.WrapperBanners>
          </>
        ))}
        {dataBanner && (
          <S.WrapperBanners>
            <Banner
              icon={<WarningTriangle />}
              text="O boleto do novo pacote contratado já está disponível. O saldo de qualificações será atualizado assim que recebermos o pagamento."
            >
              <a
                href={dataBanner.link2via}
                target="_blank"
                onClick={() => setDataBanner(undefined)}
              >
                Ver boleto
              </a>
            </Banner>
          </S.WrapperBanners>
        )}
        <S.WrapperTitle>
          <Heading
            title={t('title.clientArea')}
            subtitle={t('title.subtitleClientArea')}
            size="xxlarge"
            subtitleSize="medium"
            weight="700"
          />
          <Button
            as="a"
            href="https://suporte.kenlo.com.br/pt-BR/collections/3795647-kenlo-area-do-cliente"
            target="blank"
            icon={<QuestionCircle />}
          >
            Ajuda
          </Button>
        </S.WrapperTitle>
        <S.Wrapper>
          <RealState
            img={img}
            key={dataAgency?.id_client}
            title={dataAgency?.name}
            subtitle={`${subtitleCapitalize(dataAgency?.city)} - ${
              dataAgency?.state
            }`}
            cnpj={dataAgency?.cpfcnpj}
            creci={dataAgency?.creci}
            email={dataAgency?.arrEmail}
          />
        </S.Wrapper>
        <S.Wrapper>
          <S.ElementWrapper>
            <Heading title="Planos" size="large" weight="600" color="#1A1A1A" />
          </S.ElementWrapper>
          <S.contentPlansWrapper className="teste">
            {plans.map((signaturesData) => {
              return (
                <Plan
                  key={signaturesData.id_plan}
                  title={signaturesData.plan.name}
                  infoLeft={`${formattedValue(signaturesData.costMonthly)}/mês`}
                  infoRight={`Renova em
                    ${moment(signaturesData.terminationDate).format('ll')}`}
                >
                  <Link to={`PlanDetails/${signaturesData.id_signature}`}>
                    <Button>Ver detalhes</Button>
                  </Link>
                </Plan>
              );
            })}
            {dataClientInfo &&
              dataClientInfo.useQualifier === true &&
              getQualifications(getMockQualification())}
            {plans.length === 1 && (
              <Plan
                title="Kenlo Locação"
                infoLeft="Gerencie os contratos de aluguel de forma eficiente!"
                border={true}
              >
                <Button
                  as="a"
                  href="https://www.kenlo.com.br/administracao-de-imoveis"
                  target="blank"
                >
                  Saiba como contratar
                </Button>
              </Plan>
            )}
          </S.contentPlansWrapper>
        </S.Wrapper>
        <S.Wrapper>
          <S.ElementWrapper>
            <S.LastInvoiceWrapper>
              <Heading
                title="Últimas faturas"
                size="large"
                weight="600"
                color="#1A1A1A"
              />
              <Link to={`/HistoryOfInvoices/${plans[0]?.id_signature}`}>
                <Button>Ver histórico de faturas</Button>
              </Link>
            </S.LastInvoiceWrapper>
          </S.ElementWrapper>

          <S.ElementWrapper>
            {lastsInovices.map((item) => {
              return (
                <Invoice
                  key={item.id_billing}
                  title={`${capitalizeFirstLetter(
                    moment(item.invoiceMonth).format(`MMMM [de] YYYY`),
                  )}`}
                  subtitle={signaturesData[0]?.name}
                  value={`${formattedValue(item.value)}`}
                  paidValue={`${formattedValue(item.paidValue)}`}
                  invoiceDateTitle={`${capitalizeFirstLetter(item.status)}`}
                  invoiceDate={`${moment(item.dueDate).format('L')} `}
                  paymentDate={`${moment(item.paymentDate).format('L')} `}
                  invoiceStatusText={`${capitalizeFirstLetter(item.status)}`}
                  idBilling={item.id_billing}
                  idInvoice={item.invoices_billings}
                  invoicesBillings={item.invoices_billings}
                  invoicesStatus={item.status}
                  idTypePay={item.id_typePay}
                  creditCardNumber={item.creditCardNumber}
                  description={item.description}
                  hasCompoBilling={item?.hasCompoBilling}
                />
              );
            })}
          </S.ElementWrapper>
        </S.Wrapper>
      </BaseMain>
    </>
  );
};
