import styled, { css } from 'styled-components';

export const Overlay = styled.div`
  ${() => css`
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    width: 100vw;
    height: 100vh;
    inset: 0;
    z-index: 999999999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    min-width: 60rem;
    border-radius: ${theme.border.radius};
    padding: 3.2rem;
    background: ${theme.colors.neutral.neutral0};

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    p {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.neutral.neutral100};
      font-weight: 600;
    }

    img {
      cursor: pointer;
    }

    .wrapperTitle {
      display: flex;
      justify-content: space-between;
    }

    span {
      display: inline-block;
      color: ${theme.colors.neutral.neutral70};
      font-size: ${theme.font.sizes.medium};
      margin-top: 2.4rem;
    }
  `}
`;
