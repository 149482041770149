import { Heading } from 'components/Heading';
import { Price } from '../Price';
import * as S from './styles';

import { PriceProps } from '../Price/index';

type CardProps = {
  title: string;
  description: string;
  id: string;
  icon: React.ReactNode;
  children: React.ReactNode;
  className: string;
  handleClick: () => void;
} & PriceProps;

export const Card = ({
  children,
  description,
  handleClick,
  icon,
  id,
  title,
  className,
  ...PriceProps
}: CardProps) => {
  return (
    <S.Wrapper className={className} id={id} onClick={handleClick}>
      <S.WrapperHeader>
        <Heading title={title} weight={'600'} size="large" color="#1A1A1A" />
        {icon}
      </S.WrapperHeader>
      <S.Description>{description}</S.Description>
      <S.Benefit>{children}</S.Benefit>
      <Price {...PriceProps} />
    </S.Wrapper>
  );
};
