import { BackButton } from 'components/BackButton';
import { BaseContainer } from 'components/BaseContainer';
import Button from 'components/Button';
import { DataDetails } from 'components/DataDetails';
import { Heading } from 'components/Heading';
import {
  ArrowLeft,
  Check,
  Contracts,
  MetricsScreen,
  PlusContracts,
  QuestionCircle,
  SalesModule,
  SmartTickets,
  ToolTipIcon,
  TransferManagement,
  Apps,
  DocumentEndnote,
  CircleWavyCheck,
} from 'components/Icons';
import { capitalizeFirstLetter, formattedValue } from 'helpers/util';
import { signaturesDataProps } from 'pages/ClienteArea';
import { DataProps, PlanDetailsValuesProps } from 'pages/PlanDetails';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { api } from 'services/api';
import { Loading } from 'components/Loading';
import * as Dialog from '@radix-ui/react-dialog';
import * as S from './style';

import moment from 'moment';
import 'moment/locale/pt-br';

import { ToggleButton } from '../../pages/ChangeOfPlan/components/ToggleButton';
import { CounterButton } from '../../pages/ChangeOfPlan/components/CounterBotton';
import { Line } from 'components/Line';
import { Card } from './components/Card';
import { Benefit } from './components/Benefit';
import { MainHeader } from 'components/MainHeader';
import { BaseMain } from 'components/BaseMain';
import { PlanSummaryModal } from 'components/PlanSummaryModal';
import { PlanCustomContext } from 'contexts/PlanCustomContext';
import { Banner } from 'components/Banner';
import { PaymentOptionsDetais } from 'components/PaymentOptionsDetails';

type PremiumProps = {
  plan: {
    name: string;
  };
  finalValue: string;
  frequency: string;
  terminationDate: string;
  totalCost: string;
};

type PlanValuesProps = {
  cost: string;
  frequency: string;
};

type ContractsProps = {
  qtProductMax: string;
  limit: number;
};

export const RenewPlan = () => {
  const [signaturesData, setSignaturesData] = useState<signaturesDataProps>();
  const [signatures, setSignatures] = useState<DataProps>();
  const [signaturesValues, setSignaturesValues] =
    useState<PlanDetailsValuesProps>();
  const [isLoading, setIsLoading] = useState(false);
  const [countInput, setCountInput] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [premiumPlan, setPremiumPlan] = useState<PremiumProps[]>([]);
  const [isIcon, setIsIcon] = useState(0);
  const [planValue, setPlanValue] = useState<PlanValuesProps[]>([]);
  const [contracts, setContracts] = useState<ContractsProps[]>([]);
  const { id } = useParams<{ id: string }>();

  const accessToken = localStorage.getItem('accessToken');
  const idToken = localStorage.getItem('idToken');

  const {
    amount,
    balance,
    open,
    plan,
    paymentOptions,
    newTotalPlan,
    handleSetTypeProduct,
    handleCloseMoldal,
    frequency,
    getAmountInstallment,
    isFrequencyToggled,
    handleFrequency,
    handleSetCount,
    handleSetBalance,
    handleNewPaymentOptions,
    handleFrequencyAutomatic,
    handleSetAmount,
    handleSetIsFrequencyToggled,
    handleGetAmountInstallment,
  } = useContext(PlanCustomContext);

  useEffect(() => {
    const newCalculatePayment = () => {
      const total =
        plan[0]?.frequency === 'anual'
          ? Number(plan[0]?.costTotalPlan) - amount
          : Number(plan[0]?.costTotalPlan);

      const newPayment = paymentOptions.map((item) => {
        const totalTest = Number(total) / item.amountInstallment;
        return {
          amountInstallment: item.amountInstallment,
          valueInstallment: totalTest,
        };
      });
      handleNewPaymentOptions(newPayment);
      handleGetAmountInstallment(newPayment[0]);
    };
    newCalculatePayment();
  }, [
    amount,
    paymentOptions,
    plan,
    handleNewPaymentOptions,
    handleGetAmountInstallment,
  ]);

  const handleIcon = useCallback(
    (index: number, value?: number) => {
      setIsIcon(index);
      if (index === 1) {
        handleSetCount(50);
        setCountInput(0);
      } else if (index === 2) {
        handleSetCount(200);
        setCountInput(0);
      } else {
        if (!value) {
          handleSetCount(400);
        } else {
          const newValue: number = Math.ceil(value / 100) * 100;
          let calcContracts = newValue - 400;
          calcContracts = calcContracts <= 0 ? 0 : calcContracts;
          setCountInput(calcContracts);
          handleSetCount(newValue);
        }
      }
    },
    [handleSetCount],
  );

  const selectCard = useCallback((value: number) => {
    if (value <= 50) {
      return 1;
    } else if (value <= 200) {
      return 2;
    } else {
      return 3;
    }
  }, []);

  const handleCountDecrease = () => {
    if (countInput <= 0) return;
    const newCountInput = countInput - 100;
    setCountInput(newCountInput);

    handleSetCount(newCountInput + 400);
  };

  const handleCountIncrease = () => {
    const newCountInput = countInput + 100;
    const maxContracts = newCountInput + 400;
    if (maxContracts > contracts[0].limit) return;
    setCountInput(newCountInput);
    handleSetCount(newCountInput + 400);
  };

  useEffect(() => {
    const fetchDataPlan = async () => {
      try {
        const { data } = await api.get(`client/signatures/${id}`, {
          headers: {
            'content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            idToken,
          },
        });
        handleFrequencyAutomatic(
          data.data.frequency ? data.data.frequency : 'mensal',
        );
        setSignaturesData(data.data.plan);
        setSignatures(data.data);
        setSignaturesValues(data.data);
        data.data && data.data?.frequency === 'mensal'
          ? handleSetIsFrequencyToggled(false)
          : handleSetIsFrequencyToggled(true);
        handleSetTypeProduct('locacao');
        setIsLoading(true);
        const selectedCard = selectCard(
          Number(data.data?.totalLeasingContractCount) > 0
            ? Number(data.data?.totalLeasingContractCount)
            : 0,
        );
        handleIcon(selectedCard, Number(data.data.totalLeasingContractCount));
      } catch (error) {
        console.log(error);
      }
    };
    fetchDataPlan();
  }, [
    id,
    accessToken,
    idToken,
    handleSetTypeProduct,
    handleFrequencyAutomatic,
    handleSetIsFrequencyToggled,
    handleIcon,
    selectCard,
  ]);

  useEffect(() => {
    const fetchDataPlan = async () => {
      try {
        const { data } = await api.post(
          '/plan/available/locacao',
          {
            frequency: `${frequency}`,
          },
          {
            headers: {
              'content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
              idToken,
            },
          },
        );

        setPlanValue(data.data);
        setContracts(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDataPlan();
  }, [frequency, accessToken, idToken]);

  useEffect(() => {
    const fetchDataPlan = async () => {
      try {
        const { data } = await api.get('client/signatures/site', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            idToken,
          },
        });
        setPremiumPlan(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDataPlan();
  }, [accessToken, idToken]);

  useEffect(() => {
    const fetchDataMigrationBalance = async () => {
      try {
        const { data } = await api.get(
          `client/signatures/migrationbalance/${id}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
              idToken,
            },
          },
        );
        handleSetBalance(data.data);
        handleSetAmount(data.data.amount);
      } catch (error) {
        console.log(error);
      }
    };
    if (
      signatures?.frequency == 'anual' &&
      (signatures?.typeProduct == 'imob' ||
        signatures?.typeProduct == 'locacao')
    )
      fetchDataMigrationBalance();
  }, [id, accessToken, idToken, signatures, handleSetAmount, handleSetBalance]);

  useEffect(() => {
    const valueSignature = parseFloat(signaturesValues?.finalValue as string);
    const valueSitePremium = premiumPlan.reduce(
      (ac, item) => (ac + item.finalValue ? parseFloat(item.finalValue) : 0),
      0,
    );
    const total = valueSignature + valueSitePremium;
    setTotalValue(total);
  }, [signaturesValues, premiumPlan]);

  return (
    <>
      <MainHeader />
      {!isLoading && <Loading />}
      <BaseMain>
        <S.HeaderWrapper>
          <Link to={`/PlanDetails/${id}`}>
            <BackButton>
              <ArrowLeft />
            </BackButton>
          </Link>
          <BaseContainer>
            <Heading title="Trocar de plano" weight="600" />
            <S.ButtonsWrapper>
              <Button
                as="a"
                href="https://suporte.kenlo.com.br/pt-BR/collections/3795647-kenlo-area-do-cliente"
                target="blank"
                icon={<QuestionCircle />}
              >
                Ajuda
              </Button>
            </S.ButtonsWrapper>
          </BaseContainer>
        </S.HeaderWrapper>
        <S.BodyContentWrapper>
          <S.WrapperAside>
            <S.Aside>
              <Heading title="Plano atual" weight="700" />
              <S.TitleWrapper key={signaturesData?.id_plan}>
                <Heading
                  title={signaturesData?.name}
                  weight="600"
                  size="large"
                  color="#4C4C4C"
                />
              </S.TitleWrapper>
              <S.WrapperDataContent key={signatures?.id_client}>
                <DataDetails
                  color="neutral70"
                  uppercase={false}
                  row={true}
                  title="Contratos"
                  subtitle={
                    signatures?.totalLeasingContractCount
                      ? Number(signatures?.totalLeasingContractCount)
                      : 0
                  }
                />

                <DataDetails
                  color="neutral70"
                  uppercase={false}
                  row={true}
                  title="Frequência"
                  subtitle={`
                        ${
                          signatures?.frequency
                            ? capitalizeFirstLetter(signatures.frequency)
                            : null
                        }
                      `}
                />
                {signatures?.frequency === 'anual' ? (
                  <DataDetails
                    color="neutral70"
                    uppercase={false}
                    row={true}
                    title="Fim da vigência"
                    subtitle={`${moment(signatures?.terminationDate).format(
                      'L',
                    )}`}
                  />
                ) : (
                  <DataDetails
                    color="neutral70"
                    uppercase={false}
                    row={true}
                    title="Data de renovação"
                    subtitle={`${moment(signatures?.terminationDate).format(
                      'L',
                    )}`}
                  />
                )}
                <DataDetails
                  color="neutral70"
                  uppercase={false}
                  row={true}
                  title="Valor do Plano"
                  subtitle={`${formattedValue(signatures?.finalValue)}`}
                />
              </S.WrapperDataContent>
              <br />
              <Line border="0.0625rem" />
              <br />
              <DataDetails
                color="neutral70"
                uppercase={false}
                row={true}
                title="Valor total"
                subtitle={`${formattedValue(totalValue)}`}
              />
            </S.Aside>
          </S.WrapperAside>
          <S.WrapperMain>
            <S.ListValueWrapper>
              <S.WrapperTitleNewPlan>
                <Heading title="Novo plano" weight="700" />
                <S.WrapperQuantity>
                  <ToggleButton
                    frequency={() => handleFrequency()}
                    isToggled={isFrequencyToggled}
                  />
                </S.WrapperQuantity>
              </S.WrapperTitleNewPlan>
              <S.WrapperBenefit>
                <Card
                  className={isIcon === 1 ? 'active' : ''}
                  id="1"
                  title="Locação Econômico"
                  description="Atende principalmente corretores autônomos."
                  descriptionPrice="A partir de"
                  price={`${formattedValue(planValue[0]?.cost)}`}
                  frequency={frequency}
                  handleClick={() => handleIcon(1)}
                  icon={isIcon === 1 ? <Check /> : ''}
                >
                  <Benefit description="50 contratos" icon={<Contracts />} />
                  <Benefit
                    description="Boletos inteligentes"
                    icon={<SmartTickets />}
                  />
                  <Benefit
                    description="Gestão de repasses"
                    icon={<TransferManagement />}
                  />
                </Card>
                <Card
                  className={isIcon === 2 ? 'active' : ''}
                  id="2"
                  title="Locação Essencial"
                  description="Para imobiliárias de pequeno e médio porte."
                  descriptionPrice="A partir de"
                  price={
                    frequency === 'mensal'
                      ? `${formattedValue(planValue[1]?.cost)}`
                      : `${formattedValue(planValue[1]?.cost)}`
                  }
                  frequency={frequency}
                  handleClick={() => handleIcon(2)}
                  icon={isIcon === 2 ? <Check /> : ''}
                >
                  <Benefit description="200 contratos" icon={<Contracts />} />
                  <Benefit
                    description="Tela de métricas"
                    icon={<MetricsScreen />}
                  />
                  <Benefit
                    description="Nota fiscal integrada"
                    icon={<DocumentEndnote />}
                  />
                </Card>
                <Card
                  className={isIcon === 3 ? 'active' : ''}
                  id="3"
                  title="Locação Enterprise"
                  description="Focado em grandes imobiliárias e parceiros."
                  descriptionPrice="A partir de"
                  price={`${formattedValue(planValue[2]?.cost)}`}
                  frequency={frequency}
                  handleClick={() => handleIcon(3)}
                  icon={isIcon === 3 ? <Check /> : ''}
                >
                  <Benefit description="400 contratos" icon={<Contracts />} />
                  <Benefit
                    description="Módulo de vendas"
                    icon={<SalesModule />}
                  />
                  <Benefit
                    description="Aplicativo de vistoria"
                    icon={<Apps />}
                  />
                </Card>
              </S.WrapperBenefit>
            </S.ListValueWrapper>
            {isIcon === 3 && (
              <S.BenefitWrapper>
                <S.WrapperQuantityAndFrequency>
                  <S.WrapperQuantity>
                    <CounterButton
                      width="13.8rem"
                      title="Contratos adicionais"
                      count={countInput}
                      inCount={() => handleCountIncrease()}
                      decCount={() => handleCountDecrease()}
                    />
                  </S.WrapperQuantity>
                  <S.WrapperContracts>
                    <Benefit
                      description={`${contracts[2]?.qtProductMax + countInput}
                      contratos no seu combo`}
                      icon={<PlusContracts />}
                    />
                  </S.WrapperContracts>
                </S.WrapperQuantityAndFrequency>
              </S.BenefitWrapper>
            )}

            {balance?.signatureBalance &&
              balance?.signatureBalance &&
              Number(balance?.amount) > 0 && (
                <S.WrapperBanner>
                  <Banner
                    icon={<CircleWavyCheck />}
                    text={`Saldo de antecipação da renovação do plano ${
                      signaturesData?.name
                    } no valor de ${formattedValue(balance?.amount)}.`}
                  />
                </S.WrapperBanner>
              )}

            <S.BenefitWrapper>
              <S.WrapperFooter>
                <div>
                  <S.WrapperPayment>
                    <span>Pagamento</span>
                    <ToolTipIcon />
                  </S.WrapperPayment>
                  <S.WrapperPaymentTotal>
                    {/* <h2>{`${formattedValue(newTotalPlan)}`}</h2>
                    <span>No boleto mensal</span> */}
                    {!!getAmountInstallment && (
                      <>
                        {frequency === 'anual' ? (
                          <>
                            <h2>{`${formattedValue(
                              newTotalPlan - amount,
                            )}`}</h2>
                            {getAmountInstallment.amountInstallment > 1 ? (
                              <span>{`Em ${getAmountInstallment.amountInstallment}x no boleto`}</span>
                            ) : (
                              <span>À vista no boleto</span>
                            )}
                          </>
                        ) : (
                          <>
                            <h2>{`${formattedValue(newTotalPlan)}`}</h2>
                            <span>No boleto mensal</span>
                          </>
                        )}
                      </>
                    )}
                    <Dialog.Root open={open} onOpenChange={handleCloseMoldal}>
                      <Dialog.Trigger asChild>
                        {frequency === 'anual' && (
                          <Button className="buttonOpcao">
                            Opções de pagamento
                          </Button>
                        )}
                      </Dialog.Trigger>

                      <PaymentOptionsDetais />
                    </Dialog.Root>
                  </S.WrapperPaymentTotal>
                </div>
                <Dialog.Root>
                  <Dialog.Trigger asChild>
                    <Button minimal={false} size="medium" height="5.6rem">
                      Confirmar valores
                    </Button>
                  </Dialog.Trigger>
                  <PlanSummaryModal
                    id={Number(id)}
                    planId={plan[0]?.id_plan}
                    name={`Locação ${plan[0]?.nickname}`}
                    frequency={plan[0]?.frequency}
                    amountProductPlan={plan[0]?.qtProductMax}
                    terminationDate={`${moment(moment()).format('DD/MM/YYYY')}`}
                    costTotalPlan={plan[0]?.costTotalPlan}
                    migrationBalance={amount}
                  />
                </Dialog.Root>
              </S.WrapperFooter>
            </S.BenefitWrapper>
          </S.WrapperMain>
        </S.BodyContentWrapper>
      </BaseMain>
    </>
  );
};
