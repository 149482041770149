import { createContext, useCallback, useState } from 'react';
import { api } from 'services/api';

type ChildrenProps = {
  children: React.ReactNode;
};

type dataClientInfoProps = {
  daysToTerminateOffer: number | null;
  id_client_info?: string;
  id_client?: string;
  id_realstate?: string | undefined;
  dgLeadsActivationDate?: string;
  lastProcess?: string;
  useQualifier?: boolean;
  howOldQualifier?: string;
};

type ClientInfoContextType = {
  dataClientInfo?: dataClientInfoProps;
  setDataClientInfo: (dataClientInfo?: dataClientInfoProps) => void;
  fetchDataClientInfo: (idClient: string) => void;
  setClearDataClientInfo: () => void;
};

export const ClientInfoContext = createContext({} as ClientInfoContextType);

export const ClientInfoProvider = ({ children }: ChildrenProps) => {
  const [dataClientInfo, setDataClientInfo] = useState<
    dataClientInfoProps | undefined
  >();

  const accessToken = localStorage.getItem('accessToken');
  const idToken = localStorage.getItem('idToken');

  const setClearDataClientInfo = () => {
    setDataClientInfo(undefined);
  };

  const fetchDataClientInfo = useCallback(
    async (idClient) => {
      try {
        const { data } = await api.get(`client/client-info/${idClient}`, {
          headers: {
            'content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            idToken,
          },
        });
        setDataClientInfo(data.data);
      } catch (error) {
        console.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <ClientInfoContext.Provider
      value={{
        dataClientInfo,
        setDataClientInfo,
        fetchDataClientInfo,
        setClearDataClientInfo,
      }}
    >
      {children}
    </ClientInfoContext.Provider>
  );
};
