import React, { createContext, useState, useEffect, useCallback } from 'react';
import { api } from 'services/api';

type PlanProps = {
  name: string;
  nickname: string;
  qtUserAdditional: number;
  qtUserPlan: string;
  qtProductMax: number;
  costTotalPlan: string;
  costMonthlyPlan: string;
  savecost: string;
  costUserAdditionalSingle?: number;
  frequency: string;
  product: string;
  id_plan: number;
};

export type PaymentOptionsProps = {
  amountInstallment: number;
  valueInstallment: number;
};

type ChildrenProps = {
  children: React.ReactNode;
};

type GetAmountInstallmentProps = {
  amountInstallment: number;
  valueInstallment: number;
};

type BalanceTypeProps = {
  amountSignature: string;
  amountPaid: number;
  usedDays: number;
  balanceMigration: number;
};

type BalanceProps = {
  amount: number | undefined;
  signatureBalance: BalanceTypeProps;
  sitePremiumBalance: BalanceTypeProps;
};

type PlanCustomContextType = {
  plan: PlanProps[];
  paymentOptions: PaymentOptionsProps[];
  newPaymentOptions: PaymentOptionsProps[];
  frequency: string;
  count: number;
  getAmountInstallment: GetAmountInstallmentProps | null;
  open: boolean;
  newTotalPlan: number;
  isFrequencyToggled: boolean;
  amount: any;
  balance: any;
  typeProduct: string;
  handleCountDecrease: () => void;
  handleCountIncrease: () => void;
  handleFrequency: () => void;
  getTotalUserCount: (number: number) => void;
  handleGetAmountInstallment: (value: GetAmountInstallmentProps) => void;
  handleCloseMoldal: (value: boolean) => void;
  handleNewPaymentOptions: (data: any) => void;
  handleNewTotalPlan: (value: number) => void;
  handleFrequencyAutomatic: (value: string) => void;
  handleSetIsFrequencyToggled: (value: boolean) => void;
  handleSetTypeProduct: (value: string) => void;
  handleSetIdSignature: (value: number) => void;
  handleSetAmount: (value: number) => void;
  handleSetCount: (value: number) => void;
  handleSetBalance: (value: any) => void;
};

export const PlanCustomContext = createContext({} as PlanCustomContextType);

export const PlanCustomProvider = ({ children }: ChildrenProps) => {
  const [plan, setPlan] = useState<PlanProps[]>([]);
  const [paymentOptions, setPaymentOptions] = useState<PaymentOptionsProps[]>(
    [],
  );
  const [newPaymentOptions, setNewPaymentOptions] = useState<
    PaymentOptionsProps[]
  >([]);
  const [frequency, setFrequency] = useState('mensal');
  const [isFrequencyToggled, setIsFrequencyToggled] = useState(false);
  const [count, setCount] = useState(0);
  const [getAmountInstallment, setGetAmountInstallment] =
    useState<GetAmountInstallmentProps | null>(null);

  const [newTotalPlan, setNewTotalPlan] = useState(0);
  const [open, setOpen] = useState(false);
  const [typeProduct, setTypeProduct] = useState('');
  const [idSignature, setIdSignature] = useState(0);
  const [amount, setAmount] = useState<BalanceProps | null>(null);
  const [balance, setBalance] = useState<BalanceProps | null>(null);

  const accessToken = localStorage.getItem('accessToken');
  const idToken = localStorage.getItem('idToken');

  const handleCountDecrease = () => {
    if (count <= 2) return;
    setCount(count - 1);
  };

  const handleCountIncrease = () => {
    setCount(count + 1);
  };

  count === 1 ? setCount(count + 1) : 1;

  const handleFrequency = () => {
    frequency === 'mensal' ? setFrequency('anual') : setFrequency('mensal');
    setIsFrequencyToggled(isFrequencyToggled ? false : true);
  };

  const handleFrequencyAutomatic = useCallback((value: string) => {
    setFrequency(value);
  }, []);

  const handleSetIsFrequencyToggled = useCallback((value: boolean) => {
    setIsFrequencyToggled(value);
  }, []);

  const getTotalUserCount = useCallback((number: number) => {
    setCount(number);
  }, []);

  const handleGetAmountInstallment = useCallback(
    (value: GetAmountInstallmentProps) => {
      setGetAmountInstallment(value);
    },
    [],
  );

  const handleCloseMoldal = (value: boolean) => {
    setOpen(value);
  };

  const handleNewPaymentOptions = useCallback((data: any) => {
    setNewPaymentOptions(data);
  }, []);

  const handleNewTotalPlan = useCallback((value: number) => {
    setNewTotalPlan(value);
  }, []);

  const handleSetTypeProduct = useCallback((value: string) => {
    setTypeProduct(value);
  }, []);

  const handleSetIdSignature = useCallback((value: number) => {
    setIdSignature(value);
  }, []);

  const handleSetAmount = useCallback((value: any) => {
    setAmount(value);
  }, []);

  const handleSetCount = useCallback((value: number) => {
    setCount(value);
  }, []);

  const handleSetBalance = useCallback((value: any) => {
    setBalance(value);
  }, []);

  useEffect(() => {
    const fetchDataPlan = async () => {
      try {
        const { data } = await api.post(
          'plan/custom',
          {
            frequency: `${frequency}`,
            product: typeProduct,
            qt_product: `${count}`,
          },
          {
            headers: {
              'content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
              idToken,
            },
          },
        );
        setPlan(data.data);

        setNewTotalPlan(data.data[0].costTotalPlan);
        setPaymentOptions(data.data[0].paymentOptions[0].options);
      } catch (error) {
        console.log(error);
      }
    };
    if (idSignature != undefined && typeProduct != '' && count > 0) {
      fetchDataPlan();
    }
  }, [count, frequency, accessToken, idToken, typeProduct, idSignature]);

  return (
    <PlanCustomContext.Provider
      value={{
        amount,
        count,
        frequency,
        paymentOptions,
        plan,
        balance,
        getTotalUserCount,
        getAmountInstallment,
        open,
        isFrequencyToggled,
        newPaymentOptions,
        newTotalPlan,
        typeProduct,
        handleCountDecrease,
        handleCountIncrease,
        handleFrequency,
        handleGetAmountInstallment,
        handleSetIsFrequencyToggled,
        handleSetIdSignature,
        handleCloseMoldal,
        handleNewPaymentOptions,
        handleFrequencyAutomatic,
        handleSetBalance,
        handleSetTypeProduct,
        handleNewTotalPlan,
        handleSetAmount,
        handleSetCount,
      }}
    >
      {children}
    </PlanCustomContext.Provider>
  );
};
