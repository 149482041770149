//import { ShoppingCart } from 'styled-icons';
import * as S from './styles';

type UserIconProps = {
  username?: string;
  quantity?: number;
};

export const UserIcon = ({ quantity = 0, username }: UserIconProps) => (
  <S.Wrapper>
    <img src={username} />
    {quantity === 0 && <S.Badge aria-label="user items">{quantity}</S.Badge>}
    {/* {<S.WrapperName aria-label="user name">{username}</S.WrapperName>} */}
  </S.Wrapper>
);
