import * as S from './styles';

export type LineProps = {
  width?: string;
  border?: string;
};

export const Line = ({ border, width = '4rem' }: LineProps) => {
  return <S.WrapperLine border={border} width={width} />;
};
