import { Radio } from './compoents/Radio';
import * as Dialog from '@radix-ui/react-dialog';
import * as S from './styles';
import { Close } from 'components/Icons';
import { formattedValue } from 'helpers/util';
import { FormEvent, useContext, useState } from 'react';
import { PlanCustomContext } from 'contexts/PlanCustomContext';

export const PaymentOptionsDetais = () => {
  const [paymentOptionsValue, setPaymentOptionsValue] = useState('');
  const { newPaymentOptions, handleGetAmountInstallment, handleCloseMoldal } =
    useContext(PlanCustomContext);

  const wait = () => new Promise((resolver) => setTimeout(resolver, 150));

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    const selected = newPaymentOptions.filter(
      (item) => item.amountInstallment === Number(paymentOptionsValue),
    );

    handleGetAmountInstallment(selected[0]);
    wait().then(() => handleCloseMoldal(false));
    setPaymentOptionsValue('');
  }

  return (
    <Dialog.Portal>
      <S.Overlay />
      <S.Content>
        <S.Title>Opções de pagamento</S.Title>
        <S.CloseButton>
          <Close />
        </S.CloseButton>
        <S.Container>
          <S.Wrapper>
            <S.BackgroundRadio>
              <Radio
                label="Boleto"
                labelFor="boleto"
                name="nome"
                defaultChecked
              />
            </S.BackgroundRadio>
            {/* <S.BackgroundRadio>
              <Radio label="Cartão de crédito" labelFor="cartao" name="nome" />
            </S.BackgroundRadio> */}
          </S.Wrapper>

          <span
            style={{
              fontFamily: 'Manrope',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '24px',
            }}
          >
            Você pode parcelar o valor do plano.
          </span>

          <form onSubmit={handleSubmit}>
            {/* <div
              className="form-floating"
              style={{
                flexDirection: 'row',
                borderColor: 'white',
                width: '100%',
                display: 'flex',
                fontWeight: 'normal',
                paddingTop: '0',
              }}
            >
              <S.WrapperSelect>
                <option value="">Parcelas</option>
                {newPaymentOptions.map((item) => {
                  console.log('ITEM', item);
                  return (
                    <option
                      key={item.amountInstallment}
                      value={item.amountInstallment}
                    >
                      {`${item.amountInstallment}x ${formattedValue(
                        item.valueInstallment,
                      )} no boleto`}
                    </option>
                  );
                })}
              </S.WrapperSelect>
            </div> */}

            <div
              className="form-floating"
              style={{
                flexDirection: 'row',
                borderColor: 'white',
                width: '100%',
                display: 'flex',
                fontWeight: 'normal',
                paddingTop: '0',
              }}
            >
              <S.WrapperSelect
                className="form-select"
                id="floatingSelect"
                value={paymentOptionsValue}
                onChange={(e) => setPaymentOptionsValue(e.target.value)}
              >
                {newPaymentOptions.map((item) => {
                  return (
                    <option
                      key={item.amountInstallment}
                      value={item.amountInstallment}
                    >
                      {`${item.amountInstallment}x ${formattedValue(
                        item.valueInstallment,
                      )} no boleto`}
                    </option>
                  );
                })}
              </S.WrapperSelect>

              <label
                htmlFor="floatingSelect"
                style={{
                  height: '16px',
                  width: '49px',
                  fontFamily: 'Manrope',
                  fontSize: '14px',
                  lineHeight: '16px',
                  fontWeight: 'normal',
                  padding: '1.3rem 1.3rem',
                }}
              >
                Parcelas
              </label>
            </div>
            <S.FooterButton>
              <>
                <Dialog.Close className="cancelar">Cancelar</Dialog.Close>

                <button className="salvar" type="submit">
                  Salvar opção
                </button>
              </>
            </S.FooterButton>
          </form>

          {/* <S.Wrapper>
            <TextField placeholder="Número do cartão" />
            <TextField placeholder="Nome no cartão" />
          </S.Wrapper>
          <S.Wrapper>
            <TextField placeholder="Data de expiração" />
            <TextField placeholder="CVV" />
            <TextField placeholder="CPF do titular" />
          </S.Wrapper>
          <S.Wrapper>
            <TextField placeholder="Parcelas" />
          </S.Wrapper> */}
        </S.Container>
      </S.Content>
    </Dialog.Portal>
  );
};
