const ArrowTrendingDown = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3985 17.2L19.4416 17.1996L19.5218 17.1885L19.6008 17.1664L19.649 17.1459C19.7124 17.118 19.7718 17.0775 19.824 17.0253L19.8566 16.9901L19.8914 16.945L19.9347 16.8729L19.9657 16.8006L19.981 16.7494L19.9919 16.6982L19.9994 16.6228L19.9997 10.5969C19.9997 10.2655 19.7311 9.99689 19.3997 9.99689C19.096 9.99689 18.845 10.2226 18.8052 10.5155L18.7997 10.5969L18.7994 15.1528L12.6243 8.9766C12.4113 8.76358 12.078 8.7442 11.8431 8.91848L11.7758 8.97658L9.3994 11.3529L5.02365 6.97713C4.78934 6.74281 4.40944 6.74281 4.17513 6.97713C3.96211 7.19014 3.94275 7.52347 4.11703 7.75836L4.17513 7.82566L8.97513 12.6257C9.18814 12.8387 9.52146 12.858 9.75635 12.6838L9.82365 12.6257L12.2 10.2494L17.9498 16H13.3985C13.0948 16 12.8437 16.2257 12.804 16.5186L12.7985 16.6C12.7985 16.9038 13.0242 17.1548 13.3171 17.1945L13.3985 17.2Z"
        fill="#DD442C"
      />
    </svg>
  );
};
export default ArrowTrendingDown;
