/* eslint-disable react/jsx-no-target-blank */
import * as Dialog from '@radix-ui/react-dialog';
import * as S from './styles';
import { BoxContent } from 'components/BoxContent';
import Button from 'components/Button';
import { useState } from 'react';
import { BaseModal } from '../BaseModal';
import { theme } from 'styles/theme';
import { ToolTip } from 'components/ToolTip';
import {
  CaretDoubleUp,
  CaretUp,
  HexagonMinus,
  ToolTipIcon,
} from 'components/Icons';
import { indicatorsProps } from 'pages/QualifierDetails';
import moment from 'moment';
import { formattedValue } from 'helpers/util';

interface IModalProps {
  el?: any;
  value?: indicatorsProps[];
  potential?: string;
  label?: string;
}

export const PerformaceDetail = ({ el }: IModalProps) => {
  const [showDetails, setShowDetails] = useState(false);

  function capitalize(str: any): string {
    if (typeof str === 'string') {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return '';
    }
  }
  const getPotential = () => {
    return (
      <>
        <BoxContent
          color={theme.colors.neutral.neutral10}
          style={{ padding: '1.6rem 2.4rem 1.6rem 2.4rem' }}
        >
          <S.Row>
            <S.Qualify>
              <S.Title color={theme.colors.neutral.neutral120}>
                {`${el?.tot_leads_qualificados_mes} clientes qualificados esse mês`}
              </S.Title>
            </S.Qualify>
          </S.Row>
          <S.RowLeads>
            <S.WrapperLeads>
              <S.Title color={theme.colors.neutral.neutral120}>
                <S.Leads>
                  <CaretDoubleUp />
                  <div>
                    <p>{el?.qtd_leads_qualificados_mes_alto}</p>
                    {el?.qtd_leads_qualificados_mes_alto > 1 ? (
                      <span>Leads quentes</span>
                    ) : (
                      <span>Lead quente</span>
                    )}
                  </div>
                </S.Leads>
              </S.Title>
            </S.WrapperLeads>
            <S.WrapperLeads>
              <S.Title color={theme.colors.neutral.neutral120}>
                <S.Leads>
                  <CaretUp />
                  <div>
                    <p>{el?.qtd_leads_qualificados_mes_medio}</p>
                    {el?.qtd_leads_qualificados_mes_medio > 1 ? (
                      <span>Leads mornos</span>
                    ) : (
                      <span>Lead morno</span>
                    )}
                  </div>
                </S.Leads>
              </S.Title>
            </S.WrapperLeads>
            <S.WrapperLeads>
              <S.Title color={theme.colors.neutral.neutral120}>
                <S.Leads>
                  <HexagonMinus />
                  <div>
                    <p>{el?.qtd_leads_qualificados_mes_baixo}</p>
                    {el?.qtd_leads_qualificados_mes_baixo > 1 ? (
                      <span>Leads frios</span>
                    ) : (
                      <span>Lead frio</span>
                    )}
                  </div>
                </S.Leads>
              </S.Title>
            </S.WrapperLeads>
          </S.RowLeads>
        </BoxContent>
      </>
    );
  };

  const getService = () => {
    return (
      <BoxContent
        color={theme.colors.neutral.neutral10}
        style={{ padding: '1.6rem 2.4rem 1.6rem 2.4rem' }}
      >
        <S.Row>
          <S.Title color={theme.colors.primary50}>
            <a
              href="https://signin.valuegaia.com.br/?provider=imob"
              target="_blank"
              style={{ color: theme.colors.primary50, textDecoration: 'none' }}
            >
              Abrir área de atendimentos
            </a>
          </S.Title>
          <S.Subtitle color={theme.colors.neutral.neutral130}>
            Período
          </S.Subtitle>
        </S.Row>
        <S.Row>
          <S.Subtitle color={theme.colors.neutral.neutral40} alignItem="center">
            Acompanhe em tempo real as qualificações nesse mês.
          </S.Subtitle>
          <S.TextOne>
            <S.Title color={theme.colors.neutral.neutral120}>
              {`${capitalize(
                `${moment(`${el?.ano}-${el?.mes}-01`, [
                  'YYYY-M-DD',
                  'YYYY-MM-DD',
                ]).format('MMMM')} de ${el?.ano}`,
              )}`}
            </S.Title>
          </S.TextOne>
        </S.Row>
      </BoxContent>
    );
  };

  const getCommission = () => {
    return (
      <BoxContent
        color={theme.colors.neutral.neutral10}
        style={{ padding: '1.6rem 2.4rem 1.6rem 2.4rem' }}
      >
        <S.Row>
          <S.Title color={theme.colors.neutral.neutral120}>
            <ToolTip
              content="Somamos a comissão estimada de todos os clientes em atendimento."
              icon={
                <S.ToolTip>
                  <ToolTipIcon />
                </S.ToolTip>
              }
              text="Comissão total estimada"
            />
          </S.Title>
        </S.Row>
        <S.Row>
          <S.Commission>
            <S.Subtitle
              color={theme.colors.neutral.neutral140}
              lineHeight="16px"
              fontWeight={500}
            >
              Venda
            </S.Subtitle>
            <S.SubtitleCommission color={theme.colors.neutral.neutral120}>
              {formattedValue(
                el?.comissao_estimada_mes_atual_atendimentos_venda,
              )}
            </S.SubtitleCommission>
          </S.Commission>
          <S.Commission>
            <S.Subtitle
              color={theme.colors.neutral.neutral140}
              lineHeight="16px"
              fontWeight={500}
            >
              Locação
            </S.Subtitle>
            <S.SubtitleCommission color={theme.colors.neutral.neutral120}>
              {formattedValue(
                el?.comissao_estimada_mes_atual_atendimentos_locacao,
              )}
            </S.SubtitleCommission>
          </S.Commission>
        </S.Row>
      </BoxContent>
    );
  };
  return (
    <>
      <Dialog.Root>
        <Dialog.Trigger asChild id={el}>
          <Button onClick={() => setShowDetails(true)}>Ver detalhes</Button>
        </Dialog.Trigger>
        {showDetails && (
          <BaseModal title="Detalhe do desempenho" key={el}>
            {getService()}
            {getPotential()}
            {getCommission()}
          </BaseModal>
        )}
      </Dialog.Root>
    </>
  );
};
