import { useEffect, useRef, useState } from 'react';
import * as S from './styles';

export type DropdownProps = {
  element?: React.ReactNode;
  children: React.ReactNode;
  title?: boolean;
  height?: string;
  position?: string;
  width?: string;
};

export const Dropdown = ({
  height,
  element,
  children,
  title = true,
  position = '0',
  width,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const icon = useRef(null);

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handler = (event: any) => {
      if (isOpen && ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
    return () => {
      document.addEventListener('mousedown', handler);
      document.addEventListener('touchstart', handler);
    };
  });

  return (
    <S.Wrapper isOpen={isOpen} data-testid="dropdown" ref={ref}>
      <S.Element ref={icon} onClick={() => setIsOpen(!isOpen)}>
        {element}
      </S.Element>
      <S.Content
        aria-hidden={!isOpen}
        height={height}
        position={position}
        width={width}
      >
        {!!title && <S.Title>Produtos Kenlo</S.Title>}
        {children}
      </S.Content>
    </S.Wrapper>
  );
};
