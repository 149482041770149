const ToolTipIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 1.99896C17.5237 1.99896 22.0015 6.47681 22.0015 12.0005C22.0015 17.5242 17.5237 22.0021 12 22.0021C6.47626 22.0021 1.99841 17.5242 1.99841 12.0005C1.99841 6.47681 6.47626 1.99896 12 1.99896ZM12 3.49896C7.30469 3.49896 3.49841 7.30524 3.49841 12.0005C3.49841 16.6958 7.30469 20.5021 12 20.5021C16.6952 20.5021 20.5015 16.6958 20.5015 12.0005C20.5015 7.30524 16.6952 3.49896 12 3.49896ZM11.9963 10.4995C12.376 10.4993 12.69 10.7812 12.7399 11.1473L12.7468 11.2491L12.7504 16.7506C12.7507 17.1649 12.4151 17.5009 12.0009 17.5011C11.6212 17.5014 11.3072 17.2194 11.2573 16.8534L11.2504 16.7516L11.2468 11.25C11.2465 10.8358 11.5821 10.4998 11.9963 10.4995ZM12.0004 7.00178C12.552 7.00178 12.9991 7.4489 12.9991 8.00044C12.9991 8.55199 12.552 8.99911 12.0004 8.99911C11.4489 8.99911 11.0017 8.55199 11.0017 8.00044C11.0017 7.4489 11.4489 7.00178 12.0004 7.00178Z"
        fill="#404040"
      />
    </svg>
  );
};
export default ToolTipIcon;
