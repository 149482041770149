export const theme = {
  grid: {
    container: '136.6rem',
    gutter: '3.2rem',
  },
  border: {
    radius: '1.2rem',
    radiustwo: '0.75rem',
  },
  font: {
    family:
      "Manrope, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    normal: 500,
    semiBold: 600,
    bold: 700,
    sizes: {
      xsmall: '1.2rem',
      small: '1.4rem',
      medium: '1.6rem',
      large: '2rem',
      xlarge: '2.4rem',
      xxlarge: '3.2rem',
      huge: '5.2rem',
    },
  },
  colors: {
    primary5: '#FB8499',
    primary10: '#FA5673',
    primary30: '#E50029',
    primary50: '#F82E52',
    primary70: '#DC1840',
    primary100: '#AF031B',
    neutral: {
      neutral0: '#FFFFFF',
      neutral5: '#F2F2F2',
      neutral10: '#E5E5E5',
      neutral15: '#F5F5F5',
      neutral30: '#D8D8D8',
      neutral40: '#404040',
      neutral50: '#888888',
      neutral60: '#595959',
      neutral70: '#4C4C4C',
      neutral80: '#030202',
      neutral100: '#242424',
      neutral110: '#D32D3B',
      neutral120: '#1A1A1A',
      neutral130: '#808080',
      neutral140: '#404040',
      neutral150: '#D8EAF7',
      neutral160: '#0B74D0',
      neutral170: '#127CC9',
      neutral180: '#007FDB',
    },
    feedback: {
      feedbackError10: '#EF9AA6',
      feedbackError15: '#F2C6CA',
      feedbackError20: '#F1B4AB',
      feedbackError50: '#D32D3B',
      feedbackError60: '#DD442C',
      feedbackAlert70: '#FADDC3',
      feedbackSuccess10: '#C9EBDD',
      feedbackSuccess20: '#A7D6C2',
      feedbackSuccess50: '#4ABD8D',
      feedbackSuccess60: '#C0EBD9',
      feedbackAlert10: '#F0D3A9',
      feedbackAlert50: '#ED8E36',
    },
  },
  spacings: {
    xxsmall: '0.5rem',
    xsmall: '1rem',
    small: '1.6rem',
    medium: '2rem',
    large: '2.4rem',
    xlarge: '3rem',
    xxlarge: '3.5em',
  },
  breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50,
  },
  transition: {
    default: '0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    fast: '0.1s cubic-bezier(0.4, 0, 0.2, 1)',
  },
} as const;
