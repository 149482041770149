import * as S from './styles';

type AsideProps = {
  title: React.ReactNode;
  children: React.ReactNode;
};

export const BaseAside = ({ children, title }: AsideProps) => {
  return (
    <S.WrapperAside>
      <S.Title>{title}</S.Title>
      {children}
    </S.WrapperAside>
  );
};
