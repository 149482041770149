import * as S from './styles';
import * as Dialog from '@radix-ui/react-dialog';
import { ArrowsCounterClockwis, Close, TwoUsers } from 'components/Icons';
import { Line } from 'components/Line';
import Button from 'components/Button';
import { capitalizeFirstLetter, formattedValue } from 'helpers/util';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import 'moment/locale/pt-br';
import { PlanCustomContext } from 'contexts/PlanCustomContext';
import { api } from 'services/api';
import { MessageModal } from 'components/Invoice/components/MessageModal';
import { toast } from 'react-toastify';

const notifySuccess = () =>
  toast.success('Solicitação realizada com sucesso, aguarde contato...');
const notifyError = (msg: string) => toast.error(msg);

import close from 'assets/image/close.svg';

type signaturesDataProps = {
  name?: string | undefined;
  frequency: string | undefined;
  count?: number;
  terminationDate: number | string | undefined;
  totalCost?: number | string | undefined;
  discountValue?: number | string | undefined;
  costTotalPlan?: number | string;
  migrationBalance?: number | undefined;
  id: number | undefined;
  planId?: number | undefined;
  amountProductPlan?: number | undefined;
  amountProductExtra?: number | undefined;
  onClose?: () => void;
};

export const PlanSummaryModal = ({
  frequency,
  amountProductPlan,
  amountProductExtra,
  costTotalPlan,
  migrationBalance,
  id,
  planId,
  onClose,
  name,
}: signaturesDataProps) => {
  const amountUser = () => {
    if (plan[0]?.product === 'locacao') {
      return ' contratos selecionados ';
    } else {
      return amountProductPlan === 0 || amountProductPlan === 1
        ? ' usuário selecionado'
        : ' usuários selecionados';
    }
  };

  const { getAmountInstallment, plan, typeProduct } =
    useContext(PlanCustomContext);
  const [modalError, setModalError] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [isRequest, setIsRequest] = useState(false);

  const accessToken = localStorage.getItem('accessToken');
  const idToken = localStorage.getItem('idToken');
  const totalProduct = () => {
    return (
      Number(amountProductPlan) +
      Number(amountProductExtra ? amountProductExtra : 0)
    );
  };

  const dataChangePlan = async () => {
    try {
      setIsRequest(true);
      // eslint-disable-next-line
      const { data } = await api.post(
        'client/signatures/change',
        {
          id_signature: id,
          id_plan: planId,
          frequency: frequency,
          amountProductPlan: amountProductPlan,
          amountProductAddit: amountProductExtra,
          installmentAmount: getAmountInstallment?.amountInstallment,
          planValue: costTotalPlan,
          id_typePay: '1',
          discountValue: migrationBalance,
        },
        {
          headers: {
            'content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            idToken,
          },
        },
      );
    } catch (error: any) {
      console.error('Error request api change plan', error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        throw error.response.data.message;
      }
      throw 'Error on request api';
    } finally {
      setIsRequest(false);
    }
  };

  const history = useHistory();

  const redirectUserClientArea = async () => {
    try {
      await dataChangePlan();
      notifySuccess();
      setIsModal(false);
      history.push(`/PlanDetails/${id}`);
    } catch (e: any) {
      const text: string = e ? e : '';
      notifyError(text);
    }
  };

  const CloseButton = () => {
    return (
      <S.CloseButton onClick={onClose}>
        <Close />
      </S.CloseButton>
    );
  };

  return (
    <>
      {isModal && (
        <MessageModal
          img={close}
          text="Troca de plano efetuada com sucesso"
          description="Você será redirecido para próxima página"
          isOpen={modalError}
          setIsOpen={setModalError}
        />
      )}
      <Dialog.Portal>
        <S.Overlay />
        <S.Content>
          <S.Title>Resumo do plano</S.Title>
          <CloseButton />
          <S.PlanContent>
            <ul>
              <li>
                <div>
                  <h2>{name}</h2>
                  <p className="frequency">
                    {capitalizeFirstLetter(frequency ?? '')}
                  </p>
                </div>
              </li>
              <ul>
                <li>
                  <div>
                    <TwoUsers />
                    <span>
                      {totalProduct()}
                      {amountUser()}
                    </span>
                  </div>
                </li>
                <li>
                  <div>
                    <ArrowsCounterClockwis />
                    <span>
                      Renova em
                      {` ${moment(moment()).add(1, 'years').format('L')}`}
                    </span>
                  </div>
                </li>
              </ul>
            </ul>
          </S.PlanContent>
          <S.ValuesContent>
            <ul>
              <li>
                <p>Valor do plano</p>
                <span>{formattedValue(costTotalPlan)}</span>
              </li>
              {Number(migrationBalance) > 0 && (
                <li>
                  <p>Saldo de antecipação</p>
                  <span>{`- ${formattedValue(migrationBalance)}`}</span>
                </li>
              )}
              <li>
                <p>Descontos</p>
                <span>R$ 0,00</span>
              </li>
            </ul>
            <Line width="100%" border="1px" />
            <div>
              {frequency === 'anual' ? (
                <span>
                  {formattedValue(
                    Number(costTotalPlan) - Number(migrationBalance),
                  )}
                </span>
              ) : (
                <span>{formattedValue(Number(costTotalPlan))}</span>
              )}
              {!!getAmountInstallment && (
                <S.WrapperPaymentOptions>
                  {frequency === 'anual' && (
                    <>
                      <span>{`Em ${
                        getAmountInstallment.amountInstallment
                      }x ${formattedValue(
                        getAmountInstallment?.valueInstallment,
                      )} no boleto`}</span>
                    </>
                  )}
                  {frequency === 'mensal' && !migrationBalance && (
                    <span>no boleto mensal</span>
                  )}
                  {frequency === 'mensal' && Number(migrationBalance) > 0 && (
                    <span>
                      {`Ao fim do saldo, o valor será de ${formattedValue(
                        Number(costTotalPlan),
                      )} no boleto mensal`}
                    </span>
                  )}
                </S.WrapperPaymentOptions>
              )}
            </div>
          </S.ValuesContent>
          <S.Footer>
            <p>
              Ao trocar de plano você aceita os
              {typeProduct === 'locacao' ? (
                <a
                  href="https://blog.kenlo.com.br/docs/Kenlo-Locacao-Termo-de-Servico.pdf"
                  target="blank"
                >
                  termos de serviço
                </a>
              ) : (
                <a
                  href="https://blog.kenlo.com.br/docs/Kenlo-Imob-Termo-de-Servico.pdf"
                  target="blank"
                >
                  termos de serviço
                </a>
              )}
              dos produtos Kenlo.
            </p>
            <div>
              <Dialog.Close asChild>
                <Button
                  minimal={false}
                  border
                  height="5.6rem"
                  disabled={isRequest}
                >
                  Cancelar
                </Button>
              </Dialog.Close>
              <Button
                minimal={false}
                height="5.6rem"
                disabled={isRequest}
                onClick={() => {
                  redirectUserClientArea();
                }}
              >
                Troca de plano
              </Button>
            </div>
          </S.Footer>
        </S.Content>
      </Dialog.Portal>
    </>
  );
};
