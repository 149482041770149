import * as S from './styles';

type MessageTypePaymentProps = {
  icon: React.ReactElement;
  text: string;
  content?: string;
};

export const MessageTypePayment = ({
  content,
  icon,
  text,
}: MessageTypePaymentProps) => {
  return (
    <S.Wrapper role="tooltip" aria-label={content}>
      {icon}
      <p>{text}</p>
    </S.Wrapper>
  );
};
