import * as S from './styles';

type BannerProps = {
  icon: React.ReactNode;
  text: string;
  children?: React.ReactNode;
};

export const BannerPromotion = ({ icon, text, children }: BannerProps) => {
  return (
    <S.Wrapper>
      {icon}
      <div>
        <S.TextCustomized
          color="#105DA0"
          fontWeight={700}
          fontSize="16px"
          lineHeight="24px"
        >
          {text}
        </S.TextCustomized>
        <br />
        <S.TextCustomized
          color="#105DA0"
          fontWeight={500}
          fontSize="12px"
          lineHeight="16px"
        >
          {children}
        </S.TextCustomized>
      </div>
    </S.Wrapper>
  );
};
