import styled, { css } from 'styled-components';

import { DropdownProps } from '.';

export const Element = styled.div`
  ${() => css`
    cursor: pointer;
    display: flex;
    align-items: center;
  `}
`;

export const Content = styled.div<DropdownProps>`
  ${({ theme, height, position, width }) => css`
    display: flex;
    width: ${() => width};
    background: ${theme.colors.neutral.neutral0};
    box-shadow: 0 0 1rem rgba(36, 36, 36, 0.12);
    flex-direction: column;
    border-radius: 12px;
    position: absolute;
    top: ${height};
    right: ${position}rem;
    padding: 0 0 2.4rem 0;
  `}
`;

export const Title = styled.span`
  padding: 2rem 0.8rem 2.4rem 2.4rem;
  font-size: 1.2rem;
`;

type WrapperProps = {
  isOpen?: boolean;
};

const wrapperModifiers = {
  open: () => css`
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
    z-index: 5;
  `,
  close: () => css`
    opacity: 0;
    pointer-events: none;
    transform: translateY(-2rem);
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ isOpen }) => css`
    position: relative;

    ${Content} {
      transition: transform 0.2s ease-in, opacity 0.2s ease-in-out;
      ${isOpen && wrapperModifiers.open()}
      ${!isOpen && wrapperModifiers.close()}
    }
  `}
`;
