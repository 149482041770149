export const capitalizeFirstLetter = (word: string) => {
  const capitalized = word?.charAt(0).toUpperCase();
  const remainingLetters = word?.slice(1);
  return capitalized + remainingLetters;
};

export const uncapitalizeFirstLetter = (word: string) => {
  const capitalized = word?.charAt(0).toLowerCase();
  const remainingLetters = word?.slice(1);
  return capitalized + remainingLetters;
};

export const formattedValue = (value: any) => {
  const valueFormatted = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
  return valueFormatted;
};

export const handleExtraUsers = (maxUser: number, totalUser: number) => {
  if (maxUser && totalUser !== 0) return maxUser + totalUser;
};

export const handlePlanValueDivide = (
  value: number,
  installmentPlan: number,
) => {
  return value / installmentPlan;
};

export const handleDiscount = (totalCost: number, discount: number) => {
  return totalCost - discount || 0;
};

export const SumArrayOfNumbers = (numbersArray: Array<number>) => {
  let total = 0;
  if (numbersArray?.length > 0) {
    for (const number of numbersArray) {
      total += number;
    }
  }
  return total;
};

export const subtitleCapitalize = (text: any) => {
  const loweredText = text?.toLowerCase();
  const words = loweredText?.split(' ');
  for (let a = 0; a < words?.length; a++) {
    let w = words[a];

    const firstLetter = w[0];
    w = firstLetter.toUpperCase() + w.slice(1);

    words[a] = w;
  }
  return words?.join(' ');
};

export const trimString = (value: string, length: number) => {
  return value.length > length ? value.substring(0, length) + '...' : value;
};
