const Apps = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#4C4C4C"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.4923 1.3304L18.671 4.50917C19.5497 5.38785 19.5497 6.81247 18.671 7.69115L16.0866 10.2751C17.1696 10.4375 18 11.3718 18 12.5001V17.7501C18 18.9927 16.9926 20.0001 15.75 20.0001H2.25C1.00736 20.0001 0 18.9927 0 17.7501V4.25007C0 3.00743 1.00736 2.00007 2.25 2.00007H7.5C8.62894 2.00007 9.56369 2.83152 9.72529 3.91547L12.3103 1.3304C13.189 0.451717 14.6136 0.451717 15.4923 1.3304ZM1.5 17.7501C1.5 18.1643 1.83579 18.5001 2.25 18.5001L8.249 18.4994L8.25 11.7501L1.5 11.7494V17.7501ZM9.749 18.4994L15.75 18.5001C16.1642 18.5001 16.5 18.1643 16.5 17.7501V12.5001C16.5 12.0859 16.1642 11.7501 15.75 11.7501L9.749 11.7494V18.4994ZM7.5 3.50007H2.25C1.83579 3.50007 1.5 3.83585 1.5 4.25007V10.2494H8.25V4.25007C8.25 3.83585 7.91421 3.50007 7.5 3.50007ZM9.75 8.30939V10.2501L11.69 10.2494L9.75 8.30939ZM13.3709 2.39106L10.1922 5.56983C9.89927 5.86272 9.89927 6.33759 10.1922 6.63049L13.3709 9.80926C13.6638 10.1022 14.1387 10.1022 14.4316 9.80926L17.6104 6.63049C17.9033 6.33759 17.9033 5.86272 17.6104 5.56983L14.4316 2.39106C14.1387 2.09816 13.6638 2.09816 13.3709 2.39106Z" />
    </svg>
  );
};
export default Apps;
