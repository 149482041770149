import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 1.2rem;

    span {
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.neutral.neutral70};
    }
  `}
`;
