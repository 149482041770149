import IconLoading from 'assets/image/loadingSpinner.gif';
import { Wrapper } from './styles';

export default function LoadingSpinner() {
  return (
    <Wrapper>
      <img src={IconLoading} alt="loading" />
      <h1>Carregando...</h1>
    </Wrapper>
  );
}
