import styled, { css } from 'styled-components';

export const BaseMain = styled.div`
  ${({ theme }) => css`
    max-width: ${theme.grid.container};
    margin-left: auto;
    margin-right: auto;
    padding: 1.6rem 3.2rem;
  `}
`;
