import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  ${() => css`
    margin-bottom: 2.4rem;
  `}
`;

export const WrapperTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.6rem 0 2.4rem;
  line-height: 5rem;
`;

export const WrapperText = styled.p`
  ${() => css`
    color: red;
    font-size: 2rem;
  `}
`;

export const WrapperButton = styled.button`
  ${() => css`
    color: #f82e52;
    font-size: 2rem;
  `}
`;

export const WrapperBanners = styled.div`
  ${() => css`
    margin-bottom: 2.4rem;
  `}
`;

export const ElementWrapper = styled.div`
  ${() => css`
    margin-bottom: 1.6rem;

    > div + div {
      margin-top: 1.6rem;
    }
  `}
`;

export const LastInvoiceWrapper = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2.7rem;

    a {
      text-decoration: none;
    }
  `}
`;

export const contentPlansWrapper = styled.div`
  ${() => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    row-gap: 2rem;
  `}
`;
