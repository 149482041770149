import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background: ${theme.colors.neutral.neutral70};
    color: ${theme.colors.neutral.neutral0};
    position: relative;
  `}

  img {
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
  }
`;

export const Badge = styled.span`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -0.4rem;
    right: -0.4rem;
    background: ${theme.colors.primary50};
    border: 1px solid ${theme.colors.neutral.neutral0};
    border-radius: 50%;
    width: 1.6rem;
    height: 1.6rem;
    font-size: 8px;
  `}
`;

export const WrapperName = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    font-weight: 700;
  `}
`;
