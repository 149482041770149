const Screen = () => {
  return (
    <svg
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="#212121"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.08337 19C4.66916 19 4.33337 18.6642 4.33337 18.25C4.33337 17.8703 4.61553 17.5565 4.9816 17.5068L5.08337 17.5L6.83237 17.4995V15.0015L2.58337 15.0018C1.39251 15.0018 0.417729 14.0766 0.338565 12.9058L0.333374 12.7518V2.24951C0.333374 1.05865 1.25854 0.0838668 2.42933 0.00470257L2.58337 -0.000488281H18.0822C19.2731 -0.000488281 20.2479 0.924673 20.327 2.09546L20.3322 2.24951V12.7518C20.3322 13.9427 19.407 14.9174 18.2363 14.9966L18.0822 15.0018L13.8324 15.0015V17.4995L15.5834 17.5C15.9976 17.5 16.3334 17.8357 16.3334 18.25C16.3334 18.6296 16.0512 18.9434 15.6851 18.9931L15.5834 19H5.08337ZM12.3314 15.0015H8.33137L8.33237 17.5H12.3324L12.3314 15.0015ZM18.0822 1.49951H2.58337C2.20368 1.49951 1.88988 1.78167 1.84022 2.14774L1.83337 2.24951V12.7518C1.83337 13.1315 2.11553 13.4453 2.4816 13.4949L2.58337 13.5018H18.0822C18.4619 13.5018 18.7757 13.2196 18.8254 12.8536L18.8322 12.7518V2.24951C18.8322 1.86982 18.5501 1.55602 18.184 1.50636L18.0822 1.49951Z" />
    </svg>
  );
};
export default Screen;
