import { Heading } from 'components/Heading';
import moment from 'moment';
import { HistoryOfQualificationProps } from 'pages/QualifierDetails';
import * as S from './styles';

interface Props {
  element: HistoryOfQualificationProps;
  modal: React.ReactNode;
}

export const HistoryOfQualification = (qualificationHistory: Props) => {
  return (
    <S.Wrapper>
      {qualificationHistory ? (
        <>
          <Heading
            title={moment
              .parseZone(qualificationHistory?.element?.date)
              .utcOffset(0, true)
              .format('DD/MM/YYYY')}
            size="medium"
            weight="600"
          />
          <Heading
            title={
              qualificationHistory?.element?.transaction === 'debit'
                ? 'Qualificações Diárias'
                : qualificationHistory?.element?.qualififer_package?.name ===
                  'Franquia Boosted'
                ? 'Pacote gratuito'
                : 'Pacote ' +
                  qualificationHistory?.element?.qualififer_package?.name
            }
            size="medium"
            weight="600"
          />
          <Heading
            title={
              qualificationHistory?.element?.transaction === 'credit'
                ? `+ ${qualificationHistory?.element?.value}`
                : Number(qualificationHistory?.element?.value) < 0
                ? Number(qualificationHistory?.element?.value) * -1
                : Number(qualificationHistory?.element?.value)
            }
            size="medium"
            weight="700"
            color={
              qualificationHistory?.element?.transaction === 'credit'
                ? '#239867'
                : '#242424'
            }
          />
          {qualificationHistory.modal}
        </>
      ) : (
        <p>Loading...</p>
      )}
    </S.Wrapper>
  );
};
