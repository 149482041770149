const Home = () => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="#242424"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.2162 0.532139C9.05411 -0.174451 10.2793 -0.17445 11.1172 0.53214L17.8672 6.22425C18.3741 6.65176 18.6667 7.28115 18.6667 7.94431V17.7471C18.6667 18.7136 17.8832 19.4971 16.9167 19.4971H13.4167C12.4502 19.4971 11.6667 18.7136 11.6667 17.7471V12.2471C11.6667 12.109 11.5548 11.9971 11.4167 11.9971H7.91668C7.77861 11.9971 7.66668 12.109 7.66668 12.2471V17.7471C7.66668 18.7136 6.88318 19.4971 5.91668 19.4971H2.41669C1.45019 19.4971 0.666687 18.7136 0.666687 17.7471V7.94431C0.666687 7.28115 0.959238 6.65176 1.4662 6.22425L8.2162 0.532139ZM10.1502 1.67884C9.87088 1.44331 9.4625 1.44331 9.18319 1.67884L2.43319 7.37095C2.2642 7.51346 2.16669 7.72325 2.16669 7.94431V17.7471C2.16669 17.8851 2.27862 17.9971 2.41669 17.9971H5.91668C6.05475 17.9971 6.16668 17.8851 6.16668 17.7471V12.2471C6.16668 11.2806 6.95018 10.4971 7.91668 10.4971H11.4167C12.3832 10.4971 13.1667 11.2806 13.1667 12.2471V17.7471C13.1667 17.8851 13.2786 17.9971 13.4167 17.9971H16.9167C17.0548 17.9971 17.1667 17.8851 17.1667 17.7471V7.94431C17.1667 7.72325 17.0692 7.51346 16.9002 7.37095L10.1502 1.67884Z" />
    </svg>
  );
};
export default Home;
