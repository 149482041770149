import styled, { css } from 'styled-components';
import { FilterButtonProps } from '.';

type WrapperProps = {
  size?: string;
} & Pick<FilterButtonProps, 'size'>;

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, size }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${theme.colors.neutral.neutral0};
    border-radius: 8rem;
    border: 1px solid ${theme.colors.neutral.neutral30};
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.neutral.neutral70};
    height: 4rem;
    width: ${size};
    padding: 0.8rem 1.6rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    letter-spacing: -0.4px;

    svg {
      margin-left: 1.4rem;
    }
  `}
`;
