const CreditCard = () => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="#4C4C4C"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.75 9.5C13.3358 9.5 13 9.83579 13 10.25C13 10.6642 13.3358 11 13.75 11H16.25C16.6642 11 17 10.6642 17 10.25C17 9.83579 16.6642 9.5 16.25 9.5H13.75ZM2.75 0C1.23122 0 0 1.23122 0 2.75V11.25C0 12.7688 1.23122 14 2.75 14H17.25C18.7688 14 20 12.7688 20 11.25V2.75C20 1.23122 18.7688 0 17.25 0H2.75ZM1.5 11.25V6H18.5V11.25C18.5 11.9404 17.9404 12.5 17.25 12.5H2.75C2.05964 12.5 1.5 11.9404 1.5 11.25ZM1.5 4.5V2.75C1.5 2.05964 2.05964 1.5 2.75 1.5H17.25C17.9404 1.5 18.5 2.05964 18.5 2.75V4.5H1.5Z" />
    </svg>
  );
};
export default CreditCard;
