import styled, { css } from 'styled-components';
import { IBoxContent } from '.';

export type WrapperProps = Pick<IBoxContent, 'color' | 'background'>;

export const BoxContent = styled.div<WrapperProps>`
  ${({ theme, color, background }) => css`
    border-radius: ${theme.border.radius};
    border: 1px solid ${color};
    background: ${background};
    width: 100%;
    margin-bottom: 2rem;
    display?: {style};
    justify-content: space-between;
  `}
`;
