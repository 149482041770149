import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 9.6rem 24rem 19rem max-content;
    gap: 9.3rem;
    padding: 1.6rem 0 1.6rem 0;
    border-bottom: 1px solid ${theme.colors.neutral.neutral5};

    p {
      font-size: ${theme.font.sizes.medium};
    }
  `}
`;

export const Span = styled.span<{ color: string }>`
  ${({ theme, color }) => css`
    color: ${color};
    font-size: ${theme.font.sizes.medium};
  `}
`;
