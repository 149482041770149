// import Button from 'components/Button';
// import { Link } from 'react-router-dom';
import * as S from './styles';

type BannerProps = {
  icon: React.ReactNode;
  text: string;
  button?: React.ReactNode;
  id?: string;
  typeProduct?: string;
  href?: string;
  as?: string;
  target?: string;
  children?: React.ReactNode;
};

export const Banner = ({
  // id,
  icon,
  text,
  // button = false,
  // typeProduct,
  children,
}: BannerProps) => {
  return (
    <S.Wrapper>
      {icon}
      <p>{text}</p>
      {/* {!!button && typeProduct === 'imob' && (
        <Link to={`ChangeOfPlan/${id}`}>
          <Button>Renovar plano</Button>{' '}
        </Link>
      )} */}
      {/* {!!button && typeProduct === 'locacao' && (
        <Link to={`RenewPlan/${id}`}>
          <Button>Renovar plano</Button>{' '}
        </Link>
      )} */}
      <a>{children}</a>
    </S.Wrapper>
  );
};
