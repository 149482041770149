import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.4rem;
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};
    color: ${theme.colors.neutral.neutral70};
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.4px;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    position: relative;
    top: 3px;
    z-index: 0;
    & > .anual,
    .mensal {
      position: absolute;
      z-index: 1;
      pointer-events: none;
    }
    & > .mensal,
    .anual {
      left: calc(50% - 65px);
      top: 8px;
    }
    & > .anual {
      left: 96px;
    }
    & > .active {
      color: ${theme.colors.neutral.neutral0};
      transition: all 0.5s ease;
    }
  `}
`;

export const SpanTitle = styled.span`
  ${({ theme }) => css`
    position: absolute;
    z-index: 1;
    pointer-events: none;
    color: ${theme.colors.neutral.neutral70};
    font-size: ${theme.font.sizes.medium};
    font-weight: 500;
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    position: relative;
    display: inline-block;
    width: 16.6rem;
    height: 4rem;
    span {
      position: absolute;
      cursor: pointer;
      border-radius: 25px;
      border: 1px solid ${theme.colors.neutral.neutral30};
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: background-color 0.2s ease;
    }
    span::before {
      position: absolute;
      content: '';
      left: 4px;
      top: 4px;
      width: 8.4rem;
      height: 30px;
      background: ${theme.colors.primary50};
      border-radius: 20px;
      transition: transform 0.3s ease;
    }
    & > input[type='checkbox']:checked + .slider::before {
      transform: translateX(70px);
      background: ${theme.colors.primary50};
    }
    input[type='checkbox'] {
      display: none;
    }
  `}
`;
