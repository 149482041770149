import styled, { css } from 'styled-components';
import { LoadingProps } from '.';

type WrapperProps = Pick<LoadingProps, 'background'>;

export const Wrapper = styled.div<WrapperProps>`
  ${({ background }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    left: 0;
    top: 5.6rem;
    bottom: 0;
    background: ${background};
    position: fixed;
    z-index: 999999999;
    height: 100vh;
    img {
      width: 80px;
      position: absolute;
    }
  `}
`;
