import React from 'react';
import styled, { css } from 'styled-components';

export const Wrapper: React.FC = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.feedback.feedbackAlert10};
    border-radius: ${theme.border.radius};
    padding: 2.4rem 3.2rem;
    display: grid;
    grid-template-columns: min-content auto auto;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    text-size-adjust: 100%;

    svg {
      margin-right: 23px;
    }

    a {
      color: ${theme.colors.primary50};
      text-decoration: none;
    }
    > p {
      word-break: break-all;
      width: auto;
      text-size-adjust: none;
      grid-column: 2 / 3;
      margin: 0;

      @media (max-width: ${theme.breakpoints.sm}) {
        font-size: 14px;
        grid-column: 1 / 4;
      }
    }
  `}
`;
