const DesktopPulse = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.25 22C6.83579 22 6.5 21.6642 6.5 21.25C6.5 20.8703 6.78215 20.5565 7.14823 20.5068L7.25 20.5L8.999 20.4995V18.0015L4.75 18.0018C3.55914 18.0018 2.58436 17.0766 2.50519 15.9058L2.5 15.7518V13H4V15.7518C4 16.1315 4.28215 16.4453 4.64823 16.4949L4.75 16.5018H20.2488C20.6285 16.5018 20.9423 16.2196 20.992 15.8536L20.9988 15.7518V13H22.4988V15.7518C22.4988 16.9427 21.5737 17.9174 20.4029 17.9966L20.2488 18.0018L15.999 18.0015V20.4995L17.75 20.5C18.1642 20.5 18.5 20.8357 18.5 21.25C18.5 21.6296 18.2178 21.9434 17.8518 21.9931L17.75 22H7.25ZM14.498 18.0015H10.498L10.499 20.5H14.499L14.498 18.0015ZM4 9.5H2.5V5.24951C2.5 4.05865 3.42516 3.08387 4.59595 3.0047L4.75 2.99951H20.2488C21.4397 2.99951 22.4145 3.92467 22.4936 5.09546L22.4988 5.24951V9.5H20.9988V5.24951C20.9988 4.86982 20.7167 4.55602 20.3506 4.50636L20.2488 4.49951H4.75C4.3703 4.49951 4.05651 4.78167 4.00685 5.14774L4 5.24951V9.5ZM10.1964 6.47137C10.0846 6.19188 9.8165 6.00631 9.51555 6.00007C9.21459 5.99383 8.93907 6.16813 8.81578 6.44275L6.99438 10.5H3.25C2.83579 10.5 2.5 10.8358 2.5 11.25C2.5 11.6642 2.83579 12 3.25 12H7.47979C7.77517 12 8.04304 11.8266 8.16401 11.5572L9.4602 8.66986L11.8036 14.5285C11.9098 14.7939 12.1576 14.9759 12.4426 14.9978C12.7276 15.0197 13.0003 14.8776 13.1457 14.6315L15.8033 10.134L16.8856 11.6801C17.0259 11.8806 17.2553 12 17.5 12H21.75C22.1642 12 22.5 11.6642 22.5 11.25C22.5 10.8358 22.1642 10.5 21.75 10.5H17.8905L16.3644 8.31982C16.2182 8.11094 15.9759 7.99065 15.7211 8.00047C15.4664 8.01028 15.234 8.14886 15.1043 8.36837L12.63 12.5556L10.1964 6.47137Z"
        fill="#404040"
      />
    </svg>
  );
};
export default DesktopPulse;
