import { createContext, useState } from 'react';

type ChildrenProps = {
  children: React.ReactNode;
};

type dataBannerProps = {
  id_billing: string;
  link2via: string;
};

type NotificationBannerContextType = {
  dataBanner?: dataBannerProps | null;
  setDataBanner: (el?: dataBannerProps) => void;
};

export const NotificationBannerContext = createContext(
  {} as NotificationBannerContextType,
);

export const NotificationBannerProvider = ({ children }: ChildrenProps) => {
  const [dataBanner, setDataBanner] = useState<
    dataBannerProps | null | undefined
  >(null);

  return (
    <NotificationBannerContext.Provider value={{ dataBanner, setDataBanner }}>
      {children}
    </NotificationBannerContext.Provider>
  );
};
