import { Dot } from 'components/Icons';
import * as S from './styles';

export type HeadingProps = {
  title: string | React.ReactNode;
  size?: 'medium' | 'large' | 'xlarge' | 'xxlarge';
  subtitle?: string;
  subtitleSize?: 'small' | 'medium';
  detailInfoRight?: string;
  detailInfoLeft?: string;
  children?: string | React.ReactNode;
  weight?: '500' | '600' | '700';
  color?: string;
};

export const Heading = ({
  title,
  size = 'xlarge',
  subtitle,
  subtitleSize,
  detailInfoRight,
  detailInfoLeft,
  children,
  weight,
  color = '#242424',
}: HeadingProps) => {
  return (
    <S.Wrapper>
      <S.Title
        size={size}
        subtitle={subtitle}
        subtitleSize={subtitleSize}
        detailInfoLeft={detailInfoLeft}
        detailInfoRight={detailInfoRight}
        weight={weight}
        color={color}
      >
        {title}
      </S.Title>
      {!!subtitle && (
        <S.Subtitle subtitleSize={subtitleSize} weight={weight}>
          {subtitle}
        </S.Subtitle>
      )}
      {!!detailInfoLeft && (
        <S.InfoDetails>
          <span>{detailInfoLeft}</span>
          {!detailInfoRight ? '' : <Dot />}

          <span>{detailInfoRight}</span>
        </S.InfoDetails>
      )}

      {children}
    </S.Wrapper>
  );
};
