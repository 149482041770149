const DecreaseDisabled = () => {
  return (
    <svg
      width="19"
      height="2"
      viewBox="0 0 19 2"
      fill="#808080"
      stroke="#808080"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18 1.5H1.99649C1.72035 1.5 1.49649 1.27614 1.49649 1C1.49649 0.723858 1.72035 0.5 1.99649 0.5H18C18.2761 0.5 18.5 0.723858 18.5 1C18.5 1.27614 18.2761 1.5 18 1.5Z" />
    </svg>
  );
};
export default DecreaseDisabled;
