import * as S from './styles';

type BoxMessageProps = {
  icon?: React.ReactNode;
  text: string | undefined;
};

export const BoxMessage = ({ icon, text }: BoxMessageProps) => {
  return (
    <S.WrapperMessage>
      {icon}
      <S.TextMessage>{text}</S.TextMessage>
    </S.WrapperMessage>
  );
};
