import { Decrease, DecreaseDisabled, Increase } from 'components/Icons';
import * as S from './styles';
import { InputEdit } from './InputEdit';
import { useEffect, useState } from 'react';
//import { useState } from 'react';
//import { useState } from 'react';

export type CounterButtonProps = {
  disabled?: boolean;
  title?: string;
  count: number;
  width?: string;
  decCount: () => void;
  inCount: () => void;
  setCount?: (value: any) => void;
  handleSetCount: (e: any) => void;
};

export const CounterButton = ({
  disabled = false,
  count,
  setCount,
  decCount,
  handleSetCount,
  inCount,
  title,
  width,
}: CounterButtonProps) => {
  const [minimumAmountOfLeads] = useState(count);

  useEffect(() => {
    if (Number(count) <= minimumAmountOfLeads || isNaN(count)) {
      setCount?.(minimumAmountOfLeads);
    }
  }, [count, setCount, minimumAmountOfLeads]);

  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      <S.WrapperButton width={width}>
        <button
          onClick={decCount}
          disabled={count === minimumAmountOfLeads && disabled}
        >
          {disabled === true ? <DecreaseDisabled /> : <Decrease />}
        </button>
        <InputEdit
          value={count}
          onChange={(e) => setCount?.(e)}
          inputValue={count}
          decCount={decCount}
          inCount={inCount}
          count={count}
          handleSetCount={handleSetCount}
        />
        {/* <span>{count}</span> */}

        <button onClick={inCount}>{<Increase />}</button>
      </S.WrapperButton>
    </S.Container>
  );
};
