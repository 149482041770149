const ArrowUp = () => {
  return (
    <svg
      width="16"
      height="9"
      viewBox="0 0 16 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.292893 8.70711C0.683417 9.09763 1.31658 9.09763 1.70711 8.70711L8 2.41421L14.2929 8.70711C14.6834 9.09763 15.3166 9.09763 15.7071 8.70711C16.0976 8.31658 16.0976 7.68342 15.7071 7.29289L8.70711 0.292894C8.31658 -0.0976312 7.68342 -0.0976312 7.29289 0.292894L0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711Z"
        fill="#1A1A1A"
      />
    </svg>
  );
};
export default ArrowUp;
