import { formattedValue } from 'helpers/util';
import { theme } from 'styles/theme';
import * as S from './styles';

export type PriceProps = {
  price?: string;
  installments: number;
  value?: string;
  final_value?: string;
  estimated_duration: number | string;
  valueForQualification?: string;
  amount_installment?: string | undefined;
};

export const Price = ({
  installments,
  final_value,
  value,
  amount_installment,
}: PriceProps) => {
  const getValueInstallment = () => {
    if (amount_installment === '1') {
      return Number(final_value) / installments;
    }
    if (value && installments) {
      return Number(value) / installments;
    }
  };

  return (
    <S.Wrapper>
      <S.TextCustomized
        fontWeight={700}
        color={theme.colors.neutral.neutral120}
        lineHeight={'32px'}
        fontSize="2.4rem"
      >
        {formattedValue(final_value)}
      </S.TextCustomized>
      <S.Installments>
        <S.TextCustomized
          fontWeight={500}
          color={theme.colors.neutral.neutral130}
          lineHeight={'16px'}
          fontSize="1.2rem"
        >
          Ou até &nbsp;
        </S.TextCustomized>
        <S.TextCustomized
          fontWeight={700}
          color={theme.colors.neutral.neutral40}
          lineHeight={'16px'}
          fontSize="1.2rem"
        >
          {installments}x &nbsp;
        </S.TextCustomized>
        <S.TextCustomized
          fontWeight={500}
          color={theme.colors.neutral.neutral130}
          lineHeight={'16px'}
          fontSize="1.2rem"
        >
          de &nbsp;
        </S.TextCustomized>
        <S.TextCustomized
          fontWeight={700}
          color={theme.colors.neutral.neutral40}
          lineHeight={'16px'}
          fontSize="1.2rem"
        >
          {formattedValue(getValueInstallment())}
        </S.TextCustomized>
      </S.Installments>
    </S.Wrapper>
  );
};
